import * as tslib_1 from "tslib";
var _a;
import { DeviceActionTypes } from './device.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export var adapter = createEntityAdapter({
    selectId: function (item) { return item.id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DeviceActionTypes.AddDevice:
        case DeviceActionTypes.UpdateDeviceGroupConf:
        case DeviceActionTypes.UpdateDeviceCommunicator:
        case DeviceActionTypes.RenameDevice:
        case DeviceActionTypes.UpdateTimezoneDevice:
        case DeviceActionTypes.HideDevice:
        case DeviceActionTypes.DeleteDevice:
            return tslib_1.__assign({}, state, { pending: true, errors: null });
        case DeviceActionTypes.AddDeviceFail:
        case DeviceActionTypes.UpdateDeviceGroupConfFail:
        case DeviceActionTypes.UpdateDeviceCommunicatorFail:
        case DeviceActionTypes.RenameDeviceFail:
        case DeviceActionTypes.UpdateTimezoneDeviceFail:
        case DeviceActionTypes.HideDeviceFail:
        case DeviceActionTypes.DeleteDeviceFail:
            return tslib_1.__assign({}, state, { pending: false, errors: action.payload.asyncMessages });
        case DeviceActionTypes.AddDeviceSuccess:
        case DeviceActionTypes.UpdateDeviceGroupConfSuccess:
        case DeviceActionTypes.UpdateDeviceCommunicatorSuccess:
        case DeviceActionTypes.RenameDeviceSuccess:
        case DeviceActionTypes.UpdateTimezoneDeviceSuccess:
        case DeviceActionTypes.HideDeviceSuccess:
        case DeviceActionTypes.DeleteDeviceSuccess:
            return tslib_1.__assign({}, state, { pending: false });
        case DeviceActionTypes.LoadDevice:
            return tslib_1.__assign({}, state, { 
                // selectedID: null,
                pending: true, loaded: false, errors: null });
        case DeviceActionTypes.LoadDeviceSuccess:
            return adapter.upsertOne(tslib_1.__assign({}, action.payload.item), tslib_1.__assign({}, state, { pending: false, loaded: true }));
        case DeviceActionTypes.LoadDeviceFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceActionTypes.SelectDevice:
            return tslib_1.__assign({}, state, { selectedID: action.payload });
        case DeviceActionTypes.LoadDevices:
            if (!action.payload.offset) {
                return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: true, loaded: false, errors: null }));
            }
            return tslib_1.__assign({}, state, { pending: true, loaded: false, errors: null });
        case DeviceActionTypes.LoadDevicesSuccess:
            return adapter.upsertMany(action.payload.items, tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true }));
        case DeviceActionTypes.LoadDevicesFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceActionTypes.ClearDevices:
            return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: false, loaded: true }));
        default:
            return state;
    }
}
export var getSelectedID = function (state) { return state.selectedID; };
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
export var getErrors = function (state) { return state.errors; };
export var getDeviceState = createFeatureSelector('device');
export var selectDeviceSelectedID = createSelector(getDeviceState, getSelectedID);
export var selectDeviceTotalCount = createSelector(getDeviceState, getTotalCount);
export var selectDeviceLoaded = createSelector(getDeviceState, getLoaded);
export var selectDevicePending = createSelector(getDeviceState, getPending);
export var selectDeviceErrors = createSelector(getDeviceState, getErrors);
export var selectDeviceEntities = (_a = adapter.getSelectors(getDeviceState), _a.selectEntities), selectDeviceAll = _a.selectAll;
export var selectUnhiddenDevices = createSelector(selectDeviceAll, function (devices) { return devices.filter(function (d) { return !d.is_hidden; }); });
export var selectDevice = createSelector(selectDeviceEntities, selectDeviceSelectedID, function (entities, id) { return entities && entities[id] ? entities[id] : null; });

import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLObject } from '@app/object/models/object';
import { IWSLObjectFilter } from '@app/object/models/object-filter';

export enum ObjectActionTypes {
  SelectObject = '[Object] Select',

  LoadObject = '[Object] Load',
  LoadObjectSuccess = '[Object] Load Success',
  LoadObjectFail = '[Object] Load Fail',

  UpdateObject = '[Object] Update',
  UpdateObjectSuccess = '[Object] Update Success',
  UpdateObjectFail = '[Object] Update Fail',

  LoadObjects = '[Object] Load Many',
  LoadObjectsSuccess = '[Object] Load Many Success',
  LoadObjectsFail = '[Object] Load Many Fail',

  ClearObjects = '[Object] Clear',

  GenerateActivationCodeForObject = '[Object] Generate Activation Code',
  GenerateActivationCodeForObjectSuccess = '[Object] Generate Activation Code Success',
  GenerateActivationCodeForObjectFail = '[Object] Generate Activation Code Fail'
}

/** Select */
export class SelectObject implements Action {
  readonly type = ObjectActionTypes.SelectObject;

  constructor(public payload: number) {
  }
}

/** Load One */
export class LoadObject implements Action {
  readonly type = ObjectActionTypes.LoadObject;

  constructor(public payload: number) {
  }
}

export class LoadObjectSuccess implements Action {
  readonly type = ObjectActionTypes.LoadObjectSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLObject>) {
  }
}

export class LoadObjectFail implements Action {
  readonly type = ObjectActionTypes.LoadObjectFail;

  constructor(public payload: IWSLHttpResponse<IWSLObject>) {
  }
}

/** Update One */
export class UpdateObject implements Action {
  readonly type = ObjectActionTypes.UpdateObject;

  constructor(public payload: IWSLObject) {
  }
}

export class UpdateObjectSuccess implements Action {
  readonly type = ObjectActionTypes.UpdateObjectSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLObject>) {
  }
}

export class UpdateObjectFail implements Action {
  readonly type = ObjectActionTypes.UpdateObjectFail;

  constructor(public payload: IWSLHttpResponse<IWSLObject>) {
  }
}

/** Load Many */
export class LoadObjects implements Action {
  readonly type = ObjectActionTypes.LoadObjects;

  constructor(public payload: IWSLObjectFilter) {
  }
}

export class LoadObjectsSuccess implements Action {
  readonly type = ObjectActionTypes.LoadObjectsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLObject>) {
  }
}

export class LoadObjectsFail implements Action {
  readonly type = ObjectActionTypes.LoadObjectsFail;

  constructor(public payload: IWSLHttpResponse<IWSLObject>) {
  }
}

export class ClearObjects implements Action {
  readonly type = ObjectActionTypes.ClearObjects;
}


/** Generate Activation Code */
export class GenerateActivationCodeForObject implements Action {
  readonly type = ObjectActionTypes.GenerateActivationCodeForObject;

  constructor(public payload: number) {
  }
}

export class GenerateActivationCodeForObjectSuccess implements Action {
  readonly type = ObjectActionTypes.GenerateActivationCodeForObjectSuccess;
}

export class GenerateActivationCodeForObjectFail implements Action {
  readonly type = ObjectActionTypes.GenerateActivationCodeForObjectFail;
}

export type ObjectActions =
  SelectObject |

  LoadObject |
  LoadObjectSuccess |
  LoadObjectFail |

  UpdateObject |
  UpdateObjectSuccess |
  UpdateObjectFail |

  LoadObjects |
  LoadObjectsSuccess |
  LoadObjectsFail |

  ClearObjects |

  GenerateActivationCodeForObject |
  GenerateActivationCodeForObjectSuccess |
  GenerateActivationCodeForObjectFail;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format, isSameHour } from 'date-fns';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DBDateTimeFormat, IWSLHttpResponse, WSLHttpHelper, WSLLocalStorageService } from 'wsl-core';
import {
  ApiHttpClientWithPermissionsService,
  IWSLEntityPermissions,
  ObjectPermissions,
  StorageKey,
  UserProfileService
} from 'wsl-ek-core';
import { IWSLObject } from '../models/object';
import { IWSLObjectFilter } from '../models/object-filter';

@Injectable()
export class ObjectService extends ApiHttpClientWithPermissionsService<IWSLObject, IWSLObjectFilter> {

  static sort(a, b) {
    if (a.name === b.name) {
      return 0;
    }
    if (Number.isNaN(+a.name) && Number.isNaN(+b.name)) {
      return a.name < b.name ? -1 : 1;
    } else if (Number.isNaN(+a.name)) {
      return 1;
    } else if (Number.isNaN(+b.name)) {
      return -1;
    }
    return +a.name < +b.name ? -1 : 1;
  }

  constructor(public http: HttpClient, private localStorage: WSLLocalStorageService, public userProfileService: UserProfileService) {
    super(http, 'object', <IWSLEntityPermissions>ObjectPermissions, userProfileService);
  }

  public generateActivationCode(object_id: number) {
    return this.http.get<IWSLHttpResponse<any>>(
      'generate_activation_code',
      {params: WSLHttpHelper.getParams({object_id})});
  }

  getObjectTypes() {
    const dict = this.localStorage.get(StorageKey.object_types);
    if (dict != null && isSameHour(new Date(), new Date(dict.time))) {
      return of(dict);
    }
    return this.http.get<IWSLHttpResponse<IWSLObject>>('getObjectType')
      .pipe(map(data => {
        this.localStorage.set(StorageKey.object_types, {
          time: format(new Date(), DBDateTimeFormat),
          items: data.items
        });
        return data;
      }));
  }

  update(obj: IWSLObject): Observable<IWSLHttpResponse<IWSLObject>> {
    return this.http.put<IWSLHttpResponse<IWSLObject>>(`object_living_info/${obj.id}`, obj);
  }

  public getMeteoStationSuggestionByCoordinate(lat: number, lon: number) {
    return this.http.get<any>('meteoStationSuggestion', {
      params: WSLHttpHelper.getParams({
        lat,
        lon
      })
    });
  }

  public getMeteoStationSuggestionByObject(object_id: number) {
    return this.http.get<any>('meteoStationSuggestion', {params: WSLHttpHelper.getParams({object_id})});
  }
}

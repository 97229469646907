import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PushNotificationService = /** @class */ (function () {
    function PushNotificationService(http) {
        this.http = http;
        this.permission = this.isSupported() ? 'default' : 'denied';
    }
    PushNotificationService.prototype.addPushSubscriber = function (sub) {
        return this.http.post('api_subscribe/notifications', sub);
    };
    PushNotificationService.prototype.getNews = function () {
        return this.http.post('api_subscribe/newsletter', null);
    };
    PushNotificationService.prototype.isSupported = function () {
        return 'Notification' in window;
    };
    PushNotificationService.prototype.requestPermission = function () {
        var self = this;
        if ('Notification' in window) {
            Notification.requestPermission(function (status) {
                return self.permission = status;
            });
        }
    };
    PushNotificationService.prototype.create = function (title, options) {
        var self = this;
        return new Observable(function (obs) {
            if (!('Notification' in window)) {
                console.log('Notifications are not available in this environment');
                obs.complete();
            }
            if (self.permission !== 'granted') {
                console.log('The user hasn\'t granted you permission to send push notifications');
                obs.complete();
            }
            var _notify = new Notification(title, options);
            _notify.onshow = function (e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclick = function (e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onerror = function (e) {
                return obs.error({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclose = function () {
                return obs.complete();
            };
        });
    };
    PushNotificationService.prototype.generateNotification = function (source) {
        var self = this;
        source.forEach(function (item) {
            var options = {
                body: item.alertContent,
                icon: '../resource/images/bell-icon.png'
            };
            var notify = self.create(item.title, options).subscribe();
        });
    };
    PushNotificationService.ngInjectableDef = i0.defineInjectable({ factory: function PushNotificationService_Factory() { return new PushNotificationService(i0.inject(i1.HttpClient)); }, token: PushNotificationService, providedIn: "root" });
    return PushNotificationService;
}());
export { PushNotificationService };

import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DeviceDataService, IWSLDeviceData, IWSLDeviceDataFilter} from 'wsl-device';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {DBDateTimeFormat, MomentDBDateFormat, WSLRouterHelperService, WSLUtils} from 'wsl-core';
import {IWSLResourceUnit, IWSLResourceValue, ResourceType, ResourceValueExtType, ResourceVariableType} from 'wsl-ek-core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'wsl-elect-device-tariffs-data-chart',
  templateUrl: './elect-device-tariffs-data-chart.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElectDeviceTariffsDataChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() id = 'elect_tariff_chart_data';
  @Input() data: IWSLDeviceData[];
  @Input() filter: IWSLDeviceDataFilter;
  @Input() showTable = true;

  chart: AmChart;
  showChart = false;

  private rvalues: IWSLResourceValue[] = [];
  private runits: IWSLResourceUnit[] = [];
  periods = [];
  tableData = [];

  tariffRvalues = {
    t1: null,
    t2: null,
    t3: null,
    t4: null
  };

  constructor(private route: ActivatedRoute,
              private AmCharts: AmChartsService) { }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.rvalues = data.resourceValues;
    this.runits = data.resourceUnits;

    this.tariffRvalues = {
      t1: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.tariff === 1),
      t2: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.tariff === 2),
      t3: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.tariff === 3),
      t4: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.tariff === 4)
    };
  }

  ngAfterViewInit() {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.updateChart();
    }
  }

  ngOnDestroy() {
    if (this.chart) {
      try {
        this.AmCharts.destroyChart(this.chart);
      } catch (e) {

      }
      this.chart = null;
    }
  }

  private updateChart() {
    if (this.chart) {
      try {
        this.AmCharts.destroyChart(this.chart); // clear
      } catch (e) {
      }
      this.chart = null;
    }
    const conf = this.getChartConfig();
    if (conf && conf.dataProvider && conf.dataProvider.length > 0) {
      setTimeout(() => {
        this.chart = this.AmCharts.makeChart(this.id, conf);
        this.chart.validateNow();
      }, 100);

    }
  }


  private getChartConfig() {
    const periodFormat = DeviceDataService.defineChartPeriodFormat(this.filter);
    const separators = WSLUtils.getNumberSeparators();
    const data = this.makeDataProvider();
    const graphs = [];
    if (data.some(d => d.tariff1 !== null)) {
      graphs.push({
        'id': 'g1',
        'fillAlphas': 0.5,
        // 'labelText': '[[percents]] %',
        'balloonText': '[[value]] кВт.ч [[percents]] %',
        'lineAlpha': 0.3,
        'title': 'Тариф 1',
        'type': 'column',
        // "fillColors": "#2a9cc6",
        'valueField': 'tariff1'
      });
    }
    if (data.some(d => d.tariff2 !== null)) {
      graphs.push({
        'id': 'g2',
        'fillAlphas': 0.5,
        // 'labelText': '[[percents]] %',
        'balloonText': '[[value]] кВт.ч [[percents]] %',
        'lineAlpha': 0.3,
        'title': 'Тариф 2',
        'type': 'column',
        // "fillColors": "#3aa640",
        'valueField': 'tariff2'
      });
    }
    if (data.some(d => d.tariff3 !== null)) {
      graphs.push({
        'id': 'g3',
        'fillAlphas': 0.5,
        // 'labelText': '[[percents]] %',
        'balloonText': '[[value]] кВт.ч [[percents]] %',
        'lineAlpha': 0.3,
        'title': 'Тариф 3',
        'type': 'column',
        // "fillColors": "#f09c50",
        'valueField': 'tariff3'
      });
    }
    if (data.some(d => d.tariff4 !== null)) {
      graphs.push( {
        'id': 'g4',
        'fillAlphas': 0.5,
        // 'labelText': '[[percents]] %',
        'balloonText': '[[value]] кВт.ч [[percents]] %',
        'lineAlpha': 0.3,
        'title': 'Тариф 4',
        'type': 'column',
        // "fillColors": "#555",
        'valueField': 'tariff4'
      });
    }
    return {
      'language': 'ru',
      // 'path': 'assets/amcharts',
      'type': 'serial',
      'theme': 'light',
      'addClassNames': true,
      'percentPrecision': 0,
      'dataDateFormat': DBDateTimeFormat,
      'decimalSeparator': separators.decimal,
      'thousandsSeparator': separators.thousand,
      'dataProvider': data,
      'valueAxes': [
        {
          'stackType': 'regular',
          'axisAlpha': 0.3,
          'gridAlpha': 0.02,
          // 'totalText': '[[total]]',
          'title': 'Потребление, кВт.ч'
        }
      ],
      'graphs': graphs,
      'categoryField': 'date',
      'categoryAxis': {
        'gridPosition': 'start',
        // "axisAlpha": 0,
        'gridAlpha': 0,
        'minPeriod': periodFormat.minPeriod,
        'parseDates': true,
        'position': 'left'
      },
      'legend': {
        'horizontalGap': 10,
        // "maxColumns": 1,
        // "position": "right",
        'useGraphSettings': true,
        // "markerSize": 10,
      },
    };
  }

  private makeDataProvider() {
    let data = [];
    if (this.data && this.data.length > 0) {


      const periods = DeviceDataService.definePeriods(this.filter);

      data = periods.map(period => {
        const date = period.datetime; // .split(' ')[0];
        const values = this.data.find(ds => ds.datetime === date) ? this.data.find(ds => ds.datetime === date) : null;
        if (values) {
          const t1 = this.tariffRvalues.t1 && values.arch_data.find(ar => ar.rvalue_id === this.tariffRvalues.t1.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.tariffRvalues.t1.id).value : null;
          const t2 = this.tariffRvalues.t2 && values.arch_data.find(ar => ar.rvalue_id === this.tariffRvalues.t2.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.tariffRvalues.t2.id).value : null;
          const t3 = this.tariffRvalues.t3 && values.arch_data.find(ar => ar.rvalue_id === this.tariffRvalues.t3.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.tariffRvalues.t3.id).value : null;
          const t4 = this.tariffRvalues.t4 && values.arch_data.find(ar => ar.rvalue_id === this.tariffRvalues.t4.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.tariffRvalues.t4.id).value : null;

          return {
            date: moment(period.datetime, MomentDBDateFormat).toDate(), // period.value,
            tariff1: t1, // !== null ? t1.toFixed(3) : null,
            tariff2: t2, // !== null ? t2.toFixed(3) : null,
            tariff3: t3, // !== null ? t3.toFixed(3) : null,
            tariff4: t4, // !== null ? t4.toFixed(3) : null
          };
        } else {
          return {
            date: moment(period.datetime, MomentDBDateFormat).toDate(), // period.value,
            tariff1: null,
            tariff2: null,
            tariff3: null,
            tariff4: null
          };
        }
      });
    }
    this.showChart = data.length > 0;
    this.tableData = [];
    if (this.showTable && this.showChart) {
      this.defineTableData();
    }
    return data;
  }

  private defineTableData() {
    this.periods = DeviceDataService.definePeriods(this.filter);
    this.tableData = [];
    if (this.data.length > 0) {
      this.tableData = DeviceDataService.fillDataForRValues(
        // this.device,
        this.data,
        [],
        this.periods,
        this.rvalues.filter(rv => rv.resource_id === this.filter.resource_id &&
          (this.filter.device_id || rv.ext && (rv.ext.ipu.includes(ResourceValueExtType.chart) ||
            rv.ext.ipu.includes(ResourceValueExtType.table)/* &&
            (this.screenSize !== WSLScreenSize.xs ||
              (this.screenSize === WSLScreenSize.xs && rv.ext.ipu.includes(ResourceValueExtType.report)))*/
          ))
        ),
        this.runits
      );
    }
    // peityBar('.bar');
    // peityLine('.line');
  }


}

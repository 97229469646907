import { IWSLBillingCompany, IWSLBillingCompanyFilter } from '@app/billing-company/models/billing-company';
import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';

export enum BillingCompanyActionTypes {
  LoadBCompanies = '[Billing] Load Billings Companies',
  LoadBCompaniesSuccess = '[Billing] Load Billings Companies Success',
  LoadBCompaniesFail = '[Billing] Load Billings Companies Fail',

}

// LOAD BCompanies
export class LoadBCompanies implements Action {
  readonly type = BillingCompanyActionTypes.LoadBCompanies;

  constructor(public payload: IWSLBillingCompanyFilter) {
  }
}

export class LoadBCompaniesSuccess implements Action {
  readonly type = BillingCompanyActionTypes.LoadBCompaniesSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLBillingCompany>) {
  }
}

export class LoadBCompaniesFail implements Action {
  readonly type = BillingCompanyActionTypes.LoadBCompaniesFail;

  constructor(public payload: IWSLHttpResponse<IWSLBillingCompany>) {
  }
}


export type BillingCompanyActions =
  LoadBCompanies |
  LoadBCompaniesSuccess |
  LoadBCompaniesFail;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { WSLHttpHelper } from 'wsl-core';

@Injectable({
  providedIn: 'root'
})
export class BillingDataService {

  constructor(private http: HttpClient) { }

  public getReport(date: string, bcompany_id: number, token: string) {
    return this.http.get('bcompany_report', {params: WSLHttpHelper.getParams({date, bcompany_id, token})});
  }
}

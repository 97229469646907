<!--<wsl-input-wrapper class="wsl-select wsl-relative"
                   [class.wsl-select-low]="low"
                   [label]="label"
                   [inputId]="inputID"
                   [required]="required"
                   [asyncError]="asyncError">
</wsl-input-wrapper>-->

<wsl-input-wrapper class="wsl-block wsl-relative wsl-w-100"
                   [class.wsl-select-low]="low"
                   [label]="label"
                   [required]="required"
                   [asyncError]="asyncError"
                   [readonly]="disabled"
                   [showClear]="showClear"
                   [value]="value"
                   (clear)="onClear()">
  <wsl-autocomplete type="text"
                    [focus]="inputFocus"
                    [showMeta]="showMeta && !!value"
                    [(ngModel)]="search"
                    [pH]="text"
                    [required]="required"
                    [disabled]="disabled"
                    (search)="onSearch($event)"
                    (enterKey)="onEnterKey($event)"
                    (blured)="onBlur()">

      <ng-container *ngIf="items && items.length > 0; else empty"
                    #container
                    wslSpyScroll
                    (scrolled)="onScroll($event)">
        <ng-container *ngFor="let item of items; let index = index; trackBy: trackById">
        <li class="wsl-p-y-10 wsl-p-x-15 wsl-bg-white_hover wsl-relative"
            #row
            (click)="onSelect(item)">
            <div class="wsl-text-nowrap wsl-text-right wsl-abs wsl-abs-left wsl-abs-top wsl-flex-middle-ng wsl-h-100 wsl-w-100" style="margin-left: calc(-5px - 100%);">
              <span class="wsl-h-60" style="width: 5px;"
                    [ngClass]="{
                       'wsl-bg-blue-light':item.id === value,
                       'wsl-bg-gray-light_hover': item.id !== value && index === highlightedId
                      }"></span>
            </div>
            <ng-container *ngTemplateOutlet="lineTmpl, context: { $implicit: item}"></ng-container>
        </li>
      </ng-container>

    </ng-container>
    <ng-template #empty>
      <li class="wsl-p-y-10 wsl-p-x-15">
        <mat-progress-bar mode="buffer" *ngIf="pending"></mat-progress-bar>
        <span [class.wsl-hide]="pending">Ничего не нашли по Вашему запросу</span>
      </li>
    </ng-template>
  </wsl-autocomplete>
</wsl-input-wrapper>

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {WSLLocalStorageService} from 'wsl-core';
import * as fromRoot from '@store/reducers/index';
import {select, Store} from '@ngrx/store';
import {catchError, filter, map, switchMap, take, tap, timeout} from 'rxjs/operators';
import {StorageKey, UserProfileService} from 'wsl-ek-core';
import {LoadUserProfileSuccess} from '@store/actions/user-profile.actions';

@Injectable()
export class UnauthorizedGuard implements CanActivate {
  constructor(private store: Store<fromRoot.State>,
              private userService: UserProfileService,
              private localStorage: WSLLocalStorageService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store
      .pipe(
        select(fromRoot.selectLoggedIn),
        filter(loaded => loaded),
        take(1),
        timeout(100),
        catchError(() => of(false)),
        switchMap(authed => {
          if (!authed) {
            if (this.localStorage.get(StorageKey.token)) {
              return this.userService
                .get()
                .pipe(
                  // map(user => new profile.GetUserProfileSuccess({user})),
                  // tap((action: profile.GetUserProfileSuccess) => this.store.dispatch(action)),
                  tap(response => {
                    //  this.store.dispatch(new LoginSuccess({token: this.localStorage.get(StorageKey.token)}));
                    this.store.dispatch(new LoadUserProfileSuccess(response));
                  }),
                  map(
                    res => false
                  ),
                  catchError((response) => {
                    return of(true);
                  })
                );
            } else {
              return of(true);
            }
          }
          return of(false);
        }),
        take(1)
      );
  }
}

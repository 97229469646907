import * as tslib_1 from "tslib";
import { UserProfileActionTypes } from '../actions/user-profile.actions';
import { CompanyRoleType } from 'wsl-ek-core';
import { CompanyProfileActionTypes } from '../actions/company-profile.actions';
import { TownActionTypes } from '../actions/town.actions';
import * as moment from 'moment';
import { MomentDBDateFormat } from 'wsl-core';
import { UserRoleType } from 'wsl-ek-core';
import { environment } from '@env/environment';
export var initialState = {
    user: null,
    company: null,
    availableCompanies: [],
    town: null,
    availableRooms: [],
    roomId: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case UserProfileActionTypes.LoadUserProfileSuccess:
            return tslib_1.__assign({}, state, { user: action.payload.item });
        case UserProfileActionTypes.LoadUserProfileFail:
            return initialState;
        case CompanyProfileActionTypes.LoadCompanyProfileSuccess:
            return tslib_1.__assign({}, state, { company: action.payload.item });
        case CompanyProfileActionTypes.LoadCompanyProfileFail:
            return initialState;
        case UserProfileActionTypes.LoadAvailableCompanies:
            return tslib_1.__assign({}, state, { availableCompanies: [] });
        case UserProfileActionTypes.LoadAvailableCompaniesSuccess:
            return tslib_1.__assign({}, state, { availableCompanies: action.payload.items });
        case TownActionTypes.LoadTownProfileSuccess:
            return tslib_1.__assign({}, state, { town: action.payload.item });
        case UserProfileActionTypes.LoadAvailableRoomsSuccess:
            return tslib_1.__assign({}, state, { availableRooms: action.payload.items });
        case UserProfileActionTypes.SetSelectedRoomId:
            return tslib_1.__assign({}, state, { roomId: action.payload });
        case TownActionTypes.LoadTownProfileFail:
            return initialState;
        default:
            return state;
    }
}
export var getUser = function (state) { return state.user; };
export var getCompany = function (state) { return state.company; };
export var getIsCompanyFreeAccount = function (state) { return !!state.company && !state.company.contract_type; }; // !state.company.contract_date_end;
export var getCompanyHasLimitedAccess = function (state) {
    if (state.company) {
        if (!state.company.contract_type) {
            // is free access
            return !state.company.contract_date_end ||
                (!!state.company.contract_date_end && (
                // contract ended
                moment().isAfter(moment(state.company.contract_date_end, MomentDBDateFormat), 'day') ||
                    // promo period 90 days not started yet
                    moment(state.company.contract_date_end, MomentDBDateFormat).diff(moment(), 'days') > 90));
        }
        else {
            // is paid access
            return !(!!state.company.contract_date_beg && moment().isSameOrAfter(moment(state.company.contract_date_beg, MomentDBDateFormat), 'day') &&
                !!state.company.contract_date_end && moment().isSameOrBefore(moment(state.company.contract_date_end, MomentDBDateFormat), 'day'));
        }
    }
    return environment.dom ? null : environment.kv ? null : false;
};
export var getShowPromotion = function (state) {
    if (!!state.user && !!state.company) {
        return !environment.production &&
            state.user.role_ids.includes(UserRoleType.admin) &&
            state.company.role_id === CompanyRoleType.uc &&
            !state.company.contract_type && !state.company.contract_date_end;
    }
    return false;
};
export var getAvailableCompanies = function (state) { return state.availableCompanies; };
export var getTown = function (state) { return state.town; };
export var getRooms = function (state) { return state.availableRooms; };
export var getRoom = function (state) { return state.availableRooms.find(function (r) { return r.id === state.roomId; }); };

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWSLBCompanyAccount, IWSLBCompanyAccountFilter } from '@app/billing-company/models/b-company-account';
import { WSLApiHttpClientService } from 'wsl-core';

@Injectable()
export class BCompanyAccountService extends WSLApiHttpClientService<IWSLBCompanyAccount, IWSLBCompanyAccountFilter> {

  constructor(public http: HttpClient) {
    super(http, 'bcompany_account');
  }
}

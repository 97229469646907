import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { WSLActionButtonHelper, WSLActionButtonType } from 'wsl-shared';
var DeviceConfExtListComponent = /** @class */ (function () {
    function DeviceConfExtListComponent(chr) {
        this.chr = chr;
        this.readonly = true;
        this.edit = new EventEmitter();
        this.delete = new EventEmitter();
        this.update = new EventEmitter();
        this.cancel = new EventEmitter();
        /** @internal */
        this.actionButtons = [
        //  {...AddButtonType}
        ];
    }
    DeviceConfExtListComponent.prototype.ngOnInit = function () {
    };
    DeviceConfExtListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.conf) {
            this.validateConfiguration();
        }
    };
    Object.defineProperty(DeviceConfExtListComponent.prototype, "confs", {
        get: function () {
            var _this = this;
            return this.conf ? this.conf.filter(function (c) { return c.dconf_id === _this.deviceConf.id; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    DeviceConfExtListComponent.prototype.onBtnClick = function (ev) {
        switch (ev) {
            case WSLActionButtonType.add:
                if (this.selectedID === null) {
                    this.conf.push({
                        id: this.conf.length > 0 ? (-this.conf.length - 1) : -1,
                        index: this.conf.length > 0 ? (this.conf[this.conf.length - 1].index + this.conf[this.conf.length - 1].len) : 1,
                        type_data: null,
                        len: 0,
                        little_endian: true
                    });
                    this.conf = this.conf.slice(0);
                    this.onEdit(this.conf[this.conf.length - 1].id);
                }
                break;
        }
    };
    DeviceConfExtListComponent.prototype.onEdit = function (id) {
        this.actionButtons = WSLActionButtonHelper.dirtyAddActionButton({ dirty: true }, this.actionButtons);
        // this.actionButtons = this.actionButtons.slice(0);
        this.edit.emit(id);
    };
    /*onUpdate(conf: IWSLDataConf) {
      this.update.emit(conf);
    }*/
    DeviceConfExtListComponent.prototype.onCancel = function () {
        this.cancel.emit();
    };
    DeviceConfExtListComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    DeviceConfExtListComponent.prototype.validateConfiguration = function () {
        var _this = this;
        if (this.conf && !this.readonly) {
            this.conf = this.conf.map(function (p) {
                if (_this.conf.some(function (c) { return c.id !== p.id && p.index >= c.index && p.index < c.index + c.len; })) {
                    return tslib_1.__assign({}, p, { meta: {
                            wrongIndex: true
                        } });
                }
                return tslib_1.__assign({}, p, { meta: {
                        wrongIndex: false
                    } });
            });
        }
        this.actionButtons = WSLActionButtonHelper.dirtyAddActionButton({ dirty: false }, this.actionButtons);
        this.chr.markForCheck();
    };
    return DeviceConfExtListComponent;
}());
export { DeviceConfExtListComponent };

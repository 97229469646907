import { Action } from '@ngrx/store';
import { IWSLAuthenticate, IWSLConfirmRegistration, IWSLForgotPassword, IWSLRegistration, IWSLResetPassword } from 'wsl-auth';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLRefreshToken } from 'wsl-ek-core';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] Login Success',
  LoginFail = '[Auth] Login Fail',
  LoginRedirect = '[Auth] Login Redirect',

  Logout = '[Auth] Logout',

  ForgotPassword = '[Auth] ForgotPassword Password',
  ForgotPasswordSuccess = '[Auth] ForgotPassword Password Success',
  ForgotPasswordFail = '[Auth] ForgotPassword Password Fail',

  ResetPassword = '[Auth] Reset Password',
  ResetPasswordSuccess = '[Auth] Reset Password Success',
  ResetPasswordFail = '[Auth] Reset Password Fail',

  RefreshToken = '[Auth] Refresh Token',
  RefreshTokenSuccess = '[Auth] Refresh Token Success',
  RefreshTokenFail = '[Auth] Refresh Token Fail',

  LoginAsHomeUser = '[Auth] Login As Home User',
  LoginAsHomeUserSuccess = '[Auth]Login As Home User Success',
  LoginAsHomeUserFail = '[Auth] Login As Home User Fail',

  Registration = '[Auth] Registration',
  RegistrationSuccess = '[Auth] Registration Success',
  RegistrationFail = '[Auth] Registration Fail',

  ConfirmRegistration = '[Auth] ConfirmRegistration',
  ConfirmRegistrationSuccess = '[Auth] ConfirmRegistration Success',
  ConfirmRegistrationFail = '[Auth] ConfirmRegistration Fail'
}

/**
 * Login Actions
 */
export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: IWSLAuthenticate) {
  }
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLAuthenticate>) {
  }
}

export class LoginFail implements Action {
  readonly type = AuthActionTypes.LoginFail;

  constructor(public payload: IWSLHttpResponse<IWSLAuthenticate>) {
  }
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;
}

/**
 * Logout Actions
 */
export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

/**
 * Forgot Password Actions
 */
export class ForgotPassword implements Action {
  readonly type = AuthActionTypes.ForgotPassword;

  constructor(public payload: IWSLForgotPassword) {
  }
}

export class ForgotPasswordSuccess implements Action {
  readonly type = AuthActionTypes.ForgotPasswordSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLForgotPassword>) {
  }
}

export class ForgotPasswordFail implements Action {
  readonly type = AuthActionTypes.ForgotPasswordFail;

  constructor(public payload: IWSLHttpResponse<IWSLForgotPassword>) {
  }
}

/**
 * Reset Password Actions
 */
export class ResetPassword implements Action {
  readonly type = AuthActionTypes.ResetPassword;

  constructor(public payload: IWSLResetPassword) {
  }
}

export class ResetPasswordSuccess implements Action {
  readonly type = AuthActionTypes.ResetPasswordSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLResetPassword>) {
  }
}

export class ResetPasswordFail implements Action {
  readonly type = AuthActionTypes.ResetPasswordFail;

  constructor(public payload: IWSLHttpResponse<IWSLResetPassword>) {
  }
}

/**
 * Refresh Toekn Actions
 */
export class RefreshToken implements Action {
  readonly type = AuthActionTypes.RefreshToken;

  constructor(public payload: IWSLRefreshToken) {
  }
}

export class RefreshTokenSuccess implements Action {
  readonly type = AuthActionTypes.RefreshTokenSuccess;
}

export class RefreshTokenFail implements Action {
  readonly type = AuthActionTypes.RefreshTokenFail;

  constructor(public payload: IWSLHttpResponse<IWSLRefreshToken>) {
  }
}

/**
 * LoginAsHomeUser
 */
export class LoginAsHomeUser implements Action {
  readonly type = AuthActionTypes.LoginAsHomeUser;

  constructor(public payload: number) {
  }
}

export class LoginAsHomeUserSuccess implements Action {
  readonly type = AuthActionTypes.LoginAsHomeUserSuccess;
}

export class LoginAsHomeUserFail implements Action {
  readonly type = AuthActionTypes.LoginAsHomeUserFail;

  constructor(public payload: IWSLHttpResponse<IWSLRefreshToken>) {
  }
}

/**
 * Registration
 */
export class Registration implements Action {
  readonly type = AuthActionTypes.Registration;

  constructor(public payload: IWSLRegistration) {
  }
}

export class RegistrationSuccess implements Action {
  readonly type = AuthActionTypes.RegistrationSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLRegistration>) {
  }
}

export class RegistrationFail implements Action {
  readonly type = AuthActionTypes.RegistrationFail;

  constructor(public payload: IWSLHttpResponse<IWSLRegistration>) {
  }
}

export class ConfirmRegistration implements Action {
  readonly type = AuthActionTypes.ConfirmRegistration;

  constructor(public payload: IWSLConfirmRegistration) {
  }
}

export class ConfirmRegistrationSuccess implements Action {
  readonly type = AuthActionTypes.ConfirmRegistrationSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLConfirmRegistration>) {
  }
}

export class ConfirmRegistrationFail implements Action {
  readonly type = AuthActionTypes.ConfirmRegistrationFail;

  constructor(public payload: IWSLHttpResponse<IWSLConfirmRegistration>) {
  }
}

export type AuthActions =
  Login |
  LoginSuccess |
  LoginFail |

  LoginRedirect |

  Logout |

  ForgotPassword |
  ForgotPasswordSuccess |
  ForgotPasswordFail |

  ResetPassword |
  ResetPasswordSuccess |
  ResetPasswordFail |

  Registration |
  RegistrationSuccess |
  RegistrationFail |

  ConfirmRegistration |
  ConfirmRegistrationSuccess |
  ConfirmRegistrationFail |

  RefreshToken |
  RefreshTokenSuccess |
  RefreshTokenFail |

  LoginAsHomeUser |
  LoginAsHomeUserSuccess |
  LoginAsHomeUserFail;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWSLDefineDataFilter, IWSLDefineDataFilterResponse } from '@core/models/define-data-filter';
import { Observable } from 'rxjs';
import { IWSLHttpResponse, WSLHttpHelper } from 'wsl-core';

@Injectable({
  providedIn: 'root'
})
export class DefineDataFilterService {

  constructor(private http: HttpClient) { }

  public get(filter: IWSLDefineDataFilter): Observable<IWSLHttpResponse<IWSLDefineDataFilterResponse>> {
    return this.http.get<IWSLHttpResponse<IWSLDefineDataFilterResponse>>('get_filter', {params: WSLHttpHelper.getParams(filter)});
  }
}

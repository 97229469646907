export enum ConnectionType {
  hand = 1,
  tcp = 2,
  lora = 3,
  gprs = 4,
  tcp_gprs = 5
}

export const ConnectionTypes = [
  {id: ConnectionType.tcp, name: 'TCP порт', icon: 'icon-lan'},
  {id: ConnectionType.gprs, name: 'GPRS', icon: 'icon-signal-80'},
  {id: ConnectionType.lora, name: 'LoRa', icon: 'icon-broadcast'}
];

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '@store/reducers';
import { Observable, of } from 'rxjs';
import { catchError, filter, take, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HasBillingGuard implements CanActivate {
  constructor(private store: Store<fromRoot.State>) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  public check() {
    return this.store.select(fromRoot.selectHasBilling)
      .pipe(
        filter(has => !!has),
        take(1),
        timeout(1000),
        catchError(() => of(false))
      );
  }
}

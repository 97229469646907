import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {SwPush, SwUpdate} from '@angular/service-worker';
import {routerTransition} from '@app/router.animations';
import {AuthService} from '@core/services/auth.service';
import {MenuHelperService} from '@core/services/menu-helper.service';
import {PushNotificationService} from '@core/services/push-notification.service';
import {TownService} from '@core/services/town.service';
import {environment} from '@env/environment';
import {Actions, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {ClearContractData, HideMenuTabBar, SetAppEnvironment, StartLoading, StopLoading} from '@store/actions/app.actions';
import {AuthActionTypes, LoginSuccess, Logout} from '@store/actions/auth.actions';
import * as fromRoot from '@store/reducers/index';
import {CrossStorageClient} from 'cross-storage';
import * as moment from 'moment';
import {interval, Observable, Subject, Subscription, timer} from 'rxjs';
import {distinctUntilChanged, filter, finalize, switchMap, take, takeUntil, takeWhile, tap} from 'rxjs/operators';
import {IWSLAsyncErrors, IWSLMenu, MomentDBDateFormat, MomentDBDateTimeFormat, WSLLocalStorageService, WSLUtils, WSLWindowService} from 'wsl-core';
import {CompanyProfileService, EK_ROLES, IWSLCompany, UserProfileService} from 'wsl-ek-core';
import {IWSLUser} from 'wsl-user';
import {SYSTEM_SUB_TITLE, SYSTEM_SUB_TITLE_XS, SYSTEM_TITLE, SYSTEM_TITLE_XS} from '../config/app.config';
import {WSLMaterializeHelper} from 'wsl-shared';
import {PromotionSnackBarComponent} from '@common/components/promotion-snack-bar/promotion-snack-bar.component';

@Component({
  selector: 'wsl-root',
  templateUrl: './app.component.html',
  styles: [],
  animations: [routerTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated
})
export class AppComponent implements OnInit, OnDestroy {
  /** @internal */
  readonly systemTitle = SYSTEM_TITLE;
  /** @internal */
  readonly systemSubTitle = SYSTEM_SUB_TITLE;
  /** @internal */
  readonly systemTitleXS = SYSTEM_TITLE_XS;
  /** @internal */
  readonly systemSubTitleXS = SYSTEM_SUB_TITLE_XS;
  /** @internal */
  pending$: Observable<boolean>;
  /** @internal */
  showSidenav$: Observable<boolean>;
  /** @internal */
  loggedIn$: Observable<boolean>;
  /** @internal */
  menu$: Observable<IWSLMenu[]>;
  /** @internal */
  menu: IWSLMenu[] = [];
  /** @internal */
  mainMenu = null;
  /** @internal */
  user$: Observable<IWSLUser>;
  /** @internal */
  company$: Observable<IWSLCompany>;
  /** @internal */
  townName: string;
  /** @internal */
  snackbarMessage$: Observable<IWSLAsyncErrors>;
  /** @internal */
  isScrolled = false;
  /** @internal */
  scrollTop = 0;
  user: IWSLUser;
  contractData: {name?: string, contract_num?: string, contract_date_beg?: string} = null;

  showCompany = environment.dom;
  authorized = null;
  private tokenRefreshed = false;
  private aliveTimer = false;
  private timer: Subscription;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  private MModal;

  readonly VAPID_KEY = 'BO6OY05yLHpkVTQtsTaelaZQYWeaPBvXtBxg1XYordBViTtDnOsSc5ERm7hPLBA8jPvxEb03JLumnn3mPo6vLMA';

  constructor(private store$: Store<fromRoot.State>,
              // private billingStore: Store<fromBilling.BillingState>,
              private router: Router,
              private route: ActivatedRoute,
              private updates$: Actions,
              private chr: ChangeDetectorRef,
              @Inject('WINDOW') private window: any,
              private windowService: WSLWindowService,
              private localStorage: WSLLocalStorageService,
              private menuService: MenuHelperService,
              private userProfileService: UserProfileService,
              private companyProfileService: CompanyProfileService,
              private townService: TownService,
              private swUpdate: SwUpdate,
              private swPush: SwPush,
              private pushService: PushNotificationService,
              public snackBar: MatSnackBar) {
    /**
     * Selectors can be applied with the `select` operator which passes the state
     * tree to the provided selector
     */
    this.pending$ = store$.pipe(select(fromRoot.selectPending));
    // this.showSidenav$ = store.pipe(select(fromRoot.getShowSidenav));
    this.loggedIn$ = store$.pipe(select(fromRoot.selectLoggedIn));
    this.menu$ = store$.pipe(select(fromRoot.selectMenu));
    this.updates$
        .pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(AuthActionTypes.RefreshTokenSuccess, AuthActionTypes.LoginSuccess),
            tap((action) => {
              this.tokenRefreshed = true;
              this.chr.markForCheck();
            })
        ).subscribe();
    /*this.user$ = */
    store$.pipe(
        select(fromRoot.selectUserProfile),
        filter(user => !!user && !!user.id),
        tap(user => {
          this.user = user;
          this.userProfileService.definePermissionsAndRoles();
          this.store$.dispatch(new SetAppEnvironment({
            isUc: environment.dom && this.companyProfileService.isUC(),
            isKv: environment.kv && !!this.userProfileService.hasRole([EK_ROLES.owner, EK_ROLES.renter], false, false),
            isHome: environment.home && this.companyProfileService.isHomeUsers()
          }));
          this.mainMenu = [];
        }),
        switchMap(user => this.menuService.defineMainMenu().pipe(take(1))),
        tap(menu => {
          AuthService.redirectURL = AuthService.redirectURL.split('#')[0].split('?')[0];
          const redirectUrl = AuthService.redirectURL.lastIndexOf('/') > 0 ? ('/' + AuthService.redirectURL.split('/')[1]) : AuthService.redirectURL;
          if (menu.length > 0) {
            this.mainMenu = menu.slice(0);
          }
          const curUrl =  this.router.routerState.snapshot.url.split('#')[0].split('?')[0];
          const curBasePageUrl = curUrl.lastIndexOf('/') > 0 ? ('/' + curUrl.split('/')[1]) : curUrl;
          if (['/login', '/reset', '/forgot', '/confirm', '/registration'].some(s => AuthService.redirectURL.startsWith(s))) {
              this.router.navigate(['/'],
              {queryParams: {uid: WSLUtils.randomID()}, queryParamsHandling: 'merge'});
            return;
          }
          if (this.tokenRefreshed) {
            this.store$.dispatch(new HideMenuTabBar());
            this.router.navigate(
                [!this.companyProfileService.isUC() || this.mainMenu.length > 0 ? '/' : '/404'],
                {queryParams: {uid: WSLUtils.randomID()}, queryParamsHandling: 'merge'});
            this.tokenRefreshed = false;
          }
          this.chr.markForCheck();
        })
    ).subscribe();

    this.snackbarMessage$ = store$.pipe(select(fromRoot.selectSnackBarMessage));

    this.company$ = store$.pipe(
        select(fromRoot.selectUserCompanyProfile),
        filter(company => !!company),
        take(1),
        tap((company) => {
          this.townName = this.townService.getTownName(company);
          if (!!company.contract_date_end && moment(company.contract_date_end, MomentDBDateFormat, true).isValid() &&
              moment(company.contract_date_end, MomentDBDateFormat).diff(moment(), 'days') < 14) {
            const snack = this.snackBar.open('У Вас заканчивается срок действия договора пользования ресурсом ЭнергоКабинет', 'Ок', {
              duration: 10 * 60 * 1000,
              horizontalPosition: 'right',
              verticalPosition: 'top'
            });
            snack.onAction().pipe(take(1)).subscribe(e => {
              // snack.dismiss();
            });
          }
          this.chr.markForCheck();
        }));

    this.store$
        .pipe(
            select(fromRoot.selectNoAccessData),
            tap(data => {
              if (!!data) {
                this.contractData = {...data};
                this.initModal();
                this.MModal.open();
              } else {
                this.contractData = null;
              }
            })
        )
        .subscribe();

    this.store$
        .pipe(
            select(fromRoot.selectShowPromotion),
            filter(show => !!show),
            take(1),
            tap(show => {
              const showPromo = this.localStorage.get('show_promotion');
              if (!showPromo) {
                const snack = this.snackBar.openFromComponent(PromotionSnackBarComponent,
                  {
                    duration: 4 * 1000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top'
                  });
                snack.afterDismissed().subscribe(() => {
                  this.localStorage.set('show_promotion', true);
                });
              }
            })
        )
        .subscribe();

    if (swUpdate.isEnabled) {
      interval(10 * 60 * 1000).subscribe(() => swUpdate.checkForUpdate());
    }

    // this.pushService.requestPermission();
  }

  ngOnInit() {
    this.subscribeLoggedIn();
    this.subscribeRouteChange();

    if (this.window) {
      this.window.addEventListener('beforeunload', (event) => {
        // this.socketService.disconnect();
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
      });

      // срабатывает при обновлениях, сделанных в том же хранилище из других документов
      window.onstorage = event => {
        if (event.key !== 'token') { return; }
        window.location.reload();
      };
    }
    if (this.swUpdate.isEnabled) {
      this.subscribeNewAppVersion();
    } else {
    }
   /* @todo push not work for phone browsers
    if (this.swPush.isEnabled) {
      this.subscribeBackendNotifications();
    } else {
      console.log('swPush disabled');
    }*/

    this.menu$
        .pipe(
            takeUntil(this.ngUnsubscribe),
            distinctUntilChanged()
        )
        .subscribe((val) => {
          this.menu = val;
          this.addBadgeToMenuTabBar();
          this.chr.detectChanges();
        });

    if (environment.home && this.window.location.pathname !== '/test' && window.opener && window.opener !== window) {
      const storage = new CrossStorageClient(environment.hubCrossStorageLink, {
        timeout: 10000,
        frameId: 'storageFrame'
      });
      storage.onConnect()
          .then(() => {
            return storage.get('home_token');
          })
          .then((res) => {
            if (res) {
              this.store$.dispatch(new LoginSuccess({token: res}));
            }
            return res;
          })
          .catch((err) => {
            // Handle error
          });
    }
    // this.checkBrowserFeatures();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  get withNavSecond() {
    return this.menu && this.menu.length > 0;
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  onScroll(scrollTop) {
    this.scrollTop = scrollTop;
    this.isScrolled = scrollTop > 50;
    this.chr.markForCheck();
  }

  closeSidenav() {
    /**
     * All state updates are handled through dispatched actions in 'container'
     * components. This provides a clear, reproducible history of state
     * updates and user interaction through the life of our
     * application.
     */
    // this.store.dispatch(new app.CloseSidenav());
  }

  openSidenav() {
    // this.store.dispatch(new app.OpenSidenav());
  }

  onToggleProfile() {
    if (!this.userProfileService.isWildOwner()) {
      this.userProfileService.setUser(this.userProfileService.getUserID());
    }
  }

  onLogout() {
    this.closeSidenav();
    this.store$.dispatch(new Logout());
  }

  private addBadgeToMenuTabBar() {
    this.menu = this.menu.map(item => {
      if (item.alias) {
        switch (item.alias) {
            /*  case MenuItemAliasType.event:
                return Object.assign({}, item, {badge: this.newestEventCount});*/
          default:
            return item;
        }
      }
      return item;
    });
  }

  private subscribeRouteChange() {
    this.router.events
        .pipe(
            takeUntil(this.ngUnsubscribe),
            distinctUntilChanged()
        )
        .subscribe((routeEvent) => {
          // console.log('route', routeEvent);
          // const routeEvent = await val;
          // if (this.authorized) {
          if (routeEvent instanceof NavigationStart) {
            // NavigationRecognized ??
            // this.store.dispatch(new RouterActions.NavigationStart());
            this.store$.dispatch(new StartLoading('route'));
          }
          if (routeEvent instanceof NavigationEnd) {
            // this.store.dispatch(new RouterActions.NavigationEnd({current: val.urlAfterRedirects.split('#')[0], prev: this.prevRoute}));
            // this.prevRoute = val.urlAfterRedirects;
            AuthService.redirectURL = routeEvent.urlAfterRedirects.split('#')[0].split('?')[0];
            this.windowService.scrollTop();
            /*const data = WSLRouterHelperService.collectRouteData(this.route);
            if (data.menu) {
              this.store$.dispatch(new ShowMenuTabBar(data.menu));
            } else {
              this.store$.dispatch(new HideMenuTabBar());
            }*/
            this.hideSpinner();
          }

          if (routeEvent instanceof NavigationCancel) {
            this.hideSpinner();
          }
          if (routeEvent instanceof NavigationError) {
            this.hideSpinner();
          }
          // }
        });
  }

  private subscribeLoggedIn() {
    this.loggedIn$
        .pipe(
            takeUntil(this.ngUnsubscribe),
            //  delay(100),
            //   distinctUntilChanged(),
            tap(loginEvent => {
              if (loginEvent) {
                this.defineLogoutTimer();
                const popupNoBlocked = this.localStorage.get('check_popup');
                if (!popupNoBlocked) {
                  const popup = window.open('/test', '_blank');
                  this.checkPopupBlocked(popup);
                }
              } else if (loginEvent === false) {
                if (this.authorized === true) {
                  this.router.navigateByUrl('/login');
                }
                this.aliveTimer = false;
                this.hideSpinner();
              }
              this.authorized = loginEvent;
            }),
            finalize(() => {
              this.chr.markForCheck();
            })
        )
        .subscribe();
  }

  private defineLogoutTimer() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.aliveTimer = true;
    this.timer = timer(moment(this.userProfileService.getTokenExpiration(), MomentDBDateTimeFormat).toDate())
    // moment(this.userProfileService.getTokenExpiration(), MomentDBDateTimeFormat).diff(moment(), 'ms'))
        .pipe(
            takeWhile(() => this.aliveTimer),
            tap(() => {
              this.aliveTimer = false;
              this.onLogout();
            })
        )
        .subscribe();
  }

  private hideSpinner() {
    // this.loading = false;
    this.store$.dispatch(new StopLoading('route'));
    this.userProfileService.setUser(null);
  }

  private checkPopupBlocked(popup_window) {
    if (popup_window) {
      /*this.window.on('message', function(event) {
        alert(event.originalEvent.data.loaded)
      });*/
      this.window.addEventListener('message',
          (event) => {
            if (event.data && event.data.loaded) {
              this.localStorage.set('check_popup', true);
            }
          }
          , false);
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
        setTimeout(() => {
          this.isPopupBlocked(popup_window);
        }, 200);
      } else {
        popup_window.onload = () => {
          this.isPopupBlocked(popup_window);
        };
      }
    } else {
      this.displayPopubBlockedError();
    }
  }

  private isPopupBlocked(popup_window) {
    popup_window.opener.postMessage({'loaded': true}, '*');
    popup_window.close();
    /*if ((popup_window.innerHeight > 0)==false){
      this.displayPopubBlockedError();
    } else {
      popup_window.close();
    }*/
  }

  private displayPopubBlockedError() {
    const snack = this.snackBar.open('У Вас включен блокировщик всплывающих окон. Добавьте сайт в исключения', 'Ок', {
      duration: 10 * 60 * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });
    snack.onAction().pipe(take(1)).subscribe(e => {
      // snack.dismiss();
    });
  }

  private subscribeBackendNotifications() {

    this.swPush.messages.subscribe((msg) => {
    });

    this.swPush.requestSubscription({serverPublicKey: this.VAPID_KEY})
        .then(pushSubscription => {
          this.pushService.addPushSubscriber(pushSubscription).subscribe(
              () => {

                //     this.pushService.getNews().subscribe();
              },
              err =>  console.log('Could not send subscription object to server, reason: ', err)
          );
        })
        .catch(err => console.error('Could not subscribe to notifications', err));
  }

  private subscribeNewAppVersion() {
    this.swUpdate.available.subscribe((event) => {
      const snack = this.snackBar.open('Доступна новая версия', 'Обновить', {
        duration: 10 * 60 * 1000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
      });
      snack.onAction().pipe(take(1)).subscribe(e => {
        this.window.location.reload();
      });
      // WSLMaterializeHelper.toast({html: 'Загружена новая версия'});
      /*if (this.pushService.isSupported()) {
        const notification = this.pushService.create('Новая версия', {body: 'Мы сделали много изменений и поравили ошибки'});
        notification.subscribe(e => {
          console.log('notif', e);
        });
      } else {
        if (confirm('Доступна новая версия. Загрузить?')) {
          this.window.location.reload();
        }
      }*/
    });
    this.swUpdate.activated.subscribe(event => {
      // this.swPush.
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
      /* if (this.pushService.isSupported()) {
         this.pushService.create('Обновление', {body: 'Мы сделали много изменений и поравили ошибки'});
       }*/
    });
  }

  closeModal() {
    this.MModal.close();
    this.store$.dispatch(new ClearContractData());
  }

  private initModal() {
    if (!this.MModal) {
      this.MModal = WSLMaterializeHelper.initModal('#no_access_modal', {dismissible: false});
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { WSLUtils } from 'wsl-core';
import { IWSLDevice } from 'wsl-device';
import {IWSLServiceProcedure} from '@app/service-procedure/models/service-procedure';

@Component({
  selector: 'wsl-devices-per-rooms-list',
  templateUrl: './devices-per-rooms-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesPerRoomsListComponent implements OnInit {
  @Input() set devices(devices: IWSLDevice[]) {
    this.roomDevices = this.defineRoomDevices(devices);
  }
  @Input() serviceProcedures: IWSLServiceProcedure[] = [];

  roomDevices = [];

  constructor() {
  }

  ngOnInit() {
  }

  private defineRoomDevices(devices) {
    if (!devices || devices.length === 0) {
      return [];
    }
    const data = [];
    let devs = [];
    for (let i = 0; i < devices.length; i++) {
      if (i > 0 && devices.length > 1) {
        if (devices[i - 1].room_id !== devices[i].room_id) {
          const device = devices[i - 1];
          data.push({
            room: {
              id: device.room_id,
              name: device.room_name,
              residential: device.residential,
              object_id: device.object_id
            },
            devices: [...devs]
          });
          devs = [];
        }
      }
      devs.push(devices[i]);
    }
    if (devs.length > 0) {
      data.push({
        room: {
          id: devs[0].room_id,
          name: devs[0].room_name,
          residential: devs[0].residential,
          object_id: devs[0].object_id,
        },
        devices: [...devs]
      });
      devs = [];
    }
    /*const groups = WSLUtils.groupBy(this.devices, 'room_id');
    Object.keys(groups).forEach(room_id => {
      const device: IWSLDevice = groups[room_id][0];
      data.push({
        room: {
          id: device.room_id,
          name: device.room_name,
          residential: device.residential,
          object_id: device.object_id
        },
        devices: groups[room_id]
      });
    });*/
    return data;
  }

  trackById(index: number, item: any) {
    return item.id;
  }

}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeviceDataService, IWSLDeviceData, IWSLDeviceDataFilter} from 'wsl-device';
import {DataArchiveType, IWSLResourceUnit, IWSLResourceValue, ResourceType, ResourceValueExtType, ResourceVariableType} from 'wsl-ek-core';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {IWSLAsyncErrors, WSLHttpHelper, WSLRouterHelperService, WSLUtils} from 'wsl-core';
import {Subject} from 'rxjs';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'wsl-elect-power-profile-per-device',
  templateUrl: './elect-power-profile-per-device.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElectPowerProfilePerDeviceComponent implements OnInit, OnDestroy {
  @Input() id = 'elect-power-profile-per-device';
  @Input() set filter(f: IWSLDeviceDataFilter) {
    if (f.period_id === DataArchiveType.day_int) {
      this.changeFilterSub.next(f);
    } else {
      this.showData = false;
      this.data = [];
    }
  }
  @Input() showTable = true;

  days = [];
  dataFilter: IWSLDeviceDataFilter = null;
  data: IWSLDeviceData[] = [];
  tableData = [];
  pending = false;
  asyncErrors: IWSLAsyncErrors = null;
  showData = false;
  chart: AmChart;
  showChart = false;
  selectedDay = '01';
  periods = [];

  powerRvalues = {
    Ap1: null,
    Ap2: null,
    Am1: null,
    Am2: null,
    Rp1: null,
    Rp2: null,
    Rm1: null,
    Rm2: null
  };

  private changeFilterSub = new Subject();

  private rvalues: IWSLResourceValue[] = [];
  private runits: IWSLResourceUnit[] = [];

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private deviceDataService: DeviceDataService,
              private AmCharts: AmChartsService,
              private chr: ChangeDetectorRef) { }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.rvalues = data.resourceValues;
    this.runits = data.resourceUnits;

    this.powerRvalues = {
      Ap1: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.ap1),
      Ap2: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.ap2),
      Am1: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.am1),
      Am2: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.am2),
      Rp1: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.rp1),
      Rp2: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.rp2),
      Rm1: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.rm1),
      Rm2: this.rvalues.find(rv =>
        rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.rm2)
    };

    this.changeFilterSub.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(f => {
      this.dataFilter = {
        ...f,
        period_id: DataArchiveType.hour,
        //   by_period: true
      };
      this.defineDays();
    });
  }

  ngOnDestroy() {
    if (this.chart) {
      try {
        this.AmCharts.destroyChart(this.chart);
      } catch (e) {

      }
      this.chart = null;
    }
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  selectDay(day: string) {
    this.selectedDay = day;
    this.dataFilter.date = `${day}.${this.dataFilter.month + 1}.${this.dataFilter.year}`;
    this.loadData();
  }

  private loadData() {
    const powerDate = DeviceDataService.getArchiveDate(this.dataFilter);
    this.pending = true;
    this.asyncErrors = null;
    this.deviceDataService.getMany({
      device_id: this.dataFilter.device_id,
      individual: this.dataFilter.individual,
      date: powerDate,
      resource_id: this.dataFilter.resource_id,
      period_id: this.dataFilter.period_id,
      by_period: this.dataFilter.by_period
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        res => {
          this.data = res.items.filter(data => data.arch_data || data.error_arch);
          this.pending = false;
          this.showData = true;
          this.updateChart();
          this.chr.markForCheck();
        },
        err => {
          const error = WSLHttpHelper.parseHTTPErrorResponse(err);
          if (error.view) {
            this.asyncErrors = WSLHttpHelper.parseAsyncMessage(error.message);
          }
          this.pending = false;
          this.chr.markForCheck();
        }
      );
  }

  private defineDays() {
    const periods = DeviceDataService.definePeriods({...this.dataFilter, period_id: DataArchiveType.day});
    this.days = periods.map(p => ({id: p.id, value: p.value}));
    if (this.days.length > 0) {
      this.selectDay(this.days[this.days.length - 1].value);
    } else {
      // this.selectDay('01';
      this.showData = false;
    }
  }

  private updateChart() {
    if (this.chart) {
      try {
        this.AmCharts.destroyChart(this.chart); // clear
      } catch (e) {
      }
      this.chart = null;
    }
    const conf = this.getChartConfig();
    if (conf && conf.dataProvider && conf.dataProvider.length > 0) {
      setTimeout(() => {
        this.chart = this.AmCharts.makeChart(this.id, conf);
        this.chart.validateNow();
      }, 100);

    }
  }

  private getChartConfig() {
    const periodFormat = DeviceDataService.defineChartPeriodFormat(this.dataFilter);
    const separators = WSLUtils.getNumberSeparators();

    return {
      'language': 'ru',
      // 'path': 'assets/amcharts',
      'type': 'serial',
      'theme': 'light',
      'addClassNames': true,
      'percentPrecision': 0,
      'decimalSeparator': separators.decimal,
      'thousandsSeparator': separators.thousand,
      'dataProvider': this.makeDataProvider(),
      'valueAxes': [{
        'gridAlpha': 0.07,
        'position': 'left',
        // "title": "Потребление, ват"
      }],
      'graphs': [{
        'id': 'Ap',
        'title': 'A+',
        'type': 'line',
        'lineThickness': 2,
        'lineColor': '#c43a35',
        'lineAlpha': 0.8,
        'valueField': 'Ap',
        'fillToGraph': 'Ap15',
        'fillColors': ['#c43a35'],
        'fillAlphas': 0.1,
        'balloonText': '[[Ap]]',
        'bullet': 'round',
        'bulletBorderAlpha': 1,
        'useLineColorForBulletBorder': true,
        'bulletColor': '#FFFFFF'
      }, {
        'id': 'Ap15',
        'title': '15% от A+',
        'type': 'line',
        'lineThickness': 2,
        'lineColor': '#12bd37',
        'lineAlpha': 0.8,
        'valueField': 'Ap15',
        'fillColors': ['#12bd37'],
        'fillAlphas': 0.1,
        'balloonText': '[[Ap15]]',
        // "bullet": "round",
        // "bulletBorderAlpha": 1,
        // "useLineColorForBulletBorder": true,
        // "bulletColor": "#FFFFFF"
      }, {
        'id': 'Rp',
        'title': 'R+',
        'type': 'line',
        'lineThickness': 2,
        'lineColor': '#f0760f',
        'lineAlpha': 0.8,
        'valueField': 'Rp',
        'bullet': 'square',
        'bulletBorderAlpha': 1,
        'bulletBorderThickness': 1
      }, {
        'id': 'Am',
        'title': 'A-',
        'type': 'line',
        'lineThickness': 2,
        'lineColor': '#2a9cc6',
        'lineAlpha': 0.8,
        'valueField': 'Am',
        // "fillToGraph": "Am15",
        'fillColors': ['#2a9cc6'],
        'fillAlphas': 0.1,
        'balloonText': '[[Am]]',
        'bullet': 'round',
        'bulletBorderAlpha': 1,
        'useLineColorForBulletBorder': true,
        'bulletColor': '#FFFFFF',
        'hidden': true
      }, {
        'id': 'Am15',
        'title': '15% от A-',
        'type': 'line',
        'lineThickness': 2,
        'lineColor': '#f0c94e',
        'lineAlpha': 0.8,
        'valueField': 'Am15',
        'fillColors': ['#f0c94e'],
        'fillAlphas': 0.1,
        'balloonText': '[[Am15]]',
        'hidden': true
        // "bullet": "round",
        // "bulletBorderAlpha": 1,
        // "useLineColorForBulletBorder": true,
        // "bulletColor": "#FFFFFF"
      }, {
        'id': 'Rm',
        'title': 'R-',
        'type': 'line',
        'lineThickness': 2,
        'lineColor': '#2c3cf0',
        'lineAlpha': 0.8,
        'valueField': 'Rm',
        'bullet': 'square',
        'bulletBorderAlpha': 1,
        'bulletBorderThickness': 1,
        'hidden': true
      }],
      'categoryField': 'hour',
      'categoryAxis': {
        'startOnAxis': true
      },
      'legend': {
        'enabled': true,
        'useGraphSettings': true
      }
    };
  }

  private makeDataProvider() {
    let data = [];
    if (this.data && this.data.length > 0) {
      this.periods = DeviceDataService.definePeriods(this.dataFilter);
      data = this.periods.map(period => {
        const date = period.datetime; // .split(' ')[0];
        const values = this.data.find(ds => ds.datetime === date) ? this.data.find(ds => ds.datetime === date) : null;
        if (values) {
          const ap1 = this.powerRvalues.Ap1 && values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Ap1.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Ap1.id).value : null;
          const ap2 = this.powerRvalues.Ap2 && values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Ap2.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Ap2.id).value : null;

          const ap = ap1 + ap2;
          const ap15 = ap * 0.15;

          const am1 = this.powerRvalues.Am1 && values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Am1.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Am1.id).value : null;
          const am2 = this.powerRvalues.Am2 && values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Am2.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Am2.id).value : null;

          const am = am1 + am2;
          const am15 = am * 0.15;

          const rp1 = this.powerRvalues.Rp1 && values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Rp1.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Rp1.id).value : null;
          const rp2 = this.powerRvalues.Rp2 && values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Rp2.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Rp2.id).value : null;

          const rp = rp1 + rp2;

          const rm1 = this.powerRvalues.Rm1 && values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Rm1.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Rm1.id).value : null;
          const rm2 = this.powerRvalues.Rm2 && values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Rm2.id) ?
            values.arch_data.find(ar => ar.rvalue_id === this.powerRvalues.Rm2.id).value : null;

          const rm = rm1 + rm2;
          return {
            hour: period.value,
            Ap: ap,
            Ap15: ap15,
            Am: am,
            Am15: am15,
            Rp: rp,
            Rm: rm
          };
        } else {
          return {
            hour: period.value,
            Ap: null,
            Ap15: null,
            Am: null,
            Am15: null,
            Rp: null,
            Rm: null
          };
        }
      });
    }

    this.showChart = data.length > 0;
    this.tableData = [];
    if (this.showTable && this.showChart) {
      this.defineTableData();
    }
    return data;
  }

  private defineTableData() {
    this.tableData = [];
    if (this.data.length > 0) {
      this.tableData = DeviceDataService.fillDataForRValues(
        // this.device,
        this.data,
        [],
        this.periods,
        this.rvalues.filter(rv => rv.resource_id === this.dataFilter.resource_id && rv.ext &&
          ((rv.ext.ipu && rv.ext.ipu.includes(ResourceValueExtType.table)) || (rv.ext.odpu && rv.ext.odpu.includes(ResourceValueExtType.table)))),
        this.runits
      );
    }
    // peityBar('.bar');
    // peityLine('.line');
  }

}

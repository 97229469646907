import * as tslib_1 from "tslib";
import { AfterViewChecked, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WSLFormHelper, WSLFormValidators, WSLWindowService } from 'wsl-core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AllSystems, ResourceType } from 'wsl-ek-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
var DeviceConfExtEditRowComponent = /** @class */ (function () {
    function DeviceConfExtEditRowComponent(route, chr, elem, windowService) {
        this.route = route;
        this.chr = chr;
        this.elem = elem;
        this.windowService = windowService;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        /** @internal */
        this.form = new FormGroup({
            dconf_id: new FormControl(null, [Validators.required]),
            id: new FormControl(null, []),
            //  name: new FormControl('', [Validators.required]),
            type_data: new FormControl(null, [Validators.required]),
            index: new FormControl(null, [Validators.required, WSLFormValidators.positiveNumberValidator]),
            len: new FormControl(null, [Validators.required, WSLFormValidators.positiveNumberValidator]),
            little_endian: new FormControl(true),
            resource_id: new FormControl(null),
            rvariable_id: new FormControl(null, [Validators.required]),
            rvalue_id: new FormControl(null),
            resource_inx: new FormControl(null),
            is_sensor: new FormControl(false),
            unit_id: new FormControl(null)
        });
        /** @internal */
        this.resources = [];
        /** @internal */
        this.dataTypes = [];
        this.ngUnsubscribe = new Subject();
        this.rvariables = [];
        this.rvalues = [];
        this.units = [];
        this.dmodels = [];
        this.dexecutions = [];
        this.maxResCount = 0;
    }
    DeviceConfExtEditRowComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.rvariables = this.route.snapshot.data.resourceVariables;
        this.rvalues = this.route.snapshot.data.resourceValues;
        this.units = this.route.snapshot.data.resourceUnits;
        this.dmodels = this.route.snapshot.data.dmodels;
        this.dexecutions = this.route.snapshot.data.dexecutions;
        this.dataTypes = this.route.snapshot.data.dataTypes;
        this.form.get('type_data').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), debounceTime(100), distinctUntilChanged())
            .subscribe(function (val) {
            /* if (val === DataConfType.other) {
               //    this.form.get('len').setValue(null);
               this.form.get('len').setValidators([Validators.required, FormValidators.positiveNumberValidator]);
               this.form.get('len').enable();
             } else {*/
            var dataType = _this.dataTypes.find(function (t) { return t.id === val; });
            if (dataType) {
                _this.form.get('len').setValue(dataType.len);
            }
            _this.form.get('len').clearValidators();
            _this.form.get('len').disable();
            // }
            _this.form.get('len').updateValueAndValidity();
            _this.chr.markForCheck();
        });
        this.form.get('resource_id').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), debounceTime(100), distinctUntilChanged())
            .subscribe(function (val) {
            if (!_this.availableRvariables.find(function (ar) { return ar.id === _this.form.get('rvariable_id').value; })) {
                _this.form.get('rvariable_id').setValue(null);
                _this.form.get('rvalue_id').setValue(null);
                _this.form.get('resource_inx').setValue(null);
                _this.form.get('unit_id').setValue(null);
            }
            if (val === ResourceType.system) {
                _this.form.get('rvalue_id').clearValidators();
                _this.form.get('rvalue_id').setValue(null);
                _this.form.get('rvalue_id').disable();
                _this.form.get('resource_inx').clearValidators();
                _this.form.get('resource_inx').setValue(null);
                _this.form.get('resource_inx').disable();
                _this.form.get('unit_id').setValue(null);
                _this.form.get('unit_id').clearValidators();
                _this.form.get('unit_id').disable();
                _this.form.get('is_sensor').setValue(false);
                _this.form.get('is_sensor').disable();
            }
            else {
                _this.form.get('rvalue_id').enable();
                _this.form.get('resource_inx').enable();
                _this.form.get('unit_id').enable();
                _this.form.get('is_sensor').enable();
                _this.defineValidators();
                _this.form.get('unit_id').setValidators([Validators.required]);
            }
            _this.form.updateValueAndValidity();
            _this.chr.markForCheck();
        });
        this.form.get('rvariable_id').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), debounceTime(100), distinctUntilChanged())
            .subscribe(function (val) {
            if (!_this.availableRvalues.find(function (ar) { return ar.id === _this.form.get('rvalue_id').value; })) {
                _this.form.get('rvalue_id').setValue(null);
                _this.form.get('resource_inx').setValue(null);
                _this.form.get('unit_id').setValue(null);
            }
            _this.chr.markForCheck();
        });
        this.fillForm();
    };
    DeviceConfExtEditRowComponent.prototype.ngAfterViewChecked = function () {
        this.scrollToBottom();
    };
    DeviceConfExtEditRowComponent.prototype.ngOnChanges = function (changes) {
        if (changes.conf || changes.deviceConf) {
            this.fillForm();
        }
    };
    DeviceConfExtEditRowComponent.prototype.ngOnDestroy = function () {
        if (this.MSelectResource) {
            this.MSelectResource.destroy();
        }
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(DeviceConfExtEditRowComponent.prototype, "availableRvariables", {
        get: function () {
            var _this = this;
            return this.form.get('resource_id').value ?
                this.rvariables.filter(function (rv) { return rv.resource_ids.includes(_this.form.get('resource_id').value); })
                    .map(function (rv) { return (tslib_1.__assign({}, rv, { name: rv.alias + ' - ' + rv.name })); }) :
                this.rvariables.map(function (rv) { return (tslib_1.__assign({}, rv, { name: rv.alias + ' - ' + rv.name })); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceConfExtEditRowComponent.prototype, "availableRvalues", {
        get: function () {
            var _this = this;
            /* if (this.form.get('resource_id').value === ResourceType.system) {
               const rvar = this.rvariables.find(rv => rv.id === this.form.get('rvariable_id').value);
               if (!rvar) {
                 return [];
               }
               if (rvar.alias === 'TimeArch') {
                 return [
                   {id: 1, name: 'YYYY-MM-DD'},
                   {id: 2, name: 'YYYY-MM-DD HH:mm'},
                   {id: 3, name: 'DD.MM.YYYY'}
                 ];
               } else if (rvar.alias === 'Code') {
                 return [];
               } else if (rvar.alias === 'Type') {
                 return [];
               }
             }*/
            return this.rvalues.filter(function (rv) { return rv.resource_id === _this.form.get('resource_id').value &&
                rv.rvariable_id === _this.form.get('rvariable_id').value; }).map(function (rv) { return (tslib_1.__assign({}, rv, { name: rv.name + ' - ' + rv.note })); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceConfExtEditRowComponent.prototype, "availableUnits", {
        get: function () {
            var _this = this;
            return this.units.filter(function (u) { return u.rvariable_id === _this.form.get('rvariable_id').value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceConfExtEditRowComponent.prototype, "availableResourceNum", {
        get: function () {
            return this.deviceConf && this.deviceConf.template ? Array.from(new Array(this.maxResCount), function (val, index) { return ({
                id: index + 1,
                name: "\u2116 " + (index + 1)
            }); }) : [];
        },
        enumerable: true,
        configurable: true
    });
    DeviceConfExtEditRowComponent.prototype.onSave = function () {
        this.form.updateValueAndValidity();
        WSLFormHelper.markAllDirty(this.form);
        this.form.markAsDirty();
        this.form.updateValueAndValidity();
        if (this.form.valid) {
            var data = this.form.getRawValue();
            if (data.id < 0) {
                delete data.id;
            }
            this.save.emit(data);
        }
        else {
            WSLMaterializeHelper.toast({ html: 'Форма заполнена не правильно', displayLength: 2000 });
        }
        this.chr.markForCheck();
    };
    DeviceConfExtEditRowComponent.prototype.onCancel = function () {
        this.fillForm();
        this.cancel.emit();
    };
    DeviceConfExtEditRowComponent.prototype.trackById = function (index, obj) {
        return obj.id;
    };
    DeviceConfExtEditRowComponent.prototype.fillForm = function () {
        var _this = this;
        this.defineValidators();
        if (this.deviceConf && this.conf) {
            WSLFormHelper.fillForm(this.form, this.conf);
            this.form.get('dconf_id').setValue(this.deviceConf.id);
            this.form.updateValueAndValidity();
            this.form.markAsPristine();
            this.form.markAsUntouched();
            this.resources = AllSystems.map(function (ar) {
                return tslib_1.__assign({}, ar, { disabled: ar.id !== ResourceType.system && (!_this.deviceConf || /*!this.deviceConf.dmodel_ids ||*/
                        (_this.deviceConf.template && _this.dexecutions
                            .filter(function (de) {
                            return _this.dmodels
                                //    .filter(dm => this.deviceConf.dmodel_ids.includes(dm.id))
                                .map(function (dm) { return dm.id; })
                                .includes(de.dmodel_id) && de.resources.includes(ar.id);
                        }).length === 0) ||
                        (!_this.deviceConf.template &&
                            _this.deviceConf.ext // &&
                        //   this.deviceConf.ext.resources &&
                        //   !this.deviceConf.ext.resources.find(r => r.resource_id === ar.id)
                        )) });
            });
            this.maxResCount = 0;
            /* if (this.deviceConf.dmodel_ids) {
               this.dexecutions
                 .filter(de =>
                   this.dmodels
                     .filter(dm => this.deviceConf.dmodel_ids.includes(dm.id))
                     .map(dm => dm.id)
                     .includes(de.dmodel_id)
                 )
                 .forEach(de => {
                   const dmodel = this.dmodels.find(dm => dm.id === de.dmodel_id);
                   if (de.v + dmodel.count_in > this.maxResCount) {
                     this.maxResCount = de.v + dmodel.count_in;
                   }
                 });
             }*/
            setTimeout(function () {
                _this.MSelectResource = WSLMaterializeHelper.initSelect('.resource_select');
            }, 350);
        }
    };
    DeviceConfExtEditRowComponent.prototype.defineValidators = function () {
        if (this.form.get('resource_id').value !== ResourceType.system) {
            if (!this.deviceConf.template) {
                this.form.get('resource_id').setValidators([Validators.required]);
                this.form.get('rvalue_id').setValidators([Validators.required]);
                this.form.get('resource_inx').setValidators([Validators.required]);
            }
            else {
                this.form.get('resource_id').clearValidators();
                this.form.get('rvalue_id').clearValidators();
                this.form.get('resource_inx').clearValidators();
            }
        }
        this.form.updateValueAndValidity();
    };
    DeviceConfExtEditRowComponent.prototype.scrollToBottom = function () {
        var _this = this;
        setTimeout(function () {
            try {
                var p = _this.windowService.getScrollingElement(_this.elem.nativeElement);
                _this.windowService.scrollToInContainer(_this.elem.nativeElement.offsetTop, 150, p);
            }
            catch (err) {
            }
        }, 350);
    };
    return DeviceConfExtEditRowComponent;
}());
export { DeviceConfExtEditRowComponent };

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWSLLoraServer } from '@core/models/lora-server';
import { IWSLEntityFilter, WSLApiHttpClientService } from 'wsl-core';

@Injectable()
export class LoraServerService extends WSLApiHttpClientService<IWSLLoraServer, IWSLEntityFilter> {

  constructor(public http: HttpClient) {
    super(http, 'lora_server');
  }
}

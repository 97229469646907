import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddButtonType, WSLActionButtonType } from 'wsl-shared';
var DeviceConfListComponent = /** @class */ (function () {
    function DeviceConfListComponent(route, router) {
        this.route = route;
        this.router = router;
        this.checklist = [];
        this.checkable = false;
        this.readonly = false;
        this.check = new EventEmitter();
        this.selectItem = new EventEmitter();
        this.delete = new EventEmitter();
        /** @internal */
        this.actionButtons = [
        // {...AddButtonType}
        ];
        /** @internal */
        this.scrollTop = 0;
        this.showAddConfs = false;
    }
    DeviceConfListComponent.prototype.ngOnInit = function () {
        if (!this.readonly) {
            this.actionButtons.push(tslib_1.__assign({}, AddButtonType));
        }
    };
    DeviceConfListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.group || changes.deviceConfKits) {
            if (this.showAddConfs) {
                this.showAddConfs = false;
            }
        }
    };
    DeviceConfListComponent.prototype.onBtnClick = function (ev) {
        switch (ev) {
            case WSLActionButtonType.add:
                this.router.navigate(['/support/add_device_conf']);
                break;
        }
    };
    DeviceConfListComponent.prototype.onScroll = function (st) {
        this.scrollTop = st;
    };
    DeviceConfListComponent.prototype.trackByConfId = function (index, obj) {
        return obj.confs.reduce(function (pv, cv) { return pv + '_' + cv.id; }, '');
    };
    DeviceConfListComponent.prototype.trackById = function (index, obj) {
        return obj.id;
    };
    DeviceConfListComponent.prototype.onShowAddConf = function () {
        this.showAddConfs = true;
    };
    return DeviceConfListComponent;
}());
export { DeviceConfListComponent };

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {CompanyProfileService, UserProfileService} from 'wsl-ek-core';
import { TownService } from '../../services/town.service';
import { IWSLCompany } from 'wsl-ek-core';
import { IWSLRefreshToken } from 'wsl-ek-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {IWSLRoom} from "@app/room/models/room";
import {environment} from "@env/environment";
import {IWSLAppEnvironment} from "@core/models/app-environment";

@Component({
  selector: 'wsl-b2b-environment-setup',
  templateUrl: './environment-setup.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvironmentSetupComponent implements OnInit, OnDestroy {
  @Input() set companies(companies: IWSLCompany[]) {
    this.availableCompanies = companies.map(c => ({...c, name: c.short_name}));
  }
  @Input() set rooms(rooms: IWSLRoom[]) {
    this.availableRooms = rooms.map(r => ({...r, name: `${r.street} ${r.house_num}, кв. ${r.name}`}))
  }
  @Output() edit = new EventEmitter<IWSLRefreshToken>();
  @Output() close = new EventEmitter();

  /** @internal */
  form = new FormGroup({
    company_id: new FormControl(null),
    town_id: new FormControl(null),
    room_id: new FormControl(null)
  });

  availableCompanies: IWSLCompany[] = [];
  availableRooms: IWSLRoom[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private userProfileService: UserProfileService,
              private companyProfileService: CompanyProfileService,
              private townService: TownService) {
  }

  ngOnInit() {
    this.form.get('company_id').setValue(this.companyProfileService.getCompanyID());
    this.form.get('town_id').setValue(this.townService.getTownID());
    this.form.get('room_id').setValue(this.userProfileService.getRoomID());

    this.form.get('company_id').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(350),
        // delay(150),
        distinctUntilChanged()
      ).subscribe(comp_id => {
      this.form.get('town_id').setValue(this.companyTowns.length > 0 ? this.companyTowns[0].id : null);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }


  get companyTowns() {
    const comp = this.availableCompanies.find(c => c.id === this.form.get('company_id').value);
    return comp ? comp.towns : [];
  }

  save() {
    if (this.form.valid) {
      if (environment.kv) {
        if (this.form.get('room_id').value !== this.userProfileService.getRoomID()) {
          this.edit.emit({room_id: this.form.get('room_id').value})
        } else {
          this.close.emit();
        }
      } else {
        if (this.form.get('company_id').value !== this.companyProfileService.getCompanyID() || this.form.get('town_id').value !== this.townService.getTownID()) {
          this.edit.emit({company_id: this.form.get('company_id').value, town_id: this.form.get('town_id').value});
        } else {
          this.close.emit();
        }
      }
    } else {
      WSLMaterializeHelper.toast({html: 'Есть ошибки заполнения формы'});
    }
  }

}

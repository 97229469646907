import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoomActions, RoomActionTypes } from './room.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromRoot from '@store/reducers/index';
import { IWSLAsyncErrors } from 'wsl-core';
import { IWSLRoom } from '@app/room/models/room';

export interface RoomState extends EntityState<IWSLRoom> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLRoom> = createEntityAdapter<IWSLRoom>({
  selectId: (item: IWSLRoom) => item.id,
  sortComparer: false
});

export const initialState: RoomState = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(state = initialState, action: RoomActions): RoomState {
  switch (action.type) {

    case RoomActionTypes.LoadRoom:
      return {
        ...state,
        // selectedID: null,
        pending: true,
        loaded: false,
        errors: null
      };

    case RoomActionTypes.LoadRoomSuccess:
      return adapter.upsertOne({
        ...action.payload.item
      }, {
        ...state,
        pending: false,
        loaded: true
      });

    case RoomActionTypes.LoadRoomFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case RoomActionTypes.SelectRoom:
      return {...state, selectedID: action.payload};

    case RoomActionTypes.LoadRooms:
      if (!action.payload.offset) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          pending: true,
          loaded: false,
          errors: null
        });
      }
      return {...state, pending: true, loaded: false, errors: null};

    case RoomActionTypes.LoadRoomsSuccess:
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case RoomActionTypes.LoadRoomsFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case RoomActionTypes.ClearRooms:
      return adapter.removeAll({
        ...state,
        totalCount: 0,
        pending: false,
        loaded: true
      });

    default:
      return state;
  }
}

export const getSelectedID = (state: RoomState) => state.selectedID;
export const getTotalCount = (state: RoomState) => state.totalCount;
export const getLoaded = (state: RoomState) => state.loaded;
export const getPending = (state: RoomState) => state.pending;
export const getErrors = (state: RoomState) => state.errors;

export interface State extends fromRoot.State {
  'room': RoomState;
}

export const getRoomState = createFeatureSelector<RoomState>('room');

export const selectRoomSelectedID = createSelector(getRoomState, getSelectedID);
export const selectRoomTotalCount = createSelector(getRoomState, getTotalCount);
export const selectRoomLoaded = createSelector(getRoomState, getLoaded);
export const selectRoomPending = createSelector(getRoomState, getPending);
export const selectRoomErrors = createSelector(getRoomState, getErrors);

export const {
  selectEntities: selectRoomEntities,
  selectAll: selectRoomAll
} = adapter.getSelectors(getRoomState);

export const selectRoom = createSelector(
  selectRoomEntities,
  selectRoomSelectedID,
  (entities, id) => entities && entities[id] ? entities[id] : null
);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWSLBillingCompanyLink, IWSLBillingCompanyLinkFilter } from '@app/billing-company/models/billing-company-link';
import { IWSLEntityFilter, WSLApiHttpClientService } from 'wsl-core';

@Injectable({
  providedIn: 'root'
})
export class BillingCompanyLinkService extends WSLApiHttpClientService<IWSLBillingCompanyLink, IWSLBillingCompanyLinkFilter> {

  constructor(public http: HttpClient) {
    super(http, 'bcompany_link');
  }
}

import * as tslib_1 from "tslib";
var _a;
import { createEntityAdapter } from '@ngrx/entity';
import { DeviceConfGroupFileIndexActionTypes } from '../actions/device-conf-group-file-index.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export var adapter = createEntityAdapter();
export var initialState = adapter.getInitialState({
    // additional entity state properties
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndex:
        case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexes:
            return tslib_1.__assign({}, state, { pending: true, errors: null });
        case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexSuccess:
            return adapter.upsertOne(action.payload.item, tslib_1.__assign({}, state, { pending: false }));
        case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexesSuccess:
            return adapter.upsertMany(action.payload.items, adapter.removeAll(tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true })));
        case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexFail:
        case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexesFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        default: {
            return state;
        }
    }
}
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
export var getErrors = function (state) { return state.errors; };
export var getDeviceConfGroupFileIndexState = createFeatureSelector('deviceConfGroupFileIndex');
export var selectDeviceConfGroupFileIndexTotalCount = createSelector(getDeviceConfGroupFileIndexState, getTotalCount);
export var selectDeviceConfGroupFileIndexLoaded = createSelector(getDeviceConfGroupFileIndexState, getLoaded);
export var selectDeviceConfGroupFileIndexPending = createSelector(getDeviceConfGroupFileIndexState, getPending);
export var selectDeviceConfGroupFileIndexErrors = createSelector(getDeviceConfGroupFileIndexState, getErrors);
export var selectIds = (_a = adapter.getSelectors(getDeviceConfGroupFileIndexState), _a.selectIds), selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;

<div class="wsl-p-x-20">
  <p class="wsl-gray wsl-st6 wsl-text-left">
    Пожалуйста, введите email, который вы указали при создании своей учетной записи, и мы
    направим вам электронное письмо с дальнейшими инструкциями.
  </p>
</div>

<wsl-error-message [message]="asyncErrors?.get('common')"></wsl-error-message>

<form class="wsl-bg-white wsl-p-x-20 wsl-p-y-25 z-depth-1 wsl-hide-bg-xs"
      [formGroup]="form"
      (ngSubmit)="submit()">

  <wsl-input-wrapper label="Email"
                     inputId="email"
                     [required]="true"
                     [asyncError]="asyncErrors?.get('email')">
    <input type="email"
           id="email"
           class="form-control"
           formControlName="email">
  </wsl-input-wrapper>

  <div class="wsl-p-a-5"></div>

  <div class="wsl-flex-center-ng wsl-m-t-20">
    <button class="btn waves-effect waves-light" type="submit">Восстановить пароль</button>
  </div>
</form>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceSensorActions, DeviceSensorActionTypes } from './device-sensor.actions';
import * as fromRoot from '@store/reducers/index';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IWSLAsyncErrors } from 'wsl-core';
import { IWSLDeviceSensor } from 'wsl-device';

export interface DeviceSensorState extends EntityState<IWSLDeviceSensor> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLDeviceSensor> = createEntityAdapter<IWSLDeviceSensor>({
  selectId: (item: IWSLDeviceSensor) => item.id,
  sortComparer: false
});

export const initialState: DeviceSensorState = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(state = initialState, action: DeviceSensorActions): DeviceSensorState {
  switch (action.type) {

    case DeviceSensorActionTypes.AddDeviceSensor:
    case DeviceSensorActionTypes.UpdateDeviceSensor:
    case DeviceSensorActionTypes.DeleteDeviceSensor:
    case DeviceSensorActionTypes.DeleteDeviceSensors:
      return {...state, pending: true, errors: null};

    case DeviceSensorActionTypes.AddDeviceSensorSuccess:
    case DeviceSensorActionTypes.UpdateDeviceSensorSuccess:
    case DeviceSensorActionTypes.DeleteDeviceSensorSuccess:
    case DeviceSensorActionTypes.DeleteDeviceSensorsSuccess:
      return {...state, pending: false};

    case DeviceSensorActionTypes.UpdateDeviceSensorFail:
    case DeviceSensorActionTypes.AddDeviceSensorFail:
    case DeviceSensorActionTypes.DeleteDeviceSensorFail:
    case DeviceSensorActionTypes.DeleteDeviceSensorsFail:
      return {...state, pending: false, errors: action.payload.asyncMessages};

    case DeviceSensorActionTypes.LoadDeviceSensor:
      return {
        ...state,
        // selectedID: null,
        pending: true,
        loaded: false,
        errors: null
      };

    case DeviceSensorActionTypes.LoadDeviceSensorSuccess:
      return adapter.upsertOne({
        ...action.payload.item
      }, {
        ...state,
        pending: false,
        loaded: true
      });

    case DeviceSensorActionTypes.LoadDeviceSensorFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case DeviceSensorActionTypes.SelectDeviceSensor:
      return {...state, selectedID: action.payload};

    case DeviceSensorActionTypes.LoadDeviceSensors:
      if (!action.payload.offset) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          pending: true,
          loaded: false,
          errors: null
        });
      }
      return {...state, pending: true, loaded: false, errors: null};

    case DeviceSensorActionTypes.LoadDeviceSensorsSuccess:
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case DeviceSensorActionTypes.LoadDeviceSensorsFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case DeviceSensorActionTypes.ClearDeviceSensors:
      return adapter.removeAll({
        ...state,
        totalCount: 0,
        pending: false,
        loaded: true
      });

    default:
      return state;
  }
}

export const getSelectedID = (state: DeviceSensorState) => state.selectedID;
export const getTotalCount = (state: DeviceSensorState) => state.totalCount;
export const getLoaded = (state: DeviceSensorState) => state.loaded;
export const getPending = (state: DeviceSensorState) => state.pending;
export const getErrors = (state: DeviceSensorState) => state.errors;

export interface State extends fromRoot.State {
  'deviceSensor': DeviceSensorState;
}

export const getDeviceSensorState = createFeatureSelector<DeviceSensorState>('deviceSensor');

export const selectDeviceSensorSelectedID = createSelector(getDeviceSensorState, getSelectedID);
export const selectDeviceSensorTotalCount = createSelector(getDeviceSensorState, getTotalCount);
export const selectDeviceSensorLoaded = createSelector(getDeviceSensorState, getLoaded);
export const selectDeviceSensorPending = createSelector(getDeviceSensorState, getPending);
export const selectDeviceSensorErrors = createSelector(getDeviceSensorState, getErrors);

export const {
  selectEntities: selectDeviceSensorEntities,
  selectAll: selectDeviceSensorAll
} = adapter.getSelectors(getDeviceSensorState);

export const selectDeviceSensor = createSelector(
  selectDeviceSensorEntities,
  selectDeviceSensorSelectedID,
  (entities, id) => entities && entities[id] ? entities[id] : null
);

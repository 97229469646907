import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLCommunicator } from 'wsl-device';
import { IWSLCommunicatorFilter } from '@app/communicator/models/communicator-filter';

export enum CommunicatorActionTypes {
  AddCommunicator = '[Communicator] Add',
  AddCommunicatorSuccess = '[Communicator] Add Success',
  AddCommunicatorFail = '[Communicator] Add Fail',

  UpdateCommunicator = '[Communicator] Update',
  UpdateCommunicatorSuccess = '[Communicator] Update Success',
  UpdateCommunicatorFail = '[Communicator] Update Fail',

  DeleteCommunicator = '[Communicator] Delete',
  DeleteCommunicatorSuccess = '[Communicator] Delete Success',
  DeleteCommunicatorFail = '[Communicator] Delete Fail',

  SelectCommunicator = '[Communicator] Select',

  LoadCommunicator = '[Communicator] Load',
  LoadCommunicatorSuccess = '[Communicator] Load Success',
  LoadCommunicatorFail = '[Communicator] Load Fail',

  LoadCommunicators = '[Communicator] Load Many',
  LoadCommunicatorsSuccess = '[Communicator] Load Many Success',
  LoadCommunicatorsFail = '[Communicator] Load Many Fail',

  ClearCommunicators = '[Communicator] Clear'
}

/** Add */
export class AddCommunicator implements Action {
  readonly type = CommunicatorActionTypes.AddCommunicator;

  constructor(public payload: IWSLCommunicator) {
  }
}

export class AddCommunicatorSuccess implements Action {
  readonly type = CommunicatorActionTypes.AddCommunicatorSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

export class AddCommunicatorFail implements Action {
  readonly type = CommunicatorActionTypes.AddCommunicatorFail;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

/** Update */
export class UpdateCommunicator implements Action {
  readonly type = CommunicatorActionTypes.UpdateCommunicator;

  constructor(public payload: IWSLCommunicator) {
  }
}

export class UpdateCommunicatorSuccess implements Action {
  readonly type = CommunicatorActionTypes.UpdateCommunicatorSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

export class UpdateCommunicatorFail implements Action {
  readonly type = CommunicatorActionTypes.UpdateCommunicatorFail;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

/** Delete */
export class DeleteCommunicator implements Action {
  readonly type = CommunicatorActionTypes.DeleteCommunicator;

  constructor(public payload: number) {
  }
}

export class DeleteCommunicatorSuccess implements Action {
  readonly type = CommunicatorActionTypes.DeleteCommunicatorSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

export class DeleteCommunicatorFail implements Action {
  readonly type = CommunicatorActionTypes.DeleteCommunicatorFail;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

/** Select */
export class SelectCommunicator implements Action {
  readonly type = CommunicatorActionTypes.SelectCommunicator;

  constructor(public payload: number) {
  }
}

/** Load One */
export class LoadCommunicator implements Action {
  readonly type = CommunicatorActionTypes.LoadCommunicator;

  constructor(public payload: number) {
  }
}

export class LoadCommunicatorSuccess implements Action {
  readonly type = CommunicatorActionTypes.LoadCommunicatorSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

export class LoadCommunicatorFail implements Action {
  readonly type = CommunicatorActionTypes.LoadCommunicatorFail;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

/** Load Many */
export class LoadCommunicators implements Action {
  readonly type = CommunicatorActionTypes.LoadCommunicators;

  constructor(public payload: IWSLCommunicatorFilter) {
  }
}

export class LoadCommunicatorsSuccess implements Action {
  readonly type = CommunicatorActionTypes.LoadCommunicatorsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

export class LoadCommunicatorsFail implements Action {
  readonly type = CommunicatorActionTypes.LoadCommunicatorsFail;

  constructor(public payload: IWSLHttpResponse<IWSLCommunicator>) {
  }
}

export class ClearCommunicators implements Action {
  readonly type = CommunicatorActionTypes.ClearCommunicators;
}

export type CommunicatorActions =
  AddCommunicator |
  AddCommunicatorSuccess |
  AddCommunicatorFail |

  UpdateCommunicator |
  UpdateCommunicatorSuccess |
  UpdateCommunicatorFail |

  DeleteCommunicator |
  DeleteCommunicatorSuccess |
  DeleteCommunicatorFail |

  SelectCommunicator |

  LoadCommunicator |
  LoadCommunicatorSuccess |
  LoadCommunicatorFail |

  LoadCommunicators |
  LoadCommunicatorsSuccess |
  LoadCommunicatorsFail |

  ClearCommunicators;


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWSLBCompanyAccountInfo, IWSLBCompanyDevice, IWSLBCompanyDeviceFilter } from '@app/billing-company/models/b-company-device';
import { Observable } from 'rxjs';
import { IWSLHttpResponse, WSLApiHttpClientService, WSLHttpHelper } from 'wsl-core';

@Injectable()
export class BCompanyDeviceService extends WSLApiHttpClientService<IWSLBCompanyDevice, IWSLBCompanyDeviceFilter> {

  constructor(public http: HttpClient) {
    super(http, 'bcompany_device');
  }

  public accountInfo(bcompany_account_id: number): Observable<IWSLHttpResponse<IWSLBCompanyAccountInfo>> {
    return this.http.get<IWSLHttpResponse<IWSLBCompanyAccountInfo>>('get_account_tric', {params: WSLHttpHelper.getParams({bcompany_account_id})});
  }
}

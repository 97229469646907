import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DeviceConfGroupFileIndexService } from '@app/device-conf-group/services/device-conf-group-file-index.service';
import { catchError, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { DeviceConfGroupFileIndexActionTypes, LoadDeviceConfGroupFileIndexesFail, LoadDeviceConfGroupFileIndexesSuccess, LoadDeviceConfGroupFileIndexFail, LoadDeviceConfGroupFileIndexSuccess } from '@store/device-conf-group-file-index/actions/device-conf-group-file-index.actions';
import { StoreHelperService } from '@core/services/store-helper.service';
var DeviceConfGroupFileIndexEffects = /** @class */ (function () {
    function DeviceConfGroupFileIndexEffects(actions$, service, storeHelper) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.storeHelper = storeHelper;
        this.load$ = this.actions$.pipe(ofType(DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndex), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndex), skip(1));
            return _this.service.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceConfGroupFileIndexSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceConfGroupFileIndexFail); }));
        }));
        this.loadMany$ = this.actions$.pipe(ofType(DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexes), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexes), skip(1));
            return _this.service.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceConfGroupFileIndexesSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceConfGroupFileIndexesFail); }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupFileIndexEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupFileIndexEffects.prototype, "loadMany$", void 0);
    return DeviceConfGroupFileIndexEffects;
}());
export { DeviceConfGroupFileIndexEffects };

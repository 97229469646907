import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromCommunicator from '@store/communicator/communicator.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CommunicatorEffects } from '@store/communicator/communicator.effects';
import { WslSharedModule } from 'wsl-shared';
import { AppCommonModule } from '@common/app-common.module';
import { CommunicatorService } from './services/communicator.service';
import { CommunicatorFormComponent } from './components/communicator-form/communicator-form.component';
import { CommunicatorExistsGuard } from './guard/communicator-exists.guard';
import { CommunicatorListComponent } from './components/communicator-list/communicator-list.component';
import { CommunicatorListFilterComponent } from './components/communicator-list-filter/communicator-list-filter.component';
import { CommunicatorRowComponent } from './components/communicator-row/communicator-row.component';
import {CommunicatorInfoComponent} from '@app/communicator/components/communicator-info/communicator-info.component';

const COMPONENTS = [
  CommunicatorFormComponent,
  CommunicatorListComponent,
  CommunicatorListFilterComponent,
  CommunicatorRowComponent,
  CommunicatorInfoComponent
];

@NgModule({
  imports: [
    WslSharedModule,
    AppCommonModule,
    StoreModule.forFeature('communicator', fromCommunicator.reducer),
    EffectsModule.forFeature([CommunicatorEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    CommunicatorService,

    CommunicatorExistsGuard
  ]
})
export class CommunicatorModule { }

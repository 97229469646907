import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {CompanyRoleType, KV_ROLES} from 'wsl-ek-core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IWSLAsyncErrors, WSLFormHelper, WSLFormValidators, WSLUtils } from 'wsl-core';
import {
  EK_ROLES,
  OPERATOR_ROLES,
  TS_ROLES,
  UC_ROLES,
  HOME_ROLES,
  UserPermissions,
  UserProfileService,
  UserRoleType
} from 'wsl-ek-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { IWSLUser } from 'wsl-user';
import { IWSLCompany } from 'wsl-ek-core';
import { CompanyProfileService } from 'wsl-ek-core';
import {environment} from '@env/environment';
import {IWSLRoom} from '@app/room/models/room';
import {IWSLAppEnvironment} from '@core/models/app-environment';

@Component({
  selector: 'wsl-b2b-user-profile-settings',
  templateUrl: './user-profile-settings.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() appEnv: IWSLAppEnvironment;
  @Input() user: IWSLUser;
  @Input() company: IWSLCompany;
  @Input() companies: IWSLCompany[];
  @Input() rooms: IWSLRoom[];
  @Input() asyncErrors: IWSLAsyncErrors;
  @Output() save = new EventEmitter<{ user: IWSLUser, refresh: boolean }>();
  @Output() load = new EventEmitter<number>();

  /** @internal */
  form = new FormGroup({
    id: new FormControl(null),
    default_company_id: new FormControl(null),
    default_town_id: new FormControl(null),
    default_room_id: new FormControl(null),
    role_ids: new FormControl([]),
    role_controller: new FormControl(false),
    role_reader: new FormControl(false),
    role_demo: new FormControl(false),
    password: new FormControl(''),
    password_confirmation: new FormControl('')
  }, {
    validators: WSLFormValidators.matchingPasswords('password', 'password_confirmation')
  });

  showReader = false;
  showDemo = false;
  multipleRoles = false;
  roles = [];

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private userProfileService: UserProfileService,
              private companyService: CompanyProfileService,
              private chr: ChangeDetectorRef) {
  }

  ngOnInit() {
    let roles = [];
    switch (this.companyService.getRoleId()) {
      case CompanyRoleType.uc:
        roles = [...UC_ROLES.filter(role => ![UserRoleType.demo, UserRoleType.reader, UserRoleType.controller].includes(role.id))];
        this.multipleRoles = !this.userProfileService.isOwner() && !this.userProfileService.isRenter() && !this.userProfileService.isController();
        break;
      case CompanyRoleType.data_provider:
        roles = [...OPERATOR_ROLES];
        this.multipleRoles = true;
        break;
      case CompanyRoleType.tech_support:
        roles = [...TS_ROLES];
        this.multipleRoles = true;
        break;
      case CompanyRoleType.home_users:
        roles = [...HOME_ROLES];
        this.multipleRoles = false;
        break;
      default:
        roles = [];
    }
    if (environment.kv) {
      roles = [...KV_ROLES];
      this.multipleRoles = false;
    }

   this.roles = roles.map(role => ({...role}));
    /*if (this.isMyProfile && this.userProfileService.hasRole(EK_ROLES.admin)) {
      const adminRole = this.roles.find(r => r.id === UserRoleType.admin);
      if (adminRole) {
        adminRole.disabled = true;
      }
      this.roles = this.roles.slice(0);
    }*/
    /*if (this.userProfileService.hasRole(EK_ROLES.admin)) {
      const adminRole = this.roles.find(r => r.id === UserRoleType.admin);
      if (adminRole) {
        adminRole.disabled = true;
      }
    }*/
    this.fillForm();

    this.form.get('default_company_id').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(350),
        // delay(150),
        distinctUntilChanged()
      ).subscribe(comp_id => {
      this.form.get('default_town_id').setValue(this.companyTowns.length > 0 ? this.companyTowns[0].id : null);
      this.chr.markForCheck();
    });

    this.form.get('role_reader').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
      ).subscribe(val => {
      if (this.form.get('role_reader').dirty) {
        if (val) {
          this.form.get('role_demo').setValue(false, {emitEvent: false});
          this.form.get('role_demo').disable({emitEvent: false});
          this.form.get('role_controller').setValue(false, {emitEvent: false});
          this.form.get('role_controller').disable({emitEvent: false});
          this.form.get('role_ids').disable({emitEvent: false});
        } else {
          this.form.get('role_demo').setValue(false, {emitEvent: false});
          this.form.get('role_demo').enable({emitEvent: false});
          this.form.get('role_controller').setValue(false, {emitEvent: false});
          this.form.get('role_controller').enable({emitEvent: false});
          this.form.get('role_ids').enable({emitEvent: false});
        }
        this.chr.markForCheck();
      }
    });
    this.form.get('role_demo').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
      ).subscribe(val => {
      if (this.form.get('role_demo').dirty) {
        if (val) {
          this.form.get('role_reader').setValue(false, {emitEvent: false});
          this.form.get('role_reader').disable({emitEvent: false});
          this.form.get('role_controller').setValue(false, {emitEvent: false});
          this.form.get('role_controller').disable({emitEvent: false});
          this.form.get('role_ids').disable({emitEvent: false});
        } else {
          this.form.get('role_reader').setValue(false, {emitEvent: false});
          this.form.get('role_reader').enable({emitEvent: false});
          this.form.get('role_controller').setValue(false, {emitEvent: false});
          this.form.get('role_controller').enable({emitEvent: false});
          this.form.get('role_ids').enable({emitEvent: false});
        }
        this.chr.markForCheck();
      }
    });

    this.form.get('role_controller').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
      ).subscribe(val => {
      if (this.form.get('role_controller').dirty) {
        if (val) {
          this.form.get('role_reader').setValue(false, {emitEvent: false});
          this.form.get('role_reader').disable({emitEvent: false});
          this.form.get('role_demo').setValue(false, {emitEvent: false});
          this.form.get('role_demo').disable({emitEvent: false});
          this.form.get('role_ids').disable({emitEvent: false});
        } else {
          this.form.get('role_reader').setValue(false, {emitEvent: false});
          this.form.get('role_reader').enable({emitEvent: false});
          this.form.get('role_demo').setValue(false, {emitEvent: false});
          this.form.get('role_demo').enable({emitEvent: false});
          this.form.get('role_ids').enable({emitEvent: false});
        }
        this.chr.markForCheck();
      }
    });
  }

  ngOnChanges(changes) {
    if (changes.user) {
      this.fillForm();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  get isMyProfile() {
    return this.user && this.user.id === this.userProfileService.getUserID();
  }


  get isAdmin() {
    return this.userProfileService.isAdmin();
  }

  get availableCompanies() {
    return this.companies.map(c => ({id: c.id, name: c.short_name}));
  }


  get availableRooms() {
    return this.rooms.map(r => ({...r, name: `${r.street} ${r.house_num}, кв. ${r.name}`}));
  }

  get companyTowns() {
    const comp = this.companies.find(c => c.id === this.form.get('default_company_id').value);
    return comp ? comp.towns : [];
  }

  onSave() {
    if (this.form.dirty && this.form.valid) {
      if (this.userProfileService.isDemo()) {
        // @todo show demo user message
      } else {
        const data = this.form.getRawValue();
        if (data.role_demo) {
          data.role_ids = [UserRoleType.demo];
        }
        if (data.role_reader) {
          data.role_ids = [UserRoleType.reader];
        }
        if (data.role_controller) {
          data.role_ids = [UserRoleType.controller];
        }
        delete data.role_demo;
        delete data.role_reader;
        delete data.role_controller;
        if (data.role_ids.length < 1) {
          WSLMaterializeHelper.toast({html: 'Укажите роль для пользователя'});
        } else {
          this.save.emit({
            user: {...this.user, ...data},
            refresh: this.isMyProfile && /*((!!data.password && data.password !== '') ||*/ WSLUtils.isDifferentArrays(data.role_ids, this.user.role_ids)});
        }
      }
    } else {
      WSLMaterializeHelper.toast({html: 'Есть ошибки заполнения формы'});
    }
  }

  onCancel() {
    if (this.form.dirty) {
      this.load.emit(this.user.id);
    }
  }

  onSelectRoles(roles) {
    if (WSLUtils.isDifferentArrays(roles, this.form.get('role_ids').value)) {
      this.form.get('role_ids').setValue(roles);
      this.form.get('role_ids').markAsDirty();
    }
  }

  onSelectRole(roles) {
    if (WSLUtils.isDifferentArrays([roles], this.form.get('role_ids').value)) {
      this.form.get('role_ids').setValue([roles]);
      this.form.get('role_ids').markAsDirty();
    }
  }

  trackById(index: number, obj: any): any {
    return obj.id;
  }

  private fillForm() {
    this.form.reset();
    this.showReader =  this.appEnv.isUc; // && !(this.isMyProfile && this.userProfileService.hasPermission(UserPermissions.update));
    this.showDemo = false; // this.companyService.isUC();
    WSLFormHelper.fillForm(this.form, this.user);
    if ((this.userProfileService.hasPermission(UserPermissions.update) || this.isMyProfile) && !this.userProfileService.isReader() && !this.userProfileService.isDemo()) {
      if (this.appEnv.isUc) {
        this.form.enable();
        WSLFormHelper.enableAll(this.form);
        const adminRole = this.roles.find(r => r.id === UserRoleType.admin);
        if (adminRole) {
          adminRole.disabled = this.isMyProfile && this.userProfileService.hasRole(EK_ROLES.admin);
        }
        this.roles = this.roles.slice(0);
        if (!this.userProfileService.isAdmin()) {
          this.form.get('role_reader').disable();
          this.form.get('role_demo').disable();
          this.form.get('role_ids').disable();
        }
        if (this.isMyProfile && this.userProfileService.hasPermission(UserPermissions.update)) {
          this.form.get('role_reader').disable();
        }
      } else if (this.companyService.isHomeUsers()) {
        this.form.get('role_ids').disable();
      } else if (environment.kv) {
        if (this.userProfileService.isOwner() && !this.isMyProfile && this.user.role_ids.includes(UserRoleType.renter)) {
          /** арендатора можем повысить до проживающего */
          this.form.get('role_ids').enable();
        } else {
          this.form.get('role_ids').disable();
        }
      } else if (this.companyService.isDP() || this.companyService.isTS()) {
        this.form.enable();
        WSLFormHelper.enableAll(this.form);
        const adminRole = this.roles.find(r => r.id === UserRoleType.admin);
        if (adminRole) {
          adminRole.disabled = this.isMyProfile && this.userProfileService.hasRole(EK_ROLES.admin);
        }
        this.roles = this.roles.slice(0);
        if (!this.userProfileService.isAdmin()) {
          this.form.get('role_ids').disable();
        }
      }
    } else {
      this.form.disable();
      WSLFormHelper.disableAll(this.form);
    }
    if (this.user && this.user.role_ids.every(role_id => role_id === UserRoleType.reader || role_id === UserRoleType.demo)) { // @todo merge demo with reader
      this.form.get('role_reader').setValue(true, {emitEvent: false});
      this.form.get('role_demo').setValue(false, {emitEvent: false});
      this.form.get('role_demo').disable({emitEvent: false});
      this.form.get('role_ids').setValue([], {emitEvent: false});
      this.form.get('role_ids').disable({emitEvent: false});
    }
    /*if (this.user && this.user.role_ids.every(role_id => role_id === UserRoleType.demo)) {
      this.form.get('role_demo').setValue(true, {emitEvent: false});
      this.form.get('role_reader').setValue(false, {emitEvent: false});
      this.form.get('role_reader').disable({emitEvent: false});
      this.form.get('role_ids').setValue([], {emitEvent: false});
      this.form.get('role_ids').disable({emitEvent: false});
    }*/
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }
}

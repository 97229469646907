/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./promotion-snack-bar.component";
import * as i4 from "@angular/material/snack-bar";
var styles_PromotionSnackBarComponent = [];
var RenderType_PromotionSnackBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PromotionSnackBarComponent, data: {} });
export { RenderType_PromotionSnackBarComponent as RenderType_PromotionSnackBarComponent };
export function View_PromotionSnackBarComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "wsl-relative wsl-p-y-5 wsl-p-x-10 wsl-text-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0410\u043A\u0446\u0438\u044F! \u041F\u043E\u043B\u043D\u0430\u044F \u0432\u0435\u0440\u0441\u0438\u044F \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u044B \u042D\u043D\u0435\u0440\u0433\u043E\u041A\u0430\u0431\u0438\u043D\u0435\u0442 \u043D\u0430 90 \u0434\u043D\u0435\u0439! \u041F\u043E\u0434\u0440\u043E\u0431\u043D\u043E\u0441\u0442\u0438 \u0432 "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.dismissSnackbar() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["\u043F\u0440\u043E\u0444\u0438\u043B\u0435 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "icon-cross wsl-st4 wsl-red wsl-hover wsl-abs wsl-abs-right wsl-abs-top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissSnackbar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_2 = "/settings/company"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_PromotionSnackBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wsl-promotion-snack-bar", [], null, null, null, View_PromotionSnackBarComponent_0, RenderType_PromotionSnackBarComponent)), i0.ɵdid(1, 114688, null, 0, i3.PromotionSnackBarComponent, [i4.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromotionSnackBarComponentNgFactory = i0.ɵccf("wsl-promotion-snack-bar", i3.PromotionSnackBarComponent, View_PromotionSnackBarComponent_Host_0, {}, {}, []);
export { PromotionSnackBarComponentNgFactory as PromotionSnackBarComponentNgFactory };

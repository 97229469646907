export enum MainMenuType {
  data = 1,
  monitoring = 2,
  reports = 3,
  service = 4,
  settings = 5,
  journal = 6,
  operator = 50,
  support = 100,
}

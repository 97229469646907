import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WSLApiHttpClientService } from 'wsl-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BillingCompanyLinkService = /** @class */ (function (_super) {
    tslib_1.__extends(BillingCompanyLinkService, _super);
    function BillingCompanyLinkService(http) {
        var _this = _super.call(this, http, 'bcompany_link') || this;
        _this.http = http;
        return _this;
    }
    BillingCompanyLinkService.ngInjectableDef = i0.defineInjectable({ factory: function BillingCompanyLinkService_Factory() { return new BillingCompanyLinkService(i0.inject(i1.HttpClient)); }, token: BillingCompanyLinkService, providedIn: "root" });
    return BillingCompanyLinkService;
}(WSLApiHttpClientService));
export { BillingCompanyLinkService };

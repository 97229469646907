import {Action} from '@ngrx/store';
import {IWSLDeviceConfGroupFileIndex, IWSLDeviceConfGroupFileIndexFilter} from '@app/device-conf-group/models/device-conf-group-file-index.model';
import {IWSLHttpResponse} from 'wsl-core';

export enum DeviceConfGroupFileIndexActionTypes {
  LoadDeviceConfGroupFileIndex = '[DeviceConfGroupFileIndex] Load File Index',
  LoadDeviceConfGroupFileIndexSuccess = '[DeviceConfGroupFileIndex] Load File Index Success',
  LoadDeviceConfGroupFileIndexFail = '[DeviceConfGroupFileIndex] Load File Index Fail',

  LoadDeviceConfGroupFileIndexes = '[DeviceConfGroupFileIndex] Load File Indexes',
  LoadDeviceConfGroupFileIndexesSuccess = '[DeviceConfGroupFileIndex] Load File Indexes Success',
  LoadDeviceConfGroupFileIndexesFail = '[DeviceConfGroupFileIndex] Load File Indexes Fail',
}

export class LoadDeviceConfGroupFileIndex implements Action {
  readonly type = DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndex;

  constructor(public payload: number) {}
}

export class LoadDeviceConfGroupFileIndexSuccess implements Action {
  readonly type = DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroupFileIndex>) {}
}

export class LoadDeviceConfGroupFileIndexFail implements Action {
  readonly type = DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroupFileIndex>) {}
}

export class LoadDeviceConfGroupFileIndexes implements Action {
  readonly type = DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexes;

  constructor(public payload: IWSLDeviceConfGroupFileIndexFilter) {}
}

export class LoadDeviceConfGroupFileIndexesSuccess implements Action {
  readonly type = DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexesSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroupFileIndex>) {}
}

export class LoadDeviceConfGroupFileIndexesFail implements Action {
  readonly type = DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexesFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroupFileIndex>) {}
}
export type DeviceConfGroupFileIndexActions =
  LoadDeviceConfGroupFileIndex |
  LoadDeviceConfGroupFileIndexSuccess |
  LoadDeviceConfGroupFileIndexFail |
  LoadDeviceConfGroupFileIndexes |
  LoadDeviceConfGroupFileIndexesSuccess |
  LoadDeviceConfGroupFileIndexesFail;

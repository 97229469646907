<div class="profile-sidenav wsl-side-nav" [wslClickOutsideListen]="opened"
     (wslClickOutside)="closeSideNav()">

    <div class="wsl-flex-column-ng" style="min-height: 100%;">
        <div class="wsl-p-y-5 wsl-p-x-15 wsl-bg-black wsl-white wsl-flex-spaceBetween-middle-ng" style="height: 44px;">
            <span>{{(user$|async)?.first_name}} {{(user$|async)?.last_name}}</span>
            <a class="wsl-white wsl-st4 wsl-p-x-0" (click)="closeSideNav()">
                <span class="icon-cross"></span>
            </a>
        </div>

        <div class="wsl-flex-column wsl-p-x-15 wsl-p-t-15" *ngIf="isMyProfile" style="background: #fcfcfc;">
            <wsl-b2b-environment-setup [companies]="companies$|async"
                                       [rooms]="rooms$|async"
                                       (edit)="onEditEnvironment($event)"
                                       (close)="closeSideNav()"></wsl-b2b-environment-setup>
        </div>

        <div class="wsl-flex-column-spaceBetween-ng" style="flex-grow: 1;" *ngIf="(user$|async)?.id">
            <ul class="profile_collapsible collapsible wsl-m-y-0 z-depth-0"
                style="background: #fcfcfc;">
                <li>
                    <div class="collapsible-header wsl-bg-gray-lightest wsl-relative">
                        Личные данные
                    </div>
                    <div class="collapsible-body wsl-p-a-0">
                        <wsl-user-profile [user]="user$|async"
                                          [asyncErrors]="asyncErrors$|async"
                                          (visibleCalendar)="onToggleCalendar($event)"
                                          (save)="onUpdateUser($event)"
                                          (load)="onLoadUser($event)"></wsl-user-profile>

                    </div>
                </li>
                <li>
                    <div class="collapsible-header wsl-bg-gray-lightest wsl-relative">
                        Настройки
                    </div>
                    <div class="collapsible-body wsl-p-a-0">
                        <wsl-b2b-user-profile-settings [appEnv]="appEnv$|async"
                                                       [user]="user$|async"
                                                       [company]="company$|async"
                                                       [companies]="companies$|async"
                                                       [rooms]="rooms$|async"
                                                       [asyncErrors]="asyncErrors$|async"
                                                       (save)="onUpdateUser($event)"
                                                       (load)="onLoadUser($event)"></wsl-b2b-user-profile-settings>
                    </div>
                </li>
                <!--<li *ngIf="isMyProfile">
                    <div class="collapsible-header wsl-bg-gray-lightest" (click)="toState(2)">Моя Компания</div>
                    <div class="collapsible-body wsl-p-a-0">
                        &lt;!&ndash;<wsl-user-company *ngIf="state=='company' && user.id"></wsl-user-company>&ndash;&gt;
                        &lt;!&ndash;     <wsl-company *ngIf="state=='company'" [isUserCompanyProfile]="true"></wsl-company>&ndash;&gt;
                    </div>
                </li>-->
            </ul>
        </div>
    </div>

</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'wsl-relative-backdrop',
  templateUrl: './backdrop.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate('.3s ease')
      ]),
      transition('* => void', [
        animate('.3s ease', style({
          opacity: 0,
        }))
      ])
    ])
  ],
})
export class BackdropComponent {
  @Input() isActive: boolean;
  @Input() isFull = true;
  @Input() showProgress = false;
  @Input() showFreeAccessMessage = false;
}

import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLServiceProcedure } from '@app/service-procedure/models/service-procedure';
import { IWSLServiceProcedureFilter } from '@app/service-procedure/models/service-procedure-filter';

export enum ServiceProcedureActionTypes {
  AddServiceProcedure = '[ServiceProcedure] Add',
  AddServiceProcedureSuccess = '[ServiceProcedure] Add Success',
  AddServiceProcedureFail = '[ServiceProcedure] Add Fail',

  UpdateServiceProcedure = '[ServiceProcedure] Update',
  UpdateServiceProcedureSuccess = '[ServiceProcedure] Update Success',
  UpdateServiceProcedureFail = '[ServiceProcedure] Update Fail',

  DeleteServiceProcedure = '[ServiceProcedure] Delete',
  DeleteServiceProcedureSuccess = '[ServiceProcedure] Delete Success',
  DeleteServiceProcedureFail = '[ServiceProcedure] Delete Fail',

  SelectServiceProcedure = '[ServiceProcedure] Select',

  LoadServiceProcedure = '[ServiceProcedure] Load',
  LoadServiceProcedureSuccess = '[ServiceProcedure] Load Success',
  LoadServiceProcedureFail = '[ServiceProcedure] Load Fail',

  LoadServiceProcedures = '[ServiceProcedure] Load Many',
  LoadServiceProceduresSuccess = '[ServiceProcedure] Load Many Success',
  LoadServiceProceduresFail = '[ServiceProcedure] Load Many Fail',

  ClearServiceProcedures = '[ServiceProcedure] Clear'
}


/** Add */
export class AddServiceProcedure implements Action {
  readonly type = ServiceProcedureActionTypes.AddServiceProcedure;

  constructor(public payload: IWSLServiceProcedure) {
  }
}

export class AddServiceProcedureSuccess implements Action {
  readonly type = ServiceProcedureActionTypes.AddServiceProcedureSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

export class AddServiceProcedureFail implements Action {
  readonly type = ServiceProcedureActionTypes.AddServiceProcedureFail;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

/** Update */
export class UpdateServiceProcedure implements Action {
  readonly type = ServiceProcedureActionTypes.UpdateServiceProcedure;

  constructor(public payload: IWSLServiceProcedure) {
  }
}

export class UpdateServiceProcedureSuccess implements Action {
  readonly type = ServiceProcedureActionTypes.UpdateServiceProcedureSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

export class UpdateServiceProcedureFail implements Action {
  readonly type = ServiceProcedureActionTypes.UpdateServiceProcedureFail;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

/** Delete */
export class DeleteServiceProcedure implements Action {
  readonly type = ServiceProcedureActionTypes.DeleteServiceProcedure;

  constructor(public payload: number) {
  }
}

export class DeleteServiceProcedureSuccess implements Action {
  readonly type = ServiceProcedureActionTypes.DeleteServiceProcedureSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

export class DeleteServiceProcedureFail implements Action {
  readonly type = ServiceProcedureActionTypes.DeleteServiceProcedureFail;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

/** Select */
export class SelectServiceProcedure implements Action {
  readonly type = ServiceProcedureActionTypes.SelectServiceProcedure;

  constructor(public payload: number) {
  }
}

/** Load One */
export class LoadServiceProcedure implements Action {
  readonly type = ServiceProcedureActionTypes.LoadServiceProcedure;

  constructor(public payload: number) {
  }
}

export class LoadServiceProcedureSuccess implements Action {
  readonly type = ServiceProcedureActionTypes.LoadServiceProcedureSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

export class LoadServiceProcedureFail implements Action {
  readonly type = ServiceProcedureActionTypes.LoadServiceProcedureFail;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

/** Load Many */
export class LoadServiceProcedures implements Action {
  readonly type = ServiceProcedureActionTypes.LoadServiceProcedures;

  constructor(public payload: IWSLServiceProcedureFilter) {
  }
}

export class LoadServiceProceduresSuccess implements Action {
  readonly type = ServiceProcedureActionTypes.LoadServiceProceduresSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

export class LoadServiceProceduresFail implements Action {
  readonly type = ServiceProcedureActionTypes.LoadServiceProceduresFail;

  constructor(public payload: IWSLHttpResponse<IWSLServiceProcedure>) {
  }
}

export class ClearServiceProcedures implements Action {
  readonly type = ServiceProcedureActionTypes.ClearServiceProcedures;
}

export type ServiceProcedureActions =
  AddServiceProcedure |
  AddServiceProcedureSuccess |
  AddServiceProcedureFail |

  UpdateServiceProcedure |
  UpdateServiceProcedureSuccess |
  UpdateServiceProcedureFail |

  DeleteServiceProcedure |
  DeleteServiceProcedureSuccess |
  DeleteServiceProcedureFail |

  SelectServiceProcedure |

  LoadServiceProcedure |
  LoadServiceProcedureSuccess |
  LoadServiceProcedureFail |

  LoadServiceProcedures |
  LoadServiceProceduresSuccess |
  LoadServiceProceduresFail |

  ClearServiceProcedures;

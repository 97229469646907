import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import * as fromObject from '@store/object/object.reducer';
import { catchError, filter, map, mergeMap, skip, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { StoreHelperService } from '@core/services/store-helper.service';
import { ObjectService } from '@app/object/services/object.service';
import {
  GenerateActivationCodeForObject,
  GenerateActivationCodeForObjectFail,
  GenerateActivationCodeForObjectSuccess,
  LoadObject,
  LoadObjectFail,
  LoadObjects,
  LoadObjectsFail,
  LoadObjectsSuccess,
  LoadObjectSuccess,
  ObjectActionTypes,
  UpdateObject,
  UpdateObjectFail,
  UpdateObjectSuccess
} from './object.actions';

@Injectable()
export class ObjectEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(ObjectActionTypes.LoadObject),
    map((action: LoadObject) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(ObjectActionTypes.LoadObject), skip(1));

      return this.objectService.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadObjectSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadObjectFail))
        );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(ObjectActionTypes.UpdateObject),
    map((action: UpdateObject) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(ObjectActionTypes.UpdateObject), skip(1));

      return this.objectService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateObjectSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateObjectFail))
        );
    })
  );

  @Effect()
  updateSuccess$ = this.actions$.pipe(
    ofType(ObjectActionTypes.UpdateObjectSuccess),
    withLatestFrom(this.store$.pipe(select(fromObject.selectObjectSelectedID))),
    map(([action, id]) => id),
    map(id => new LoadObject(id))
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(ObjectActionTypes.LoadObjects),
    map((action: LoadObjects) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(ObjectActionTypes.LoadObjects), skip(1));

      return this.objectService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadObjectsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadObjectsFail))
        );
    })
  );

  @Effect()
  generateActivationCode$ = this.actions$.pipe(
    ofType(ObjectActionTypes.GenerateActivationCodeForObject),
    map((action: GenerateActivationCodeForObject) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(ObjectActionTypes.GenerateActivationCodeForObject), skip(1));

      return this.objectService.generateActivationCode(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, GenerateActivationCodeForObjectSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, GenerateActivationCodeForObjectFail))
        );
    })
  );

  @Effect()
  startLoad$ = this.actions$
    .pipe(
      ofType(
        ObjectActionTypes.LoadObject,
        ObjectActionTypes.UpdateObject,
        ObjectActionTypes.GenerateActivationCodeForObject),
      map(() => new StartLoading('object'))
    );

  @Effect()
  startLoadMany$ = this.actions$
    .pipe(
      ofType(ObjectActionTypes.LoadObjects),
      map((action: LoadObjects) => action.payload),
      filter((payload) => payload.offset === 0),
      map(() => new StartLoading('object'))
    );


  @Effect()
  stopLoad$ = this.actions$
    .pipe(
      ofType(
        ObjectActionTypes.LoadObjectSuccess, ObjectActionTypes.LoadObjectFail,
        ObjectActionTypes.LoadObjectsSuccess, ObjectActionTypes.LoadObjectsFail,
        ObjectActionTypes.UpdateObjectSuccess, ObjectActionTypes.UpdateObjectFail,
        ObjectActionTypes.GenerateActivationCodeForObjectSuccess, ObjectActionTypes.GenerateActivationCodeForObjectFail
      ),
      map(() => new StopLoading('object'))
    );

  constructor(private actions$: Actions,
              private storeHelper: StoreHelperService,
              private store$: Store<fromObject.State>,
              private objectService: ObjectService) {
  }
}

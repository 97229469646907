import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@store/reducers/index';
import { catchError, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { WSLLocalStorageService } from 'wsl-core';
import { StorageKey, UserProfileService } from 'wsl-ek-core';
import { LoginRedirect, LoginSuccess, Logout } from '@store/actions/auth.actions';
import { LoadUserProfileSuccess } from '@store/actions/user-profile.actions';
import { of } from 'rxjs';

@Injectable()
export class AuthorizedGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private store: Store<fromRoot.State>,
              private userService: UserProfileService,
              private localStorage: WSLLocalStorageService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(state.url);
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(null);
  }

  private check(url: string) {
    return this.store
      .pipe(
        select(fromRoot.selectLoggedIn),
        filter(loaded => loaded),
        take(1),
        timeout(100),
        catchError(() => of(false)),
        switchMap(authed => {
          if (url) {
            AuthService.redirectURL = url;
          }
          if (!authed) {
            if (this.localStorage.get(StorageKey.token)) {
              return this.userService
                .get()
                .pipe(
                  // map(user => new profile.GetUserProfileSuccess({user})),
                  // tap((action: profile.GetUserProfileSuccess) => this.store.dispatch(action)),
                  tap(response => {
                   // this.store.dispatch(new LoginSuccess({token: this.localStorage.get(StorageKey.token)}));
                    this.store.dispatch(new LoadUserProfileSuccess(response));
                  }),
                  map(res => true),
                  catchError((response) => {
                    this.store.dispatch(new Logout());
                    return of(false);
                  })
                );
            } else {
              this.store.dispatch(new LoginRedirect());
              return of(false);
            }
          }
          return of(true);
        }),
        take(1)
      );
  }
}

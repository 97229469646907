import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLDataConf } from '@app/device-conf-ext/models/data-conf';
import { IWSLDataConfFilter } from '@app/device-conf-ext/models/data-conf-filter';

export enum DeviceConfExtActionTypes {
  AddDeviceConfExt = '[DeviceConfExt] Add',
  AddDeviceConfExtSuccess = '[DeviceConfExt] Add Success',
  AddDeviceConfExtFail = '[DeviceConfExt] Add Fail',

  UpdateDeviceConfExt = '[DeviceConfExt] Update',
  UpdateDeviceConfExtSuccess = '[DeviceConfExt] Update Success',
  UpdateDeviceConfExtFail = '[DeviceConfExt] Update Fail',

  DeleteDeviceConfExt = '[DeviceConfExt] Delete',
  DeleteDeviceConfExtSuccess = '[DeviceConfExt] Delete Success',
  DeleteDeviceConfExtFail = '[DeviceConfExt] Delete Fail',

  SelectDeviceConfExt = '[DeviceConfExt] Select',

  LoadDeviceConfExt = '[DeviceConfExt] Load',
  LoadDeviceConfExtSuccess = '[DeviceConfExt] Load Success',
  LoadDeviceConfExtFail = '[DeviceConfExt] Load Fail',

  LoadDeviceConfExts = '[DeviceConfExt] Load Many',
  LoadDeviceConfExtsSuccess = '[DeviceConfExt] Load Many Success',
  LoadDeviceConfExtsFail = '[DeviceConfExt] Load Many Fail',

  ClearDeviceConfExts = '[DeviceConfExt] Clear'
}

/** Add */
export class AddDeviceConfExt implements Action {
  readonly type = DeviceConfExtActionTypes.AddDeviceConfExt;

  constructor(public payload: IWSLDataConf) {
  }
}

export class AddDeviceConfExtSuccess implements Action {
  readonly type = DeviceConfExtActionTypes.AddDeviceConfExtSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

export class AddDeviceConfExtFail implements Action {
  readonly type = DeviceConfExtActionTypes.AddDeviceConfExtFail;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

/** Update */
export class UpdateDeviceConfExt implements Action {
  readonly type = DeviceConfExtActionTypes.UpdateDeviceConfExt;

  constructor(public payload: IWSLDataConf) {
  }
}

export class UpdateDeviceConfExtSuccess implements Action {
  readonly type = DeviceConfExtActionTypes.UpdateDeviceConfExtSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

export class UpdateDeviceConfExtFail implements Action {
  readonly type = DeviceConfExtActionTypes.UpdateDeviceConfExtFail;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

/** Delete */
export class DeleteDeviceConfExt implements Action {
  readonly type = DeviceConfExtActionTypes.DeleteDeviceConfExt;

  constructor(public payload: number) {
  }
}

export class DeleteDeviceConfExtSuccess implements Action {
  readonly type = DeviceConfExtActionTypes.DeleteDeviceConfExtSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

export class DeleteDeviceConfExtFail implements Action {
  readonly type = DeviceConfExtActionTypes.DeleteDeviceConfExtFail;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

/** Select */
export class SelectDeviceConfExt implements Action {
  readonly type = DeviceConfExtActionTypes.SelectDeviceConfExt;

  constructor(public payload: number) {
  }
}

/** Load One */
export class LoadDeviceConfExt implements Action {
  readonly type = DeviceConfExtActionTypes.LoadDeviceConfExt;

  constructor(public payload: number) {
  }
}

export class LoadDeviceConfExtSuccess implements Action {
  readonly type = DeviceConfExtActionTypes.LoadDeviceConfExtSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

export class LoadDeviceConfExtFail implements Action {
  readonly type = DeviceConfExtActionTypes.LoadDeviceConfExtFail;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

/** Load Many */
export class LoadDeviceConfExts implements Action {
  readonly type = DeviceConfExtActionTypes.LoadDeviceConfExts;

  constructor(public payload: IWSLDataConfFilter) {
  }
}

export class LoadDeviceConfExtsSuccess implements Action {
  readonly type = DeviceConfExtActionTypes.LoadDeviceConfExtsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

export class LoadDeviceConfExtsFail implements Action {
  readonly type = DeviceConfExtActionTypes.LoadDeviceConfExtsFail;

  constructor(public payload: IWSLHttpResponse<IWSLDataConf>) {
  }
}

export class ClearDeviceConfExts implements Action {
  readonly type = DeviceConfExtActionTypes.ClearDeviceConfExts;
}

export type DeviceConfExtActions =
  AddDeviceConfExt |
  AddDeviceConfExtSuccess |
  AddDeviceConfExtFail |

  UpdateDeviceConfExt |
  UpdateDeviceConfExtSuccess |
  UpdateDeviceConfExtFail |

  DeleteDeviceConfExt |
  DeleteDeviceConfExtSuccess |
  DeleteDeviceConfExtFail |

  SelectDeviceConfExt |

  LoadDeviceConfExt |
  LoadDeviceConfExtSuccess |
  LoadDeviceConfExtFail |

  LoadDeviceConfExts |
  LoadDeviceConfExtsSuccess |
  LoadDeviceConfExtsFail |

  ClearDeviceConfExts;


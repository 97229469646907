import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'wsl-promotion-snack-bar',
  templateUrl: './promotion-snack-bar.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionSnackBarComponent implements OnInit {

  constructor(public snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  public dismissSnackbar(): void {
    this.snackBar.dismiss();
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../dist/wsl-shared/wsl-shared.ngfactory";
import * as i4 from "wsl-shared";
import * as i5 from "../../../../../../../dist/wsl-auth/wsl-auth.ngfactory";
import * as i6 from "wsl-auth";
import * as i7 from "wsl-core";
import * as i8 from "./login.page";
import * as i9 from "@ngrx/store";
var styles_LoginPage = [];
var RenderType_LoginPage = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginPage, data: {} });
export { RenderType_LoginPage as RenderType_LoginPage };
function View_LoginPage_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(-1, null, ["\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C\u0441\u044F"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "/registration"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LoginPage_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", ""]], [[1, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C\u0441\u044F"]))], null, function (_ck, _v) { var currVal_0 = "https://reg.energokabinet.ru"; _ck(_v, 0, 0, currVal_0); }); }
function View_LoginPage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "wsl-p-a-10 wsl-show-xs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginPage_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginPage_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDom; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isDom; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_LoginPage_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "wsl-guest-layout", [["pageTitle", "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u044F"]], null, null, null, i3.View_WSLGuestLayoutComponent_0, i3.RenderType_WSLGuestLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i4.WSLGuestLayoutComponent, [], { title: [0, "title"], subTitle: [1, "subTitle"], pageTitle: [2, "pageTitle"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 3, "wsl-login-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_WSLLoginFormComponent_0, i5.RenderType_WSLLoginFormComponent)), i0.ɵdid(3, 245760, null, 0, i6.WSLLoginFormComponent, [i0.ChangeDetectorRef, i7.WSLWindowService], { pending: [0, "pending"], asyncErrors: [1, "asyncErrors"] }, { submitted: "submitted" }), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, 0, 6, "div", [["class", "wsl-flex-spaceBetween_xs-Cols-ng wsl-m-t-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [["class", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(9, 1), (_l()(), i0.ɵted(-1, null, ["\u0412\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginPage_1)), i0.ɵdid(12, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.systemTitle; var currVal_1 = _co.systemSubTitle; var currVal_2 = "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u044F"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.pending$)); var currVal_4 = i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 5).transform(_co.errors$)); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_7 = _ck(_v, 9, 0, "/forgot"); _ck(_v, 8, 0, currVal_7); var currVal_8 = _co.showRegistration; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var currVal_5 = i0.ɵnov(_v, 8).target; var currVal_6 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_5, currVal_6); }); }
export function View_LoginPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wsl-login", [], null, null, null, View_LoginPage_0, RenderType_LoginPage)), i0.ɵdid(1, 245760, null, 0, i8.LoginPage, [i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPageNgFactory = i0.ɵccf("wsl-login", i8.LoginPage, View_LoginPage_Host_0, {}, {}, []);
export { LoginPageNgFactory as LoginPageNgFactory };

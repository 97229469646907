import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AddDeviceFail, AddDeviceSuccess, DeleteDeviceFail, DeleteDeviceSuccess, DeviceActionTypes, HideDeviceFail, HideDeviceSuccess, LoadDeviceFail, LoadDevicesFail, LoadDevicesSuccess, LoadDeviceSuccess, RenameDeviceFail, RenameDeviceSuccess, UpdateDeviceCommunicatorFail, UpdateDeviceCommunicatorSuccess, UpdateDeviceGroupConfFail, UpdateDeviceGroupConfSuccess, UpdateTimezoneDeviceFail, UpdateTimezoneDeviceSuccess } from './device.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from 'wsl-device';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StoreHelperService } from '@core/services/store-helper.service';
var DeviceEffects = /** @class */ (function () {
    function DeviceEffects(actions$, router, route, storeHelper, deviceService) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.route = route;
        this.storeHelper = storeHelper;
        this.deviceService = deviceService;
        this.add$ = this.actions$.pipe(ofType(DeviceActionTypes.AddDevice), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceActionTypes.AddDevice), skip(1));
            return _this.deviceService.add(payload)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddDeviceSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddDeviceFail); }));
        }));
        /* @Effect({dispatch: false})
         addSuccess$ = this.actions$.pipe(
           ofType(DeviceActionTypes.AddDeviceSuccess),
           map((action: any) => action.payload),
           tap(payload => this.router.navigate(['settings', 'devices', payload]))
         );*/
        this.updateGroupConf$ = this.actions$.pipe(ofType(DeviceActionTypes.UpdateDeviceGroupConf), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceActionTypes.UpdateDeviceGroupConf), skip(1));
            return _this.deviceService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateDeviceGroupConfSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateDeviceGroupConfFail); }));
        }));
        this.updateCommunicator$ = this.actions$.pipe(ofType(DeviceActionTypes.UpdateDeviceCommunicator), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceActionTypes.UpdateDeviceCommunicator), skip(1));
            return _this.deviceService.updateCommunicator(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateDeviceCommunicatorSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateDeviceCommunicatorFail); }));
        }));
        this.rename$ = this.actions$.pipe(ofType(DeviceActionTypes.RenameDevice), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceActionTypes.RenameDevice), skip(1));
            return _this.deviceService.updateExt(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, RenameDeviceSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, RenameDeviceFail); }));
        }));
        this.updateTimezone$ = this.actions$.pipe(ofType(DeviceActionTypes.UpdateTimezoneDevice), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceActionTypes.UpdateTimezoneDevice), skip(1));
            return _this.deviceService.updateExt(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateTimezoneDeviceSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateTimezoneDeviceFail); }));
        }));
        this.hide$ = this.actions$.pipe(ofType(DeviceActionTypes.HideDevice), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceActionTypes.HideDevice), skip(1));
            return _this.deviceService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, HideDeviceSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, HideDeviceFail); }));
        }));
        this.load$ = this.actions$.pipe(ofType(DeviceActionTypes.LoadDevice), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceActionTypes.LoadDevice), skip(1));
            return _this.deviceService.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceFail); }));
        }));
        this.loadMany$ = this.actions$.pipe(ofType(DeviceActionTypes.LoadDevices), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceActionTypes.LoadDevices), skip(1));
            return _this.deviceService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDevicesSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDevicesFail); }));
        }));
        this.delete$ = this.actions$.pipe(ofType(DeviceActionTypes.DeleteDevice), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceActionTypes.DeleteDevice), skip(1));
            return _this.deviceService.delete(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteDeviceSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteDeviceFail); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(DeviceActionTypes.AddDevice, DeviceActionTypes.LoadDevice, DeviceActionTypes.UpdateDeviceGroupConf, DeviceActionTypes.UpdateDeviceCommunicator, DeviceActionTypes.DeleteDevice), map(function () { return new StartLoading('device'); }));
        this.startLoadMany$ = this.actions$
            .pipe(ofType(DeviceActionTypes.LoadDevices), map(function (action) { return action.payload; }), filter(function (payload) { return payload.offset === 0; }), map(function () { return new StartLoading('device'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(DeviceActionTypes.AddDeviceSuccess, DeviceActionTypes.AddDeviceFail, DeviceActionTypes.LoadDeviceSuccess, DeviceActionTypes.LoadDeviceFail, DeviceActionTypes.UpdateDeviceGroupConfSuccess, DeviceActionTypes.UpdateDeviceGroupConfFail, DeviceActionTypes.UpdateDeviceCommunicatorSuccess, DeviceActionTypes.UpdateDeviceCommunicatorFail, DeviceActionTypes.DeleteDeviceSuccess, DeviceActionTypes.DeleteDeviceFail, DeviceActionTypes.LoadDevicesSuccess, DeviceActionTypes.LoadDevicesFail), map(function () { return new StopLoading('device'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "updateGroupConf$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "updateCommunicator$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "rename$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "updateTimezone$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "hide$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "loadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "delete$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "startLoadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceEffects.prototype, "stopLoad$", void 0);
    return DeviceEffects;
}());
export { DeviceEffects };

import { Injectable } from '@angular/core';
import { WSLApiHttpClientService } from 'wsl-core';
import { IWSLDeviceConfGroup } from '../models/device-conf-group';
import { IWSLDeviceConfGroupFilter } from '../models/device-conf-group-filter';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeviceConfGroupService extends WSLApiHttpClientService<IWSLDeviceConfGroup, IWSLDeviceConfGroupFilter> {

  constructor(public http: HttpClient) {
    super(http, 'device_conf_group');
  }

}

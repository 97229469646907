import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { MenuHelperService } from '@core/services/menu-helper.service';
import { select, Store } from '@ngrx/store';
import { LoginRedirect, Logout } from '@store/actions/auth.actions';
import * as fromRoot from '@store/reducers';
import { of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, take, timeout } from 'rxjs/operators';
import { WSLUtils } from 'wsl-core';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../services/menu-helper.service";
import * as i3 from "@angular/router";
var MainPageGuard = /** @class */ (function () {
    function MainPageGuard(store, menuService, router) {
        this.store = store;
        this.menuService = menuService;
        this.router = router;
    }
    MainPageGuard.prototype.canActivate = function (next, state) {
        return this.check();
    };
    MainPageGuard.prototype.check = function () {
        var _this = this;
        return this.store
            .pipe(select(fromRoot.selectLoggedIn), delay(300), filter(function (loaded) { return loaded; }), take(1), timeout(3000), catchError(function () { return of(false); }), map(function (authed) {
            if (!authed) {
                _this.store.dispatch(new Logout());
                _this.store.dispatch(new LoginRedirect());
                return false;
            }
            return true;
        }), switchMap(function (user) { return _this.menuService.defineMainMenu().pipe(take(1)); }), map(function (menu) {
            AuthService.redirectURL = AuthService.redirectURL.split('#')[0].split('?')[0];
            var redirectUrl = AuthService.redirectURL.lastIndexOf('/') > 0 ? ('/' + AuthService.redirectURL.split('/')[1]) : AuthService.redirectURL;
            if (menu.length > 0 && menu.map(function (m) { return m.route; }).includes(redirectUrl)) {
                _this.router.navigate([AuthService.redirectURL], { queryParams: { uid: WSLUtils.randomID() }, queryParamsHandling: 'merge' });
            }
            else if (menu.length > 0 && menu[0].name !== 'DEV') {
                _this.router.navigate([menu[0].route], { queryParams: { uid: WSLUtils.randomID() }, queryParamsHandling: 'merge' });
            }
            else {
                return false;
            }
            /*if (menu.length > 0) {
              this.router.navigateByUrl(menu[0].route);
            }*/
            return true;
        }));
    };
    MainPageGuard.ngInjectableDef = i0.defineInjectable({ factory: function MainPageGuard_Factory() { return new MainPageGuard(i0.inject(i1.Store), i0.inject(i2.MenuHelperService), i0.inject(i3.Router)); }, token: MainPageGuard, providedIn: "root" });
    return MainPageGuard;
}());
export { MainPageGuard };

import {IWSLAsyncErrors, IWSLMenu} from 'wsl-core';
import {AppActions, AppActionTypes} from '../actions/app.actions';

export interface State {
  loading: Map<string, boolean>;
  menu: IWSLMenu[];
  asyncMessages: IWSLAsyncErrors;
  hasBilling: boolean;
  isUc: boolean;
  isKv: boolean;
  isHome: boolean;
  noAccessData: {contract_num: string, contract_date_beg: string, name: string};
}

export const initialState: State = {
  loading: new Map(),
  menu: [],
  asyncMessages: null,
  hasBilling: false,
  isUc: false,
  isKv: false,
  isHome: false,
  noAccessData: null
};

export function reducer(state = initialState, action: AppActions): State {
  switch (action.type) {
    case AppActionTypes.StartLoading: {
      const loading = state.loading;
      if (action.payload === 'route') {
        loading.clear();
      }
      loading.set(action.payload, true);
      return {
        ...state,
        loading,
        asyncMessages: null
      };
    }

    case AppActionTypes.StopLoading: {
      const loading = state.loading;
      loading.set(action.payload, false);
      return {
        ...state,
        loading
      };
    }

    case AppActionTypes.SetAppEnvironment: {
      return {
        ...state,
        ...action.payload
      };
    }

    case AppActionTypes.ShowMenuTabBar:
      return {
        ...state,
        menu: action.payload
      };

    case AppActionTypes.HideMenuTabBar:
      return {
        ...state,
        menu: []
      };

    case AppActionTypes.SetSnackBarMessage:
      return {
        ...state,
        asyncMessages: action.payload
      };

    case AppActionTypes.ShowNoAccessMessage:
      return {
        ...state,
        noAccessData: {...action.payload}
      };

    case AppActionTypes.ClearContractData:
      return {...state, noAccessData: null};


    case AppActionTypes.HasBilling:
      return {
        ...state,
        hasBilling: true
      };


    default:
      return state;
  }
}

export const getPending = (state: State) => state.loading ? Array.from(state.loading.values()).some(l => l) : false;
export const getMenu = (state: State) => state.menu;
export const getMessage = (state: State) => state.asyncMessages;
export const getNoAccessData = (state: State) => state.noAccessData;
export const getHasBilling = (state: State) => state.hasBilling;
export const getIsUc = (state: State) => state.isUc;
export const getIsKv = (state: State) => state.isKv;
export const getIsHome = (state: State) => state.isHome;

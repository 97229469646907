import 'hammerjs';
import { enableProdMode, Injector } from '@angular/core';
// import { bootstrapWorkerUi } from '@angular/platform-webworker';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { bootloader, hmrModule } from '@angularclass/hmr';

// import './oldbrowser.js';
// import './rxjs.imports';
// import * as moment from 'moment';
import { hmrBootstrap } from './hmr';

// moment.locale('ru');
export let appInjector: Injector;

if (environment.production) {
//  window.console.log = function(){};
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (environment.hmr) {
  if (module[ 'hot' ]) {
    // hmrBootstrap(module, bootstrap);
    bootstrap().then((ngModuleRef) => {
      appInjector = ngModuleRef.injector;
      return hmrModule(ngModuleRef, module);
    }).catch(err => console.log(err));
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap()
    .then(
      (componentRef) => {
        /**
         * https://github.com/angular/angular-cli/issues/13351
         * https://github.com/angular/angular-cli/issues/8515#issuecomment-486017106
         * https://github.com/angular/angular/issues/20970
         * https://github.com/angular/angular-cli/issues/8779
         */
        if ('serviceWorker' in navigator && location.protocol === 'https:' &&
          !location.pathname.includes('/api') &&
          !location.pathname.includes('/report_generator')) {
          navigator.serviceWorker.register('./ngsw-worker.js');
        }
        appInjector = componentRef.injector;
        return componentRef;
      }
      )
    .catch(err => console.log(err));
}

/*
const bootstrap = () => platformBrowserDynamic()
  .bootstrapModule(AppModule).then(
    (componentRef) => {
      appInjector = componentRef.injector;
      return componentRef;
    });

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
  }
} else {
  bootstrap()
    .then(() => {
      /!**
       * https://github.com/angular/angular-cli/issues/13351
       * https://github.com/angular/angular-cli/issues/8515#issuecomment-486017106
       * https://github.com/angular/angular/issues/20970
       * https://github.com/angular/angular-cli/issues/8779
       *!/
    if ('serviceWorker' in navigator && location.protocol === 'https:' &&
      !location.pathname.includes('/api') &&
      !location.pathname.includes('/report_generator')) {
      navigator.serviceWorker.register('./ngsw-worker.js');
    }
  }).catch(err => console.log(err));
}
*/

/*platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));*/

// Cache storage estimate
if ('storage' in navigator && 'estimate' in (<any>navigator).storage) {
  (<any>navigator).storage.estimate().then(({usage, quota}) => {
    console.log(`Using ${usage} out of ${quota} bytes.`);
  });
}

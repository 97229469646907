import { Injectable } from '@angular/core';
import { BillingCompanyLinkService } from '@app/billing-company/services/billing-company-link.service';
import { StoreHelperService } from '@core/services/store-helper.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  BillingCompanyLinkActionTypes,
  LoadBCompanyLinks,
  LoadBCompanyLinksFail,
  LoadBCompanyLinksSuccess
} from '@store/billing/billing-company-link.actions';
import { catchError, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class BillingCompanyLinkEffects {

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(BillingCompanyLinkActionTypes.LoadBCompanyLinks),
    map((action: LoadBCompanyLinks) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(BillingCompanyLinkActionTypes.LoadBCompanyLinks), skip(1));

      return this.bCompanyLinkService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadBCompanyLinksSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadBCompanyLinksFail))
        );
    })
  );

  constructor(private actions$: Actions,
              private bCompanyLinkService: BillingCompanyLinkService,
              private storeHelper: StoreHelperService) {
  }
}

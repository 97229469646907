import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {IWSLDevice, IWSLDeviceModel, DeviceService} from 'wsl-device';
import {ActivatedRoute} from '@angular/router';
import {MomentDateTimeFormat, MomentDBDateTimeFormat, WSLRouterHelperService} from 'wsl-core';
import {AllResources, DataArchiveType, DataArchiveTypes, IWSLResource, ResourceType} from 'wsl-ek-core';
import {CommunicatorService} from '@app/communicator/services/communicator.service';
import * as moment from 'moment';
import {IWSLServiceProcedure} from '@app/service-procedure/models/service-procedure';
import {IWSLServiceProcedureDict} from '@app/service-procedure/models/service-procedure-dict';
import {IWSLServiceOperationDict} from '@app/service-operation/models/service-operation-dict';
import {IWSLBCompanyDevice} from '@app/billing-company/models/b-company-device';

@Component({
  selector: 'wsl-device-card-pro',
  templateUrl: './device-card-pro.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceCardProComponent implements OnInit, OnChanges {
  @Input() device: IWSLDevice;
  @Input() links: IWSLBCompanyDevice[] = [];
  @Input() showExtra = true;
  @Input() extra: {name?: string, icon?: string}[] = [];
  @Input() set serviceProcedures(serviceProcedures: IWSLServiceProcedure[]) {
    if (serviceProcedures) {
      this.deviceServiceProcedures = serviceProcedures;
      this.activeServiceProcedure = serviceProcedures.find(s => s.device_id === this.device.id && !s.cancel && !s.close);
      this.defineActiveServiceProcedureTooltip();
    }
  }

  withDateNext = false;
  withImpulseSensors = false;
  isInWork = false;
  isOutWork = false;
  devEUI = '';
  showRename = false;
  showRenameInput = false;
  alias = '';
  image = null;
  internalResources: IWSLResource[] = [];
  sensors = [];
  connections = [];
  deviceArchives = '';
  deviceServiceProcedures: IWSLServiceProcedure[];
  activeServiceProcedure: IWSLServiceProcedure = null;
  activeServiceProcedureTooltip = '';
  deviceLink = null;

  private dmodels: IWSLDeviceModel[] = [];
  private sp: IWSLServiceProcedureDict[] = [];
  private so: IWSLServiceOperationDict[] = [];


  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.dmodels = data.dmodels;
    this.sp = data.sp;
    this.so = data.so;
    this.init();
  }

  ngOnChanges(changes) {
    if (changes.device) {
      this.init();
    }
  }

  clickRename() {
    this.showRenameInput = true;
  }

  save() {
    // this.renameDevice.emit({id: this.device.id, alias: this.alias});
  }

  cancel() {
    this.alias = this.device.alias;
    this.showRenameInput = false;
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  private init() {
    if (this.device) {
      this.alias = this.device.alias;
      const dmodel = this.dmodels ? this.dmodels.find(dm => dm.id === this.device.dmodel_id) : null;
      this.image = dmodel && dmodel.ext && dmodel.ext.image ? dmodel.ext.image : null;
      this.withDateNext = DeviceService.withDateNext(this.dmodels, this.device);
      // this.withImpulseSensors = DeviceService.withImpulseSensors(this.dmodels, this.device);
      if (this.showRenameInput) {
        this.showRenameInput = false;
      }
      this.showRename = !this.device.individual;
      this.isInWork = !!this.device.date_input;
      this.isOutWork = !!this.device.date_output;
      this.devEUI = null;
      if (!!this.device.communicators && this.device.communicators.length > 0 && this.device.communicators[0].network.lora) {
        this.devEUI = this.device.communicators[0].network.lora.devEUI;
      }
      this.internalResources = [];
      if (!!this.device.sensors) {
        this.internalResources =  AllResources
          .filter(ar => !!this.device.resources && this.device.resources.includes(ar.id) &&
            !this.device.sensors.map(s => s.resource_id).includes(ar.id));
      } else {
        this.internalResources = AllResources.filter(ar => !!this.device.resources &&
          this.device.resources.includes(ar.id));
      }

      this.sensors = [];
      if (!!this.device && !!this.device.sensors) {
        this.sensors = this.device.sensors.map(sensor => {
          const res = AllResources.find(r => r.id === sensor.resource_id);

          return {
            serial_num: sensor.serial_num,
            date_next: sensor.date_next,
            resource_id: res.id,
            resource_icon: res.icon,
            entry: sensor.entry
          };
        });
      }

      this.connections = [];
      if (this.device && this.device.communicators) {
        this.device.communicators.forEach(com => {
          if (com) {
            const nt = CommunicatorService.getConnectionType(com);
            if (!nt) {
              console.error(com);
            } else {
              this.connections.push({
                id: com.id,
                on_board: com.network.on_board,
                icon: nt.icon,
                name: nt.name,
                describe: CommunicatorService.getConnectionParameters(com)
              });
            }
          }
        });
      }

      this.deviceArchives = '';
      if ( this.device.archives) {
        this.deviceArchives = this.device.archives ? DataArchiveTypes
          .filter(a => this.device.archives.includes(a.id))
          .map(a => {
            if (this.device.resources && this.device.resources.includes(ResourceType.elec) && a.id === DataArchiveType.hour) {
              return {...a, name: 'профиль мощности'};
            }
            return a;
          })
          .reduce((prev, cur) => prev + ' ' + cur.name, '') : '';
        this.defineActiveServiceProcedureTooltip();
      }

      this.deviceLink = this.links ? this.links.find(l => l.device_id === this.device.id) : null;
    }
  }

  private defineActiveServiceProcedureTooltip() {
    this.activeServiceProcedureTooltip = '';
    if (this.activeServiceProcedure) {
      if (this.sp) {
        const sp = this.sp.find(p => p.id === this.activeServiceProcedure.sprocedure_id);
        this.activeServiceProcedureTooltip = sp ? sp.name : '';
      }
      if (this.so) {
        const o = this.so.find(so => so.id === this.activeServiceProcedure.so_data_soperation_id);
        this.activeServiceProcedureTooltip += o ? (': ' + o.name) : '';
      }
      this.activeServiceProcedureTooltip += ' от ' +
        moment(this.activeServiceProcedure.time_beg, MomentDBDateTimeFormat).format(MomentDateTimeFormat);
    }
  }

}

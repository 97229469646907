import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IWSLDeviceSensor, IWSLDeviceSensorFilter } from 'wsl-device';
import { WSLApiHttpClientService } from 'wsl-core';
import {combineLatest, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {WSLMaterializeHelper} from 'wsl-shared';
import {IWSLDeviceInputV} from '@app/device/components/add-device-setup-metering-node/add-device-setup-metering-node.component';
import {IWSLDataConf} from '@app/device-conf-ext/models/data-conf';

@Injectable()
export class DeviceSensorService extends WSLApiHttpClientService<IWSLDeviceSensor, IWSLDeviceSensorFilter> {

  constructor(public http: HttpClient) {
    super(http, 'sensor');
  }

  static defineDeleteSensors(inputs: IWSLDeviceInputV[], sensors: IWSLDeviceSensor[]) {
    return inputs.length > 0 ? sensors
      .filter(s => !inputs.find(i => i.sensor_id === s.id))
      .map(s => s.id) : sensors.map(s => s.id);
  }

  static defineUpsertSensors(inputs: IWSLDeviceInputV[], sensors: IWSLDeviceSensor[], confExts: IWSLDataConf[]) {
    const unknownSensors = [];
    const upsertSensors = inputs.map(input => {
      const confs = confExts.filter(c =>
        c.is_sensor &&
        c.resource_id === input.resource_id &&
        c.rvariable_id === input.rvariable.id &&
        c.rvalue_id === input.rvalue_id &&
        c.resource_inx === input.resource_inx
      );
      if (confs.length === 1) {
        input.dconf_ext_id = confs[0].id;
        if (input.sensor_id) {
          const sensor = sensors.find(s => s.id === input.sensor_id);
          if (sensor.dconf_ext_id !== input.dconf_ext_id) {
            return {
              ...sensor,
              serial_num: input.serial_num,
              date_next: input.date_next,
              impulse_weight: input.impulse_weight,
              entry: input.entry,
              resource_id: input.resource_id,
              rvalue_id: input.rvalue_id,
              resource_inx: input.resource_inx,
              dconf_ext_id: input.dconf_ext_id
            };
          } else if (sensor.serial_num !== input.serial_num || sensor.date_next !== input.date_next ||
            sensor.resource_inx !== input.resource_inx || sensor.entry !== input.entry ||
            sensor.impulse_weight !== input.impulse_weight || sensor.rvalue_id !== input.rvalue_id) {
            return {
              ...sensor,
              serial_num: input.serial_num,
              date_next: input.date_next,
              impulse_weight: input.impulse_weight,
              entry: input.entry,
              resource_id: input.resource_id,
              rvalue_id: input.rvalue_id,
              resource_inx: input.resource_inx,
              dconf_ext_id: input.dconf_ext_id
            };
          }
          return null;
        } else {
          return {
            device_id: input.device_id,
            serial_num: input.serial_num,
            date_next: input.date_next,
            impulse_weight: input.impulse_weight,
            entry: input.entry,
            resource_id: input.resource_id,
            rvalue_id: input.rvalue_id,
            resource_inx: input.resource_inx,
            dconf_ext_id: input.dconf_ext_id
          };
        }
      } else {
        console.log('unkn', input, confs, confExts.filter(c => c.is_sensor &&
          c.resource_id === input.resource_id));
        unknownSensors.push(input);
        // @todo

        return null;
      }
    }).filter(s => !!s);
    return {unknownSensors, upsertSensors};
  }

  public upsertMany(sensors: IWSLDeviceSensor[]) {
    return this.http.post('upsert_sensors', {items: sensors});
  }
}

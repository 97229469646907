import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '@store/reducers';
import * as fromBCompanyAccount from './billing-company-account.reducer';
import * as fromBCompanyLink from './billing-company-link.reducer';
import * as fromBCompany from './billing-company.reducer';



export interface BillingState {
  companies: fromBCompany.State;
  links: fromBCompanyLink.State;
  accounts: fromBCompanyAccount.State;
}

export interface State extends fromRoot.State {
  'billing': BillingState;
}

export const reducers: ActionReducerMap<BillingState> = {
  companies: fromBCompany.reducer,
  links: fromBCompanyLink.reducer,
  accounts: fromBCompanyAccount.reducer
};

export const getBillingState = createFeatureSelector<BillingState>('billing');

export const getBillingCompanyState = createSelector(getBillingState, state => state.companies);

export const selectBillingCompanies = createSelector(
  getBillingCompanyState,
  fromBCompany.getBCompaniesAll
);

export const selectBillingCompaniesPending = createSelector(
  getBillingCompanyState,
  fromBCompany.getPending
);

export const selectBillingCompaniesLoaded = createSelector(
  getBillingCompanyState,
  fromBCompany.getLoaded
);


export const getBillingCompanyLinkState = createSelector(getBillingState, state => state.links);

export const selectBillingCompanyLinks = createSelector(
  getBillingCompanyLinkState,
  fromBCompanyLink.getBCompanyLinkAll
);

export const selectBillingCompanyLinkEntities = createSelector(
  getBillingCompanyLinkState,
  fromBCompanyLink.getBCompanyLinkEntities
);

export const selectBillingCompanyLinksPending = createSelector(
  getBillingCompanyLinkState,
  fromBCompanyLink.getPending
);

export const selectBillingCompanyLinksLoaded = createSelector(
  getBillingCompanyLinkState,
  fromBCompanyLink.getLoaded
);


export const getBillingCompanyAccountState = createSelector(getBillingState, state => state.accounts);

export const selectBillingCompanyAccounts = createSelector(
  getBillingCompanyAccountState,
  fromBCompanyAccount.getBCompanyAccountAll
);

export const selectBillingCompanyAccountsPending = createSelector(
  getBillingCompanyAccountState,
  fromBCompanyAccount.getPending
);

export const selectBillingCompanyAccountsLoaded = createSelector(
  getBillingCompanyAccountState,
  fromBCompanyAccount.getLoaded
);

import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLDeviceConfGroupFilter } from '@app/device-conf-group/models/device-conf-group-filter';
import { IWSLDeviceConfGroup } from '@app/device-conf-group/models/device-conf-group';

export enum DeviceConfGroupActionTypes {
  AddDeviceConfGroup = '[DeviceConfGroup] Add',
  AddDeviceConfGroupSuccess = '[DeviceConfGroup] Add Success',
  AddDeviceConfGroupFail = '[DeviceConfGroup] Add Fail',

  UpdateDeviceConfGroup = '[DeviceConfGroup] Update',
  UpdateDeviceConfGroupSuccess = '[DeviceConfGroup] Update Success',
  UpdateDeviceConfGroupFail = '[DeviceConfGroup] Update Fail',

  DeleteDeviceConfGroup = '[DeviceConfGroup] Delete',
  DeleteDeviceConfGroupSuccess = '[DeviceConfGroup] Delete Success',
  DeleteDeviceConfGroupFail = '[DeviceConfGroup] Delete Fail',

  SelectDeviceConfGroup = '[DeviceConfGroup] Select',

  LoadDeviceConfGroup = '[DeviceConfGroup] Load',
  LoadDeviceConfGroupSuccess = '[DeviceConfGroup] Load Success',
  LoadDeviceConfGroupFail = '[DeviceConfGroup] Load Fail',

  LoadDeviceConfGroups = '[DeviceConfGroup] Load Many',
  LoadDeviceConfGroupsSuccess = '[DeviceConfGroup] Load Many Success',
  LoadDeviceConfGroupsFail = '[DeviceConfGroup] Load Many Fail',

  ClearDeviceConfGroups = '[DeviceConfGroup] Clear'
}

/** Add */
export class AddDeviceConfGroup implements Action {
  readonly type = DeviceConfGroupActionTypes.AddDeviceConfGroup;

  constructor(public payload: IWSLDeviceConfGroup) {
  }
}

export class AddDeviceConfGroupSuccess implements Action {
  readonly type = DeviceConfGroupActionTypes.AddDeviceConfGroupSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

export class AddDeviceConfGroupFail implements Action {
  readonly type = DeviceConfGroupActionTypes.AddDeviceConfGroupFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

/** Update */
export class UpdateDeviceConfGroup implements Action {
  readonly type = DeviceConfGroupActionTypes.UpdateDeviceConfGroup;

  constructor(public payload: IWSLDeviceConfGroup) {
  }
}

export class UpdateDeviceConfGroupSuccess implements Action {
  readonly type = DeviceConfGroupActionTypes.UpdateDeviceConfGroupSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

export class UpdateDeviceConfGroupFail implements Action {
  readonly type = DeviceConfGroupActionTypes.UpdateDeviceConfGroupFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

/** Delete */
export class DeleteDeviceConfGroup implements Action {
  readonly type = DeviceConfGroupActionTypes.DeleteDeviceConfGroup;

  constructor(public payload: number) {
  }
}

export class DeleteDeviceConfGroupSuccess implements Action {
  readonly type = DeviceConfGroupActionTypes.DeleteDeviceConfGroupSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

export class DeleteDeviceConfGroupFail implements Action {
  readonly type = DeviceConfGroupActionTypes.DeleteDeviceConfGroupFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

/** Select */
export class SelectDeviceConfGroup implements Action {
  readonly type = DeviceConfGroupActionTypes.SelectDeviceConfGroup;

  constructor(public payload: number) {
  }
}

/** Load One */
export class LoadDeviceConfGroup implements Action {
  readonly type = DeviceConfGroupActionTypes.LoadDeviceConfGroup;

  constructor(public payload: number) {
  }
}

export class LoadDeviceConfGroupSuccess implements Action {
  readonly type = DeviceConfGroupActionTypes.LoadDeviceConfGroupSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

export class LoadDeviceConfGroupFail implements Action {
  readonly type = DeviceConfGroupActionTypes.LoadDeviceConfGroupFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

/** Load Many */
export class LoadDeviceConfGroups implements Action {
  readonly type = DeviceConfGroupActionTypes.LoadDeviceConfGroups;

  constructor(public payload: IWSLDeviceConfGroupFilter) {
  }
}

export class LoadDeviceConfGroupsSuccess implements Action {
  readonly type = DeviceConfGroupActionTypes.LoadDeviceConfGroupsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

export class LoadDeviceConfGroupsFail implements Action {
  readonly type = DeviceConfGroupActionTypes.LoadDeviceConfGroupsFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConfGroup>) {
  }
}

export class ClearDeviceConfGroups implements Action {
  readonly type = DeviceConfGroupActionTypes.ClearDeviceConfGroups;
}

export type DeviceConfGroupActions =
  AddDeviceConfGroup |
  AddDeviceConfGroupSuccess |
  AddDeviceConfGroupFail |

  UpdateDeviceConfGroup |
  UpdateDeviceConfGroupSuccess |
  UpdateDeviceConfGroupFail |

  DeleteDeviceConfGroup |
  DeleteDeviceConfGroupSuccess |
  DeleteDeviceConfGroupFail |

  SelectDeviceConfGroup |

  LoadDeviceConfGroup |
  LoadDeviceConfGroupSuccess |
  LoadDeviceConfGroupFail |

  LoadDeviceConfGroups |
  LoadDeviceConfGroupsSuccess |
  LoadDeviceConfGroupsFail |

  ClearDeviceConfGroups;


import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IWSLDeviceModel} from 'wsl-device';
import {IWSLDeviceConf} from '../../models/device-conf';
import {AllResources, DataArchiveTypes} from 'wsl-ek-core';
import {ActivatedRoute} from '@angular/router';
import {WSLRouterHelperService} from 'wsl-core';
import {KaratLoraProtocolService} from '@core/services/karat-lora-protocol.service';
import {DeviceConnectionTypes} from '@app/device-conf/enums/device-connection-type.enum';

@Component({
  selector: '[wsl-device-conf-row]',
  templateUrl: './device-conf-row.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfRowComponent implements OnInit, OnChanges {
  @Input() conf: IWSLDeviceConf;
  @Input() checked = false;
  @Input() checkable = false;
  @Output() check = new EventEmitter<{ id: number, value: boolean }>();
  @Output() selectItem = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();

  /** @internal */
  dmodels: IWSLDeviceModel[] = [];
  archives = [];
 // protocols = [];
  resources = [];
  connect = null;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.dmodels = data.dmodels;
    this.define();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.conf) {
      this.define();
    }
  }

  onCheck($event) {
    this.check.emit({id: this.conf.id, value: $event});
  }

  onDelete() {
    this.delete.emit(this.conf.id);
  }

  private define() {
    this.archives = this.conf.ext ? (
      !!this.conf.ext.archive_types ?
        DataArchiveTypes.filter(at => this.conf.ext.archive_types.includes(at.id)) : DataArchiveTypes
    ) : [];
   // this.protocols = this.conf.protocols ? KaratLoraProtocolService.get().filter(p => this.conf.protocols.includes(p.id)) : [];
    this.resources = this.conf && this.conf.resources ?
      this.conf.resources.map(id => {
        return AllResources.find(ar => ar.id === id);
      }) : [];
    this.connect = DeviceConnectionTypes.find(c => c.id === this.conf.type);
  }
}

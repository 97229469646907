import { Injectable } from '@angular/core';
import { WSLApiHttpClientService } from 'wsl-core';
import { HttpClient } from '@angular/common/http';
import { IWSLDeviceBind } from '@app/+service/models/device-bind';
import { IWSLDeviceBindFilter } from '@app/+service/models/device-bind-filter';

@Injectable()
export class DeviceBindService extends WSLApiHttpClientService<IWSLDeviceBind, IWSLDeviceBindFilter> {

  constructor(public http: HttpClient) {
    super(http, 'device_bind');
  }

}

import { UserProfileActions, UserProfileActionTypes } from '../actions/user-profile.actions';
import { IWSLUser } from 'wsl-user';
import {CompanyRoleType, IWSLCompany} from 'wsl-ek-core';
import { IWSLTown } from 'wsl-ek-core';
import { CompanyProfileActions, CompanyProfileActionTypes } from '../actions/company-profile.actions';
import { TownActions, TownActionTypes } from '../actions/town.actions';
import {IWSLRoom} from '@app/room/models/room';
import * as moment from 'moment';
import {MomentDBDateFormat} from 'wsl-core';
import {UserRoleType} from 'wsl-ek-core';
import {environment} from '@env/environment';

export interface State {
  user: IWSLUser;
  company: IWSLCompany;
  availableCompanies: IWSLCompany[];
  town: IWSLTown;
  availableRooms: IWSLRoom[];
  roomId: number;
}

export const initialState: State = {
  user: null,
  company: null,
  availableCompanies: [],
  town: null,
  availableRooms: [],
  roomId: null
};

export function reducer(state = initialState, action: UserProfileActions | CompanyProfileActions | TownActions): State {
  switch (action.type) {

    case UserProfileActionTypes.LoadUserProfileSuccess:
      return {
        ...state,
        user: action.payload.item
      };

    case UserProfileActionTypes.LoadUserProfileFail:
      return initialState;

    case CompanyProfileActionTypes.LoadCompanyProfileSuccess:
      return {
        ...state,
        company: action.payload.item
      };

    case CompanyProfileActionTypes.LoadCompanyProfileFail:
      return initialState;

    case UserProfileActionTypes.LoadAvailableCompanies:
      return {
        ...state,
        availableCompanies: []
      };

    case UserProfileActionTypes.LoadAvailableCompaniesSuccess:
      return {
        ...state,
        availableCompanies: action.payload.items
      };

    case TownActionTypes.LoadTownProfileSuccess:
      return {
        ...state,
        town: action.payload.item
      };

    case UserProfileActionTypes.LoadAvailableRoomsSuccess:
      return {
        ...state,
        availableRooms: action.payload.items
      };

    case UserProfileActionTypes.SetSelectedRoomId:
      return {
        ...state, roomId: action.payload
      };

    case TownActionTypes.LoadTownProfileFail:
      return initialState;

    default:
      return state;
  }
}

export const getUser = (state: State) => state.user;
export const getCompany = (state: State) => state.company;
export const getIsCompanyFreeAccount = (state: State) => !!state.company && !state.company.contract_type; // !state.company.contract_date_end;
export const getCompanyHasLimitedAccess = (state: State) => {
  if (state.company) {
    if (!state.company.contract_type) {
      // is free access
      return !state.company.contract_date_end ||
        (!!state.company.contract_date_end && (
            // contract ended
            moment().isAfter(moment(state.company.contract_date_end, MomentDBDateFormat), 'day') ||
            // promo period 90 days not started yet
            moment(state.company.contract_date_end, MomentDBDateFormat).diff(moment(), 'days') > 90
          )
        );
    } else {
      // is paid access
      return !(!!state.company.contract_date_beg && moment().isSameOrAfter(moment(state.company.contract_date_beg, MomentDBDateFormat), 'day') &&
        !!state.company.contract_date_end && moment().isSameOrBefore(moment(state.company.contract_date_end, MomentDBDateFormat), 'day'));
    }
  }
  return environment.dom ? null : environment.kv ? null : false;
};
export const getShowPromotion = (state: State) => {
  if (!!state.user && !!state.company) {
    return !environment.production &&
        state.user.role_ids.includes(UserRoleType.admin) &&
        state.company.role_id === CompanyRoleType.uc &&
        !state.company.contract_type && !state.company.contract_date_end;
  }
  return false;
};
export const getAvailableCompanies = (state: State) => state.availableCompanies;
export const getTown = (state: State) => state.town;
export const getRooms = (state: State) => state.availableRooms;
export const getRoom = (state: State) => state.availableRooms.find(r => r.id === state.roomId);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../dist/wsl-auth/wsl-auth.ngfactory";
import * as i2 from "wsl-auth";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../dist/wsl-shared/wsl-shared.ngfactory";
import * as i5 from "wsl-shared";
import * as i6 from "@angular/router";
import * as i7 from "./forgot-password.page";
import * as i8 from "@ngrx/store";
import * as i9 from "@ngrx/effects";
var styles_ForgotPasswordPage = [];
var RenderType_ForgotPasswordPage = i0.ɵcrt({ encapsulation: 2, styles: styles_ForgotPasswordPage, data: {} });
export { RenderType_ForgotPasswordPage as RenderType_ForgotPasswordPage };
function View_ForgotPasswordPage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "wsl-forgot-password-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_WSLForgotPasswordFormComponent_0, i1.RenderType_WSLForgotPasswordFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.WSLForgotPasswordFormComponent, [], { pending: [0, "pending"], asyncErrors: [1, "asyncErrors"] }, { submitted: "submitted" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.pending$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.errors$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ForgotPasswordPage_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "wsl-bg-white wsl-p-x-20 wsl-p-y-25 z-depth-1 wsl-hide-bg-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "wsl-green wsl-st6 wsl-text-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u0412\u0430\u043C \u043F\u0438\u0441\u044C\u043C\u043E \u043D\u0430 \u0443\u043A\u0430\u0437\u0430\u043D\u043D\u044B\u0439 email \u0441 \u0434\u0430\u043B\u044C\u043D\u0435\u0439\u0448\u0438\u043C\u0438 \u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u044F\u043C\u0438 "]))], null, null); }
export function View_ForgotPasswordPage_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "wsl-guest-layout", [["pageTitle", "\u0412\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C"]], null, null, null, i4.View_WSLGuestLayoutComponent_0, i4.RenderType_WSLGuestLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i5.WSLGuestLayoutComponent, [], { title: [0, "title"], subTitle: [1, "subTitle"], pageTitle: [2, "pageTitle"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ForgotPasswordPage_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ForgotPasswordPage_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, 0, 4, "div", [["class", "wsl-flex-spaceBetween_xs-Cols-ng wsl-m-t-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [["class", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(9, 1), (_l()(), i0.ɵted(-1, null, ["\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.systemTitle; var currVal_1 = _co.systemSubTitle; var currVal_2 = "\u0412\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = !_co.submitted; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.submitted; _ck(_v, 5, 0, currVal_4); var currVal_7 = _ck(_v, 9, 0, "/login"); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_5 = i0.ɵnov(_v, 8).target; var currVal_6 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_5, currVal_6); }); }
export function View_ForgotPasswordPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wsl-forgot-password", [], null, null, null, View_ForgotPasswordPage_0, RenderType_ForgotPasswordPage)), i0.ɵdid(1, 245760, null, 0, i7.ForgotPasswordPage, [i8.Store, i9.Actions, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordPageNgFactory = i0.ɵccf("wsl-forgot-password", i7.ForgotPasswordPage, View_ForgotPasswordPage_Host_0, {}, {}, []);
export { ForgotPasswordPageNgFactory as ForgotPasswordPageNgFactory };

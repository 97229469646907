import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromRoom from '@store/room/room.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RoomEffects } from '@store/room/room.effects';
import { WslSharedModule } from 'wsl-shared';
import { AppCommonModule } from '@common/app-common.module';
import { RoomService } from './services/room.service';
import { RoomListComponent } from './components/room-list/room-list.component';

const COMPONENTS = [
  RoomListComponent];

@NgModule({
  imports: [
    WslSharedModule,
    AppCommonModule,
    StoreModule.forFeature('room', fromRoom.reducer),
    EffectsModule.forFeature([RoomEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    RoomService
  ]
})
export class RoomModule { }

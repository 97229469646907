/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../dist/wsl-shared/wsl-shared.ngfactory";
import * as i2 from "wsl-shared";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./not-found.page";
var styles_NotFoundPage = [];
var RenderType_NotFoundPage = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundPage, data: {} });
export { RenderType_NotFoundPage as RenderType_NotFoundPage };
export function View_NotFoundPage_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "wsl-guest-layout", [["pageTitle", "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430"]], null, null, null, i1.View_WSLGuestLayoutComponent_0, i1.RenderType_WSLGuestLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i2.WSLGuestLayoutComponent, [], { title: [0, "title"], subTitle: [1, "subTitle"], pageTitle: [2, "pageTitle"] }, null), (_l()(), i0.ɵted(-1, 0, [" \u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F "])), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u043D\u0430\u0437\u0430\u0434"])), (_l()(), i0.ɵted(-1, 0, [" \u0438\u043B\u0438 \u043D\u0430 "])), (_l()(), i0.ɵeld(6, 0, null, 0, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(8, 1), (_l()(), i0.ɵted(-1, null, ["\u0433\u043B\u0430\u0432\u043D\u0443\u044E"])), (_l()(), i0.ɵted(-1, 0, ["? "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.systemTitle; var currVal_1 = _co.systemSubTitle; var currVal_2 = "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _ck(_v, 8, 0, "/"); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 7).target; var currVal_4 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_3, currVal_4); }); }
export function View_NotFoundPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wsl-not-found", [], null, null, null, View_NotFoundPage_0, RenderType_NotFoundPage)), i0.ɵdid(1, 114688, null, 0, i5.NotFoundPage, [i4.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundPageNgFactory = i0.ɵccf("wsl-not-found", i5.NotFoundPage, View_NotFoundPage_Host_0, {}, {}, []);
export { NotFoundPageNgFactory as NotFoundPageNgFactory };

import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { StoreHelperService } from '@core/services/store-helper.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, delay, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { WSLLocalStorageService } from 'wsl-core';
import { CompanyProfileService, CompanyRoleType, StorageKey } from 'wsl-ek-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { StartLoading, StopLoading } from '../actions/app.actions';
import { AuthActionTypes, ConfirmRegistrationFail, ConfirmRegistrationSuccess, ForgotPasswordFail, ForgotPasswordSuccess, LoginAsHomeUserFail, LoginAsHomeUserSuccess, LoginFail, LoginSuccess, RefreshTokenFail, RefreshTokenSuccess, RegistrationFail, RegistrationSuccess, ResetPasswordFail, ResetPasswordSuccess } from '../actions/auth.actions';
import { LoadUserProfile } from '../actions/user-profile.actions';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(actions$, router, storeHelper, localStorage, authService, companyService) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.storeHelper = storeHelper;
        this.localStorage = localStorage;
        this.authService = authService;
        this.companyService = companyService;
        this.login$ = this.actions$
            .pipe(ofType(AuthActionTypes.Login), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            return _this.authService
                .login(payload)
                .pipe(map(function (response) { return new LoginSuccess(response); }), catchError(function (response) {
                console.error(response);
                return _this.storeHelper.errorHandler(response, LoginFail);
            }));
        }));
        this.loginSuccess$ = this.actions$
            .pipe(ofType(AuthActionTypes.LoginSuccess), delay(100), map(function (action) { return action.payload; }), tap(function (payload) {
            /*const barcode = this.localStorage.get(StorageKey.device_barcode);
            this.localStorage.set(StorageKey.token, payload.token);
            if (barcode) {
              this.localStorage.set(StorageKey.device_barcode, barcode);
            }*/
            _this.localStorage.set(StorageKey.token, payload.token);
            AuthService.redirectURL = ['/login', '/reset', '/forgot', '/confirm', '/registration']
                .some(function (s) { return AuthService.redirectURL.startsWith(s); }) ? '/' : AuthService.redirectURL;
        }), map(function () { return new LoadUserProfile(); }));
        this.loginRedirect$ = this.actions$
            .pipe(ofType(AuthActionTypes.LoginRedirect, AuthActionTypes.Logout), tap(function (authed) {
            _this.router.navigate(['/login']);
        }));
        this.logout$ = this.actions$
            .pipe(ofType(AuthActionTypes.Logout), tap(function () {
            AuthService.redirectURL = '/';
            _this.safeClearLocalStorageMeta();
            // LocalStorageService.remove('token');
        }));
        this.forgot$ = this.actions$
            .pipe(ofType(AuthActionTypes.ForgotPassword), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            return _this.authService
                .forgot(payload)
                .pipe(map(function (resp) { return new ForgotPasswordSuccess({ token: resp.token }); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, ForgotPasswordFail); }));
        }));
        this.reset$ = this.actions$
            .pipe(ofType(AuthActionTypes.ResetPassword), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            return _this.authService
                .reset(payload)
                .pipe(map(function (response) { return new ResetPasswordSuccess(response); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, ResetPasswordFail); }));
        }));
        this.resetSuccess$ = this.actions$
            .pipe(ofType(AuthActionTypes.ResetPasswordSuccess), tap(function () { return _this.router.navigate(['/login']); }));
        this.refreshToken$ = this.actions$
            .pipe(ofType(AuthActionTypes.RefreshToken), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            return _this.authService
                .refreshToken(payload)
                .pipe(map(function (resp) {
                _this.safeClearLocalStorageMeta();
                _this.localStorage.set(StorageKey.token, resp.token);
                return new RefreshTokenSuccess();
            }), catchError(function (response) { return _this.storeHelper.errorHandler(response, RefreshTokenFail); }));
        }));
        this.refreshTokenSuccess$ = this.actions$
            .pipe(ofType(AuthActionTypes.RefreshTokenSuccess), mergeMap(function () {
            // get company role from new token
            switch (_this.companyService.getRoleId()) {
                case CompanyRoleType.uc:
                    AuthService.redirectURL = '/data';
                    break;
                case CompanyRoleType.data_provider:
                    AuthService.redirectURL = '/operator';
                    break;
                case CompanyRoleType.tech_support:
                    AuthService.redirectURL = '/support';
                    break;
                default:
                    AuthService.redirectURL = '/login';
            }
            window.location.replace('/');
            // window.location.reload();
            return [
                // new LoginSuccess({token: this.localStorage.get(StorageKey.token)}),
                new LoadUserProfile()
            ];
        }));
        this.loginAsHomeUser$ = this.actions$
            .pipe(ofType(AuthActionTypes.LoginAsHomeUser), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            return _this.authService
                .loginAsHomeUser(payload)
                .pipe(map(function (resp) {
                _this.localStorage.set('home_token', resp.token);
                return new LoginAsHomeUserSuccess();
            }), catchError(function (response) { return _this.storeHelper.errorHandler(response, LoginAsHomeUserFail); }));
        }));
        this.registration = this.actions$
            .pipe(ofType(AuthActionTypes.Registration), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            return _this.authService
                .registration(payload)
                .pipe(map(function (res) { return new RegistrationSuccess({ token: res.token }); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, RegistrationFail); }));
        }));
        this.registrationSuccess$ = this.actions$
            .pipe(ofType(AuthActionTypes.RegistrationSuccess), map(function (action) { return action.payload; }), tap(function (payload) {
            if (!payload.id) {
                WSLMaterializeHelper.toast({ html: 'Вы уже зарегистрированы в системе. Авторизуйтесь или восстановите пароль' });
                _this.router.navigate(['/login']);
            }
            /*const barcode = this.localStorage.get(StorageKey.device_barcode);
            this.localStorage.clear();
            this.localStorage.set(StorageKey.token, payload.token);
            if (barcode) {
              this.localStorage.set(StorageKey.device_barcode, barcode);
            }
            AuthService.redirectURL = ['/login', '/reset', '/forgot', '/confirm', '/registration']
              .some(s => AuthService.redirectURL.startsWith(s)) ? '/' : AuthService.redirectURL;*/
            /*this.router.navigate([
              ['/login', '/reset', '/forgot', '/confirm', '/registration']
                .some(s => AuthService.redirectUrl.startsWith(s)) ? '/' : AuthService.redirectUrl]);*/
        }));
        this.confirmRegistration = this.actions$
            .pipe(ofType(AuthActionTypes.ConfirmRegistration), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            return _this.authService
                .confirmRegistration(payload)
                .pipe(map(function (resp) { return new ConfirmRegistrationSuccess({ token: resp.token }); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, ConfirmRegistrationFail); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(AuthActionTypes.Login, AuthActionTypes.ForgotPassword, AuthActionTypes.ResetPassword, AuthActionTypes.RefreshToken, AuthActionTypes.LoginAsHomeUser, AuthActionTypes.Registration, AuthActionTypes.ConfirmRegistration), map(function () { return new StartLoading('auth'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(AuthActionTypes.LoginSuccess, AuthActionTypes.LoginFail, AuthActionTypes.ResetPasswordSuccess, AuthActionTypes.ResetPasswordFail, AuthActionTypes.ForgotPasswordSuccess, AuthActionTypes.ForgotPasswordFail, AuthActionTypes.RefreshTokenSuccess, AuthActionTypes.RefreshTokenFail, AuthActionTypes.LoginAsHomeUserSuccess, AuthActionTypes.LoginAsHomeUserFail, AuthActionTypes.RegistrationSuccess, AuthActionTypes.RegistrationFail, AuthActionTypes.ConfirmRegistrationSuccess, AuthActionTypes.ConfirmRegistrationFail), map(function () { return new StopLoading('auth'); }));
    }
    AuthEffects.prototype.safeClearLocalStorageMeta = function () {
        var popup = this.localStorage.get('check_popup');
        var promo = this.localStorage.get('show_promotion');
        this.localStorage.clear();
        if (popup) {
            this.localStorage.set('check_popup', popup);
        }
        if (promo) {
            this.localStorage.set('show_promotion', promo);
        }
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "login$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "loginSuccess$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "loginRedirect$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "logout$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "forgot$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "reset$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "resetSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "refreshToken$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "refreshTokenSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "loginAsHomeUser$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "registration", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "registrationSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "confirmRegistration", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "stopLoad$", void 0);
    return AuthEffects;
}());
export { AuthEffects };

import {Action} from '@ngrx/store';
import {IWSLHttpResponse} from 'wsl-core';
import {IWSLCompany} from 'wsl-ek-core';

export enum CompanyProfileActionTypes {
  LoadCompanyProfile = '[CompanyProfile] Load Company Profile',
  LoadCompanyProfileSuccess = '[CompanyProfile] Load Company Profile Success',
  LoadCompanyProfileFail = '[CompanyProfile] Load Company Profile Fail',

  UpdateCompanyProfile = '[CompanyProfile] Update',
  UpdateCompanyProfileSuccess = '[CompanyProfile] Update Success',
  UpdateCompanyProfileFail = '[CompanyProfile] Update Fail',

  StartPromotion = '[CompanyProfile] Start Promotion',
  StartPromotionSuccess = '[CompanyProfile] Start Promotion Success',
  StartPromotionFailure = '[CompanyProfile] Start Promotion Failure',
}

export class LoadCompanyProfile implements Action {
  readonly type = CompanyProfileActionTypes.LoadCompanyProfile;
}

export class LoadCompanyProfileSuccess implements Action {
  readonly type = CompanyProfileActionTypes.LoadCompanyProfileSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLCompany>) {
  }
}

export class LoadCompanyProfileFail implements Action {
  readonly type = CompanyProfileActionTypes.LoadCompanyProfileFail;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export class UpdateCompanyProfile implements Action {
  readonly type = CompanyProfileActionTypes.UpdateCompanyProfile;

  constructor(public payload: IWSLCompany) {
  }
}

export class UpdateCompanyProfileSuccess implements Action {
  readonly type = CompanyProfileActionTypes.UpdateCompanyProfileSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLCompany>) {
  }
}

export class UpdateCompanyProfileFail implements Action {
  readonly type = CompanyProfileActionTypes.UpdateCompanyProfileFail;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export class StartPromotion implements Action {
  readonly type = CompanyProfileActionTypes.StartPromotion;

  constructor(public payload: {contract_date_promo: string}) {}
}

export class StartPromotionSuccess implements Action {
  readonly type = CompanyProfileActionTypes.StartPromotionSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLCompany>) {
  }
}

export class StartPromotionFailure implements Action {
  readonly type = CompanyProfileActionTypes.StartPromotionFailure;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}


export type CompanyProfileActions =
  LoadCompanyProfile |
  LoadCompanyProfileSuccess |
  LoadCompanyProfileFail |

  UpdateCompanyProfile |
  UpdateCompanyProfileSuccess |
  UpdateCompanyProfileFail |

  StartPromotion |
  StartPromotionSuccess |
  StartPromotionFailure;

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SYSTEM_SUB_TITLE, SYSTEM_TITLE } from '../../../../config/app.config';

@Component({
  selector: 'wsl-not-found',
  templateUrl: './not-found.page.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundPage implements OnInit {
  /** @internal */
  readonly systemTitle = SYSTEM_TITLE;
  /** @internal */
  readonly systemSubTitle = SYSTEM_SUB_TITLE;

  constructor(private location: Location) { }

  ngOnInit() {
  }

  back() {
    this.location.back();
  }

}

import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ApiHttpClientWithPermissionsService, BillingCompanyPermissions, UserProfileService } from 'wsl-ek-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "wsl-ek-core";
var BillingCompanyService = /** @class */ (function (_super) {
    tslib_1.__extends(BillingCompanyService, _super);
    function BillingCompanyService(http, userProfileService) {
        var _this = _super.call(this, http, 'bcompany', BillingCompanyPermissions, userProfileService) || this;
        _this.http = http;
        _this.userProfileService = userProfileService;
        return _this;
    }
    BillingCompanyService.prototype.getServices = function (id) {
        return this.http.get('bcompany_link');
    };
    BillingCompanyService.ngInjectableDef = i0.defineInjectable({ factory: function BillingCompanyService_Factory() { return new BillingCompanyService(i0.inject(i1.HttpClient), i0.inject(i2.UserProfileService)); }, token: BillingCompanyService, providedIn: "root" });
    return BillingCompanyService;
}(ApiHttpClientWithPermissionsService));
export { BillingCompanyService };

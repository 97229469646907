import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceConfGroupActions, DeviceConfGroupActionTypes } from './device-conf-group.actions';
import * as fromRoot from '@store/reducers/index';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IWSLAsyncErrors } from 'wsl-core';
import { IWSLDeviceConfGroup } from '@app/device-conf-group/models/device-conf-group';

export interface DeviceConfGroupState extends EntityState<IWSLDeviceConfGroup> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLDeviceConfGroup> = createEntityAdapter<IWSLDeviceConfGroup>({
  selectId: (item: IWSLDeviceConfGroup) => item.id,
  sortComparer: false
});

export const initialState: DeviceConfGroupState = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(state = initialState, action: DeviceConfGroupActions): DeviceConfGroupState {
  switch (action.type) {

    case DeviceConfGroupActionTypes.AddDeviceConfGroup:
    case DeviceConfGroupActionTypes.UpdateDeviceConfGroup:
    case DeviceConfGroupActionTypes.DeleteDeviceConfGroup:
      return {...state, pending: true, errors: null};

    case DeviceConfGroupActionTypes.AddDeviceConfGroupSuccess:
    case DeviceConfGroupActionTypes.UpdateDeviceConfGroupSuccess:
    case DeviceConfGroupActionTypes.DeleteDeviceConfGroupSuccess:
      return {...state, pending: false};


    case DeviceConfGroupActionTypes.DeleteDeviceConfGroupFail:
    case DeviceConfGroupActionTypes.UpdateDeviceConfGroupFail:
    case DeviceConfGroupActionTypes.AddDeviceConfGroupFail:
      return {...state, pending: false, errors: action.payload.asyncMessages};

    case DeviceConfGroupActionTypes.LoadDeviceConfGroup:
      return {
        ...state,
        // selectedID: null,
        pending: true,
        loaded: false,
        errors: null
      };

    case DeviceConfGroupActionTypes.LoadDeviceConfGroupSuccess:
      return adapter.upsertOne({
        ...action.payload.item
      }, {
        ...state,
        pending: false,
        loaded: true
      });

    case DeviceConfGroupActionTypes.LoadDeviceConfGroupFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case DeviceConfGroupActionTypes.SelectDeviceConfGroup:
      return {...state, selectedID: action.payload};

    case DeviceConfGroupActionTypes.LoadDeviceConfGroups:
      if (!action.payload.offset) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          pending: true,
          loaded: false,
          errors: null
        });
      }
      return {...state, pending: true, loaded: false, errors: null};

    case DeviceConfGroupActionTypes.LoadDeviceConfGroupsSuccess:
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case DeviceConfGroupActionTypes.LoadDeviceConfGroupsFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case DeviceConfGroupActionTypes.ClearDeviceConfGroups:
      return adapter.removeAll({
        ...state,
        totalCount: 0,
        pending: false,
        loaded: true
      });

    default:
      return state;
  }
}

export const getSelectedID = (state: DeviceConfGroupState) => state.selectedID;
export const getTotalCount = (state: DeviceConfGroupState) => state.totalCount;
export const getLoaded = (state: DeviceConfGroupState) => state.loaded;
export const getPending = (state: DeviceConfGroupState) => state.pending;
export const getErrors = (state: DeviceConfGroupState) => state.errors;

export interface State extends fromRoot.State {
  'deviceConfGroup': DeviceConfGroupState;
}

export const getDeviceConfGroupState = createFeatureSelector<DeviceConfGroupState>('deviceConfGroup');

export const selectDeviceConfGroupSelectedID = createSelector(getDeviceConfGroupState, getSelectedID);
export const selectDeviceConfGroupTotalCount = createSelector(getDeviceConfGroupState, getTotalCount);
export const selectDeviceConfGroupLoaded = createSelector(getDeviceConfGroupState, getLoaded);
export const selectDeviceConfGroupPending = createSelector(getDeviceConfGroupState, getPending);
export const selectDeviceConfGroupErrors = createSelector(getDeviceConfGroupState, getErrors);

export const {
  selectEntities: selectDeviceConfGroupEntities,
  selectAll: selectDeviceConfGroupAll
} = adapter.getSelectors(getDeviceConfGroupState);

export const selectDeviceConfGroup = createSelector(
  selectDeviceConfGroupEntities,
  selectDeviceConfGroupSelectedID,
  (entities, id) => entities && entities[id] ? entities[id] : null
);

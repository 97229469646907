import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { DeviceDataService } from 'wsl-device';
import { DataArchiveType, ResourceType, ResourceValueExtType, ResourceVariableType } from 'wsl-ek-core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { WSLHttpHelper, WSLRouterHelperService, WSLUtils } from 'wsl-core';
import { Subject } from 'rxjs';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { ActivatedRoute } from '@angular/router';
var ElectPowerProfilePerDeviceComponent = /** @class */ (function () {
    function ElectPowerProfilePerDeviceComponent(route, deviceDataService, AmCharts, chr) {
        this.route = route;
        this.deviceDataService = deviceDataService;
        this.AmCharts = AmCharts;
        this.chr = chr;
        this.id = 'elect-power-profile-per-device';
        this.showTable = true;
        this.days = [];
        this.dataFilter = null;
        this.data = [];
        this.tableData = [];
        this.pending = false;
        this.asyncErrors = null;
        this.showData = false;
        this.showChart = false;
        this.selectedDay = '01';
        this.periods = [];
        this.powerRvalues = {
            Ap1: null,
            Ap2: null,
            Am1: null,
            Am2: null,
            Rp1: null,
            Rp2: null,
            Rm1: null,
            Rm2: null
        };
        this.changeFilterSub = new Subject();
        this.rvalues = [];
        this.runits = [];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(ElectPowerProfilePerDeviceComponent.prototype, "filter", {
        set: function (f) {
            if (f.period_id === DataArchiveType.day_int) {
                this.changeFilterSub.next(f);
            }
            else {
                this.showData = false;
                this.data = [];
            }
        },
        enumerable: true,
        configurable: true
    });
    ElectPowerProfilePerDeviceComponent.prototype.ngOnInit = function () {
        var _this = this;
        var data = WSLRouterHelperService.collectRouteData(this.route);
        this.rvalues = data.resourceValues;
        this.runits = data.resourceUnits;
        this.powerRvalues = {
            Ap1: this.rvalues.find(function (rv) {
                return rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.ap1;
            }),
            Ap2: this.rvalues.find(function (rv) {
                return rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.ap2;
            }),
            Am1: this.rvalues.find(function (rv) {
                return rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.am1;
            }),
            Am2: this.rvalues.find(function (rv) {
                return rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.am2;
            }),
            Rp1: this.rvalues.find(function (rv) {
                return rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.rp1;
            }),
            Rp2: this.rvalues.find(function (rv) {
                return rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.rp2;
            }),
            Rm1: this.rvalues.find(function (rv) {
                return rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.rm1;
            }),
            Rm2: this.rvalues.find(function (rv) {
                return rv.rvariable_id === ResourceVariableType.e && rv.resource_id === ResourceType.elec && rv.ext && rv.ext.rm2;
            })
        };
        this.changeFilterSub.pipe(takeUntil(this.ngUnsubscribe), debounceTime(500), distinctUntilChanged()).subscribe(function (f) {
            _this.dataFilter = tslib_1.__assign({}, f, { period_id: DataArchiveType.hour });
            _this.defineDays();
        });
    };
    ElectPowerProfilePerDeviceComponent.prototype.ngOnDestroy = function () {
        if (this.chart) {
            try {
                this.AmCharts.destroyChart(this.chart);
            }
            catch (e) {
            }
            this.chart = null;
        }
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    ElectPowerProfilePerDeviceComponent.prototype.selectDay = function (day) {
        this.selectedDay = day;
        this.dataFilter.date = day + "." + (this.dataFilter.month + 1) + "." + this.dataFilter.year;
        this.loadData();
    };
    ElectPowerProfilePerDeviceComponent.prototype.loadData = function () {
        var _this = this;
        var powerDate = DeviceDataService.getArchiveDate(this.dataFilter);
        this.pending = true;
        this.asyncErrors = null;
        this.deviceDataService.getMany({
            device_id: this.dataFilter.device_id,
            individual: this.dataFilter.individual,
            date: powerDate,
            resource_id: this.dataFilter.resource_id,
            period_id: this.dataFilter.period_id,
            by_period: this.dataFilter.by_period
        })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (res) {
            _this.data = res.items.filter(function (data) { return data.arch_data || data.error_arch; });
            _this.pending = false;
            _this.showData = true;
            _this.updateChart();
            _this.chr.markForCheck();
        }, function (err) {
            var error = WSLHttpHelper.parseHTTPErrorResponse(err);
            if (error.view) {
                _this.asyncErrors = WSLHttpHelper.parseAsyncMessage(error.message);
            }
            _this.pending = false;
            _this.chr.markForCheck();
        });
    };
    ElectPowerProfilePerDeviceComponent.prototype.defineDays = function () {
        var periods = DeviceDataService.definePeriods(tslib_1.__assign({}, this.dataFilter, { period_id: DataArchiveType.day }));
        this.days = periods.map(function (p) { return ({ id: p.id, value: p.value }); });
        if (this.days.length > 0) {
            this.selectDay(this.days[this.days.length - 1].value);
        }
        else {
            // this.selectDay('01';
            this.showData = false;
        }
    };
    ElectPowerProfilePerDeviceComponent.prototype.updateChart = function () {
        var _this = this;
        if (this.chart) {
            try {
                this.AmCharts.destroyChart(this.chart); // clear
            }
            catch (e) {
            }
            this.chart = null;
        }
        var conf = this.getChartConfig();
        if (conf && conf.dataProvider && conf.dataProvider.length > 0) {
            setTimeout(function () {
                _this.chart = _this.AmCharts.makeChart(_this.id, conf);
                _this.chart.validateNow();
            }, 100);
        }
    };
    ElectPowerProfilePerDeviceComponent.prototype.getChartConfig = function () {
        var periodFormat = DeviceDataService.defineChartPeriodFormat(this.dataFilter);
        var separators = WSLUtils.getNumberSeparators();
        return {
            'language': 'ru',
            // 'path': 'assets/amcharts',
            'type': 'serial',
            'theme': 'light',
            'addClassNames': true,
            'percentPrecision': 0,
            'decimalSeparator': separators.decimal,
            'thousandsSeparator': separators.thousand,
            'dataProvider': this.makeDataProvider(),
            'valueAxes': [{
                    'gridAlpha': 0.07,
                    'position': 'left',
                }],
            'graphs': [{
                    'id': 'Ap',
                    'title': 'A+',
                    'type': 'line',
                    'lineThickness': 2,
                    'lineColor': '#c43a35',
                    'lineAlpha': 0.8,
                    'valueField': 'Ap',
                    'fillToGraph': 'Ap15',
                    'fillColors': ['#c43a35'],
                    'fillAlphas': 0.1,
                    'balloonText': '[[Ap]]',
                    'bullet': 'round',
                    'bulletBorderAlpha': 1,
                    'useLineColorForBulletBorder': true,
                    'bulletColor': '#FFFFFF'
                }, {
                    'id': 'Ap15',
                    'title': '15% от A+',
                    'type': 'line',
                    'lineThickness': 2,
                    'lineColor': '#12bd37',
                    'lineAlpha': 0.8,
                    'valueField': 'Ap15',
                    'fillColors': ['#12bd37'],
                    'fillAlphas': 0.1,
                    'balloonText': '[[Ap15]]',
                }, {
                    'id': 'Rp',
                    'title': 'R+',
                    'type': 'line',
                    'lineThickness': 2,
                    'lineColor': '#f0760f',
                    'lineAlpha': 0.8,
                    'valueField': 'Rp',
                    'bullet': 'square',
                    'bulletBorderAlpha': 1,
                    'bulletBorderThickness': 1
                }, {
                    'id': 'Am',
                    'title': 'A-',
                    'type': 'line',
                    'lineThickness': 2,
                    'lineColor': '#2a9cc6',
                    'lineAlpha': 0.8,
                    'valueField': 'Am',
                    // "fillToGraph": "Am15",
                    'fillColors': ['#2a9cc6'],
                    'fillAlphas': 0.1,
                    'balloonText': '[[Am]]',
                    'bullet': 'round',
                    'bulletBorderAlpha': 1,
                    'useLineColorForBulletBorder': true,
                    'bulletColor': '#FFFFFF',
                    'hidden': true
                }, {
                    'id': 'Am15',
                    'title': '15% от A-',
                    'type': 'line',
                    'lineThickness': 2,
                    'lineColor': '#f0c94e',
                    'lineAlpha': 0.8,
                    'valueField': 'Am15',
                    'fillColors': ['#f0c94e'],
                    'fillAlphas': 0.1,
                    'balloonText': '[[Am15]]',
                    'hidden': true
                    // "bullet": "round",
                    // "bulletBorderAlpha": 1,
                    // "useLineColorForBulletBorder": true,
                    // "bulletColor": "#FFFFFF"
                }, {
                    'id': 'Rm',
                    'title': 'R-',
                    'type': 'line',
                    'lineThickness': 2,
                    'lineColor': '#2c3cf0',
                    'lineAlpha': 0.8,
                    'valueField': 'Rm',
                    'bullet': 'square',
                    'bulletBorderAlpha': 1,
                    'bulletBorderThickness': 1,
                    'hidden': true
                }],
            'categoryField': 'hour',
            'categoryAxis': {
                'startOnAxis': true
            },
            'legend': {
                'enabled': true,
                'useGraphSettings': true
            }
        };
    };
    ElectPowerProfilePerDeviceComponent.prototype.makeDataProvider = function () {
        var _this = this;
        var data = [];
        if (this.data && this.data.length > 0) {
            this.periods = DeviceDataService.definePeriods(this.dataFilter);
            data = this.periods.map(function (period) {
                var date = period.datetime; // .split(' ')[0];
                var values = _this.data.find(function (ds) { return ds.datetime === date; }) ? _this.data.find(function (ds) { return ds.datetime === date; }) : null;
                if (values) {
                    var ap1 = _this.powerRvalues.Ap1 && values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Ap1.id; }) ?
                        values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Ap1.id; }).value : null;
                    var ap2 = _this.powerRvalues.Ap2 && values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Ap2.id; }) ?
                        values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Ap2.id; }).value : null;
                    var ap = ap1 + ap2;
                    var ap15 = ap * 0.15;
                    var am1 = _this.powerRvalues.Am1 && values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Am1.id; }) ?
                        values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Am1.id; }).value : null;
                    var am2 = _this.powerRvalues.Am2 && values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Am2.id; }) ?
                        values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Am2.id; }).value : null;
                    var am = am1 + am2;
                    var am15 = am * 0.15;
                    var rp1 = _this.powerRvalues.Rp1 && values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Rp1.id; }) ?
                        values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Rp1.id; }).value : null;
                    var rp2 = _this.powerRvalues.Rp2 && values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Rp2.id; }) ?
                        values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Rp2.id; }).value : null;
                    var rp = rp1 + rp2;
                    var rm1 = _this.powerRvalues.Rm1 && values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Rm1.id; }) ?
                        values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Rm1.id; }).value : null;
                    var rm2 = _this.powerRvalues.Rm2 && values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Rm2.id; }) ?
                        values.arch_data.find(function (ar) { return ar.rvalue_id === _this.powerRvalues.Rm2.id; }).value : null;
                    var rm = rm1 + rm2;
                    return {
                        hour: period.value,
                        Ap: ap,
                        Ap15: ap15,
                        Am: am,
                        Am15: am15,
                        Rp: rp,
                        Rm: rm
                    };
                }
                else {
                    return {
                        hour: period.value,
                        Ap: null,
                        Ap15: null,
                        Am: null,
                        Am15: null,
                        Rp: null,
                        Rm: null
                    };
                }
            });
        }
        this.showChart = data.length > 0;
        this.tableData = [];
        if (this.showTable && this.showChart) {
            this.defineTableData();
        }
        return data;
    };
    ElectPowerProfilePerDeviceComponent.prototype.defineTableData = function () {
        var _this = this;
        this.tableData = [];
        if (this.data.length > 0) {
            this.tableData = DeviceDataService.fillDataForRValues(
            // this.device,
            this.data, [], this.periods, this.rvalues.filter(function (rv) { return rv.resource_id === _this.dataFilter.resource_id && rv.ext &&
                ((rv.ext.ipu && rv.ext.ipu.includes(ResourceValueExtType.table)) || (rv.ext.odpu && rv.ext.odpu.includes(ResourceValueExtType.table))); }), this.runits);
        }
        // peityBar('.bar');
        // peityLine('.line');
    };
    return ElectPowerProfilePerDeviceComponent;
}());
export { ElectPowerProfilePerDeviceComponent };

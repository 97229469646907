<span class="wsl-relative">
  <a class="icon-question-circle" (click)="show()"></a>
  <div class="wsl-abs wsl-bg-white wsl-p-y-5 wsl-p-x-10 z-depth-1" style="z-index: 2; width: 200px; font-size: 12px;"
       [wslClickOutsideListen]="!!visible"
       (wslClickOutside)="hide()"
       [@visible]="visible.toString()">
    <div class="wsl-relative">
      <span [innerHTML]="text"></span>
      <span class="icon-cross wsl-red wsl-hover wsl-abs wsl-abs-right wsl-abs-top" (click)="hide()"></span>
    </div>
  </div>
</span>

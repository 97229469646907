import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KaratLoraProtocolService {

  constructor() {
  }

  static get() {
    return [
      {id: '1.7', name: '1.7'},
      {id: '1.8', name: '1.8'},
      {id: '1.9', name: '1.9'},
      {id: '2.0', name: '2.0'}
    ];
  }
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { StoreHelperService } from '@core/services/store-helper.service';
import {
  AddUsers,
  AddUsersFail,
  AddUsersSuccess,
  DeleteUser,
  DeleteUserFail,
  DeleteUserSuccess,
  LoadUser,
  LoadUserFail,
  LoadUsers,
  LoadUsersFail,
  LoadUsersSuccess,
  LoadUserSuccess,
  UpdateUser,
  UpdateUserFail,
  UpdateUserSuccess,
  UserActionTypes
} from './user.actions';
import { UserProfileActionTypes } from '@store/actions/user-profile.actions';
import { WSLUserService } from 'wsl-user';

@Injectable()
export class UserEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(UserActionTypes.AddUsers),
    map((action: AddUsers) => action.payload),
    exhaustMap(payload => {
      const nextLoad$ = this.actions$.pipe(ofType(UserActionTypes.AddUsers), skip(1));

      return this.userService.add(payload)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddUsersSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddUsersFail))
        );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UserActionTypes.UpdateUser),
    map((action: UpdateUser) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(UserActionTypes.UpdateUser), skip(1));

      return this.userService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateUserSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateUserFail))
        );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(UserActionTypes.LoadUser),
    map((action: LoadUser) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(UserActionTypes.LoadUser), skip(1));

      return this.userService.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadUserSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadUserFail))
        );
    })
  );

  @Effect()
  loadUserProfileSuccess$ = this.actions$.pipe(
    ofType(UserProfileActionTypes.LoadUserProfileSuccess),
    map((action: any) => action.payload),
    map(payload => new LoadUserSuccess(payload))
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(UserActionTypes.LoadUsers),
    map((action: LoadUsers) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(UserActionTypes.LoadUsers), skip(1));

      return this.userService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadUsersSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadUsersFail))
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(UserActionTypes.DeleteUser),
    map((action: DeleteUser) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(UserActionTypes.DeleteUser), skip(1));

      return this.userService.delete(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteUserSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteUserFail))
        );
    })
  );

  @Effect()
  startLoad$ = this.actions$
    .pipe(
      ofType(
        UserActionTypes.AddUsers,
        UserActionTypes.LoadUser,
        UserActionTypes.UpdateUser,
        UserActionTypes.DeleteUser
      ),
      map(() => new StartLoading('user'))
    );

  @Effect()
  startLoadMany$ = this.actions$
    .pipe(
      ofType(UserActionTypes.LoadUsers),
      map((action: LoadUsers) => action.payload),
      filter((payload) => payload.offset === 0),
      map(() => new StartLoading('user'))
    );


  @Effect()
  stopLoad$ = this.actions$
    .pipe(
      ofType(
        UserActionTypes.AddUsersSuccess, UserActionTypes.AddUsersFail,
        UserActionTypes.LoadUserSuccess, UserActionTypes.LoadUserFail,
        UserActionTypes.UpdateUserSuccess, UserActionTypes.UpdateUserFail,
        UserActionTypes.DeleteUserSuccess, UserActionTypes.DeleteUserFail,
        UserActionTypes.LoadUsersSuccess, UserActionTypes.LoadUsersFail
      ),
      map(() => new StopLoading('user'))
    );

  constructor(private actions$: Actions,
              private storeHelper: StoreHelperService,
              private userService: WSLUserService) {
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges, AfterViewInit, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IWSLBCompanyDevice } from '@app/billing-company/models/b-company-device';
import { IWSLDevice, IWSLDeviceModel } from 'wsl-device';
import {MomentDateTimeFormat, MomentDBDateFormat, MomentDBDateTimeFormat, WSLRouterHelperService, WSLUtils} from 'wsl-core';
import {AllResources, ArchiveTypes, DataArchiveType, DataArchiveTypes, ResourceType} from 'wsl-ek-core';
import { CommunicatorService } from '@app/communicator/services/communicator.service';
import {IWSLServiceProcedure} from '@app/service-procedure/models/service-procedure';
import {IWSLServiceProcedureDict} from '@app/service-procedure/models/service-procedure-dict';
import {IWSLServiceOperationDict} from '@app/service-operation/models/service-operation-dict';
import * as moment from 'moment';
import {environment} from '@env/environment';
import {WSLMaterializeHelper} from 'wsl-shared';

@Component({
  selector: 'wsl-device-chip',
  templateUrl: './device-chip.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceChipComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() device: IWSLDevice;
  @Input() selectable = false;
  @Input() selected = false;
  @Input() readonly = false;
  @Input() showIndividual = true;
  @Input() showArchives = true;
  @Input() showResources = true;
  @Input() showCommunicator = true;
  @Input() routeTo = '/service/devices';
  @Input() links: IWSLBCompanyDevice[] = [];
  @Input() showExtra = true;
  @Input() extra: {name?: string, icon?: string}[] = [];
  @Input() set serviceProcedures(serviceProcedures: IWSLServiceProcedure[]) {
    if (serviceProcedures) {
      this.deviceServiceProcedures = serviceProcedures;
      this.activeServiceProcedure = serviceProcedures.find(s => s.device_id === this.device.id && !s.cancel && !s.close);
      this.defineActiveServiceProcedureTooltip();
    }
  }

  @Output() selectDevice = new EventEmitter<IWSLDevice>();

  prefix = WSLUtils.randomID();
  isDom = environment.dom;

  deviceArchives = '';
  deviceServiceProcedures: IWSLServiceProcedure[];
  activeServiceProcedure: IWSLServiceProcedure = null;
  activeServiceProcedureTooltip = '';
  showCard = false;
  private MDropdown;
  private dmodels: IWSLDeviceModel[] = [];
  private sp: IWSLServiceProcedureDict[] = [];
  private so: IWSLServiceOperationDict[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private chr: ChangeDetectorRef) {
  }


  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.dmodels = data.dmodels;
    this.sp = data.sp;
    this.so = data.so;
    this.defineArchives();
  }

  ngAfterViewInit() {
    this.defineDropdown();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.device) {
      this.defineArchives();
      this.defineDropdown();
    }
  }

  ngOnDestroy(): void {
    if (this.MDropdown) {
      WSLMaterializeHelper.destroyDropdown(this.MDropdown);
    }
  }

  get dmodelName() {
    const dmodel = this.device && this.dmodels ? this.dmodels.find(dm => dm.id === this.device.dmodel_id) : null;
    return dmodel ? dmodel.name : '';
  }

  get resources() {
    return this.device ? AllResources.filter(r => this.device.resources ? this.device.resources.includes(r.id) : false) : [];
  }

  get connections() {
    const connections = [];
    if (this.device && this.device.communicators) {
      this.device.communicators.forEach(com => {
        if (com) {
          const nt = CommunicatorService.getConnectionType(com);
          if (!nt) {
            console.error(com);
          } else {
            connections.push({
              id: com.id,
              on_board: com.network.on_board,
              icon: nt.icon,
              name: nt.name,
              describe: CommunicatorService.getConnectionParameters(com)
            });
          }
        }
      });
    }
    return connections;
  }

  get deviceLink() {
    return this.links && this.device ? this.links.find(l => l.device_id === this.device.id) : null;
  }

  get showAlias() {
    return this.device && this.device.alias && (!this.device.individual || (this.device.individual && !this.isDom));
  }

  onSelect() {
    if (this.readonly || this.device.is_hidden) {
      return;
    }
    if (this.selectable) {
      /*// @todo
      if (this.MDropdown) {
        this.MDropdown.open();
      }*/
      this.selectDevice.emit(this.device);
    } else {
   //   this.router.navigate([this.routeTo, this.device.id]);
    }
  }

  onGoToDevice(d) {

  }

  onRenameDevice(d) {
  }


  trackById(index: number, item: any) {
    return item.id;
  }

  private defineArchives() {
    if (this.device && this.device.archives) {
      this.deviceArchives = this.device.archives ? DataArchiveTypes
        .filter(a => this.device.archives.includes(a.id))
        .map(a => {
          if (this.device.resources && this.device.resources.includes(ResourceType.elec) && a.id === DataArchiveType.hour) {
            return {...a, name: 'профиль мощности'};
          }
          return a;
        })
        .reduce((prev, cur) => prev + ' ' + cur.name, '') : '';
      this.defineActiveServiceProcedureTooltip();
    }
  }

  private defineActiveServiceProcedureTooltip() {
    this.activeServiceProcedureTooltip = '';
    if (this.activeServiceProcedure) {
      if (this.sp) {
        const sp = this.sp.find(p => p.id === this.activeServiceProcedure.sprocedure_id);
        this.activeServiceProcedureTooltip = sp ? sp.name : '';
      }
      if (this.so) {
        const o = this.so.find(so => so.id === this.activeServiceProcedure.so_data_soperation_id);
        this.activeServiceProcedureTooltip += o ? (': ' + o.name) : '';
      }
      this.activeServiceProcedureTooltip += ' от ' +
        moment(this.activeServiceProcedure.time_beg, MomentDBDateTimeFormat).format(MomentDateTimeFormat);
    }
  }

  private defineDropdown() {
    if (this.device && this.device.id && !this.MDropdown) {
      const self = this;
      setTimeout(() => {
        this.MDropdown = WSLMaterializeHelper.initDropdown(`.device${this.prefix}_dropdown_trigger`, {
          constrainWidth: false,
          coverTrigger: false,
          closeOnClick: false,
          onCloseEnd: function () {
            self.showCard = false;
            self.chr.markForCheck();
          }
        });
      }, 350);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DBDateTimeFormat, IWSLHttpResponse, WSLLocalStorageService } from 'wsl-core';
import { StorageKey } from 'wsl-ek-core';
import { format, isSameHour } from 'date-fns';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { IWSLDataConfType } from '../models/data-conf-type';

@Injectable()
export class DataConfTypeService {

  constructor(private http: HttpClient, private localStorage: WSLLocalStorageService) {
  }

  public get() {
    const dict = this.localStorage.get(StorageKey.data_types);
    if (dict != null && isSameHour(new Date(), new Date(dict.time))) {
      return of(dict);
    }
    return this.http.get<IWSLHttpResponse<IWSLDataConfType>>('get_data_conf_types')
      .pipe(map(data => {
        this.localStorage.set(StorageKey.data_types, {
          time: format(new Date(), DBDateTimeFormat),
          items: data.items
        });
        return data;
      }));
  }
}

import { Injectable } from '@angular/core';
import { IWSLEntityFilter, IWSLHttpResponse, WSLApiHttpClientService, WSLJwtHelperService } from 'wsl-core';
import { HttpClient } from '@angular/common/http';
import { IWSLTown } from 'wsl-ek-core';
import { IWSLCompany } from 'wsl-ek-core';

@Injectable()
export class TownService extends WSLApiHttpClientService<IWSLTown, IWSLEntityFilter> {

  constructor(public http: HttpClient, private jwtHelperService: WSLJwtHelperService) {
    super(http, 'town');
  }

  public getTownID() {
    const token = this.jwtHelperService.getTokenPayload();
    if (!token || !token.hasOwnProperty('town_id')) {
      return null;
    }
    return parseInt(token.town_id, 10);
  }

  public getTownName(company: IWSLCompany) {
    if (company) {
      const town = company.towns.find(t => t.id === this.getTownID());
      return town ? town.name : null;
    }
    return null;
  }

  public get() {
    return this.http.get<IWSLHttpResponse<IWSLTown>>(`${this.baseUrl}/${this.getTownID()}`);
  }

}

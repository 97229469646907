import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var IsDomGuard = /** @class */ (function () {
    function IsDomGuard(router) {
        this.router = router;
    }
    IsDomGuard.prototype.canActivate = function (next, state) {
        return this.check();
    };
    IsDomGuard.prototype.canActivateChild = function (route, state) {
        return this.check();
    };
    IsDomGuard.prototype.canLoad = function (route) {
        return this.check();
    };
    IsDomGuard.prototype.check = function () {
        var _this = this;
        return of(environment.dom)
            .pipe(tap(function (isDom) {
            if (!isDom) {
                _this.router.navigate(['/login']);
            }
        }));
    };
    IsDomGuard.ngInjectableDef = i0.defineInjectable({ factory: function IsDomGuard_Factory() { return new IsDomGuard(i0.inject(i1.Router)); }, token: IsDomGuard, providedIn: "root" });
    return IsDomGuard;
}());
export { IsDomGuard };

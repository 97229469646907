import { IWSLBillingCompanyLink, IWSLBillingCompanyLinkFilter } from '@app/billing-company/models/billing-company-link';
import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';

export enum BillingCompanyLinkActionTypes {
  LoadBCompanyLinks = '[Billing] Load Billings Company Links',
  LoadBCompanyLinksSuccess = '[Billing] Load Billings Company Links Success',
  LoadBCompanyLinksFail = '[Billing] Load Billings Company Links Fail',
}

// LOAD BCompany Links
export class LoadBCompanyLinks implements Action {
  readonly type = BillingCompanyLinkActionTypes.LoadBCompanyLinks;

  constructor(public payload: IWSLBillingCompanyLinkFilter) {
  }
}

export class LoadBCompanyLinksSuccess implements Action {
  readonly type = BillingCompanyLinkActionTypes.LoadBCompanyLinksSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLBillingCompanyLink>) {
  }
}

export class LoadBCompanyLinksFail implements Action {
  readonly type = BillingCompanyLinkActionTypes.LoadBCompanyLinksFail;

  constructor(public payload: IWSLHttpResponse<IWSLBillingCompanyLink>) {
  }
}

export type BillingCompanyLinkActions =
  LoadBCompanyLinks |
  LoadBCompanyLinksSuccess |
  LoadBCompanyLinksFail;

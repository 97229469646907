import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MomentDateTimeFormat, MomentDBDateTimeFormat, WSLRouterHelperService, WSLUtils } from 'wsl-core';
import { AllResources, DataArchiveType, DataArchiveTypes, ResourceType } from 'wsl-ek-core';
import { CommunicatorService } from '@app/communicator/services/communicator.service';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { WSLMaterializeHelper } from 'wsl-shared';
var DeviceChipComponent = /** @class */ (function () {
    function DeviceChipComponent(route, router, chr) {
        this.route = route;
        this.router = router;
        this.chr = chr;
        this.selectable = false;
        this.selected = false;
        this.readonly = false;
        this.showIndividual = true;
        this.showArchives = true;
        this.showResources = true;
        this.showCommunicator = true;
        this.routeTo = '/service/devices';
        this.links = [];
        this.showExtra = true;
        this.extra = [];
        this.selectDevice = new EventEmitter();
        this.prefix = WSLUtils.randomID();
        this.isDom = environment.dom;
        this.deviceArchives = '';
        this.activeServiceProcedure = null;
        this.activeServiceProcedureTooltip = '';
        this.showCard = false;
        this.dmodels = [];
        this.sp = [];
        this.so = [];
    }
    Object.defineProperty(DeviceChipComponent.prototype, "serviceProcedures", {
        set: function (serviceProcedures) {
            var _this = this;
            if (serviceProcedures) {
                this.deviceServiceProcedures = serviceProcedures;
                this.activeServiceProcedure = serviceProcedures.find(function (s) { return s.device_id === _this.device.id && !s.cancel && !s.close; });
                this.defineActiveServiceProcedureTooltip();
            }
        },
        enumerable: true,
        configurable: true
    });
    DeviceChipComponent.prototype.ngOnInit = function () {
        var data = WSLRouterHelperService.collectRouteData(this.route);
        this.dmodels = data.dmodels;
        this.sp = data.sp;
        this.so = data.so;
        this.defineArchives();
    };
    DeviceChipComponent.prototype.ngAfterViewInit = function () {
        this.defineDropdown();
    };
    DeviceChipComponent.prototype.ngOnChanges = function (changes) {
        if (changes.device) {
            this.defineArchives();
            this.defineDropdown();
        }
    };
    DeviceChipComponent.prototype.ngOnDestroy = function () {
        if (this.MDropdown) {
            WSLMaterializeHelper.destroyDropdown(this.MDropdown);
        }
    };
    Object.defineProperty(DeviceChipComponent.prototype, "dmodelName", {
        get: function () {
            var _this = this;
            var dmodel = this.device && this.dmodels ? this.dmodels.find(function (dm) { return dm.id === _this.device.dmodel_id; }) : null;
            return dmodel ? dmodel.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceChipComponent.prototype, "resources", {
        get: function () {
            var _this = this;
            return this.device ? AllResources.filter(function (r) { return _this.device.resources ? _this.device.resources.includes(r.id) : false; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceChipComponent.prototype, "connections", {
        get: function () {
            var connections = [];
            if (this.device && this.device.communicators) {
                this.device.communicators.forEach(function (com) {
                    if (com) {
                        var nt = CommunicatorService.getConnectionType(com);
                        if (!nt) {
                            console.error(com);
                        }
                        else {
                            connections.push({
                                id: com.id,
                                on_board: com.network.on_board,
                                icon: nt.icon,
                                name: nt.name,
                                describe: CommunicatorService.getConnectionParameters(com)
                            });
                        }
                    }
                });
            }
            return connections;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceChipComponent.prototype, "deviceLink", {
        get: function () {
            var _this = this;
            return this.links && this.device ? this.links.find(function (l) { return l.device_id === _this.device.id; }) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceChipComponent.prototype, "showAlias", {
        get: function () {
            return this.device && this.device.alias && (!this.device.individual || (this.device.individual && !this.isDom));
        },
        enumerable: true,
        configurable: true
    });
    DeviceChipComponent.prototype.onSelect = function () {
        if (this.readonly || this.device.is_hidden) {
            return;
        }
        if (this.selectable) {
            /*// @todo
            if (this.MDropdown) {
              this.MDropdown.open();
            }*/
            this.selectDevice.emit(this.device);
        }
        else {
            //   this.router.navigate([this.routeTo, this.device.id]);
        }
    };
    DeviceChipComponent.prototype.onGoToDevice = function (d) {
    };
    DeviceChipComponent.prototype.onRenameDevice = function (d) {
    };
    DeviceChipComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    DeviceChipComponent.prototype.defineArchives = function () {
        var _this = this;
        if (this.device && this.device.archives) {
            this.deviceArchives = this.device.archives ? DataArchiveTypes
                .filter(function (a) { return _this.device.archives.includes(a.id); })
                .map(function (a) {
                if (_this.device.resources && _this.device.resources.includes(ResourceType.elec) && a.id === DataArchiveType.hour) {
                    return tslib_1.__assign({}, a, { name: 'профиль мощности' });
                }
                return a;
            })
                .reduce(function (prev, cur) { return prev + ' ' + cur.name; }, '') : '';
            this.defineActiveServiceProcedureTooltip();
        }
    };
    DeviceChipComponent.prototype.defineActiveServiceProcedureTooltip = function () {
        var _this = this;
        this.activeServiceProcedureTooltip = '';
        if (this.activeServiceProcedure) {
            if (this.sp) {
                var sp = this.sp.find(function (p) { return p.id === _this.activeServiceProcedure.sprocedure_id; });
                this.activeServiceProcedureTooltip = sp ? sp.name : '';
            }
            if (this.so) {
                var o = this.so.find(function (so) { return so.id === _this.activeServiceProcedure.so_data_soperation_id; });
                this.activeServiceProcedureTooltip += o ? (': ' + o.name) : '';
            }
            this.activeServiceProcedureTooltip += ' от ' +
                moment(this.activeServiceProcedure.time_beg, MomentDBDateTimeFormat).format(MomentDateTimeFormat);
        }
    };
    DeviceChipComponent.prototype.defineDropdown = function () {
        var _this = this;
        if (this.device && this.device.id && !this.MDropdown) {
            var self_1 = this;
            setTimeout(function () {
                _this.MDropdown = WSLMaterializeHelper.initDropdown(".device" + _this.prefix + "_dropdown_trigger", {
                    constrainWidth: false,
                    coverTrigger: false,
                    closeOnClick: false,
                    onCloseEnd: function () {
                        self_1.showCard = false;
                        self_1.chr.markForCheck();
                    }
                });
            }, 350);
        }
    };
    return DeviceChipComponent;
}());
export { DeviceChipComponent };

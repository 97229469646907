import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {isSameDay} from 'date-fns';
import * as moment from 'moment';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MomentDateFormat, WSLFormHelper, WSLUtils} from 'wsl-core';
import {ArchiveType, ArchiveTypes, DataArchiveType, DataArchiveTypes} from 'wsl-ek-core';

// import * as moment from 'moment';

@Component({
  selector: 'wsl-b2b-select-period',
  templateUrl: './select-period.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectPeriodComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filter: any;
  @Input() classes = ['wsl-flex-bottom'];
  @Input() prefix = '';
  @Input() availablePeriodTypes = DataArchiveTypes.map(dt => dt.id);
  @Input() showArchiveType = true;
  @Output() periodChanged = new EventEmitter<any>();

  /** @internal */
  form = new FormGroup({
    by_period: new FormControl(false),
    type_id: new FormControl(null),
    period_id: new FormControl(DataArchiveType.month),
    date: new FormControl(''),
    hour: new FormControl(null),
    month: new FormControl(null),
    year: new FormControl(null)
  });
  /** @internal */
  archiveTypes = ArchiveTypes;
  /** @internal */
  periods = [];
  /** @internal */
  hours = [];
  /** @internal */
  months = [];
  /** @internal */
  years = [];
  maxDate = moment().format(MomentDateFormat); // format(new Date(), DateFormat);

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private chr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.initYears();
    this.initMonths();
    this.initHours();

    this.form.get('type_id').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged()
      )
      .subscribe(val => {
        this.definePeriods();
        this.chr.markForCheck();
      });

    this.form.get('period_id').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged()
      )
      .subscribe(val => {
        if ([DataArchiveType.hour, DataArchiveType.hour_int].includes(this.form.get('period_id').value) && this.form.get('by_period').value) {
          this.initHours();
          this.chr.markForCheck();
        }
        if ([DataArchiveType.month, DataArchiveType.month_int].includes(this.form.get('period_id').value) && this.form.get('by_period').value) {
          this.initMonths();
          this.chr.markForCheck();
        }
        if ([DataArchiveType.day, DataArchiveType.day_int].includes(this.form.get('period_id').value) && !this.form.get('by_period').value) {
          this.initMonths();
          this.chr.markForCheck();
        }
      });

    this.form.get('date').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged()
      )
      .subscribe(val => {
        if ([DataArchiveType.hour, DataArchiveType.hour_int].includes(this.form.get('period_id').value) && this.form.get('by_period').value) {
          this.initHours();
          this.chr.markForCheck();
        }
      });

    this.form.get('year').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged()
      )
      .subscribe(val => {
        if ([DataArchiveType.month, DataArchiveType.month_int].includes(this.form.get('period_id').value) && this.form.get('by_period').value) {
          this.initMonths();
          this.chr.markForCheck();
        }
        if ([DataArchiveType.day, DataArchiveType.day_int].includes(this.form.get('period_id').value) && !this.form.get('by_period').value) {
          this.initMonths();
          this.chr.markForCheck();
        }
      });

    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(val => {
        if (this.form.dirty) {
          if (WSLUtils.isDifferentObjects(this.filter, val)) {
            this.periodChanged.emit(this.form.value);
          }
        }
      });

    this.fillForm();
  }

  ngOnChanges(changes) {
    if (changes.filter) {
      this.fillForm();
    }
    if (changes.availablePeriodTypes) {
      this.definePeriods();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  get showPeriods() {
    return this.periods.length > 1;
  }

  get showSelectYear() {
    return this.form.get('by_period').value ? [
      DataArchiveType.month,
      DataArchiveType.month_int
    ].includes(this.form.get('period_id').value) : [
      DataArchiveType.day,
      DataArchiveType.day_int,
      DataArchiveType.month,
      DataArchiveType.month_int
    ].includes(this.form.get('period_id').value);
  }

  get showSelectMonth() {
    return this.form.get('by_period').value ? [
      DataArchiveType.month,
      DataArchiveType.month_int
    ].includes(this.form.get('period_id').value) : [
      DataArchiveType.day,
      DataArchiveType.day_int,
    ].includes(this.form.get('period_id').value);
  }

  get showSelectDay() {
    return this.form.get('by_period').value ? [
      DataArchiveType.day,
      DataArchiveType.day_int,
      DataArchiveType.hour,
      DataArchiveType.hour_int
    ].includes(this.form.get('period_id').value) : [
      DataArchiveType.hour,
      DataArchiveType.hour_int
    ].includes(this.form.get('period_id').value);
  }

  get showSelectHour() {
    return this.form.get('by_period').value ? [
      DataArchiveType.hour,
      DataArchiveType.hour_int
    ].includes(this.form.get('period_id').value) : [].includes(this.form.get('period_id').value);
  }

  onSelectArchiveType(type_id) {
    if (this.form.get('type_id').value !== type_id) {
      this.form.get('type_id').setValue(type_id);
      this.form.get('type_id').markAsDirty();
    }
  }

  onSelectPeriod(period_id) {
    if (this.form.get('period_id').value !== period_id) {
      this.form.get('period_id').setValue(period_id);
      this.form.get('period_id').markAsDirty();
    }
  }

  trackById(index: number, obj: any): any {
    return obj.id;
  }

  private fillForm() {
    if (this.form && this.filter && this.filter.by_period !== null && this.filter.period_id !== null) {
      WSLFormHelper.fillForm(this.form, this.filter);
      let dirty = false;
      if (this.form.pristine) {
        const today = moment(); // new Date();
        if ((!this.form.get('date').value || this.form.get('date').value === '') && (!this.filter.date || this.filter.date === '')) {
          if (this.form.get('by_period').value) {
            this.form.get('date').setValue(moment().subtract(1, 'day').format(MomentDateFormat), {emitEvent: false});
          } else {
            this.form.get('date').setValue(today.format(MomentDateFormat), {emitEvent: false});
          }
          dirty = true;
        }
        if (this.form.get('year').value === null) {
          if (this.form.get('by_period').value && today.month() === 0) {
            this.form.get('year').setValue(moment().subtract(1, 'month').year(), {emitEvent: false});
          } else {
            this.form.get('year').setValue(today.year(), {emitEvent: false});
          }
          dirty = true;
        }
        if (this.form.get('month').value === null) {
          if (this.form.get('by_period').value /*&& today.date() < 2*/) {
            this.form.get('month').setValue(moment().subtract(1, 'month').month());
          } else {
            this.form.get('month').setValue(today.month());
          }
          dirty = true;
        }
        if (this.form.get('hour').value === null) {
          if (this.form.get('by_period').value/* && today.hours() < 2*/) {
            this.form.get('hour').setValue(moment().subtract(1, 'hour').hour());
          } else {
            this.form.get('hour').setValue(today.hour());
          }
          dirty = true;
        }
      }
      this.definePeriods();
      if (!this.filter.period_id) {
        dirty = true;
      }
      this.form.markAsUntouched();
      this.form.markAsPristine();

      if (dirty) {
        this.periodChanged.emit(this.form.value);
      }
    }

  }

  private definePeriods() {
    switch (this.form.get('type_id').value) {
      case ArchiveType.interval:
        if (this.form.get('by_period').value) {
          this.periods = [
            {id: DataArchiveType.hour, name: 'за час'},
            {id: DataArchiveType.day, name: 'за день'},
            {id: DataArchiveType.month, name: 'за месяц'}
          ];
        } else {
          this.periods = [
            {id: DataArchiveType.hour, name: 'по часам'},
            {id: DataArchiveType.day, name: 'по дням'},
            {id: DataArchiveType.month, name: 'по месяцам'}
          ];
        }
        break;
      case ArchiveType.integral:
        if (this.form.get('by_period').value) {
          this.periods = [
            {id: DataArchiveType.hour_int, name: 'за час'},
            {id: DataArchiveType.day_int, name: 'за день'},
            {id: DataArchiveType.month_int, name: 'за месяц'}
          ];
        } else {
          this.periods = [
            {id: DataArchiveType.hour_int, name: 'по часам'},
            {id: DataArchiveType.day_int, name: 'по дням'},
            {id: DataArchiveType.month_int, name: 'по месяцам'}
          ];
        }
        break;
    }
    this.periods = this.periods.filter(p => this.availablePeriodTypes.some(t => t === p.id));
    this.periods = this.periods.slice(0);
    if (this.periods.length === 0) {
      this.form.get('period_id').setValue(null, {emitEvent: false});
      // this.form.get('type_id').setValue(null, {emitEvent: false});
    }
    this.archiveTypes = ArchiveTypes
      .filter(at => DataArchiveTypes
        .filter(dt => this.availablePeriodTypes.includes(dt.id))
        .some(dt => dt.type === at.id));
    if (this.periods.length > 0 && !this.periods.map(p => p.id).includes(this.form.get('period_id').value)) {
      this.revertPeriod();
    }
  }

  private revertPeriod() {
    let period = null;
    switch (this.form.get('period_id').value) {
      case DataArchiveType.hour:
        period = DataArchiveType.hour_int;
        break;
      case DataArchiveType.hour_int:
        period = DataArchiveType.hour;
        break;
      case DataArchiveType.day:
        period = DataArchiveType.day_int;
        break;
      case DataArchiveType.day_int:
        period = DataArchiveType.day;
        break;
      case DataArchiveType.month:
        period = DataArchiveType.month_int;
        break;
      case DataArchiveType.month_int:
        period = DataArchiveType.month;
        break;
    }
    if (!this.periods.map(p => p.id).includes(period)) {
      period = this.periods[0].id;
    }
    this.form.get('period_id').setValue(period, {emitEvent: false});
  }

  private initHours() {
    const curDate = isSameDay(new Date(), new Date(this.form.get('date').value));
    // moment().isSame(moment(this.form.get('date').value, DateFormat), 'day');
    this.hours = [];
    const max = /*curDate ? moment().hour() :*/ 24;
    for (let i = 0; i < max; i++) {
      this.hours.push({id: i, name: `${i} - ${i + 1}`});
    }
    if (curDate && this.form.get('hour').value > this.hours[max - 1].id) {
      this.form.get('hour').setValue(this.hours[max - 1].id);
    }
  }

  private initMonths() {
    const curMonth = moment().month(); // new Date().getMonth();
    const curYear = moment().year() === this.form.get('year').value; // new Date().getFullYear() === this.form.get('year').value;
    const max = curYear ? curMonth + 1 : 12;
    this.months = [];
    for (let i = 0; i < max; i++) {
      const date = moment().month(i);
      // date.setMonth(i); // moment().month(i);
      this.months.push({
        id: date.month(), // date.getMonth(), // date.month(), // format('MM'),
        name: date.format('MMMM') // format(date, 'LLLL', {locale: ruLocale}) // date.format('MMMM')
      });
    }
    if (curYear && (this.form.get('month').value === null || this.form.get('month').value > curMonth)) { // moment().month()) {
      this.form.get('month').setValue(curMonth); // moment().month()); // this.months[max - 1].id);
    } else if (!this.months.map(m => m.id).includes(this.form.get('month').value)) {
      this.form.get('month').setValue(this.months[0].id);
    }
    this.form.get('month').markAsDirty();
  }

  private initYears() {
    this.years = [];
    const curYear = moment().year(); // new Date().getFullYear(); // moment().year();
    for (let i = curYear; i >= 2017; i--) {
      this.years.unshift({id: i, name: i});
    }
    if (!this.form.get('year').value) {
      this.form.get('year').setValue(this.years[this.years.length - 1].id);
      this.form.get('year').markAsDirty();
    }
  }

}

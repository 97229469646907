import { Injectable } from '@angular/core';
import { RoomService } from '@app/room/services/room.service';
import { StoreHelperService } from '@core/services/store-helper.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  skip,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { ObjectActionTypes } from '../object/object.actions';
import * as fromObject from '../object/object.reducer';
import {
  AddRoom,
  AddRoomFail,
  AddRooms,
  AddRoomsFail,
  AddRoomsSuccess,
  AddRoomSuccess,
  DeleteRoom,
  DeleteRoomFail,
  DeleteRooms,
  DeleteRoomsFail,
  DeleteRoomsSuccess,
  DeleteRoomSuccess,
  GenerateActivationCodeForRoom,
  GenerateActivationCodeForRoomFail,
  GenerateActivationCodeForRoomSuccess,
  LoadRoom,
  LoadRoomFail,
  LoadRooms,
  LoadRoomsFail,
  LoadRoomsSuccess,
  LoadRoomSuccess,
  RoomActionTypes,
  UpdateRoom,
  UpdateRoomFail,
  UpdateRoomSuccess
} from './room.actions';
import * as fromRoom from './room.reducer';
import {Observable} from 'rxjs';
import {LoadAvailableRoomsFail, LoadAvailableRoomsSuccess, UserProfileActionTypes} from '@store/actions/user-profile.actions';
import {environment} from '@env/environment';

@Injectable()
export class RoomEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(RoomActionTypes.AddRoom),
    map((action: AddRoom) => action.payload),
    exhaustMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(RoomActionTypes.AddRoom), skip(1));

      return this.roomService.add(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddRoomSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddRoomFail))
        );
    })
  );

  @Effect()
  addMany$ = this.actions$.pipe(
    ofType(RoomActionTypes.AddRooms),
    map((action: AddRooms) => action.payload),
    exhaustMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(RoomActionTypes.AddRooms), skip(1));

      return this.roomService.addMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddRoomsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddRoomsFail))
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(RoomActionTypes.DeleteRoom),
    map((action: DeleteRoom) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(RoomActionTypes.DeleteRoom), skip(1));

      return this.roomService.delete(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteRoomSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteRoomFail))
        );
    })
  );

  @Effect()
  deleteMany$ = this.actions$.pipe(
    ofType(RoomActionTypes.DeleteRooms),
    map((action: DeleteRooms) => action.payload),
    exhaustMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(RoomActionTypes.DeleteRooms), skip(1));

      return this.roomService.deleteMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteRoomsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteRoomsFail))
        );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(RoomActionTypes.LoadRoom),
    map((action: LoadRoom) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(RoomActionTypes.LoadRoom), skip(1));

      return this.roomService.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadRoomSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadRoomFail))
        );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(RoomActionTypes.UpdateRoom),
    map((action: UpdateRoom) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(RoomActionTypes.UpdateRoom), skip(1));

      return this.roomService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateRoomSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateRoomFail))
        );
    })
  );

  @Effect()
  updateSuccess$ = this.actions$.pipe(
    ofType(RoomActionTypes.UpdateRoomSuccess),
    withLatestFrom(this.store$.pipe(select(fromRoom.selectRoomSelectedID))),
    map(([action, id]) => id),
    map(id => new LoadRoom(id))
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(RoomActionTypes.LoadRooms),
    map((action: LoadRooms) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(RoomActionTypes.LoadRooms), skip(1));

      return this.roomService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadRoomsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadRoomsFail))
        );
    })
  );

  /*@Effect()
  loadManyOnGenerateCodeForObject$ = this.actions$.pipe(
    ofType(ObjectActionTypes.GenerateActivationCodeForObjectSuccess),
    withLatestFrom(this.store$.pipe(select(fromObject.selectObjectSelectedID))),
    map(([action, id]) => id),
    filer(id => !!id),
    switchMap((id) => {
      return this.roomService.getMany({offset: 0, object_id: id})
        .pipe(
          take(1),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadRoomsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadRoomsFail))
        );
    })
  );*/

  @Effect()
  generateActivationCode$ = this.actions$.pipe(
    ofType(RoomActionTypes.GenerateActivationCodeForRoom),
    map((action: GenerateActivationCodeForRoom) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(RoomActionTypes.GenerateActivationCodeForRoom), skip(1));

      return this.roomService.generateActivationCode(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, GenerateActivationCodeForRoomSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, GenerateActivationCodeForRoomFail))
        );
    })
  );

 /* @Effect()
  loadManyOnGenerateCodeForRoom$ = this.actions$.pipe(
    ofType(RoomActionTypes.GenerateActivationCodeForRoomSuccess),
    withLatestFrom(this.store$.pipe(select(fromRoom.selectRoomSelectedID))),
    map(([action, id]) => id),
    switchMap((id) => {
      return this.roomService.get(id)
        .pipe(
          take(1),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadRoomSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadRoomFail))
        );
    })
  );*/

 /* @Effect()
  loadProfileRoom$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserProfileActionTypes.LoadUserProfileSuccess),
      filter(action => !!environment.kv),
      exhaustMap(() =>
        this.roomService
          .get(this.roomService.getRoomID())
          .pipe(
            map(response => new LoadRoomSuccess(response)),
            catchError(response => this.storeHelper.errorHandler(response, LoadRoomFail))
          )
      )
    );*/

  @Effect()
  startLoad$ = this.actions$
    .pipe(
      ofType(RoomActionTypes.LoadRoom,
        RoomActionTypes.AddRoom,
        RoomActionTypes.AddRooms,
        RoomActionTypes.DeleteRoom,
        RoomActionTypes.DeleteRooms,
        RoomActionTypes.UpdateRoom,
        RoomActionTypes.GenerateActivationCodeForRoom
      ),
      map(() => new StartLoading('room'))
    );

  @Effect()
  startLoadMany$ = this.actions$
    .pipe(
      ofType(RoomActionTypes.LoadRooms),
      map((action: LoadRooms) => action.payload),
      filter((payload) => payload.offset === 0),
      map(() => new StartLoading('room'))
    );


  @Effect()
  stopLoad$ = this.actions$
    .pipe(
      ofType(
        RoomActionTypes.AddRoomSuccess, RoomActionTypes.AddRoomFail,
        RoomActionTypes.AddRoomsSuccess, RoomActionTypes.AddRoomsFail,
        RoomActionTypes.DeleteRoomSuccess, RoomActionTypes.DeleteRoomFail,
        RoomActionTypes.DeleteRoomsSuccess, RoomActionTypes.DeleteRoomsFail,
        RoomActionTypes.LoadRoomSuccess, RoomActionTypes.LoadRoomFail,
        RoomActionTypes.LoadRoomsSuccess, RoomActionTypes.LoadRoomsFail,
        RoomActionTypes.UpdateRoomSuccess, RoomActionTypes.UpdateRoomFail,
        RoomActionTypes.GenerateActivationCodeForRoomSuccess, RoomActionTypes.GenerateActivationCodeForRoomFail
      ),
      map(() => new StopLoading('room'))
    );

  constructor(private actions$: Actions,
              private store$: Store<fromRoom.State>,
              private storeHelper: StoreHelperService,
              private roomService: RoomService) {
  }
}

<div class="wsl-relative wsl-p-x-2">
      <a class="wsl-st4 wsl-m-l-15" *ngIf="isIcon; else isText" [ngClass]="icon" (click)="show()" #triggerEl></a>
      <ng-template #isText><span class="wsl-st4 wsl-m-l-15" (click)="show()" #triggerEl>{{activator}}</span></ng-template>

    <div class="wsl-abs wsl-bg-white wsl-small z-depth-1" style="z-index: 2; min-width: 150px;" #dropdownEl
       [wslClickOutsideListen]="!!visible"
       (wslClickOutside)="hide()"
       [@visible]="visible.toString()">
        <div class="wsl-relative wsl-p-y-5 wsl-p-x-10 wsl-text-left">
          <span [innerHTML]="html"></span>
          <span class="icon-cross wsl-red wsl-hover wsl-abs wsl-abs-right wsl-abs-top" (click)="hide()"></span>
        </div>
  </div>
</div>

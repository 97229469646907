import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var IsHomeGuard = /** @class */ (function () {
    function IsHomeGuard(router) {
        this.router = router;
    }
    IsHomeGuard.prototype.canActivate = function (next, state) {
        return this.check();
    };
    IsHomeGuard.prototype.canActivateChild = function (route, state) {
        return this.check();
    };
    IsHomeGuard.prototype.canLoad = function (route) {
        return this.check();
    };
    IsHomeGuard.prototype.check = function () {
        var _this = this;
        return of(environment.home).pipe(tap(function (isHome) {
            if (!isHome) {
                _this.router.navigate(['/login']);
            }
        }));
    };
    IsHomeGuard.ngInjectableDef = i0.defineInjectable({ factory: function IsHomeGuard_Factory() { return new IsHomeGuard(i0.inject(i1.Router)); }, token: IsHomeGuard, providedIn: "root" });
    return IsHomeGuard;
}());
export { IsHomeGuard };

import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '@env/environment';
import * as fromAuth from './auth.reducer';
import * as fromApp from './app.reducer';
import * as fromUserProfile from './user-profile.reducer';
import { AuthActionTypes } from '../actions/auth.actions';
// import { storeFreeze } from 'ngrx-store-freeze';

/** Fix for ngrx   action may have payload */
declare module '@ngrx/store' {
  interface Action {
    type: string;
    payload?: any;
  }
}


export interface State {

  app: fromApp.State;
  auth: fromAuth.State;
  userProfile: fromUserProfile.State;
}

export const reducers: ActionReducerMap<State> = {

  app: fromApp.reducer,
  auth: fromAuth.reducer,
  userProfile: fromUserProfile.reducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

// clear state after logout
export function logout(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state, action) {
    return reducer(action.type === AuthActionTypes.Logout || action.type === AuthActionTypes.RefreshTokenSuccess ? {app: fromApp.initialState, auth: fromAuth.initialState, userProfile: fromUserProfile.initialState} : state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [/*storeFreeze,*/ logout] : [logout];

/**
 * App Reducers
 */
export const getAppState = createFeatureSelector<fromApp.State>('app');

export const selectPending = createSelector(
  getAppState,
  fromApp.getPending
);

export const selectMenu = createSelector(
  getAppState,
  fromApp.getMenu
);

export const selectSnackBarMessage = createSelector(
  getAppState,
  fromApp.getMessage
);

export const selectNoAccessData = createSelector(
  getAppState,
  fromApp.getNoAccessData
);


export const selectHasBilling = createSelector(
  getAppState,
  fromApp.getHasBilling
);

export const selectIsUc = createSelector(
    getAppState,
    fromApp.getIsUc
);

export const selectIsKv = createSelector(
    getAppState,
    fromApp.getIsKv
);

export const selectIsHome = createSelector(
    getAppState,
    fromApp.getIsHome
);

export const selectAppEnv = createSelector(
    selectIsUc,
    selectIsKv,
    selectIsHome,
    (isUc, isKv, isHome) => ({isUc, isKv, isHome})
);

/**
 * Auth Reducers
 */
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');

export const selectLoggedIn = createSelector(
  getAuthState,
  fromAuth.getLoggedId
);

/**
 * User Profile Reducers
 */
export const getUserProfileState = createFeatureSelector<fromUserProfile.State>('userProfile');

export const selectUserProfile = createSelector(
  getUserProfileState,
  fromUserProfile.getUser
);

export const selectUserCompanyProfile = createSelector(
  getUserProfileState,
  fromUserProfile.getCompany
);

export const selectIsCompanyFreeAccount = createSelector(
  getUserProfileState,
  fromUserProfile.getIsCompanyFreeAccount
);

export const selectCompanyHasLimitedAccess = createSelector(
  getUserProfileState,
  fromUserProfile.getCompanyHasLimitedAccess
);

export const selectShowPromotion = createSelector(
    getUserProfileState,
    fromUserProfile.getShowPromotion
);

export const selectUserAvailableCompanies = createSelector(
  getUserProfileState,
  fromUserProfile.getAvailableCompanies
);

export const selectUserTown = createSelector(
  getUserProfileState,
  fromUserProfile.getTown
);


export const selectUserRooms = createSelector(
    getUserProfileState,
    fromUserProfile.getRooms
);

export const selectUserRoom = createSelector(
    getUserProfileState,
    fromUserProfile.getRoom
);

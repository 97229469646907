import { OnInit } from '@angular/core';
var DevicesPerRoomsListComponent = /** @class */ (function () {
    function DevicesPerRoomsListComponent() {
        this.serviceProcedures = [];
        this.roomDevices = [];
    }
    Object.defineProperty(DevicesPerRoomsListComponent.prototype, "devices", {
        set: function (devices) {
            this.roomDevices = this.defineRoomDevices(devices);
        },
        enumerable: true,
        configurable: true
    });
    DevicesPerRoomsListComponent.prototype.ngOnInit = function () {
    };
    DevicesPerRoomsListComponent.prototype.defineRoomDevices = function (devices) {
        if (!devices || devices.length === 0) {
            return [];
        }
        var data = [];
        var devs = [];
        for (var i = 0; i < devices.length; i++) {
            if (i > 0 && devices.length > 1) {
                if (devices[i - 1].room_id !== devices[i].room_id) {
                    var device = devices[i - 1];
                    data.push({
                        room: {
                            id: device.room_id,
                            name: device.room_name,
                            residential: device.residential,
                            object_id: device.object_id
                        },
                        devices: devs.slice()
                    });
                    devs = [];
                }
            }
            devs.push(devices[i]);
        }
        if (devs.length > 0) {
            data.push({
                room: {
                    id: devs[0].room_id,
                    name: devs[0].room_name,
                    residential: devs[0].residential,
                    object_id: devs[0].object_id,
                },
                devices: devs.slice()
            });
            devs = [];
        }
        /*const groups = WSLUtils.groupBy(this.devices, 'room_id');
        Object.keys(groups).forEach(room_id => {
          const device: IWSLDevice = groups[room_id][0];
          data.push({
            room: {
              id: device.room_id,
              name: device.room_name,
              residential: device.residential,
              object_id: device.object_id
            },
            devices: groups[room_id]
          });
        });*/
        return data;
    };
    DevicesPerRoomsListComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    return DevicesPerRoomsListComponent;
}());
export { DevicesPerRoomsListComponent };

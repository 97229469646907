import { Action } from '@ngrx/store';
import { IWSLDevice, IWSLDeviceFilter } from 'wsl-device';
import { IWSLHttpResponse } from 'wsl-core';
import {IWSLDeviceSetCommunicator} from 'wsl-device';

export enum DeviceActionTypes {
  AddDevice = '[Device] Add',
  AddDeviceSuccess = '[Device] Add Success',
  AddDeviceFail = '[Device] Add Fail',

  UpdateDeviceGroupConf = '[Device] Update GroupConf',
  UpdateDeviceGroupConfSuccess = '[Device] Update GroupConf Success',
  UpdateDeviceGroupConfFail = '[Device] Update GroupConf Fail',

  UpdateDeviceCommunicator = '[Device] Update Communicator',
  UpdateDeviceCommunicatorSuccess = '[Device] Update Communicator Success',
  UpdateDeviceCommunicatorFail = '[Device] Update Communicator Fail',

  RenameDevice = '[Device] Rename',
  RenameDeviceSuccess = '[Device] Rename Success',
  RenameDeviceFail = '[Device] Rename Fail',

  UpdateTimezoneDevice = '[Device] Update Timezone',
  UpdateTimezoneDeviceSuccess = '[Device] Update Timezone Success',
  UpdateTimezoneDeviceFail = '[Device] Update Timezone Fail',

  HideDevice = '[Device] Hide',
  HideDeviceSuccess = '[Device] Hide Success',
  HideDeviceFail = '[Device] Hide Fail',

  DeleteDevice = '[Device] Delete',
  DeleteDeviceSuccess = '[Device] Delete Success',
  DeleteDeviceFail = '[Device] Delete Fail',

  SelectDevice = '[Device] Select',

  LoadDevice = '[Device] Load',
  LoadDeviceSuccess = '[Device] Load Success',
  LoadDeviceFail = '[Device] Load Fail',

  LoadDevices = '[Device] Load Many',
  LoadDevicesSuccess = '[Device] Load Many Success',
  LoadDevicesFail = '[Device] Load Many Fail',

  ClearDevices = '[Device] Clear'
}

// export const DeviceActionTypes = StoreHelperService.generateActionTypes('Device');

/** Add */
export class AddDevice implements Action {
  readonly type = DeviceActionTypes.AddDevice;

  constructor(public payload: IWSLDevice) {
  }
}

export class AddDeviceSuccess implements Action {
  readonly type = DeviceActionTypes.AddDeviceSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class AddDeviceFail implements Action {
  readonly type = DeviceActionTypes.AddDeviceFail;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

/** Update */
export class UpdateDeviceGroupConf implements Action {
  readonly type = DeviceActionTypes.UpdateDeviceGroupConf;

  constructor(public payload: IWSLDevice) {
  }
}

export class UpdateDeviceGroupConfSuccess implements Action {
  readonly type = DeviceActionTypes.UpdateDeviceGroupConfSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class UpdateDeviceGroupConfFail implements Action {
  readonly type = DeviceActionTypes.UpdateDeviceGroupConfFail;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class UpdateDeviceCommunicator implements Action {
  readonly type = DeviceActionTypes.UpdateDeviceCommunicator;

  constructor(public payload: IWSLDeviceSetCommunicator) {
  }
}

export class UpdateDeviceCommunicatorSuccess implements Action {
  readonly type = DeviceActionTypes.UpdateDeviceCommunicatorSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class UpdateDeviceCommunicatorFail implements Action {
  readonly type = DeviceActionTypes.UpdateDeviceCommunicatorFail;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

/** Rename */
export class RenameDevice implements Action {
  readonly type = DeviceActionTypes.RenameDevice;

  constructor(public payload: IWSLDevice) {
  }
}

export class RenameDeviceSuccess implements Action {
  readonly type = DeviceActionTypes.RenameDeviceSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class RenameDeviceFail implements Action {
  readonly type = DeviceActionTypes.RenameDeviceFail;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

/** Update Timezone */
export class UpdateTimezoneDevice implements Action {
  readonly type = DeviceActionTypes.UpdateTimezoneDevice;

  constructor(public payload: IWSLDevice) {
  }
}

export class UpdateTimezoneDeviceSuccess implements Action {
  readonly type = DeviceActionTypes.UpdateTimezoneDeviceSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class UpdateTimezoneDeviceFail implements Action {
  readonly type = DeviceActionTypes.UpdateTimezoneDeviceFail;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}


/** Hide */
export class HideDevice implements Action {
  readonly type = DeviceActionTypes.HideDevice;

  constructor(public payload: IWSLDevice) {
  }
}

export class HideDeviceSuccess implements Action {
  readonly type = DeviceActionTypes.HideDeviceSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class HideDeviceFail implements Action {
  readonly type = DeviceActionTypes.HideDeviceFail;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}


/** Delete */
export class DeleteDevice implements Action {
  readonly type = DeviceActionTypes.DeleteDevice;

  constructor(public payload: number) {
  }
}

export class DeleteDeviceSuccess implements Action {
  readonly type = DeviceActionTypes.DeleteDeviceSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class DeleteDeviceFail implements Action {
  readonly type = DeviceActionTypes.DeleteDeviceFail;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

/** Select */
export class SelectDevice implements Action {
  readonly type = DeviceActionTypes.SelectDevice;

  constructor(public payload: number) {
  }
}

/** Load One */
export class LoadDevice implements Action {
  readonly type = DeviceActionTypes.LoadDevice;

  constructor(public payload: number) {
  }
}

export class LoadDeviceSuccess implements Action {
  readonly type = DeviceActionTypes.LoadDeviceSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class LoadDeviceFail implements Action {
  readonly type = DeviceActionTypes.LoadDeviceFail;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

/** Load Many */
export class LoadDevices implements Action {
  readonly type = DeviceActionTypes.LoadDevices;

  constructor(public payload: IWSLDeviceFilter) {
  }
}

export class LoadDevicesSuccess implements Action {
  readonly type = DeviceActionTypes.LoadDevicesSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class LoadDevicesFail implements Action {
  readonly type = DeviceActionTypes.LoadDevicesFail;

  constructor(public payload: IWSLHttpResponse<IWSLDevice>) {
  }
}

export class ClearDevices implements Action {
  readonly type = DeviceActionTypes.ClearDevices;
}

export type DeviceActions =
  AddDevice |
  AddDeviceSuccess |
  AddDeviceFail |

  UpdateDeviceGroupConf |
  UpdateDeviceGroupConfSuccess |
  UpdateDeviceGroupConfFail |

  UpdateDeviceCommunicator |
  UpdateDeviceCommunicatorSuccess |
  UpdateDeviceCommunicatorFail |

  RenameDevice |
  RenameDeviceSuccess |
  RenameDeviceFail |

  UpdateTimezoneDevice |
  UpdateTimezoneDeviceSuccess |
  UpdateTimezoneDeviceFail |

  HideDevice |
  HideDeviceSuccess |
  HideDeviceFail |

  DeleteDevice |
  DeleteDeviceSuccess |
  DeleteDeviceFail |

  SelectDevice |

  LoadDevice |
  LoadDeviceSuccess |
  LoadDeviceFail |

  LoadDevices |
  LoadDevicesSuccess |
  LoadDevicesFail |
  ClearDevices;

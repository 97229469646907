<div [class.wsl-is-scroll]="isScrolled"
     wslSpyScroll
     (scrolled)="onScroll($event)">
    <ng-container *ngIf="!!authorized">
        <div class="wsl-fixed-menu">
            <wsl-toolbar [title]="systemTitle"
                         [subTitle]="systemSubTitle"
                         [titleXS]="systemTitleXS"
                         [subTitleXS]="systemSubTitleXS"
                         [menu]="mainMenu"
                         [user]="user"
                         [company]="company$|async"
                         [showCompanyName]="showCompany"
                         [town]="townName"
                         (logout)="onLogout()"
                         (toggleProfile)="onToggleProfile()"></wsl-toolbar>
        </div>
        <!--<div id="slide-menu" class="side-nav">
            <wsl-toolbar [title]="systemTitle"
                         [subTitle]="systemSubTitle"
                         [titleXS]="systemTitleXS"
                         [subTitleXS]="systemSubTitleXS"
                         [menu]="mainMenu"
                         [sidenav]="true"
                         [user]="user$|async"
                         [company]="company$|async"
                         (logout)="onLogout()"
                         (toggleProfile)="onToggleProfile()"></wsl-toolbar>
        </div>-->

        <wsl-scroll-top [scrollTop]="scrollTop"></wsl-scroll-top>
        <wsl-error-message [message]="(snackbarMessage$|async)?.get('common')" [showMessage]="false"
                           [showToast]="true"></wsl-error-message>

        <wsl-profile-sidenav></wsl-profile-sidenav>
    </ng-container>

    <wsl-layout [loading]="pending$|async" [withNavSecond]="withNavSecond">
        <wsl-menu-tab-bar *ngIf="loggedIn$|async"
                          [menu]="menu"
                          prefix="page"></wsl-menu-tab-bar>
      <!--  <div [@routerTransition]="getState(o)">-->
            <router-outlet #o="outlet"></router-outlet>
       <!-- </div>-->
    </wsl-layout>
</div>

<div id="no_access_modal" class="modal modal-alert">
    <div class="modal-content">
        <div class="wsl-h4">Доступ компании {{contractData?.name}} заблокирован</div>
        <div>Контракт №{{contractData?.contract_num}} от {{contractData?.contract_date_beg ? (contractData?.contract_date_beg|date:'dd.MM.yyyy') : ''}}</div>
        <div>Справки по тел. (343) 2222-306</div>
        <div>Управление собственной продукции
            и технической поддержки НПО “КАРАТ”</div>
    </div>
    <div class="modal-footer wsl-flex-middle-ng wsl-p-x-15 wsl-text-right">
        <a class="modal-action modal-close waves-effect waves-light wsl-red wsl-hover btn-flat"
           (click)="closeModal()">
            Закрыть
        </a>
    </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyProfileService } from 'wsl-ek-core';
import { TownService } from '@core/services/town.service';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RefreshToken } from '@store/actions/auth.actions';
import * as fromRoot from '@store/reducers/index';
import { LoadUser, SelectUser, UpdateUser, UserActionTypes } from '@store/user/user.actions';
import * as fromUser from '@store/user/user.reducer';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IWSLAsyncErrors, WSLUtils } from 'wsl-core';
import { UserProfileService } from 'wsl-ek-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { IWSLUser } from 'wsl-user';
import { IWSLCompany } from 'wsl-ek-core';
import {IWSLRoom} from "@app/room/models/room";
import {environment} from "@env/environment";
import {IWSLAppEnvironment} from "@core/models/app-environment";

@Component({
  selector: 'wsl-profile-sidenav',
  templateUrl: './profile-sidenav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileSidenavComponent implements OnInit, OnDestroy {
  appEnv$: Observable<IWSLAppEnvironment>;
  /** @internal */
  user$: Observable<IWSLUser>;
  /** @internal */
  company$: Observable<IWSLCompany>;
  /** @internal */
  companies$: Observable<IWSLCompany[]>;
  rooms$: Observable<IWSLRoom[]>;
  /** @internal */
  asyncErrors$: Observable<IWSLAsyncErrors>;
  /** @internal */
  isMyProfile = false;
  /** @internal */
  opened = false;
  /** @internal */
  userID = null;
  /** @internal */
 // stateID = null;

  private openedStates = [];
  private lazyRefresh = false;

  private MSideNav;
  private MCollapsible;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private store$: Store<fromRoot.State>,
              private updates$: Actions,
              private chr: ChangeDetectorRef,
              private userProfileService: UserProfileService,
              private companyProfileService: CompanyProfileService,
              private townService: TownService) {
    this.appEnv$ = this.store$.pipe(select(fromRoot.selectAppEnv));
    this.user$ = store$.pipe(select(fromUser.selectUserSelected));
    this.company$ = store$.pipe(select(fromRoot.selectUserCompanyProfile));
    this.companies$ = store$.pipe(select(fromRoot.selectUserAvailableCompanies));
    this.asyncErrors$ = store$.pipe(select(fromRoot.selectSnackBarMessage));
    this.rooms$ = store$.pipe(select(fromRoot.selectUserRooms));

    this.updates$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        ofType(UserActionTypes.UpdateUserSuccess),
        tap(() => {
          if (this.userID) {
            if (this.lazyRefresh) {
              if (environment.kv) {
                this.store$.dispatch(new RefreshToken(
                    {
                      room_id: this.userProfileService.getRoomID()
                    }
                ));
              } else {
                this.store$.dispatch(new RefreshToken(
                    {
                      company_id: this.companyProfileService.getCompanyID(),
                      town_id: this.townService.getTownID()
                    }
                ));
              }
            } else {
              store$.dispatch(new LoadUser(this.userID));
            }
          }
          /* if (this.userID) {
             store$.dispatch(new LoadUser(this.userID));
           }*/
        })
      ).subscribe();

  }

  ngOnInit() {
    this.userProfileService.userID
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(id => {
        this.isMyProfile = id === this.userProfileService.getUserID();
        this.store$.dispatch(new SelectUser(id));
        if (id !== null) {
          if (this.MSideNav) {
            this.MSideNav.open();
            this.opened = true;
            //  if (!this.MCollapsible) {
            // this.MCollapsible = MaterializeHelper.initCollapsible('.profile_collapsible', {});
            //  }

            setTimeout(() => {
              this.MCollapsible = WSLMaterializeHelper.initCollapsible('.profile_collapsible', {});
              if (this.MCollapsible) {
                this.MCollapsible.open(0);
                // this.toState(0);
              }
            }, 350);
          }
        } else {
          if (this.MSideNav) {
           // this.stateID = null;
            this.MSideNav.close();
            this.opened = false;
          }
        }
        this.chr.markForCheck();
      });
    this.MSideNav = WSLMaterializeHelper.initSidenav('.profile-sidenav', {edge: 'right'});
  }

  ngOnDestroy() {
    if (this.MSideNav) {
      this.MSideNav.close();
      this.MSideNav.destroy();
    }
    if (this.MCollapsible) {
      this.MCollapsible.destroy();
    }
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  closeSideNav() {
    if (this.MSideNav && this.opened) {
      this.MSideNav.close();
      this.opened = false;
    }
  }

/*  toState(state: number) {
    if (this.MCollapsible) {
      if (this.stateID !== state) {
        this.MCollapsible.open(state);
        this.openedStates.push(state);
      } else {
        if (this.openedStates.includes(state)) {
          this.MCollapsible.close(state);
          WSLUtils.removeItemFromArray(state, this.openedStates);
        } else {
          this.MCollapsible.open(state);
          this.openedStates.push(state);
        }
      }
      this.stateID = state;
    }
  }*/

  onEditEnvironment(data) {
    this.store$.dispatch(new RefreshToken(data));
  }

  onToggleCalendar(visibleCalendar) {
    this.opened = !visibleCalendar;
    this.chr.markForCheck();
  }

  onUpdateUser(data) {
    this.userID = data.user.id;
    this.lazyRefresh = data.refresh;
    this.store$.dispatch(new UpdateUser(data.user));
    /* if (data.refresh) {
       this.store$.dispatch(new RefreshToken(data.user));
     } else {
       this.store$.dispatch(new UpdateUser(data.user));
     }*/
  }

  onLoadUser(id: number) {
    this.store$.dispatch(new LoadUser(id));
  }

}

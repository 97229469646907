<wsl-error-message [message]="asyncErrors?.get('common')"></wsl-error-message>

<form class="wsl-bg-white wsl-p-x-20 wsl-p-y-25 z-depth-1 wsl-hide-bg-xs"
      [formGroup]="form"
      (ngSubmit)="submit()" >
  <!--<wsl-input-wrapper label="Штрихкод прибора"
                     inputId="barcode"
                     [value]="form.get('barcode').value"
                     [errors]="form.get('barcode').errors"
                     [asyncError]="errorMessage?.barcode">
    <input type="text"
           id="barcode"
           class="form-control"
           formControlName="barcode">
  </wsl-input-wrapper>

  <div class="wsl-p-a-5"></div>-->

  <!--<wsl-input-wrapper label="Логин"
                     inputId="username"
                     [dirty]="form.get('username').dirty || form.get('username').touched"
                     [value]="form.get('username').value"
                     [errors]="form.get('username').errors"
                     [asyncError]="errorMessage?.username">
    <input type="text"
           id="username"
           class="form-control"
           formControlName="username">
  </wsl-input-wrapper>

  <div class="wsl-p-a-5"></div>-->

  <wsl-input-wrapper *ngIf="showCodeActivation"
                     label="Код активации" inputId="invite"
                     [readonly]="false" [required]="true"
                     [asyncError]="asyncErrors?.get('invite')">
    <input type="text"
           id="invite"
           class="form-control"
           formControlName="invite">
  </wsl-input-wrapper>

  <div class="wsl-p-a-5" *ngIf="showCodeActivation"></div>

  <wsl-input-wrapper label="Email"
                     inputId="email"
                     [required]="true"
                     [asyncError]="asyncErrors?.get('email')">
    <input type="email"
           id="email"
           #login
           class="form-control"
           placeholder="your@email.com"
           formControlName="email">
  </wsl-input-wrapper>

  <div class="wsl-p-a-5"></div>

  <div class="wsl-p-a-5"></div>

  <wsl-input-wrapper label="Придумайте себе пароль"
                     inputId="password"
                     [required]="true"
                     [asyncError]="asyncErrors?.get('password')">
    <input type="password"
           id="password"
           #password
           class="form-control"
           formControlName="password">
  </wsl-input-wrapper>

  <div class="wsl-p-a-5"></div>

  <wsl-input-wrapper label="Пароль еще раз"
                     inputId="confirm_password"
                     [required]="true"
                     [asyncError]="asyncErrors?.get('confirm_password')">
    <input id="profile_confirm_password"
           type="password"
           class="form-control"
           formControlName="confirm_password">
    <div class="wsl-abs wsl-w-100" *ngIf="form.get('confirm_password').touched">
      <div class="wsl-small wsl-red wsl-text-right" *ngIf="form.hasError('mismatchedPasswords')">
        Пароли не совпадают
      </div>
    </div>
  </wsl-input-wrapper>

  <div class="wsl-p-a-5"></div>

  <div class="wsl-flex-column-ng wsl-m-t-20">
    <button class="btn waves-effect waves-light" type="submit">Зарегистрироваться</button>
  </div>
</form>

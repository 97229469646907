import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyProfileService, UserProfileService } from 'wsl-ek-core';
import { TownService } from '../../services/town.service';
import { WSLMaterializeHelper } from 'wsl-shared';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from "@env/environment";
var EnvironmentSetupComponent = /** @class */ (function () {
    function EnvironmentSetupComponent(userProfileService, companyProfileService, townService) {
        this.userProfileService = userProfileService;
        this.companyProfileService = companyProfileService;
        this.townService = townService;
        this.edit = new EventEmitter();
        this.close = new EventEmitter();
        /** @internal */
        this.form = new FormGroup({
            company_id: new FormControl(null),
            town_id: new FormControl(null),
            room_id: new FormControl(null)
        });
        this.availableCompanies = [];
        this.availableRooms = [];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(EnvironmentSetupComponent.prototype, "companies", {
        set: function (companies) {
            this.availableCompanies = companies.map(function (c) { return (tslib_1.__assign({}, c, { name: c.short_name })); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnvironmentSetupComponent.prototype, "rooms", {
        set: function (rooms) {
            this.availableRooms = rooms.map(function (r) { return (tslib_1.__assign({}, r, { name: r.street + " " + r.house_num + ", \u043A\u0432. " + r.name })); });
        },
        enumerable: true,
        configurable: true
    });
    EnvironmentSetupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.get('company_id').setValue(this.companyProfileService.getCompanyID());
        this.form.get('town_id').setValue(this.townService.getTownID());
        this.form.get('room_id').setValue(this.userProfileService.getRoomID());
        this.form.get('company_id').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), debounceTime(350), 
        // delay(150),
        distinctUntilChanged()).subscribe(function (comp_id) {
            _this.form.get('town_id').setValue(_this.companyTowns.length > 0 ? _this.companyTowns[0].id : null);
        });
    };
    EnvironmentSetupComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(EnvironmentSetupComponent.prototype, "companyTowns", {
        get: function () {
            var _this = this;
            var comp = this.availableCompanies.find(function (c) { return c.id === _this.form.get('company_id').value; });
            return comp ? comp.towns : [];
        },
        enumerable: true,
        configurable: true
    });
    EnvironmentSetupComponent.prototype.save = function () {
        if (this.form.valid) {
            if (environment.kv) {
                if (this.form.get('room_id').value !== this.userProfileService.getRoomID()) {
                    this.edit.emit({ room_id: this.form.get('room_id').value });
                }
                else {
                    this.close.emit();
                }
            }
            else {
                if (this.form.get('company_id').value !== this.companyProfileService.getCompanyID() || this.form.get('town_id').value !== this.townService.getTownID()) {
                    this.edit.emit({ company_id: this.form.get('company_id').value, town_id: this.form.get('town_id').value });
                }
                else {
                    this.close.emit();
                }
            }
        }
        else {
            WSLMaterializeHelper.toast({ html: 'Есть ошибки заполнения формы' });
        }
    };
    return EnvironmentSetupComponent;
}());
export { EnvironmentSetupComponent };

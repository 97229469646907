import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IWSLTimeZone } from '../../++data-provider/models/time-zone';
import { TimeZoneService } from '../services/time-zone.service';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable()
export class TimezoneResolver implements Resolve<IWSLTimeZone[]> {
  constructor(private router: Router, private timezoneService: TimeZoneService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return this.timezoneService.getMany({})
      .pipe(
        filter(res => res.items.length > 0),
        take(1),
        map(res => res.items),
        tap(items => this.timezoneService.formatUTCOffset(items))
      );
  }
}

import * as tslib_1 from "tslib";
var _a;
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoomActionTypes } from './room.actions';
import { createEntityAdapter } from '@ngrx/entity';
export var adapter = createEntityAdapter({
    selectId: function (item) { return item.id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case RoomActionTypes.LoadRoom:
            return tslib_1.__assign({}, state, { 
                // selectedID: null,
                pending: true, loaded: false, errors: null });
        case RoomActionTypes.LoadRoomSuccess:
            return adapter.upsertOne(tslib_1.__assign({}, action.payload.item), tslib_1.__assign({}, state, { pending: false, loaded: true }));
        case RoomActionTypes.LoadRoomFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case RoomActionTypes.SelectRoom:
            return tslib_1.__assign({}, state, { selectedID: action.payload });
        case RoomActionTypes.LoadRooms:
            if (!action.payload.offset) {
                return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: true, loaded: false, errors: null }));
            }
            return tslib_1.__assign({}, state, { pending: true, loaded: false, errors: null });
        case RoomActionTypes.LoadRoomsSuccess:
            return adapter.upsertMany(action.payload.items, tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true }));
        case RoomActionTypes.LoadRoomsFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case RoomActionTypes.ClearRooms:
            return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: false, loaded: true }));
        default:
            return state;
    }
}
export var getSelectedID = function (state) { return state.selectedID; };
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
export var getErrors = function (state) { return state.errors; };
export var getRoomState = createFeatureSelector('room');
export var selectRoomSelectedID = createSelector(getRoomState, getSelectedID);
export var selectRoomTotalCount = createSelector(getRoomState, getTotalCount);
export var selectRoomLoaded = createSelector(getRoomState, getLoaded);
export var selectRoomPending = createSelector(getRoomState, getPending);
export var selectRoomErrors = createSelector(getRoomState, getErrors);
export var selectRoomEntities = (_a = adapter.getSelectors(getRoomState), _a.selectEntities), selectRoomAll = _a.selectAll;
export var selectRoom = createSelector(selectRoomEntities, selectRoomSelectedID, function (entities, id) { return entities && entities[id] ? entities[id] : null; });

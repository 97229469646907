import { Store } from '@ngrx/store';
import * as fromRoot from '@store/reducers';
import { of } from 'rxjs';
import { catchError, filter, take, timeout } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var HasBillingGuard = /** @class */ (function () {
    function HasBillingGuard(store) {
        this.store = store;
    }
    HasBillingGuard.prototype.canActivate = function (next, state) {
        return this.check();
    };
    HasBillingGuard.prototype.check = function () {
        return this.store.select(fromRoot.selectHasBilling)
            .pipe(filter(function (has) { return !!has; }), take(1), timeout(1000), catchError(function () { return of(false); }));
    };
    HasBillingGuard.ngInjectableDef = i0.defineInjectable({ factory: function HasBillingGuard_Factory() { return new HasBillingGuard(i0.inject(i1.Store)); }, token: HasBillingGuard, providedIn: "root" });
    return HasBillingGuard;
}());
export { HasBillingGuard };

import { NgModule } from '@angular/core';
import { HasObjectsGuard } from '@app/object/guards/has-objects.guard';
import { ObjectsResolver } from '@app/object/resolvers/objects.resolver';
import { AppCommonModule } from '@common/app-common.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ObjectEffects } from '@store/object/object.effects';
import * as fromObject from '@store/object/object.reducer';
import { WslSharedModule } from 'wsl-shared';
import { ObjectCardComponent } from './components/object-card/object-card.component';
import { ObjectRowComponent } from './components/object-row/object-row.component';
import { ObjectsTableComponent } from './components/objects-table/objects-table.component';
import { ObjectExistsGuard } from './guards/object-exists.guard';
import { ObjectTypeResolver } from './resolvers/object-type.resolver';
import { ObjectService } from './services/object.service';

const COMPONENTS = [
  ObjectsTableComponent,
  ObjectRowComponent,
  ObjectCardComponent
];

@NgModule({
  imports: [
    WslSharedModule,
    AppCommonModule,
    StoreModule.forFeature('object', fromObject.reducer),
    EffectsModule.forFeature([ObjectEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    ObjectService,
    ObjectExistsGuard,
    HasObjectsGuard,

    ObjectTypeResolver,
    ObjectsResolver
  ]
})
export class ObjectModule {
}

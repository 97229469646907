import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { IWSLBillingCompanyLink } from '@app/billing-company/models/billing-company-link';
import { BillingCompanyLinkService } from '@app/billing-company/services/billing-company-link.service';
import { select, Store } from '@ngrx/store';
import { LoadBCompanyLinksSuccess } from '@store/billing/billing-company-link.actions';
import * as fromBilling from '@store/billing/billing.reducer';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { IWSLHttpResponse } from 'wsl-core';

@Injectable()
export class BillingCompanyLinksResolver implements Resolve<any> {
  constructor(private store$: Store<fromBilling.BillingState>,
              private router: Router,
              private billingCompanyLinkService: BillingCompanyLinkService) {
  }

  /**
   * This method creates an observable that waits for the `loaded` property
   * of the collection state to turn `true`, emitting one time once loading
   * has finished.
   */
  waitForCollectionToLoad(): Observable<boolean> {
    return this.store$
      .pipe(
        select(fromBilling.selectBillingCompanyLinksLoaded),
        filter(loaded => !!loaded),
        take(1),
        timeout(1000),
        catchError((err) => of(false))
      );
  }


  /**
   * This method checks if a links already
   * in the Store
   */
  getFromStore(): Observable<IWSLBillingCompanyLink[]> {
    return this.store$
      .select(fromBilling.selectBillingCompanyLinks)
      .pipe(
        /*   tap(items => {
             if (items.length === 0) {
               this.store$.dispatch(new LoadBCompanyLinks({}));
             }
           }),
           map(items => items.length > 0),*/
        take(1)
      );
  }

  /**
   * This method loads a links from the API and caches
   * it in the store, returning `true` or `false` if it was found.
   */
  getFromApi(): Observable<IWSLBillingCompanyLink[]> {
    return this.billingCompanyLinkService
      .getMany({})
      .pipe(
        // map(deviceEntity => new LoadDeviceSuccess(deviceEntity)),
        //  tap((action: LoadDeviceSuccess) => this.store.dispatch(action)),
        tap(resp => this.store$.dispatch(new LoadBCompanyLinksSuccess(resp))),
        map((resp: IWSLHttpResponse<IWSLBillingCompanyLink>) => resp.items),
        //  map(items => items.length > 0),
        catchError((response) => {
          return of([]);
        })
      );
  }

  /**
   * `hasEntities` composes `hasInStore` and `hasInApi`. It first checks
   * if the links is in store, and if not it then checks if it is in the
   * API.
   */
  getEntities(collectionLoaded: boolean): Observable<IWSLBillingCompanyLink[]> {
    if (collectionLoaded) {
      return this.getFromStore()
        .pipe(
          switchMap(items => {
            return of(items);
          }));
    }
    return this.getFromApi();
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return this.waitForCollectionToLoad()
      .pipe(switchMap((loaded) => this.getEntities(loaded)));
    /*this.billingCompanyLinkService.getMany({})
         .pipe(
           filter(res => res.items.length > 0),
           take(1),
           map(res => res.items),
           catchError(() => EMPTY)
         );*/
  }
}

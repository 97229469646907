import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { hmrModule } from '@angularclass/hmr';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// moment.locale('ru');
export var appInjector;
if (environment.production) {
    //  window.console.log = function(){};
    enableProdMode();
}
var bootstrap = function () { return __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory); };
var ɵ0 = bootstrap;
if (environment.hmr) {
    if (module['hot']) {
        // hmrBootstrap(module, bootstrap);
        bootstrap().then(function (ngModuleRef) {
            appInjector = ngModuleRef.injector;
            return hmrModule(ngModuleRef, module);
        }).catch(function (err) { return console.log(err); });
    }
    else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else {
    bootstrap()
        .then(function (componentRef) {
        /**
         * https://github.com/angular/angular-cli/issues/13351
         * https://github.com/angular/angular-cli/issues/8515#issuecomment-486017106
         * https://github.com/angular/angular/issues/20970
         * https://github.com/angular/angular-cli/issues/8779
         */
        if ('serviceWorker' in navigator && location.protocol === 'https:' &&
            !location.pathname.includes('/api') &&
            !location.pathname.includes('/report_generator')) {
            navigator.serviceWorker.register('./ngsw-worker.js');
        }
        appInjector = componentRef.injector;
        return componentRef;
    })
        .catch(function (err) { return console.log(err); });
}
/*
const bootstrap = () => platformBrowserDynamic()
  .bootstrapModule(AppModule).then(
    (componentRef) => {
      appInjector = componentRef.injector;
      return componentRef;
    });

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
  }
} else {
  bootstrap()
    .then(() => {
      /!**
       * https://github.com/angular/angular-cli/issues/13351
       * https://github.com/angular/angular-cli/issues/8515#issuecomment-486017106
       * https://github.com/angular/angular/issues/20970
       * https://github.com/angular/angular-cli/issues/8779
       *!/
    if ('serviceWorker' in navigator && location.protocol === 'https:' &&
      !location.pathname.includes('/api') &&
      !location.pathname.includes('/report_generator')) {
      navigator.serviceWorker.register('./ngsw-worker.js');
    }
  }).catch(err => console.log(err));
}
*/
/*platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));*/
// Cache storage estimate
if ('storage' in navigator && 'estimate' in navigator.storage) {
    navigator.storage.estimate().then(function (_a) {
        var usage = _a.usage, quota = _a.quota;
        console.log("Using " + usage + " out of " + quota + " bytes.");
    });
}
export { ɵ0 };

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWSLBCompanyAccountLog, IWSLBCompanyAccountLogFilter } from '@app/billing-company/models/b-company-account-log';
import { WSLApiHttpClientService } from 'wsl-core';

@Injectable()
export class BCompanyAccountLogService extends WSLApiHttpClientService<IWSLBCompanyAccountLog, IWSLBCompanyAccountLogFilter> {

  constructor(public http: HttpClient) {
    super(http, 'job');
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IWSLResource } from 'wsl-ek-core';

@Component({
  selector: 'wsl-resource-to-port',
  templateUrl: './resource-to-port.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceToPortComponent implements OnInit, OnChanges, OnDestroy {
  @Input() index: number;
  @Input() prefix = '';
  @Input() availableResources: IWSLResource[];
  @Input() selectedResource: number;
  @Input() showNone = false;

  @Input()
  set disabled(isDisable: boolean) {
    if (isDisable) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output() selectItem = new EventEmitter();

  /** @internal */
  form: FormGroup = new FormGroup({
    resource_id: new FormControl(null)
  });

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.form.get('resource_id').setValue(this.selectedResource);
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe((val) => {
        this.selectItem.emit(val.resource_id);
      });
  }

  ngOnChanges(changes) {
    if (changes.selectedResource && this.form.get('resource_id').value !== this.selectedResource) {
      this.form.get('resource_id').setValue(this.selectedResource);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

}

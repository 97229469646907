import * as tslib_1 from "tslib";
var _a;
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceConfExtActionTypes } from './device-conf-ext.actions';
import { createEntityAdapter } from '@ngrx/entity';
export var adapter = createEntityAdapter({
    selectId: function (item) { return item.id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DeviceConfExtActionTypes.AddDeviceConfExt:
        case DeviceConfExtActionTypes.UpdateDeviceConfExt:
        case DeviceConfExtActionTypes.DeleteDeviceConfExt:
            return tslib_1.__assign({}, state, { pending: true, errors: null });
        case DeviceConfExtActionTypes.AddDeviceConfExtSuccess:
        case DeviceConfExtActionTypes.UpdateDeviceConfExtSuccess:
        case DeviceConfExtActionTypes.DeleteDeviceConfExtSuccess:
            return tslib_1.__assign({}, state, { pending: false });
        case DeviceConfExtActionTypes.AddDeviceConfExtFail:
        case DeviceConfExtActionTypes.UpdateDeviceConfExtFail:
        case DeviceConfExtActionTypes.DeleteDeviceConfExtFail:
            return tslib_1.__assign({}, state, { pending: false, errors: action.payload.asyncMessages });
        case DeviceConfExtActionTypes.LoadDeviceConfExt:
            return tslib_1.__assign({}, state, { 
                // selectedID: null,
                pending: true, loaded: false, errors: null });
        case DeviceConfExtActionTypes.LoadDeviceConfExtSuccess:
            return adapter.upsertOne(tslib_1.__assign({}, action.payload.item), tslib_1.__assign({}, state, { pending: false, loaded: true }));
        case DeviceConfExtActionTypes.LoadDeviceConfExtFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceConfExtActionTypes.SelectDeviceConfExt:
            return tslib_1.__assign({}, state, { selectedID: action.payload });
        case DeviceConfExtActionTypes.LoadDeviceConfExts:
            if (!action.payload.offset) {
                return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: true, loaded: false, errors: null }));
            }
            return tslib_1.__assign({}, state, { pending: true, loaded: false, errors: null });
        case DeviceConfExtActionTypes.LoadDeviceConfExtsSuccess:
            return adapter.upsertMany(action.payload.items, tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true }));
        case DeviceConfExtActionTypes.LoadDeviceConfExtsFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceConfExtActionTypes.ClearDeviceConfExts:
            return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: false, loaded: true }));
        default:
            return state;
    }
}
export var getSelectedID = function (state) { return state.selectedID; };
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
export var getErrors = function (state) { return state.errors; };
export var getDeviceConfExtState = createFeatureSelector('deviceConfExt');
export var selectDeviceConfExtSelectedID = createSelector(getDeviceConfExtState, getSelectedID);
export var selectDeviceConfExtTotalCount = createSelector(getDeviceConfExtState, getTotalCount);
export var selectDeviceConfExtLoaded = createSelector(getDeviceConfExtState, getLoaded);
export var selectDeviceConfExtPending = createSelector(getDeviceConfExtState, getPending);
export var selectDeviceConfExtErrors = createSelector(getDeviceConfExtState, getErrors);
export var selectDeviceConfExtEntities = (_a = adapter.getSelectors(getDeviceConfExtState), _a.selectEntities), selectDeviceConfExtAll = _a.selectAll;
export var selectDeviceConfExt = createSelector(selectDeviceConfExtEntities, selectDeviceConfExtSelectedID, function (entities, id) { return entities && entities[id] ? entities[id] : null; });

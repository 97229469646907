import * as tslib_1 from "tslib";
import { createEntityAdapter } from '@ngrx/entity';
import { BillingCompanyActionTypes } from './billing-company.actions';
export var adapter = createEntityAdapter({
    selectId: function (item) { return item.id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0,
    clear: true
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BillingCompanyActionTypes.LoadBCompanies:
            return tslib_1.__assign({}, state, { clear: action.payload.offset === 0 || !action.payload.offset, pending: true, loaded: false });
        case BillingCompanyActionTypes.LoadBCompaniesSuccess:
            if (state.clear) {
                return adapter.addAll(action.payload.items, adapter.removeAll(tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true })));
            }
            return adapter.upsertMany(action.payload.items, tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true }));
        case BillingCompanyActionTypes.LoadBCompaniesFail:
            if (state.clear) {
                return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, errors: action.payload.asyncMessages, pending: false, loaded: true }));
            }
            return tslib_1.__assign({}, state, { errors: action.payload.asyncMessages, pending: false, loaded: true });
        default:
            return state;
    }
}
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
var _a = adapter.getSelectors(), selectIds = _a.selectIds, selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
export var getBCompaniesEntities = selectEntities;
export var getBCompaniesAll = selectAll;

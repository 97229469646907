import * as tslib_1 from "tslib";
import { WSLApiHttpClientService } from 'wsl-core';
import { HttpClient } from '@angular/common/http';
var DeviceConfGroupService = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceConfGroupService, _super);
    function DeviceConfGroupService(http) {
        var _this = _super.call(this, http, 'device_conf_group') || this;
        _this.http = http;
        return _this;
    }
    return DeviceConfGroupService;
}(WSLApiHttpClientService));
export { DeviceConfGroupService };

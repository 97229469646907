import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AddDeviceConfFail, AddDeviceConfSuccess, DeleteDeviceConfFail, DeleteDeviceConfSuccess, DeviceConfActionTypes, LoadDeviceConfFail, LoadDeviceConfsFail, LoadDeviceConfsSuccess, LoadDeviceConfSuccess, UpdateDeviceConfFail, UpdateDeviceConfSuccess } from './device-conf.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { DeviceConfService } from '@app/device-conf/services/device-conf.service';
var DeviceConfEffects = /** @class */ (function () {
    function DeviceConfEffects(actions$, router, route, storeHelper, deviceConfService) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.route = route;
        this.storeHelper = storeHelper;
        this.deviceConfService = deviceConfService;
        this.add$ = this.actions$.pipe(ofType(DeviceConfActionTypes.AddDeviceConf), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfActionTypes.AddDeviceConf), skip(1));
            return _this.deviceConfService.add(payload)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddDeviceConfSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddDeviceConfFail); }));
        }));
        this.update$ = this.actions$.pipe(ofType(DeviceConfActionTypes.UpdateDeviceConf), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfActionTypes.UpdateDeviceConf), skip(1));
            return _this.deviceConfService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateDeviceConfSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateDeviceConfFail); }));
        }));
        this.load$ = this.actions$.pipe(ofType(DeviceConfActionTypes.LoadDeviceConf), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfActionTypes.LoadDeviceConf), skip(1));
            return _this.deviceConfService.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceConfSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceConfFail); }));
        }));
        this.loadMany$ = this.actions$.pipe(ofType(DeviceConfActionTypes.LoadDeviceConfs), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfActionTypes.LoadDeviceConfs), skip(1));
            return _this.deviceConfService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceConfsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceConfsFail); }));
        }));
        this.delete$ = this.actions$.pipe(ofType(DeviceConfActionTypes.DeleteDeviceConf), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfActionTypes.DeleteDeviceConf), skip(1));
            return _this.deviceConfService.delete(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteDeviceConfSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteDeviceConfFail); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(DeviceConfActionTypes.AddDeviceConf, DeviceConfActionTypes.LoadDeviceConf, DeviceConfActionTypes.UpdateDeviceConf, DeviceConfActionTypes.DeleteDeviceConf), map(function () { return new StartLoading('device_conf'); }));
        this.startLoadMany$ = this.actions$
            .pipe(ofType(DeviceConfActionTypes.LoadDeviceConfs), map(function (action) { return action.payload; }), filter(function (payload) { return payload.offset === 0; }), map(function () { return new StartLoading('device_conf'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(DeviceConfActionTypes.AddDeviceConfSuccess, DeviceConfActionTypes.AddDeviceConfFail, DeviceConfActionTypes.LoadDeviceConfSuccess, DeviceConfActionTypes.LoadDeviceConfFail, DeviceConfActionTypes.UpdateDeviceConfSuccess, DeviceConfActionTypes.UpdateDeviceConfFail, DeviceConfActionTypes.DeleteDeviceConfSuccess, DeviceConfActionTypes.DeleteDeviceConfFail, DeviceConfActionTypes.LoadDeviceConfsSuccess, DeviceConfActionTypes.LoadDeviceConfsFail), map(function () { return new StopLoading('device_conf'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfEffects.prototype, "loadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfEffects.prototype, "delete$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfEffects.prototype, "startLoadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfEffects.prototype, "stopLoad$", void 0);
    return DeviceConfEffects;
}());
export { DeviceConfEffects };

import * as tslib_1 from "tslib";
import { WSLHttpHelper, WSLLocalStorageService } from 'wsl-core';
import { SetSnackBarMessage, ShowNoAccessMessage } from '@store/actions/app.actions';
import { Logout } from '@store/actions/auth.actions';
import { StorageKey } from 'wsl-ek-core';
var StoreHelperService = /** @class */ (function () {
    function StoreHelperService(localStorage) {
        this.localStorage = localStorage;
    }
    StoreHelperService.generateActionTypes = function (name) {
        var _a;
        return _a = {},
            _a["Add" + name] = "[" + name + "] Add",
            _a["Add" + name + "Success"] = "[" + name + "] Add Success",
            _a["Add" + name + "Fail"] = "[" + name + "] Add Fail",
            _a["Load" + name] = "[" + name + "] Load",
            _a["Load" + name + "Success"] = "[" + name + "] Load Success",
            _a["Load" + name + "Fail"] = "[" + name + "] Load Fail",
            _a["Update" + name] = "[" + name + "] Update",
            _a["Update" + name + "Success"] = "[" + name + "] Update Success",
            _a["Update" + name + "Fail"] = "[" + name + "] Update Fail",
            _a["Delete" + name] = "[" + name + "] Delete",
            _a["Delete" + name + "Success"] = "[" + name + "] Delete Success",
            _a["Delete" + name + "Fail"] = "[" + name + "] Delete Fail",
            _a["Load" + name + "s"] = "[" + name + "] Load Many",
            _a["Load" + name + "sSuccess"] = "[" + name + "] Load Many Success",
            _a["Load" + name + "sFail"] = "[" + name + "] Load Many Fail",
            _a["Select" + name] = "[" + name + "] Select",
            _a;
    };
    StoreHelperService.prototype.successHandler = function (response, action, extra) {
        if (extra === void 0) { extra = null; }
        if (response) {
            var message = response.view ? WSLHttpHelper.parseAsyncMessage(response.message) : null; // HttpHelper.parseAsyncMessage('Успешно выполнено');
            var actions = [new action(tslib_1.__assign({}, response, { asyncMessages: message }, extra))];
            if (message) {
                actions.push(new SetSnackBarMessage(message));
            }
            return actions;
        }
        return [new action() /*, new SetSnackBarMessage(HttpHelper.parseAsyncMessage('Успешно выполнено'))*/];
    };
    StoreHelperService.prototype.errorHandler = function (response, action) {
        if (action === void 0) { action = null; }
        var error = WSLHttpHelper.parseHTTPErrorResponse(response);
        var message = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
        var actions = [];
        if (action) {
            if (message) {
                actions.push(new action({ asyncMessages: message }));
            }
            else {
                actions.push(new action(error));
            }
        }
        if (this.localStorage.get(StorageKey.token) /*isAuthenticated()*/ && error.logout && !error.contract_num) {
            actions.push(new Logout());
        }
        if (error.contract_num) {
            actions.push(new ShowNoAccessMessage(error));
        }
        if (message) {
            actions.push(new SetSnackBarMessage(message));
        }
        return actions;
    };
    return StoreHelperService;
}());
export { StoreHelperService };

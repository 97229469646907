import * as tslib_1 from "tslib";
import { DBDateTimeFormat, WSLLocalStorageService } from 'wsl-core';
import { ApiHttpClientWithPermissionsService, CommunicatorPermissions, UserProfileService } from 'wsl-ek-core';
import { HttpClient } from '@angular/common/http';
import { ConnectionType, ConnectionTypes } from '../enums/connection-type.enum';
import { StorageKey } from 'wsl-ek-core';
import { format, isSameHour } from 'date-fns';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
var CommunicatorService = /** @class */ (function (_super) {
    tslib_1.__extends(CommunicatorService, _super);
    function CommunicatorService(http, localStorage, userProfileService) {
        var _this = _super.call(this, http, 'communicator', CommunicatorPermissions, userProfileService) || this;
        _this.http = http;
        _this.localStorage = localStorage;
        _this.userProfileService = userProfileService;
        return _this;
    }
    CommunicatorService.getConnectionType = function (communicator) {
        switch (communicator.network.channel_id) {
            case ConnectionType.tcp:
            case ConnectionType.tcp_gprs:
                return ConnectionTypes.find(function (nt) { return nt.id === ConnectionType.tcp; });
            case ConnectionType.gprs:
                return ConnectionTypes.find(function (nt) { return nt.id === ConnectionType.gprs; });
            case ConnectionType.lora:
                return ConnectionTypes.find(function (nt) { return nt.id === ConnectionType.lora; });
            default:
                return null;
        }
    };
    CommunicatorService.getConnectionParameters = function (communicator) {
        var network_parameters = '';
        switch (communicator.network.channel_id) {
            case ConnectionType.tcp:
                network_parameters = "tcp ip " + communicator.network.tcp.ip + ":" + communicator.network.tcp.port;
                break;
            case ConnectionType.tcp_gprs:
                network_parameters = "tcp ip " + communicator.network.tcp.ip + ":" + communicator.network.tcp.port;
                if (communicator.network.gprs.id1) {
                    network_parameters += ", gprs " + communicator.network.gprs.id1;
                }
                break;
            case ConnectionType.gprs:
                network_parameters = "gprs id1 " + communicator.network.gprs.id1;
                if (communicator.network.gprs.id2) {
                    network_parameters += ", id2 " + communicator.network.gprs.id2;
                }
                break;
            case ConnectionType.lora:
                network_parameters = "lora " + communicator.network.lora.devEUI;
                break;
            default:
                return null;
        }
        return network_parameters;
    };
    CommunicatorService.prototype.getGPRSGate = function () {
        var _this = this;
        var dict = this.localStorage.get(StorageKey.gprs_gate);
        if (dict != null && isSameHour(new Date(), new Date(dict.time))) {
            return of(dict);
        }
        return this.http.get('gprs_gate')
            .pipe(map(function (data) {
            _this.localStorage.set(StorageKey.gprs_gate, {
                time: format(new Date(), DBDateTimeFormat),
                item: data.item
            });
            return data;
        }));
    };
    return CommunicatorService;
}(ApiHttpClientWithPermissionsService));
export { CommunicatorService };

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {IWSLEntity} from 'wsl-core';
import {AllResources} from 'wsl-ek-core';

@Component({
  selector: 'wsl-resource-icon',
  templateUrl: './resource-icon.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceIconComponent implements OnInit, OnChanges {
  @Input() resourceIds: number[];
  @Input() resourceInx: number;

  resources = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resourceIds) {
      this.define();
    }
  }

  trackById(index: number, item: IWSLEntity) {
    return item.id;
  }

  private define() {
    this.resources =  this.resourceIds &&  this.resourceIds.length > 0 ? AllResources.filter(r => this.resourceIds.includes(r.id)) : [];
  }
}

import * as tslib_1 from "tslib";
var _a;
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceSensorActionTypes } from './device-sensor.actions';
import { createEntityAdapter } from '@ngrx/entity';
export var adapter = createEntityAdapter({
    selectId: function (item) { return item.id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DeviceSensorActionTypes.AddDeviceSensor:
        case DeviceSensorActionTypes.UpdateDeviceSensor:
        case DeviceSensorActionTypes.DeleteDeviceSensor:
        case DeviceSensorActionTypes.DeleteDeviceSensors:
            return tslib_1.__assign({}, state, { pending: true, errors: null });
        case DeviceSensorActionTypes.AddDeviceSensorSuccess:
        case DeviceSensorActionTypes.UpdateDeviceSensorSuccess:
        case DeviceSensorActionTypes.DeleteDeviceSensorSuccess:
        case DeviceSensorActionTypes.DeleteDeviceSensorsSuccess:
            return tslib_1.__assign({}, state, { pending: false });
        case DeviceSensorActionTypes.UpdateDeviceSensorFail:
        case DeviceSensorActionTypes.AddDeviceSensorFail:
        case DeviceSensorActionTypes.DeleteDeviceSensorFail:
        case DeviceSensorActionTypes.DeleteDeviceSensorsFail:
            return tslib_1.__assign({}, state, { pending: false, errors: action.payload.asyncMessages });
        case DeviceSensorActionTypes.LoadDeviceSensor:
            return tslib_1.__assign({}, state, { 
                // selectedID: null,
                pending: true, loaded: false, errors: null });
        case DeviceSensorActionTypes.LoadDeviceSensorSuccess:
            return adapter.upsertOne(tslib_1.__assign({}, action.payload.item), tslib_1.__assign({}, state, { pending: false, loaded: true }));
        case DeviceSensorActionTypes.LoadDeviceSensorFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceSensorActionTypes.SelectDeviceSensor:
            return tslib_1.__assign({}, state, { selectedID: action.payload });
        case DeviceSensorActionTypes.LoadDeviceSensors:
            if (!action.payload.offset) {
                return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: true, loaded: false, errors: null }));
            }
            return tslib_1.__assign({}, state, { pending: true, loaded: false, errors: null });
        case DeviceSensorActionTypes.LoadDeviceSensorsSuccess:
            return adapter.upsertMany(action.payload.items, tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true }));
        case DeviceSensorActionTypes.LoadDeviceSensorsFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceSensorActionTypes.ClearDeviceSensors:
            return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: false, loaded: true }));
        default:
            return state;
    }
}
export var getSelectedID = function (state) { return state.selectedID; };
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
export var getErrors = function (state) { return state.errors; };
export var getDeviceSensorState = createFeatureSelector('deviceSensor');
export var selectDeviceSensorSelectedID = createSelector(getDeviceSensorState, getSelectedID);
export var selectDeviceSensorTotalCount = createSelector(getDeviceSensorState, getTotalCount);
export var selectDeviceSensorLoaded = createSelector(getDeviceSensorState, getLoaded);
export var selectDeviceSensorPending = createSelector(getDeviceSensorState, getPending);
export var selectDeviceSensorErrors = createSelector(getDeviceSensorState, getErrors);
export var selectDeviceSensorEntities = (_a = adapter.getSelectors(getDeviceSensorState), _a.selectEntities), selectDeviceSensorAll = _a.selectAll;
export var selectDeviceSensor = createSelector(selectDeviceSensorEntities, selectDeviceSensorSelectedID, function (entities, id) { return entities && entities[id] ? entities[id] : null; });

<wsl-error-message [message]="asyncErrors?.get('common')"></wsl-error-message>

<form class="wsl-bg-white wsl-p-x-20 wsl-p-y-25 z-depth-1 wsl-hide-xs"
      [formGroup]="form" novalidate
      (ngSubmit)="submit()">

    <wsl-input-wrapper label="Логин"
                       inputId="login"
                       [required]="true"
                       [asyncError]="asyncErrors?.get(loginEmail ? 'email' : 'login')">
        <input type="text"
               id="login"
               name="login"
               #login
               class="form-control"
               placeholder="your@email.com"
               formControlName="login">
    </wsl-input-wrapper>

    <div class="wsl-p-a-5"></div>

    <wsl-input-wrapper label="Пароль"
                       inputId="password"
                       [required]="true">
        <input type="password"
               id="password"
               name="password"
               #password
               class="form-control"
               formControlName="password">
    </wsl-input-wrapper>

    <div class="wsl-p-a-10 wsl-show-xs"></div>

    <div class="wsl-p-a-5"></div>

    <div class="wsl-flex-center-ng wsl-m-t-20">
        <button class="btn waves-effect waves-light" type="submit">Войти</button>
    </div>
</form>

<form class="wsl-p-x-20 wsl-p-y-25 wsl-show-xs"
      [formGroup]="form"
      (ngSubmit)="submit()">

    <wsl-input-wrapper label="Логин"
                       inputId="login_xs"
                       [required]="true"
                       [asyncError]="asyncErrors?.get(loginEmail ? 'email' : 'login')">
        <input type="text"
               id="login_xs"
               #login_xs
               class="form-control"
               placeholder="your@email.com"
               formControlName="login">
    </wsl-input-wrapper>

    <div class="wsl-p-a-5"></div>

    <wsl-input-wrapper label="Пароль"
                       inputId="password_xs"
                       [required]="true">
        <input type="password"
               id="password_xs"
               #password_xs
               class="form-control"
               formControlName="password">
    </wsl-input-wrapper>

    <div class="wsl-p-a-10 wsl-show-xs"></div>

    <div class="wsl-p-a-5"></div>

    <div class="wsl-flex-center-ng wsl-m-t-20">
        <button class="btn waves-effect waves-light" type="submit">Войти</button>
    </div>
</form>

import * as tslib_1 from "tslib";
import { WSLApiHttpClientService, WSLJwtHelperService } from 'wsl-core';
import { HttpClient } from '@angular/common/http';
var TownService = /** @class */ (function (_super) {
    tslib_1.__extends(TownService, _super);
    function TownService(http, jwtHelperService) {
        var _this = _super.call(this, http, 'town') || this;
        _this.http = http;
        _this.jwtHelperService = jwtHelperService;
        return _this;
    }
    TownService.prototype.getTownID = function () {
        var token = this.jwtHelperService.getTokenPayload();
        if (!token || !token.hasOwnProperty('town_id')) {
            return null;
        }
        return parseInt(token.town_id, 10);
    };
    TownService.prototype.getTownName = function (company) {
        var _this = this;
        if (company) {
            var town = company.towns.find(function (t) { return t.id === _this.getTownID(); });
            return town ? town.name : null;
        }
        return null;
    };
    TownService.prototype.get = function () {
        return this.http.get(this.baseUrl + "/" + this.getTownID());
    };
    return TownService;
}(WSLApiHttpClientService));
export { TownService };

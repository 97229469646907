import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LoadAvailableCompaniesFail, LoadAvailableCompaniesSuccess, LoadAvailableRoomsFail, LoadAvailableRoomsSuccess, LoadUserProfileFail, LoadUserProfileSuccess, UserProfileActionTypes } from '../actions/user-profile.actions';
import { Observable } from 'rxjs';
import { catchError, exhaustMap, filter, map, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '../actions/app.actions';
import { StoreHelperService } from '@core/services/store-helper.service';
import { CompanyProfileService } from 'wsl-ek-core';
import { TownService } from '@core/services/town.service';
import { CompanyProfileActionTypes, LoadCompanyProfileFail, LoadCompanyProfileSuccess, StartPromotionFailure, StartPromotionSuccess, UpdateCompanyProfileFail, UpdateCompanyProfileSuccess } from '../actions/company-profile.actions';
import { TownActionTypes } from '../actions/town.actions';
import { UserProfileService } from 'wsl-ek-core';
import { environment } from "@env/environment";
var UserProfileEffects = /** @class */ (function () {
    function UserProfileEffects(actions$, storeHelper, userProfileService, companyProfileService, townService) {
        var _this = this;
        this.actions$ = actions$;
        this.storeHelper = storeHelper;
        this.userProfileService = userProfileService;
        this.companyProfileService = companyProfileService;
        this.townService = townService;
        this.loadUserProfile$ = this.actions$
            .pipe(ofType(UserProfileActionTypes.LoadUserProfile), exhaustMap(function () {
            return _this.userProfileService
                .get()
                .pipe(map(function (response) { return new LoadUserProfileSuccess(response); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, LoadUserProfileFail); }));
        }));
        this.loadCompanyProfile$ = this.actions$
            .pipe(ofType(UserProfileActionTypes.LoadUserProfileSuccess, CompanyProfileActionTypes.LoadCompanyProfile, CompanyProfileActionTypes.UpdateCompanyProfileSuccess, CompanyProfileActionTypes.StartPromotionSuccess), filter(function (action) { return _this.companyProfileService.isUC() || _this.companyProfileService.isDP() || _this.companyProfileService.isTS() || (_this.companyProfileService.isHomeUsers() && _this.userProfileService.isAdmin()); }), exhaustMap(function () {
            return _this.companyProfileService
                .get()
                .pipe(map(function (response) { return new LoadCompanyProfileSuccess(response); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, LoadCompanyProfileFail); }));
        }));
        this.updateCompanyProfile$ = this.actions$
            .pipe(ofType(CompanyProfileActionTypes.UpdateCompanyProfile), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(CompanyProfileActionTypes.UpdateCompanyProfile), skip(1));
            return _this.companyProfileService
                .update(data)
                .pipe(takeUntil(nextLoad$), map(function (response) { return new UpdateCompanyProfileSuccess(response); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, UpdateCompanyProfileFail); }));
        }));
        this.loadAvailableCompanies$ = this.actions$
            .pipe(ofType(UserProfileActionTypes.LoadUserProfileSuccess, UserProfileActionTypes.LoadAvailableCompanies), filter(function (action) { return !!environment.dom &&
            _this.companyProfileService.isUC() ||
            _this.companyProfileService.isDP() ||
            _this.companyProfileService.isTS() ||
            (_this.companyProfileService.isHomeUsers() && _this.userProfileService.isAdmin()); }), exhaustMap(function () {
            return _this.userProfileService
                .getAvailableCompanies()
                .pipe(map(function (response) { return new LoadAvailableCompaniesSuccess(response); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, LoadAvailableCompaniesFail); }));
        }));
        this.loadAvailableRooms$ = this.actions$
            .pipe(ofType(UserProfileActionTypes.LoadUserProfileSuccess, UserProfileActionTypes.LoadAvailableRooms), filter(function (action) { return !!environment.kv; }), exhaustMap(function () {
            return _this.userProfileService
                .getAvailableRooms()
                .pipe(map(function (response) { return new LoadAvailableRoomsSuccess(response); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, LoadAvailableRoomsFail); }));
        }));
        /*  @Effect()
          loadUserTown$: Observable<Action> = this.actions$
            .pipe(
              ofType(UserProfileActionTypes.LoadUserProfileSuccess, TownActionTypes.LoadTownProfile),
              exhaustMap(() =>
                this.townService
                  .get()
                  .pipe(
                    map(response => new LoadTownProfileSuccess(response)),
                    catchError(response => this.storeHelper.errorHandler(response, LoadTownProfileFail))
                  )
              )
            );*/
        this.startPromotion$ = this.actions$
            .pipe(ofType(CompanyProfileActionTypes.StartPromotion), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            return _this.companyProfileService
                .startPromotion(data)
                .pipe(map(function (response) { return new StartPromotionSuccess(response); }), catchError(function (response) { return _this.storeHelper.errorHandler(response, StartPromotionFailure); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(UserProfileActionTypes.LoadUserProfile, CompanyProfileActionTypes.LoadCompanyProfile, CompanyProfileActionTypes.UpdateCompanyProfile, CompanyProfileActionTypes.StartPromotion, UserProfileActionTypes.LoadAvailableCompanies, TownActionTypes.LoadTownProfile), map(function () { return new StartLoading('profile'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(UserProfileActionTypes.LoadUserProfileSuccess, UserProfileActionTypes.LoadUserProfileFail, CompanyProfileActionTypes.LoadCompanyProfileSuccess, CompanyProfileActionTypes.LoadCompanyProfileFail, CompanyProfileActionTypes.UpdateCompanyProfileSuccess, CompanyProfileActionTypes.UpdateCompanyProfileFail, CompanyProfileActionTypes.StartPromotionSuccess, CompanyProfileActionTypes.StartPromotionFailure, UserProfileActionTypes.LoadAvailableCompaniesSuccess, UserProfileActionTypes.LoadAvailableCompaniesFail, TownActionTypes.LoadTownProfileSuccess, TownActionTypes.LoadTownProfileFail), map(function () { return new StopLoading('profile'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserProfileEffects.prototype, "loadUserProfile$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserProfileEffects.prototype, "loadCompanyProfile$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserProfileEffects.prototype, "updateCompanyProfile$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserProfileEffects.prototype, "loadAvailableCompanies$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserProfileEffects.prototype, "loadAvailableRooms$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserProfileEffects.prototype, "startPromotion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserProfileEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserProfileEffects.prototype, "stopLoad$", void 0);
    return UserProfileEffects;
}());
export { UserProfileEffects };

import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AddCommunicatorFail, AddCommunicatorSuccess, CommunicatorActionTypes, DeleteCommunicatorFail, DeleteCommunicatorSuccess, LoadCommunicatorFail, LoadCommunicatorsFail, LoadCommunicatorsSuccess, LoadCommunicatorSuccess, UpdateCommunicatorFail, UpdateCommunicatorSuccess } from './communicator.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { CommunicatorService } from '@app/communicator/services/communicator.service';
var CommunicatorEffects = /** @class */ (function () {
    function CommunicatorEffects(actions$, router, route, storeHelper, communicatorService) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.route = route;
        this.storeHelper = storeHelper;
        this.communicatorService = communicatorService;
        this.add$ = this.actions$.pipe(ofType(CommunicatorActionTypes.AddCommunicator), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            var nextLoad$ = _this.actions$.pipe(ofType(CommunicatorActionTypes.AddCommunicator), skip(1));
            return _this.communicatorService.add(payload)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddCommunicatorSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddCommunicatorFail); }));
        }));
        this.update$ = this.actions$.pipe(ofType(CommunicatorActionTypes.UpdateCommunicator), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(CommunicatorActionTypes.UpdateCommunicator), skip(1));
            return _this.communicatorService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateCommunicatorSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateCommunicatorFail); }));
        }));
        this.load$ = this.actions$.pipe(ofType(CommunicatorActionTypes.LoadCommunicator), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(CommunicatorActionTypes.LoadCommunicator), skip(1));
            return _this.communicatorService.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadCommunicatorSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadCommunicatorFail); }));
        }));
        this.loadMany$ = this.actions$.pipe(ofType(CommunicatorActionTypes.LoadCommunicators), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(CommunicatorActionTypes.LoadCommunicators), skip(1));
            return _this.communicatorService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadCommunicatorsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadCommunicatorsFail); }));
        }));
        this.delete$ = this.actions$.pipe(ofType(CommunicatorActionTypes.DeleteCommunicator), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(CommunicatorActionTypes.DeleteCommunicator), skip(1));
            return _this.communicatorService.delete(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteCommunicatorSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteCommunicatorFail); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(CommunicatorActionTypes.AddCommunicator, CommunicatorActionTypes.LoadCommunicator, CommunicatorActionTypes.UpdateCommunicator, CommunicatorActionTypes.DeleteCommunicator), map(function () { return new StartLoading('communicator'); }));
        this.startLoadMany$ = this.actions$
            .pipe(ofType(CommunicatorActionTypes.LoadCommunicators), map(function (action) { return action.payload; }), filter(function (payload) { return payload.offset === 0; }), map(function () { return new StartLoading('communicator'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(CommunicatorActionTypes.AddCommunicatorSuccess, CommunicatorActionTypes.AddCommunicatorFail, CommunicatorActionTypes.LoadCommunicatorSuccess, CommunicatorActionTypes.LoadCommunicatorFail, CommunicatorActionTypes.UpdateCommunicatorSuccess, CommunicatorActionTypes.UpdateCommunicatorFail, CommunicatorActionTypes.DeleteCommunicatorSuccess, CommunicatorActionTypes.DeleteCommunicatorFail, CommunicatorActionTypes.LoadCommunicatorsSuccess, CommunicatorActionTypes.LoadCommunicatorsFail), map(function () { return new StopLoading('communicator'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommunicatorEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommunicatorEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommunicatorEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommunicatorEffects.prototype, "loadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommunicatorEffects.prototype, "delete$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommunicatorEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommunicatorEffects.prototype, "startLoadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommunicatorEffects.prototype, "stopLoad$", void 0);
    return CommunicatorEffects;
}());
export { CommunicatorEffects };

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { StoreHelperService } from '@core/services/store-helper.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, delay, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { WSLLocalStorageService } from 'wsl-core';
import { CompanyProfileService, CompanyRoleType, StorageKey } from 'wsl-ek-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { StartLoading, StopLoading } from '../actions/app.actions';
import {
  AuthActionTypes,
  ConfirmRegistration,
  ConfirmRegistrationFail,
  ConfirmRegistrationSuccess,
  ForgotPassword,
  ForgotPasswordFail,
  ForgotPasswordSuccess,
  Login,
  LoginAsHomeUser,
  LoginAsHomeUserFail,
  LoginAsHomeUserSuccess,
  LoginFail,
  LoginSuccess,
  RefreshToken,
  RefreshTokenFail,
  RefreshTokenSuccess,
  Registration,
  RegistrationFail,
  RegistrationSuccess,
  ResetPassword,
  ResetPasswordFail,
  ResetPasswordSuccess
} from '../actions/auth.actions';
import { LoadUserProfile } from '../actions/user-profile.actions';

@Injectable()
export class AuthEffects {
  @Effect()
  login$ = this.actions$
    .pipe(
      ofType(AuthActionTypes.Login),
      map((action: Login) => action.payload),
      exhaustMap(payload => {
          return this.authService
            .login(payload)
            .pipe(
              map(response => new LoginSuccess(response)),
              catchError(response => {
                console.error(response);
                return this.storeHelper.errorHandler(response, LoginFail);
              })
            );
        }
      )
    );

  @Effect()
  loginSuccess$ = this.actions$
    .pipe(
      ofType(AuthActionTypes.LoginSuccess),
      delay(100),
      map((action: LoginSuccess) => action.payload),
      tap((payload) => {
        /*const barcode = this.localStorage.get(StorageKey.device_barcode);
        this.localStorage.set(StorageKey.token, payload.token);
        if (barcode) {
          this.localStorage.set(StorageKey.device_barcode, barcode);
        }*/
        this.localStorage.set(StorageKey.token, payload.token);
        AuthService.redirectURL = ['/login', '/reset', '/forgot', '/confirm', '/registration']
          .some(s => AuthService.redirectURL.startsWith(s)) ? '/' : AuthService.redirectURL;
      }),
      map(() => new LoadUserProfile())
    );

  @Effect({dispatch: false})
  loginRedirect$ = this.actions$
    .pipe(
      ofType(AuthActionTypes.LoginRedirect, AuthActionTypes.Logout),
      tap(authed => {
        this.router.navigate(['/login']);
      })
    );

  @Effect({dispatch: false})
  logout$ = this.actions$
    .pipe(
      ofType(AuthActionTypes.Logout),
      tap(() => {
        AuthService.redirectURL = '/';
        this.safeClearLocalStorageMeta();
        // LocalStorageService.remove('token');
      })
    );

  @Effect()
  forgot$ = this.actions$
    .pipe(
      ofType(AuthActionTypes.ForgotPassword),
      map((action: ForgotPassword) => action.payload),
      exhaustMap(payload =>
        this.authService
          .forgot(payload)
          .pipe(
            map(resp => new ForgotPasswordSuccess({token: resp.token})),
            catchError(response => this.storeHelper.errorHandler(response, ForgotPasswordFail))
          )
      )
    );

  @Effect()
  reset$ = this.actions$
    .pipe(
      ofType(AuthActionTypes.ResetPassword),
      map((action: ResetPassword) => action.payload),
      exhaustMap(payload =>
        this.authService
          .reset(payload)
          .pipe(
            map(response => new ResetPasswordSuccess(response)),
            catchError(response => this.storeHelper.errorHandler(response, ResetPasswordFail))
          )
      )
    );

  @Effect({dispatch: false})
  resetSuccess$ = this.actions$
    .pipe(
      ofType(AuthActionTypes.ResetPasswordSuccess),
      tap(() => this.router.navigate(['/login']))
    );

  @Effect()
  refreshToken$: Observable<Action> = this.actions$
    .pipe(
      ofType(AuthActionTypes.RefreshToken),
      map((action: RefreshToken) => action.payload),
      exhaustMap(payload =>
        this.authService
          .refreshToken(payload)
          .pipe(
            map(resp => {
              this.safeClearLocalStorageMeta();
              this.localStorage.set(StorageKey.token, (<any>resp).token);
              return new RefreshTokenSuccess();
            }),
            catchError(response => this.storeHelper.errorHandler(response, RefreshTokenFail))
          )
      ));

  @Effect()
  refreshTokenSuccess$: Observable<Action> = this.actions$
    .pipe(
      ofType(AuthActionTypes.RefreshTokenSuccess),
      mergeMap(() => {
        // get company role from new token
        switch (this.companyService.getRoleId()) {
          case CompanyRoleType.uc:
            AuthService.redirectURL = '/data';
            break;
          case CompanyRoleType.data_provider:
            AuthService.redirectURL = '/operator';
            break;
          case CompanyRoleType.tech_support:
            AuthService.redirectURL = '/support';
            break;
          default:
            AuthService.redirectURL = '/login';
        }

        window.location.replace('/');
        // window.location.reload();
        return [
          // new LoginSuccess({token: this.localStorage.get(StorageKey.token)}),
          new LoadUserProfile()
        ];
      }));

  @Effect()
  loginAsHomeUser$: Observable<Action> = this.actions$
    .pipe(
      ofType(AuthActionTypes.LoginAsHomeUser),
      map((action: LoginAsHomeUser) => action.payload),
      exhaustMap(payload =>
        this.authService
          .loginAsHomeUser(payload)
          .pipe(
            map(resp => {
              this.localStorage.set('home_token', (<any>resp).token);
              return new LoginAsHomeUserSuccess();
            }),
            catchError(response => this.storeHelper.errorHandler(response, LoginAsHomeUserFail))
          )
      ));

  @Effect()
  registration = this.actions$
    .pipe(
      ofType(AuthActionTypes.Registration),
      map((action: Registration) => action.payload),
      exhaustMap(payload =>
        this.authService
          .registration(payload)
          .pipe(
            map((res) => new RegistrationSuccess({token: res.token})),
            catchError(response => this.storeHelper.errorHandler(response, RegistrationFail))
          )
      )
    );

  @Effect({dispatch: false})
  registrationSuccess$ = this.actions$
    .pipe(
      ofType(AuthActionTypes.RegistrationSuccess),
      map((action: RegistrationSuccess) => action.payload),
      tap((payload) => {
        if (!payload.id) {
          WSLMaterializeHelper.toast({html: 'Вы уже зарегистрированы в системе. Авторизуйтесь или восстановите пароль'});
          this.router.navigate(['/login']);
        }
        /*const barcode = this.localStorage.get(StorageKey.device_barcode);
        this.localStorage.clear();
        this.localStorage.set(StorageKey.token, payload.token);
        if (barcode) {
          this.localStorage.set(StorageKey.device_barcode, barcode);
        }
        AuthService.redirectURL = ['/login', '/reset', '/forgot', '/confirm', '/registration']
          .some(s => AuthService.redirectURL.startsWith(s)) ? '/' : AuthService.redirectURL;*/
        /*this.router.navigate([
          ['/login', '/reset', '/forgot', '/confirm', '/registration']
            .some(s => AuthService.redirectUrl.startsWith(s)) ? '/' : AuthService.redirectUrl]);*/
      })
    );

  @Effect()
  confirmRegistration = this.actions$
    .pipe(
      ofType(AuthActionTypes.ConfirmRegistration),
      map((action: ConfirmRegistration) => action.payload),
      exhaustMap(payload =>
        this.authService
          .confirmRegistration(payload)
          .pipe(
            map(resp => new ConfirmRegistrationSuccess({token: resp.token})),
            catchError(response => this.storeHelper.errorHandler(response, ConfirmRegistrationFail))
          )
      )
    );

  @Effect()
  startLoad$: Observable<Action> = this.actions$
    .pipe(
      ofType(
        AuthActionTypes.Login,
        AuthActionTypes.ForgotPassword,
        AuthActionTypes.ResetPassword,
        AuthActionTypes.RefreshToken,
        AuthActionTypes.LoginAsHomeUser,
        AuthActionTypes.Registration,
        AuthActionTypes.ConfirmRegistration
      ),
      map(() => new StartLoading('auth'))
    );

  @Effect()
  stopLoad$: Observable<Action> = this.actions$
    .pipe(
      ofType(
        AuthActionTypes.LoginSuccess, AuthActionTypes.LoginFail,
        AuthActionTypes.ResetPasswordSuccess, AuthActionTypes.ResetPasswordFail,
        AuthActionTypes.ForgotPasswordSuccess, AuthActionTypes.ForgotPasswordFail,
        AuthActionTypes.RefreshTokenSuccess, AuthActionTypes.RefreshTokenFail,
        AuthActionTypes.LoginAsHomeUserSuccess, AuthActionTypes.LoginAsHomeUserFail,
        AuthActionTypes.RegistrationSuccess, AuthActionTypes.RegistrationFail,
        AuthActionTypes.ConfirmRegistrationSuccess, AuthActionTypes.ConfirmRegistrationFail
      ),
      map(() => new StopLoading('auth'))
    );

  private safeClearLocalStorageMeta() {
    const popup = this.localStorage.get('check_popup');
    const promo = this.localStorage.get('show_promotion');
    this.localStorage.clear();
    if (popup) {
      this.localStorage.set('check_popup', popup);
    }
    if (promo) {
      this.localStorage.set('show_promotion', promo);
    }
  }

  constructor(private actions$: Actions,
              private router: Router,
              private storeHelper: StoreHelperService,
              private localStorage: WSLLocalStorageService,
              private authService: AuthService,
              private companyService: CompanyProfileService) {
  }
}

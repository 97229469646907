import * as tslib_1 from "tslib";
import { BCompanyAccountService } from '@app/billing-company/services/b-company-account.service';
import { StoreHelperService } from '@core/services/store-helper.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { AddBCompanyAccountFail, AddBCompanyAccountSuccess, BillingCompanyAccountActionTypes, DeleteBCompanyAccountFail, DeleteBCompanyAccountSuccess, LoadBCompanyAccountsFail, LoadBCompanyAccountsSuccess, UpdateBCompanyAccountFail, UpdateBCompanyAccountSuccess } from './billing-company-account.actions';
var BillingCompanyAccountEffects = /** @class */ (function () {
    function BillingCompanyAccountEffects(actions$, bCompanyAccountService, storeHelper) {
        var _this = this;
        this.actions$ = actions$;
        this.bCompanyAccountService = bCompanyAccountService;
        this.storeHelper = storeHelper;
        this.add$ = this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.AddBCompanyAccount), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.AddBCompanyAccount), skip(1));
            return _this.bCompanyAccountService.add(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddBCompanyAccountSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddBCompanyAccountFail); }));
        }));
        this.update$ = this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.UpdateBCompanyAccount), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.UpdateBCompanyAccount), skip(1));
            return _this.bCompanyAccountService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateBCompanyAccountSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateBCompanyAccountFail); }));
        }));
        this.delete$ = this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.DeleteBCompanyAccount), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.DeleteBCompanyAccount), skip(1));
            return _this.bCompanyAccountService.delete(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteBCompanyAccountSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteBCompanyAccountFail); }));
        }));
        this.loadMany$ = this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.LoadBCompanyAccounts), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.LoadBCompanyAccounts), skip(1));
            return _this.bCompanyAccountService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadBCompanyAccountsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadBCompanyAccountsFail); }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], BillingCompanyAccountEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], BillingCompanyAccountEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], BillingCompanyAccountEffects.prototype, "delete$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], BillingCompanyAccountEffects.prototype, "loadMany$", void 0);
    return BillingCompanyAccountEffects;
}());
export { BillingCompanyAccountEffects };

import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLDeviceConfFilter } from '@app/device-conf/models/device-conf-filter';
import { IWSLDeviceConf } from '@app/device-conf/models/device-conf';

export enum DeviceConfActionTypes {
  AddDeviceConf = '[DeviceConf] Add',
  AddDeviceConfSuccess = '[DeviceConf] Add Success',
  AddDeviceConfFail = '[DeviceConf] Add Fail',

  UpdateDeviceConf = '[DeviceConf] Update',
  UpdateDeviceConfSuccess = '[DeviceConf] Update Success',
  UpdateDeviceConfFail = '[DeviceConf] Update Fail',

  DeleteDeviceConf = '[DeviceConf] Delete',
  DeleteDeviceConfSuccess = '[DeviceConf] Delete Success',
  DeleteDeviceConfFail = '[DeviceConf] Delete Fail',

  SelectDeviceConf = '[DeviceConf] Select',

  LoadDeviceConf = '[DeviceConf] Load',
  LoadDeviceConfSuccess = '[DeviceConf] Load Success',
  LoadDeviceConfFail = '[DeviceConf] Load Fail',

  LoadDeviceConfs = '[DeviceConf] Load Many',
  LoadDeviceConfsSuccess = '[DeviceConf] Load Many Success',
  LoadDeviceConfsFail = '[DeviceConf] Load Many Fail',

  ClearDeviceConfs = '[DeviceConf] Clear'
}

/** Add */
export class AddDeviceConf implements Action {
  readonly type = DeviceConfActionTypes.AddDeviceConf;

  constructor(public payload: IWSLDeviceConf) {
  }
}

export class AddDeviceConfSuccess implements Action {
  readonly type = DeviceConfActionTypes.AddDeviceConfSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

export class AddDeviceConfFail implements Action {
  readonly type = DeviceConfActionTypes.AddDeviceConfFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

/** Update */
export class UpdateDeviceConf implements Action {
  readonly type = DeviceConfActionTypes.UpdateDeviceConf;

  constructor(public payload: IWSLDeviceConf) {
  }
}

export class UpdateDeviceConfSuccess implements Action {
  readonly type = DeviceConfActionTypes.UpdateDeviceConfSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

export class UpdateDeviceConfFail implements Action {
  readonly type = DeviceConfActionTypes.UpdateDeviceConfFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

/** Delete */
export class DeleteDeviceConf implements Action {
  readonly type = DeviceConfActionTypes.DeleteDeviceConf;

  constructor(public payload: number) {
  }
}

export class DeleteDeviceConfSuccess implements Action {
  readonly type = DeviceConfActionTypes.DeleteDeviceConfSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

export class DeleteDeviceConfFail implements Action {
  readonly type = DeviceConfActionTypes.DeleteDeviceConfFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

/** Select */
export class SelectDeviceConf implements Action {
  readonly type = DeviceConfActionTypes.SelectDeviceConf;

  constructor(public payload: number) {
  }
}

/** Load One */
export class LoadDeviceConf implements Action {
  readonly type = DeviceConfActionTypes.LoadDeviceConf;

  constructor(public payload: number) {
  }
}

export class LoadDeviceConfSuccess implements Action {
  readonly type = DeviceConfActionTypes.LoadDeviceConfSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

export class LoadDeviceConfFail implements Action {
  readonly type = DeviceConfActionTypes.LoadDeviceConfFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

/** Load Many */
export class LoadDeviceConfs implements Action {
  readonly type = DeviceConfActionTypes.LoadDeviceConfs;

  constructor(public payload: IWSLDeviceConfFilter) {
  }
}

export class LoadDeviceConfsSuccess implements Action {
  readonly type = DeviceConfActionTypes.LoadDeviceConfsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

export class LoadDeviceConfsFail implements Action {
  readonly type = DeviceConfActionTypes.LoadDeviceConfsFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceConf>) {
  }
}

export class ClearDeviceConfs implements Action {
  readonly type = DeviceConfActionTypes.ClearDeviceConfs;
}

export type DeviceConfActions =
  AddDeviceConf |
  AddDeviceConfSuccess |
  AddDeviceConfFail |

  UpdateDeviceConf |
  UpdateDeviceConfSuccess |
  UpdateDeviceConfFail |

  DeleteDeviceConf |
  DeleteDeviceConfSuccess |
  DeleteDeviceConfFail |

  SelectDeviceConf |

  LoadDeviceConf |
  LoadDeviceConfSuccess |
  LoadDeviceConfFail |

  LoadDeviceConfs |
  LoadDeviceConfsSuccess |
  LoadDeviceConfsFail |

  ClearDeviceConfs;


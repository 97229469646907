import * as tslib_1 from "tslib";
import { RoomService } from '@app/room/services/room.service';
import { StoreHelperService } from '@core/services/store-helper.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AddRoomFail, AddRoomsFail, AddRoomsSuccess, AddRoomSuccess, DeleteRoomFail, DeleteRoomsFail, DeleteRoomsSuccess, DeleteRoomSuccess, GenerateActivationCodeForRoomFail, GenerateActivationCodeForRoomSuccess, LoadRoom, LoadRoomFail, LoadRoomsFail, LoadRoomsSuccess, LoadRoomSuccess, RoomActionTypes, UpdateRoomFail, UpdateRoomSuccess } from './room.actions';
import * as fromRoom from './room.reducer';
var RoomEffects = /** @class */ (function () {
    function RoomEffects(actions$, store$, storeHelper, roomService) {
        var _this = this;
        this.actions$ = actions$;
        this.store$ = store$;
        this.storeHelper = storeHelper;
        this.roomService = roomService;
        this.add$ = this.actions$.pipe(ofType(RoomActionTypes.AddRoom), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(RoomActionTypes.AddRoom), skip(1));
            return _this.roomService.add(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddRoomSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddRoomFail); }));
        }));
        this.addMany$ = this.actions$.pipe(ofType(RoomActionTypes.AddRooms), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(RoomActionTypes.AddRooms), skip(1));
            return _this.roomService.addMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddRoomsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddRoomsFail); }));
        }));
        this.delete$ = this.actions$.pipe(ofType(RoomActionTypes.DeleteRoom), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(RoomActionTypes.DeleteRoom), skip(1));
            return _this.roomService.delete(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteRoomSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteRoomFail); }));
        }));
        this.deleteMany$ = this.actions$.pipe(ofType(RoomActionTypes.DeleteRooms), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(RoomActionTypes.DeleteRooms), skip(1));
            return _this.roomService.deleteMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteRoomsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteRoomsFail); }));
        }));
        this.load$ = this.actions$.pipe(ofType(RoomActionTypes.LoadRoom), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(RoomActionTypes.LoadRoom), skip(1));
            return _this.roomService.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadRoomSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadRoomFail); }));
        }));
        this.update$ = this.actions$.pipe(ofType(RoomActionTypes.UpdateRoom), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(RoomActionTypes.UpdateRoom), skip(1));
            return _this.roomService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateRoomSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateRoomFail); }));
        }));
        this.updateSuccess$ = this.actions$.pipe(ofType(RoomActionTypes.UpdateRoomSuccess), withLatestFrom(this.store$.pipe(select(fromRoom.selectRoomSelectedID))), map(function (_a) {
            var action = _a[0], id = _a[1];
            return id;
        }), map(function (id) { return new LoadRoom(id); }));
        this.loadMany$ = this.actions$.pipe(ofType(RoomActionTypes.LoadRooms), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(RoomActionTypes.LoadRooms), skip(1));
            return _this.roomService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadRoomsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadRoomsFail); }));
        }));
        /*@Effect()
        loadManyOnGenerateCodeForObject$ = this.actions$.pipe(
          ofType(ObjectActionTypes.GenerateActivationCodeForObjectSuccess),
          withLatestFrom(this.store$.pipe(select(fromObject.selectObjectSelectedID))),
          map(([action, id]) => id),
          filer(id => !!id),
          switchMap((id) => {
            return this.roomService.getMany({offset: 0, object_id: id})
              .pipe(
                take(1),
                mergeMap(resp => this.storeHelper.successHandler(resp, LoadRoomsSuccess)),
                catchError(resp => this.storeHelper.errorHandler(resp, LoadRoomsFail))
              );
          })
        );*/
        this.generateActivationCode$ = this.actions$.pipe(ofType(RoomActionTypes.GenerateActivationCodeForRoom), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(RoomActionTypes.GenerateActivationCodeForRoom), skip(1));
            return _this.roomService.generateActivationCode(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, GenerateActivationCodeForRoomSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, GenerateActivationCodeForRoomFail); }));
        }));
        /* @Effect()
         loadManyOnGenerateCodeForRoom$ = this.actions$.pipe(
           ofType(RoomActionTypes.GenerateActivationCodeForRoomSuccess),
           withLatestFrom(this.store$.pipe(select(fromRoom.selectRoomSelectedID))),
           map(([action, id]) => id),
           switchMap((id) => {
             return this.roomService.get(id)
               .pipe(
                 take(1),
                 mergeMap(resp => this.storeHelper.successHandler(resp, LoadRoomSuccess)),
                 catchError(resp => this.storeHelper.errorHandler(resp, LoadRoomFail))
               );
           })
         );*/
        /* @Effect()
         loadProfileRoom$: Observable<Action> = this.actions$
           .pipe(
             ofType(UserProfileActionTypes.LoadUserProfileSuccess),
             filter(action => !!environment.kv),
             exhaustMap(() =>
               this.roomService
                 .get(this.roomService.getRoomID())
                 .pipe(
                   map(response => new LoadRoomSuccess(response)),
                   catchError(response => this.storeHelper.errorHandler(response, LoadRoomFail))
                 )
             )
           );*/
        this.startLoad$ = this.actions$
            .pipe(ofType(RoomActionTypes.LoadRoom, RoomActionTypes.AddRoom, RoomActionTypes.AddRooms, RoomActionTypes.DeleteRoom, RoomActionTypes.DeleteRooms, RoomActionTypes.UpdateRoom, RoomActionTypes.GenerateActivationCodeForRoom), map(function () { return new StartLoading('room'); }));
        this.startLoadMany$ = this.actions$
            .pipe(ofType(RoomActionTypes.LoadRooms), map(function (action) { return action.payload; }), filter(function (payload) { return payload.offset === 0; }), map(function () { return new StartLoading('room'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(RoomActionTypes.AddRoomSuccess, RoomActionTypes.AddRoomFail, RoomActionTypes.AddRoomsSuccess, RoomActionTypes.AddRoomsFail, RoomActionTypes.DeleteRoomSuccess, RoomActionTypes.DeleteRoomFail, RoomActionTypes.DeleteRoomsSuccess, RoomActionTypes.DeleteRoomsFail, RoomActionTypes.LoadRoomSuccess, RoomActionTypes.LoadRoomFail, RoomActionTypes.LoadRoomsSuccess, RoomActionTypes.LoadRoomsFail, RoomActionTypes.UpdateRoomSuccess, RoomActionTypes.UpdateRoomFail, RoomActionTypes.GenerateActivationCodeForRoomSuccess, RoomActionTypes.GenerateActivationCodeForRoomFail), map(function () { return new StopLoading('room'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "addMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "delete$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "deleteMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "updateSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "loadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "generateActivationCode$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "startLoadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoomEffects.prototype, "stopLoad$", void 0);
    return RoomEffects;
}());
export { RoomEffects };

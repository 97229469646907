import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IWSLDevice, IWSLDeviceExecution, IWSLDeviceModel} from 'wsl-device';
import {IWSLObject} from '@app/object/models/object';
import {IWSLRoom} from '@app/room/models/room';
import {ActivatedRoute} from '@angular/router';
import {WSLRouterHelperService} from 'wsl-core';
import {CompanyProfileService, UserProfileService} from 'wsl-ek-core';
import {IWSLAppEnvironment} from '@core/models/app-environment';

@Component({
  selector: 'wsl-device-brief-info',
  templateUrl: './device-brief-info.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceBriefInfoComponent implements OnInit, OnChanges {
  @Input() appEnv: IWSLAppEnvironment;
  @Input() title = '';
  @Input() device: IWSLDevice;
  @Input() object: IWSLObject;
  @Input() room: IWSLRoom;
  @Input() routeTo = '/settings/';
  @Output() loadObject = new EventEmitter<number>();
  @Output() loadRoom = new EventEmitter<number>();

  isUC = false;
  isHomeUser = false;
  dmodel: IWSLDeviceModel;
  dexecution: IWSLDeviceExecution;

  private dmodels: IWSLDeviceModel[] = [];
  private dexecutions: IWSLDeviceExecution[] = [];

  constructor( private userProfileService: UserProfileService,
               private companyProfileService: CompanyProfileService,
               private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.isUC = this.appEnv.isUc;
    this.isHomeUser = this.appEnv.isHome;
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.dmodels = data.dmodels;
    this.dexecutions = data.dexecutions;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.device) {
      if (!!this.device) {
        if (this.device.object_id) {
          this.loadObject.emit(this.device.object_id);
        }
        if (this.device.room_id) {
          this.loadRoom.emit(this.device.room_id);
        }
        this.dmodel = this.dmodels ? this.dmodels.find(dm => dm.id === this.device.dmodel_id) : null;
        this.dexecution = this.dexecutions ? this.dexecutions.find(de => de.id === this.device.dexecution_id) : null;
      }
    }
  }
  /*
    get deviceId() {
      return this.device ? this.device.id : null;
    }

   get model() {
      return this.device ? this.dmodels.find(dm => dm.id === this.device.dmodel_id) : null;
    }

    get execution() {
      return this.device ? this.dexecutions.find(de => de.id === this.device.dexecution_id) : null;
    }

    get objectName() {
      return this.object && this.device && this.device.object_id === this.object.id ? this.object.name : this.device && this.device.object_name ? this.device.object_name : '';
    }

    get residential() {
      return this.room && this.device && this.device.room_id === this.room.id ? this.room.residential : this.device ? this.device.residential : false;
    }

    get objectId() {
      return this.object && this.device && this.device.object_id === this.object.id ? this.object.id : this.device && this.device.object_id ? this.device.object_id : null;
    }

    get roomName() {
      return this.room && this.device && this.device.room_id === this.room.id ? this.room.name : this.device && this.device.room_name ? this.device.room_name : '';
    }

    get roomId() {
      return this.room && this.device && this.device.room_id === this.room.id ? this.room.id : this.device && this.device.room_id ? this.device.room_id : null;
    }*/

}

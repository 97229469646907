import { HttpClient } from '@angular/common/http';
import { WSLHttpHelper } from 'wsl-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BillingDataService = /** @class */ (function () {
    function BillingDataService(http) {
        this.http = http;
    }
    BillingDataService.prototype.getReport = function (date, bcompany_id, token) {
        return this.http.get('bcompany_report', { params: WSLHttpHelper.getParams({ date: date, bcompany_id: bcompany_id, token: token }) });
    };
    BillingDataService.ngInjectableDef = i0.defineInjectable({ factory: function BillingDataService_Factory() { return new BillingDataService(i0.inject(i1.HttpClient)); }, token: BillingDataService, providedIn: "root" });
    return BillingDataService;
}());
export { BillingDataService };

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AddCommunicator,
  AddCommunicatorFail,
  AddCommunicatorSuccess,
  CommunicatorActionTypes,
  DeleteCommunicator,
  DeleteCommunicatorFail,
  DeleteCommunicatorSuccess,
  LoadCommunicator,
  LoadCommunicatorFail,
  LoadCommunicators,
  LoadCommunicatorsFail,
  LoadCommunicatorsSuccess,
  LoadCommunicatorSuccess,
  UpdateCommunicator,
  UpdateCommunicatorFail,
  UpdateCommunicatorSuccess
} from './communicator.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { CommunicatorService } from '@app/communicator/services/communicator.service';

@Injectable()
export class CommunicatorEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(CommunicatorActionTypes.AddCommunicator),
    map((action: AddCommunicator) => action.payload),
    exhaustMap(payload => {
      const nextLoad$ = this.actions$.pipe(ofType(CommunicatorActionTypes.AddCommunicator), skip(1));

      return this.communicatorService.add(payload)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddCommunicatorSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddCommunicatorFail))
        );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(CommunicatorActionTypes.UpdateCommunicator),
    map((action: UpdateCommunicator) => action.payload),
    exhaustMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(CommunicatorActionTypes.UpdateCommunicator), skip(1));

      return this.communicatorService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateCommunicatorSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateCommunicatorFail))
        );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(CommunicatorActionTypes.LoadCommunicator),
    map((action: LoadCommunicator) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(CommunicatorActionTypes.LoadCommunicator), skip(1));

      return this.communicatorService.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadCommunicatorSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadCommunicatorFail))
        );
    })
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(CommunicatorActionTypes.LoadCommunicators),
    map((action: LoadCommunicators) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(CommunicatorActionTypes.LoadCommunicators), skip(1));

      return this.communicatorService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadCommunicatorsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadCommunicatorsFail))
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CommunicatorActionTypes.DeleteCommunicator),
    map((action: DeleteCommunicator) => action.payload),
    exhaustMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(CommunicatorActionTypes.DeleteCommunicator), skip(1));

      return this.communicatorService.delete(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteCommunicatorSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteCommunicatorFail))
        );
    })
  );

  @Effect()
  startLoad$ = this.actions$
    .pipe(
      ofType(
        CommunicatorActionTypes.AddCommunicator,
        CommunicatorActionTypes.LoadCommunicator,
        CommunicatorActionTypes.UpdateCommunicator,
        CommunicatorActionTypes.DeleteCommunicator
      ),
      map(() => new StartLoading('communicator'))
    );

  @Effect()
  startLoadMany$ = this.actions$
    .pipe(
      ofType(CommunicatorActionTypes.LoadCommunicators),
      map((action: LoadCommunicators) => action.payload),
      filter((payload) => payload.offset === 0),
      map(() => new StartLoading('communicator'))
    );


  @Effect()
  stopLoad$ = this.actions$
    .pipe(
      ofType(
        CommunicatorActionTypes.AddCommunicatorSuccess, CommunicatorActionTypes.AddCommunicatorFail,
        CommunicatorActionTypes.LoadCommunicatorSuccess, CommunicatorActionTypes.LoadCommunicatorFail,
        CommunicatorActionTypes.UpdateCommunicatorSuccess, CommunicatorActionTypes.UpdateCommunicatorFail,
        CommunicatorActionTypes.DeleteCommunicatorSuccess, CommunicatorActionTypes.DeleteCommunicatorFail,
        CommunicatorActionTypes.LoadCommunicatorsSuccess, CommunicatorActionTypes.LoadCommunicatorsFail
      ),
      map(() => new StopLoading('communicator'))
    );

  constructor(private actions$: Actions,
              private router: Router,
              private route: ActivatedRoute,
              private storeHelper: StoreHelperService,
              private communicatorService: CommunicatorService) {
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { MainMenuType } from '@core/enums/main-menu-type.enum';
import { MenuHelperService } from '@core/services/menu-helper.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '@store/reducers';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataPermissions, UserProfileService } from 'wsl-ek-core';

@Injectable({
  providedIn: 'root'
})
export class AvailableDataPageGuard implements CanActivate, CanLoad {
  constructor(private store$: Store<fromRoot.State>,
              private userProfileService: UserProfileService,
              private menuService: MenuHelperService,
              private router: Router) {
  }

  check() {
    return of(!!this.menuService.mainMenu.find(menu => menu.id === MainMenuType.data) ||
      <boolean>this.userProfileService.hasPermission([DataPermissions.showObjectData, DataPermissions.showObjectRoomsData, DataPermissions.showDeviceData]))
      .pipe(
        tap(canLoad => {
          if (!canLoad) {
            if (this.menuService.mainMenu && this.menuService.mainMenu.length > 0) {
              this.router.navigate([this.menuService.mainMenu[0].route], {queryParamsHandling: 'merge' });
            } else {
              this.router.navigateByUrl('/404');
            }
          }
        })
      );
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }
}

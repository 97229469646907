import { Injectable } from '@angular/core';
import { BillingCompanyService } from '@app/billing-company/services/billing-company.service';
import { StoreHelperService } from '@core/services/store-helper.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import {
  BillingCompanyActionTypes,
  LoadBCompanies,
  LoadBCompaniesFail,
  LoadBCompaniesSuccess
} from './billing-company.actions';

@Injectable()
export class BillingCompanyEffects {

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(BillingCompanyActionTypes.LoadBCompanies),
    map((action: LoadBCompanies) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(BillingCompanyActionTypes.LoadBCompanies), skip(1));

      return this.bCompanyService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadBCompaniesSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadBCompaniesFail))
        );
    })
  );

  constructor(private actions$: Actions,
              private bCompanyService: BillingCompanyService,
              private storeHelper: StoreHelperService) {
  }
}

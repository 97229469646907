<ul *ngIf="menu.length > 0"
    [attr.id]="'menu_tabs_'+prefix"
    class="tabs"
    style="z-index: 1001;"
    #navEl
    [ngClass]="{'nav-second z-depth-1': isRoute, 'wsl-border-t wsl-border-b': !isRoute}">
  <li class="tab" *ngFor="let item of tabMenu; trackBy: trackById"  #menuItemsEl>
    <a class="wsl-relative" [attr.href]="(isRoute ? item.route : '/')+'#'+item.tab_id"
       (click)="routeTo($event, item)">
      {{item.name}}
      <span *ngIf="item.badge" class="wsl-badge wsl-small wsl-bg-red wsl-white wsl-abs wsl-abs-top wsl-abs-right wsl-m-t-4" style="margin-right: 5px;">{{item.badge}}</span>
    </a>
  </li>
    <li id="drop" #dropEl>
        <a class="icon-menu wsl-st4 wsl-white wsl-hover tab-menu_{{prefix}}_dropdown-trigger" [attr.data-target]="'dropdown_tab_menu_'+prefix"></a>
    </li>
</ul>

<ul [attr.id]="'dropdown_tab_menu_'+prefix" class="dropdown-content" #dropMenuEl>
    <li class="tab" *ngFor="let item of dropMenu; trackBy: trackById">
        <a class="wsl-relative" [attr.href]="(isRoute ? item.route : '/')+'#'+item.tab_id"
           (click)="routeTo($event, item)">
            {{item.name}}
            <span *ngIf="item.badge" class="wsl-badge wsl-small wsl-bg-red wsl-white wsl-abs wsl-abs-top wsl-abs-right wsl-m-t-4" style="margin-right: 5px;">{{item.badge}}</span>
        </a>
    </li>
</ul>

<style>
    nav ul > li {
        float: none;
        display: inline-block;
    }

    #dropdown_menu > li {
        display: block;
    }
    #dropdown_menu > li:hover {
        background-color: #555;
    }

    li#drop {
        display: none;
        position: relative;
    }

    #drop ul {
        display: none;
        position: absolute;
        top: 38px;
        left: 0;
    }

    #drop:hover ul {
        display: inline-block;
    }
</style>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWSLHttpResponse, WSLHttpHelper } from 'wsl-core';
import {
  ApiHttpClientWithPermissionsService,
  IWSLEntityPermissions,
  ObjectRoomPermissions,
  UserProfileService
} from 'wsl-ek-core';
import { IWSLRoom } from '../models/room';
import { IWSLRoomFilter } from '../models/room-filter';

@Injectable()
export class RoomService extends ApiHttpClientWithPermissionsService<IWSLRoom, IWSLRoomFilter> {
  static sortApartments(a, b) {
    if (a.name === b.name) {
      return 0;
    }
    if (Number.isNaN(+a.name) && Number.isNaN(+b.name)) {
      return a.name < b.name ? -1 : 1;
    } else if (Number.isNaN(+a.name)) {
      return 1;
    } else if (Number.isNaN(+b.name)) {
      return -1;
    }
    return +a.name < +b.name ? -1 : 1;
  }

  public getRoomID(): number {
    return this.userProfileService.getRoomID();
  }

  constructor(public http: HttpClient, public userProfileService: UserProfileService) {
    super(http, 'room', <IWSLEntityPermissions>ObjectRoomPermissions, userProfileService);
  }

  public addMany(rooms: IWSLRoom[]) {
    return this.http.post<IWSLHttpResponse<IWSLRoom>>('rooms_add', {params: rooms});
  }

  public deleteMany(ids: number[]) {
    return this.http.post<IWSLHttpResponse<IWSLRoom>>('rooms_delete', {ids});
  }

  public generateActivationCode(room_id: number) {
    return this.http.get<IWSLHttpResponse<any>>(
      'generate_activation_code',
      {params: WSLHttpHelper.getParams({room_id})});
  }


}

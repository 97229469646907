import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunicatorActions, CommunicatorActionTypes } from './communicator.actions';
import * as fromRoot from '@store/reducers/index';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IWSLAsyncErrors } from 'wsl-core';
import { IWSLCommunicator } from 'wsl-device';

export interface CommunicatorState extends EntityState<IWSLCommunicator> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLCommunicator> = createEntityAdapter<IWSLCommunicator>({
  selectId: (item: IWSLCommunicator) => item.id,
  sortComparer: false
});

export const initialState: CommunicatorState = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(state = initialState, action: CommunicatorActions): CommunicatorState {
  switch (action.type) {

    case CommunicatorActionTypes.AddCommunicator:
    case CommunicatorActionTypes.UpdateCommunicator:
    case CommunicatorActionTypes.DeleteCommunicator:
      return {...state, pending: true, errors: null};

    case CommunicatorActionTypes.AddCommunicatorSuccess:
    case CommunicatorActionTypes.UpdateCommunicatorSuccess:
    case CommunicatorActionTypes.DeleteCommunicatorSuccess:
      return {...state, pending: false};

    case CommunicatorActionTypes.AddCommunicatorFail:
    case CommunicatorActionTypes.UpdateCommunicatorFail:
    case CommunicatorActionTypes.DeleteCommunicatorFail:
      return {...state, pending: false, errors: action.payload.asyncMessages};

    case CommunicatorActionTypes.LoadCommunicator:
      return {
        ...state,
        // selectedID: null,
        pending: true,
        loaded: false,
        errors: null
      };

    case CommunicatorActionTypes.LoadCommunicatorSuccess:
      return adapter.upsertOne({
        ...action.payload.item
      }, {
        ...state,
        pending: false,
        loaded: true
      });

    case CommunicatorActionTypes.LoadCommunicatorFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case CommunicatorActionTypes.SelectCommunicator:
      return {...state, selectedID: action.payload};

    case CommunicatorActionTypes.LoadCommunicators:
      if (!action.payload.offset) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          pending: true,
          loaded: false,
          errors: null
        });
      }
      return {...state, pending: true, loaded: false, errors: null};

    case CommunicatorActionTypes.LoadCommunicatorsSuccess:
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case CommunicatorActionTypes.LoadCommunicatorsFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case CommunicatorActionTypes.ClearCommunicators:
      return adapter.removeAll({
        ...state,
        totalCount: 0,
        pending: false,
        loaded: true
      });

    default:
      return state;
  }
}

export const getSelectedID = (state: CommunicatorState) => state.selectedID;
export const getTotalCount = (state: CommunicatorState) => state.totalCount;
export const getLoaded = (state: CommunicatorState) => state.loaded;
export const getPending = (state: CommunicatorState) => state.pending;
export const getErrors = (state: CommunicatorState) => state.errors;


export interface State extends fromRoot.State {
  'communicator': CommunicatorState;
}

export const getCommunicatorState = createFeatureSelector<CommunicatorState>('communicator');

export const selectCommunicatorSelectedID = createSelector(getCommunicatorState, getSelectedID);
export const selectCommunicatorTotalCount = createSelector(getCommunicatorState, getTotalCount);
export const selectCommunicatorLoaded = createSelector(getCommunicatorState, getLoaded);
export const selectCommunicatorPending = createSelector(getCommunicatorState, getPending);
export const selectCommunicatorErrors = createSelector(getCommunicatorState, getErrors);

export const {
  selectEntities: selectCommunicatorEntities,
  selectAll: selectCommunicatorAll
} = adapter.getSelectors(getCommunicatorState);

export const selectCommunicator = createSelector(
  selectCommunicatorEntities,
  selectCommunicatorSelectedID,
  (entities, id) => entities && entities[id] ? entities[id] : null
);

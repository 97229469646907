import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IWSLDevice} from 'wsl-device';
import {IWSLRoom} from '../../../room/models/room';
import {IWSLServiceProcedure} from '@app/service-procedure/models/service-procedure';

@Component({
  selector: '[wsl-devices-per-rooms-row]',
  templateUrl: './devices-per-rooms-row.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesPerRoomsRowComponent implements OnInit {
  @Input() devices: IWSLDevice[] = [];
  @Input() room: IWSLRoom = {};
  @Input() serviceProcedures: IWSLServiceProcedure[] = [];



  constructor() { }

  ngOnInit() {
  }

  onSelectDevice(d) {
  }

  trackById(index: number, device: IWSLDevice) {
    return device.id;
  }

}

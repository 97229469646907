<div [class]="wrapperClass" *ngIf="isActive" [@flyInOut] [ngStyle]="style">
  <div class='wsl-flex-center-middle-ng'>
    <div class='wsl-p-a-30'>
      <div class='preloader-wrapper small active'>
        <div class='spinner-layer'>
          <div class='circle-clipper left'>
            <div class='circle'></div>
          </div>
          <div class='gap-patch'>
            <div class='circle'></div>
          </div>
          <div class='circle-clipper right'>
            <div class='circle'></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

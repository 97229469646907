import * as tslib_1 from "tslib";
import { BillingCompanyService } from '@app/billing-company/services/billing-company.service';
import { StoreHelperService } from '@core/services/store-helper.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { BillingCompanyActionTypes, LoadBCompaniesFail, LoadBCompaniesSuccess } from './billing-company.actions';
var BillingCompanyEffects = /** @class */ (function () {
    function BillingCompanyEffects(actions$, bCompanyService, storeHelper) {
        var _this = this;
        this.actions$ = actions$;
        this.bCompanyService = bCompanyService;
        this.storeHelper = storeHelper;
        this.loadMany$ = this.actions$.pipe(ofType(BillingCompanyActionTypes.LoadBCompanies), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(BillingCompanyActionTypes.LoadBCompanies), skip(1));
            return _this.bCompanyService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadBCompaniesSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadBCompaniesFail); }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], BillingCompanyEffects.prototype, "loadMany$", void 0);
    return BillingCompanyEffects;
}());
export { BillingCompanyEffects };

import { Action } from '@ngrx/store';
import {IWSLAsyncErrors, IWSLMenu} from 'wsl-core';
import {IWSLAppEnvironment} from "@core/models/app-environment";

export enum AppActionTypes {
  StartLoading = '[App] Start Loading',
  StopLoading = '[App] Stop Loading',
  ShowMenuTabBar = '[App] Show Menu Tab Bar',
  HideMenuTabBar = '[App] Hide Menu Tab Bar',
  SetSnackBarMessage = '[App] Set Snack Bar message',
  HasBilling = '[App] Has Billing',
  SetAppEnvironment = '[App] Set Environment',
  ShowNoAccessMessage = '[App] Show No Access Message',
  ClearContractData = '[App] Clear Contract Data'
}

export class StartLoading implements Action {
  readonly type = AppActionTypes.StartLoading;

  constructor(public payload: string) {}
}

export class StopLoading implements Action {
  readonly type = AppActionTypes.StopLoading;

  constructor(public payload: string) {}
}

export class ShowMenuTabBar implements Action {
  readonly type = AppActionTypes.ShowMenuTabBar;

  constructor(public payload: IWSLMenu[]) {}
}

export class HideMenuTabBar implements Action {
  readonly type = AppActionTypes.HideMenuTabBar;
}

export class SetSnackBarMessage implements Action {
  readonly type = AppActionTypes.SetSnackBarMessage;

  constructor(public payload: IWSLAsyncErrors) {}
}

export class HasBilling implements Action {
  readonly type = AppActionTypes.HasBilling;
}

export class SetAppEnvironment implements Action {
  readonly type = AppActionTypes.SetAppEnvironment;

  constructor(public payload: IWSLAppEnvironment) {}
}

export class ShowNoAccessMessage implements Action {
  readonly type = AppActionTypes.ShowNoAccessMessage;

  constructor(public payload: {contract_num: string, contract_date_beg: string, name: string}) {}
}

export class ClearContractData implements Action {
  readonly type = AppActionTypes.ClearContractData;
}

export type AppActions = StartLoading
  | StopLoading
  | ShowMenuTabBar
  | HideMenuTabBar
  | SetSnackBarMessage
  | HasBilling
  | SetAppEnvironment
  | ShowNoAccessMessage
  | ClearContractData;

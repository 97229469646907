import * as tslib_1 from "tslib";
import { WSLApiHttpClientService } from 'wsl-core';
import { HttpClient } from '@angular/common/http';
var TimeZoneService = /** @class */ (function (_super) {
    tslib_1.__extends(TimeZoneService, _super);
    function TimeZoneService(http) {
        var _this = _super.call(this, http, 'time_zone') || this;
        _this.http = http;
        return _this;
    }
    TimeZoneService.prototype.formatUTCOffset = function (tz) {
        tz.forEach(function (item) {
            item.utc_offset = item.utc_offset.split(':')[0];
            item.utc_offset = parseInt(item.utc_offset, 10) > 0 ? ('+' + item.utc_offset) : item.utc_offset;
        });
    };
    return TimeZoneService;
}(WSLApiHttpClientService));
export { TimeZoneService };

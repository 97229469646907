import * as tslib_1 from "tslib";
import { AuthActionTypes } from '../actions/auth.actions';
import { UserProfileActionTypes } from '../actions/user-profile.actions';
export var initialState = {
    loggedIn: false,
    token: null,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActionTypes.LoginSuccess: {
            return tslib_1.__assign({}, state, { token: action.payload.token });
        }
        case UserProfileActionTypes.LoadUserProfileSuccess: {
            return tslib_1.__assign({}, state, { loggedIn: true });
        }
        case AuthActionTypes.LoginFail: {
            return initialState;
        }
        case AuthActionTypes.Logout: {
            return tslib_1.__assign({}, state, { loggedIn: false });
        }
        default:
            return state;
    }
}
export var getLoggedId = function (state) { return state.loggedIn; };
export var getToken = function (state) { return state.token; };

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {DeviceConfGroupFileIndexActions, DeviceConfGroupFileIndexActionTypes} from '../actions/device-conf-group-file-index.actions';
import {IWSLDeviceConfGroupFileIndex} from '@app/device-conf-group/models/device-conf-group-file-index.model';
import {IWSLAsyncErrors} from 'wsl-core';
import {DeviceConfGroupState} from '@store/device-conf-group/device-conf-group.reducer';
import * as fromRoot from '@store/reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface DeviceConfGroupFileIndexState extends EntityState<IWSLDeviceConfGroupFileIndex> {
  // additional entities state properties
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLDeviceConfGroupFileIndex> = createEntityAdapter<IWSLDeviceConfGroupFileIndex>();

export const initialState: DeviceConfGroupFileIndexState = adapter.getInitialState({
  // additional entity state properties
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(
  state = initialState,
  action: DeviceConfGroupFileIndexActions
): DeviceConfGroupFileIndexState {
  switch (action.type) {

    case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndex:
    case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexes:
      return {...state, pending: true, errors: null};

    case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexSuccess:
      return adapter.upsertOne(action.payload.item, {
        ...state,
        pending: false
      });

    case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexesSuccess:
      return adapter.upsertMany(action.payload.items,
        adapter.removeAll({
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        })
      );

    case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexFail:
    case DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexesFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    default: {
      return state;
    }
  }
}

export const getTotalCount = (state: DeviceConfGroupFileIndexState) => state.totalCount;
export const getLoaded = (state: DeviceConfGroupFileIndexState) => state.loaded;
export const getPending = (state: DeviceConfGroupFileIndexState) => state.pending;
export const getErrors = (state: DeviceConfGroupFileIndexState) => state.errors;



export interface State extends fromRoot.State {
  'deviceConfGroupFileIndex': DeviceConfGroupFileIndexState;
}

export const getDeviceConfGroupFileIndexState = createFeatureSelector<DeviceConfGroupState>('deviceConfGroupFileIndex');

export const selectDeviceConfGroupFileIndexTotalCount = createSelector(getDeviceConfGroupFileIndexState, getTotalCount);
export const selectDeviceConfGroupFileIndexLoaded = createSelector(getDeviceConfGroupFileIndexState, getLoaded);
export const selectDeviceConfGroupFileIndexPending = createSelector(getDeviceConfGroupFileIndexState, getPending);
export const selectDeviceConfGroupFileIndexErrors = createSelector(getDeviceConfGroupFileIndexState, getErrors);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(getDeviceConfGroupFileIndexState);

import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromDeviceConf from '@store/device-conf/device-conf.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DeviceConfEffects } from '@store/device-conf/device-conf.effects';
import { DeviceConfService } from './services/device-conf.service';
import { WslSharedModule } from 'wsl-shared';
import { AppCommonModule } from '@common/app-common.module';
import { DeviceConfListComponent } from './components/device-conf-list/device-conf-list.component';
import { DeviceConfRowComponent } from './components/device-conf-row/device-conf-row.component';
import { DeviceConfFormComponent } from './components/device-conf-form/device-conf-form.component';

const COMPONENTS = [
  DeviceConfListComponent,
  DeviceConfRowComponent,
  DeviceConfFormComponent
];

@NgModule({
  imports: [
    WslSharedModule,
    AppCommonModule,
    StoreModule.forFeature('deviceConf', fromDeviceConf.reducer),
    EffectsModule.forFeature([DeviceConfEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    DeviceConfService
  ]
})
export class DeviceConfModule {
}

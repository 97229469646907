import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { MenuHelperService } from '@core/services/menu-helper.service';
import { select, Store } from '@ngrx/store';
import { LoginRedirect, Logout } from '@store/actions/auth.actions';
import * as fromRoot from '@store/reducers';
import { Observable, of } from 'rxjs';
import {catchError, delay, filter, map, switchMap, take, timeout} from 'rxjs/operators';
import { WSLUtils } from 'wsl-core';

@Injectable({
  providedIn: 'root'
})
export class MainPageGuard implements CanActivate {
  constructor(private store: Store<fromRoot.State>,
              private menuService: MenuHelperService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.check();
  }

  private check() {
    return this.store
      .pipe(
        select(fromRoot.selectLoggedIn),
        delay(300),
        filter(loaded => loaded),
        take(1),
        timeout(3000),
        catchError(() => of(false)),
        map(authed => {
          if (!authed) {
            this.store.dispatch(new Logout());
            this.store.dispatch(new LoginRedirect());
            return false;
          }
          return true;
        }),
        switchMap(user => this.menuService.defineMainMenu().pipe(take(1))),
        map(menu => {
          AuthService.redirectURL = AuthService.redirectURL.split('#')[0].split('?')[0];
          const redirectUrl = AuthService.redirectURL.lastIndexOf('/') > 0 ? ('/' + AuthService.redirectURL.split('/')[1]) : AuthService.redirectURL;
          if (menu.length > 0 && menu.map(m => m.route).includes(redirectUrl)) {
            this.router.navigate([AuthService.redirectURL], {queryParams: {uid: WSLUtils.randomID()}, queryParamsHandling: 'merge'});
          } else if (menu.length > 0 && menu[0].name !== 'DEV') {
            this.router.navigate([menu[0].route], {queryParams: {uid: WSLUtils.randomID()}, queryParamsHandling: 'merge'});
          } else {
            return false;
          }
          /*if (menu.length > 0) {
            this.router.navigateByUrl(menu[0].route);
          }*/
          return true;
        })
      );
  }

}

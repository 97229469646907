/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../dist/wsl-auth/wsl-auth.ngfactory";
import * as i2 from "wsl-auth";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "../../../../../../../dist/wsl-shared/wsl-shared.ngfactory";
import * as i6 from "wsl-shared";
import * as i7 from "./reset-password.page";
import * as i8 from "@ngrx/store";
import * as i9 from "@ngrx/effects";
import * as i10 from "../../services/auth.service";
var styles_ResetPasswordPage = [];
var RenderType_ResetPasswordPage = i0.ɵcrt({ encapsulation: 2, styles: styles_ResetPasswordPage, data: {} });
export { RenderType_ResetPasswordPage as RenderType_ResetPasswordPage };
function View_ResetPasswordPage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "wsl-reset-password-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_WSLResetPasswordFormComponent_0, i1.RenderType_WSLResetPasswordFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.WSLResetPasswordFormComponent, [], { pending: [0, "pending"], asyncErrors: [1, "asyncErrors"] }, { submitted: "submitted" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.pending$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.errors$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ResetPasswordPage_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "wsl-flex-spaceBetween_xs-Cols-ng wsl-m-t-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 1), (_l()(), i0.ɵted(-1, null, ["\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "/login"); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ResetPasswordPage_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "wsl-guest-layout", [["pageTitle", "\u0421\u0431\u0440\u043E\u0441 \u043F\u0430\u0440\u043E\u043B\u044F"]], null, null, null, i5.View_WSLGuestLayoutComponent_0, i5.RenderType_WSLGuestLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i6.WSLGuestLayoutComponent, [], { title: [0, "title"], subTitle: [1, "subTitle"], pageTitle: [2, "pageTitle"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ResetPasswordPage_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ResetPasswordPage_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.systemTitle; var currVal_1 = _co.systemSubTitle; var currVal_2 = "\u0421\u0431\u0440\u043E\u0441 \u043F\u0430\u0440\u043E\u043B\u044F"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.canReset; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.canReset; _ck(_v, 5, 0, currVal_4); }, null); }
export function View_ResetPasswordPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wsl-reset-password", [], null, null, null, View_ResetPasswordPage_0, RenderType_ResetPasswordPage)), i0.ɵdid(1, 245760, null, 0, i7.ResetPasswordPage, [i8.Store, i9.Actions, i4.ActivatedRoute, i4.Router, i10.AuthService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResetPasswordPageNgFactory = i0.ɵccf("wsl-reset-password", i7.ResetPasswordPage, View_ResetPasswordPage_Host_0, {}, {}, []);
export { ResetPasswordPageNgFactory as ResetPasswordPageNgFactory };

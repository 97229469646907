import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IWSLDeviceConf } from '../../models/device-conf';
import { AddButtonType, WSLActionButtonType } from 'wsl-shared';
import {IWSLAsyncErrors} from 'wsl-core';
import {IWSLDeviceConfGroup} from '@app/device-conf-group/models/device-conf-group';

@Component({
  selector: 'wsl-device-conf-list',
  templateUrl: './device-conf-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfListComponent implements OnInit, OnChanges {
  @Input() group: IWSLDeviceConfGroup;
  @Input() deviceConfKits: Array<{ confs: IWSLDeviceConf[] }>;
  @Input() addConfKits: Array<{ confs: IWSLDeviceConf[] }>;
  @Input() checklist: number[] = [];
  @Input() checkable = false;
  @Input() readonly = false;
  @Input() pending: boolean;
  @Input() asyncErrors: IWSLAsyncErrors;
  @Output() check = new EventEmitter<{id: number, value: boolean}>();
  @Output() selectItem = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();

  /** @internal */
  actionButtons = [
   // {...AddButtonType}
  ];
  /** @internal */
  scrollTop = 0;
  showAddConfs = false;

  constructor(private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    if (!this.readonly) {
      this.actionButtons.push({...AddButtonType});
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group || changes.deviceConfKits) {
      if (this.showAddConfs) {
        this.showAddConfs = false;
      }
    }
  }

  onBtnClick(ev) {
    switch (ev) {
      case WSLActionButtonType.add:
        this.router.navigate(['/support/add_device_conf']);
        break;
    }
  }

  onScroll(st) {
    this.scrollTop = st;
  }

  trackByConfId(index: number, obj: { confs: IWSLDeviceConf[] }) {
    return obj.confs.reduce((pv, cv) => pv + '_' + cv.id, '');
  }

  trackById(index: number, obj: IWSLDeviceConf): any {
    return obj.id;
  }

  onShowAddConf() {
    this.showAddConfs = true;
  }
}

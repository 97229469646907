import { IWSLBillingCompanyLink } from '@app/billing-company/models/billing-company-link';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IWSLAsyncErrors } from 'wsl-core';
import { BillingCompanyLinkActions, BillingCompanyLinkActionTypes } from './billing-company-link.actions';

export interface State extends EntityState<IWSLBillingCompanyLink> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
  clear: boolean;
}

export const adapter: EntityAdapter<IWSLBillingCompanyLink> = createEntityAdapter<IWSLBillingCompanyLink>({
  selectId: (item: IWSLBillingCompanyLink) => item.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0,
  clear: true
});

export function reducer(state = initialState, action: BillingCompanyLinkActions): State {
  switch (action.type) {

    case BillingCompanyLinkActionTypes.LoadBCompanyLinks:
      return {...state, clear: action.payload.offset === 0 || !action.payload.offset, pending: true, loaded: false};

    case BillingCompanyLinkActionTypes.LoadBCompanyLinksSuccess:
      if (state.clear) {
        return adapter.addAll(action.payload.items,
          adapter.removeAll({
            ...state,
            totalCount: action.payload.count,
            pending: false,
            loaded: true
          }));
      }
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case BillingCompanyLinkActionTypes.LoadBCompanyLinksFail:
      if (state.clear) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          errors: action.payload.asyncMessages,
          pending: false,
          loaded: true
        });
      }
      return {...state, errors: action.payload.asyncMessages, pending: false, loaded: true};


    default:
      return state;
  }
}

export const getTotalCount = (state: State) => state.totalCount;
export const getLoaded = (state: State) => state.loaded;
export const getPending = (state: State) => state.pending;

const {selectIds, selectEntities, selectAll, selectTotal} = adapter.getSelectors();

export const getBCompanyLinkEntities = selectEntities;
export const getBCompanyLinkAll = selectAll;

import {NgModule, Optional, SkipSelf} from '@angular/core';
import {DeviceModule} from '@app/device/device.module';
import {ObjectModule} from '@app/object/object.module';
import {AvailableDataPageGuard} from '@core/guards/available-data-page.guard';
import {HasBillingGuard} from '@core/guards/has-billing.guard';
import {MainPageGuard} from '@core/guards/main-page.guard';
import {ConfirmRegistrationPage} from '@core/pages/confirm-registration/confirm-registration.page';
import {RegistrationPage} from '@core/pages/registration/registration.page';
import {LoraResolver} from '@core/resolvers/lora.resolver';
import {DefineDataFilterService} from '@core/services/define-data-filter.service';
import {LoraServerService} from '@core/services/lora-server.service';
import {MenuHelperService} from '@core/services/menu-helper.service';
import {UnbalanceSettingsService} from '@core/services/unbalance-settings.service';
import {WslSharedModule} from 'wsl-shared';
import {WslAuthModule} from 'wsl-auth';
import {UserModule} from '@app/user/user.module';
import {LoginPage} from '@core/pages/login/login.page';
import {ForgotPasswordPage} from '@core/pages/forgot-password/forgot-password.page';
import {ResetPasswordPage} from '@core/pages/reset-password/reset-password.page';
import {NotFoundPage} from '@core/pages/not-found/not-found.page';
import {ProfileSidenavComponent} from '@core/components/profile-sidenav/profile-sidenav.component';
import {UserProfileSettingsComponent} from '@core/components/user-profile-settings/user-profile-settings.component';
import {UserCompanyProfileComponent} from '@core/components/user-company-profile/user-company-profile.component';
import {EnvironmentSetupComponent} from '@core/components/environment-setup/environment-setup.component';
import {StoreHelperService} from '@core/services/store-helper.service';
import {AuthService} from '@core/services/auth.service';
import {CompanyProfileService, WslEkCoreModule} from 'wsl-ek-core';
import {TownService} from '@core/services/town.service';
import {AlertService} from '@core/services/alert.service';
import {TimeZoneService} from '@core/services/time-zone.service';
import {AuthorizedGuard} from '@core/guards/authorized.guard';
import {TechSupportGuard} from '@core/guards/tech-support.guard';
import {ResourceVariableResolver} from '@core/resolvers/resource-variable.resolver';
import {DataProviderGuard} from '@core/guards/data-provider.guard';
import {ResourceValueResolver} from '@core/resolvers/resource-value.resolver';
import {UcGuard} from '@core/guards/uc.guard';
import {UnauthorizedGuard} from '@core/guards/unauthorized.guard';
import {ResourceUnitResolver} from '@core/resolvers/resource-unit.resolver';
import {TownResolver} from '@core/resolvers/town.resolver';
import {AlertResolver} from '@core/resolvers/alert.resolver';
import {TimezoneResolver} from '@core/resolvers/timezone.resolver';


@NgModule({
  imports: [
    WslSharedModule,
    WslAuthModule,
    WslEkCoreModule,
    UserModule,
    DeviceModule,
    ObjectModule,
  ],
  declarations: [
    LoginPage,
    ForgotPasswordPage,
    ResetPasswordPage,
    RegistrationPage,
    ConfirmRegistrationPage,
    NotFoundPage,
    ProfileSidenavComponent,
    UserProfileSettingsComponent,
    UserCompanyProfileComponent,
    EnvironmentSetupComponent
  ],
  exports: [
    ProfileSidenavComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot() {
    return {
      ngModule: CoreModule,
      providers: [
        StoreHelperService,
        AuthService,
        CompanyProfileService,
        TownService,
        AlertService,
        TimeZoneService,
        LoraServerService,
        UnbalanceSettingsService,
        MenuHelperService,
        DefineDataFilterService,

        AuthorizedGuard,
        UnauthorizedGuard,
        UcGuard,
        TechSupportGuard,
        DataProviderGuard,
        HasBillingGuard,
        AvailableDataPageGuard,
        MainPageGuard,

        ResourceUnitResolver,
        ResourceValueResolver,
        ResourceVariableResolver,
        TownResolver,
        AlertResolver,
        TimezoneResolver,
        LoraResolver
      ],
    };
  }
}

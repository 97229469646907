<wsl-error-message [message]="asyncErrors?.get('common')"></wsl-error-message>

<form class="wsl-bg-white wsl-p-x-20 wsl-p-y-25 z-depth-1 wsl-hide-bg-xs"
      [formGroup]="form"
      (ngSubmit)="submit()" >
  <wsl-input-wrapper label="Придумайте себе пароль"
                     inputId="password"
                     [required]="true"
                     [asyncError]="asyncErrors?.get('password')">
    <input type="password"
           id="password"
           class="form-control"
           formControlName="password">
  </wsl-input-wrapper>

  <div class="wsl-p-a-5"></div>

  <wsl-input-wrapper label="Пароль еще раз"
                     inputId="confirm_password"
                     [required]="true"
                     [asyncError]="asyncErrors?.get('confirm_password')">
    <input id="profile_confirm_password"
           type="password"
           class="form-control"
           formControlName="confirm_password">
    <div class="wsl-abs wsl-w-100" *ngIf="form.get('confirm_password').touched">
      <div class="wsl-small wsl-red wsl-text-right" *ngIf="form.hasError('mismatchedPasswords')">
        Пароли не совпадают
      </div>
    </div>
  </wsl-input-wrapper>

  <div class="wsl-p-a-5"></div>

  <div class="wsl-flex-column-ng wsl-m-t-20">
    <button class="btn waves-effect waves-light" type="submit">Сохранить новый пароль</button>
  </div>
</form>

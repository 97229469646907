import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IWSLAuthenticate, IWSLConfirmRegistration, IWSLForgotPassword, IWSLRegistration, IWSLResetPassword } from 'wsl-auth';
import { Observable, of } from 'rxjs';
import { IWSLHttpResponse } from 'wsl-core';

@Injectable()
export class AuthService {
  public static redirectURL = window ? window.location.pathname.replace('//', '/') : '/';

  private baseURL = `authenticate`;

  constructor(private http: HttpClient) {
  }

  login(data: IWSLAuthenticate) {
    return this.http.post(`${this.baseURL}`, data);
  }

  logout() {
    return of(true);
  }

  forgot(data: IWSLForgotPassword): Observable<IWSLHttpResponse<IWSLForgotPassword>> {
    return this.http.post(`${this.baseURL}/forgot`, data);
  }

  reset(data: IWSLResetPassword): Observable<IWSLHttpResponse<IWSLResetPassword>> {
    return this.http.post<IWSLHttpResponse<IWSLResetPassword>>(`${this.baseURL}/reset`, data);
  }

  checkReset(data: any) {
    return this.http.post(`${this.baseURL}/check_reset`, data);
  }

  refreshToken(data) {
    return this.http.post(`${this.baseURL}/refresh_token`, data);
  }

  loginAsHomeUser(owner_user_id: number) {
    return this.http.post(`${this.baseURL}_c/refresh_token`, {owner_user_id});
  }

  registration(data: IWSLRegistration): Observable<IWSLHttpResponse<IWSLRegistration>> {
    return this.http.post<IWSLHttpResponse<IWSLRegistration>>(`${this.baseURL}/registration`, data);
     // .pipe(map(resp => resp.token));
  }

  confirmRegistration(data: IWSLConfirmRegistration): Observable<IWSLHttpResponse<IWSLConfirmRegistration>> {
    return this.http.post<IWSLHttpResponse<IWSLConfirmRegistration>>(`${this.baseURL}/check_email`, data);
  }

  public getToken() {
    return this.http.post(`${this.baseURL}/refresh_token_report`, {});
  }

}

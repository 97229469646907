/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../dist/wsl-auth/wsl-auth.ngfactory";
import * as i2 from "wsl-auth";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./registration.page";
import * as i6 from "@ngrx/store";
var styles_RegistrationPage = [];
var RenderType_RegistrationPage = i0.ɵcrt({ encapsulation: 2, styles: styles_RegistrationPage, data: {} });
export { RenderType_RegistrationPage as RenderType_RegistrationPage };
export function View_RegistrationPage_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 22, "main", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 21, "div", [["class", "wsl-m-t-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 20, "div", [["class", "wsl-flex-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 19, "div", [["class", "wsl-col-4_md-5_sm-6_xs-12 wsl-text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 9, "div", [["class", "wsl-flex-center-middle_xs-Cols-ng wsl-m-b-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "a", [["class", "wsl-flex-middle-ng wsl-st4"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u042D\u043D\u0435\u0440\u0433\u043E"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "wsl-thin"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u041A\u0430\u0431\u0438\u043D\u0435\u0442"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "wsl-gray wsl-m-x-10 wsl-st4 wsl-thin wsl-hide-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["|"])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "wsl-gray wsl-t4 wsl-thin"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044F"])), (_l()(), i0.ɵeld(14, 0, null, null, 3, "wsl-registration-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_WSLRegistrationFormComponent_0, i1.RenderType_WSLRegistrationFormComponent)), i0.ɵdid(15, 114688, null, 0, i2.WSLRegistrationFormComponent, [i0.ChangeDetectorRef], { showCodeActivation: [0, "showCodeActivation"], pending: [1, "pending"], asyncErrors: [2, "asyncErrors"] }, { submitted: "submitted" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(18, 0, null, null, 4, "div", [["class", "wsl-flex-spaceBetween_xs-Cols-ng wsl-m-t-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 3, "a", [["class", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(20, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(21, 1), (_l()(), i0.ɵted(-1, null, ["\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCodeActivation; var currVal_1 = i0.ɵunv(_v, 15, 1, i0.ɵnov(_v, 16).transform(_co.pending$)); var currVal_2 = i0.ɵunv(_v, 15, 2, i0.ɵnov(_v, 17).transform(_co.errors$)); _ck(_v, 15, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _ck(_v, 21, 0, "/login"); _ck(_v, 20, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 20).target; var currVal_4 = i0.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_3, currVal_4); }); }
export function View_RegistrationPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wsl-home-registration-page", [], null, null, null, View_RegistrationPage_0, RenderType_RegistrationPage)), i0.ɵdid(1, 114688, null, 0, i5.RegistrationPage, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistrationPageNgFactory = i0.ɵccf("wsl-home-registration-page", i5.RegistrationPage, View_RegistrationPage_Host_0, {}, {}, []);
export { RegistrationPageNgFactory as RegistrationPageNgFactory };

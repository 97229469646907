import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { IWSLObject } from '@app/object/models/object';
import { ObjectService } from '@app/object/services/object.service';
import { select, Store } from '@ngrx/store';
import { SetSnackBarMessage } from '@store/actions/app.actions';
import { LoadObjectsSuccess } from '@store/object/object.actions';
import * as fromObject from '@store/object/object.reducer';
import * as fromRoot from '@store/reducers';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { IWSLHttpResponse, WSLHttpHelper } from 'wsl-core';

export let WSL_HAS_OBJECTS_GUARD_TIMEOUT = new InjectionToken<number>('WSL_HAS_OBJECTS_GUARD_TIMEOUT');

@Injectable()
export class HasObjectsGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>,
              private objectService: ObjectService,
              @Optional() @Inject(WSL_HAS_OBJECTS_GUARD_TIMEOUT) private waitTimeout: number = 100) {
  }

  /**
   * This method creates an observable that waits for the `loaded` property
   * of the collection state to turn `true`, emitting one time once loading
   * has finished.
   */
  waitForCollectionToLoad(): Observable<boolean> {
    return this.store$
      .pipe(
        select(fromObject.selectObjectLoaded),
        filter(loaded => loaded),
        take(1),
        timeout(this.waitTimeout),
        catchError(() => of(false))
      );
  }

  /**
   * This method checks if a device with the given ID is already registered
   * in the Store
   */
  hasInStore(): Observable<boolean> {
    return this.store$
      .pipe(
        select(fromObject.selectObjectAll),
        map(entities => {
          return entities.length > 0;
        }),
        take(1)
      );
  }

  /**
   * This method loads a device with the given ID from the API and caches
   * it in the store, returning `true` or `false` if it was found.
   */
  hasInApi(): Observable<boolean> {
    return this.objectService
      .getMany({offset: 0})
      .pipe(
        // map(deviceEntity => new LoadDeviceSuccess(deviceEntity)),
        //  tap((action: LoadDeviceSuccess) => this.store.dispatch(action)),
        tap(resp => this.store$.dispatch(new LoadObjectsSuccess(resp))),
        map((resp: IWSLHttpResponse<IWSLObject>) => resp.items),
        map(entities => entities.length > 0),
        catchError((response) => {
          return of(false);
        })
      );
  }

  /**
   * `hasEntity` composes `hasInStore` and `hasInApi`. It first checks
   * if the device is in store, and if not it then checks if it is in the
   * API.
   */
  hasEntities(collectionLoaded: boolean): Observable<boolean> {
    if (collectionLoaded) {
      return this.hasInStore()
        .pipe(
          switchMap(inStore => {
            if (inStore) {
              return of(inStore);
            }

            return this.hasInApi();
          }));
    }
    return this.hasInApi();
  }

  check(): Observable<boolean> {
    return this.waitForCollectionToLoad()
      .pipe(switchMap((loaded) => this.hasEntities(loaded)));
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check()
      .pipe(
        tap(res => {
          if (!res) {
            this.store$.dispatch(new SetSnackBarMessage(WSLHttpHelper.parseAsyncMessage('Нет объектов')));
          }
        })
      );
  }
}

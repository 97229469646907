import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isSameDay } from 'date-fns';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MomentDateFormat, WSLFormHelper, WSLUtils } from 'wsl-core';
import { ArchiveType, ArchiveTypes, DataArchiveType, DataArchiveTypes } from 'wsl-ek-core';
// import * as moment from 'moment';
var SelectPeriodComponent = /** @class */ (function () {
    function SelectPeriodComponent(chr) {
        this.chr = chr;
        this.classes = ['wsl-flex-bottom'];
        this.prefix = '';
        this.availablePeriodTypes = DataArchiveTypes.map(function (dt) { return dt.id; });
        this.showArchiveType = true;
        this.periodChanged = new EventEmitter();
        /** @internal */
        this.form = new FormGroup({
            by_period: new FormControl(false),
            type_id: new FormControl(null),
            period_id: new FormControl(DataArchiveType.month),
            date: new FormControl(''),
            hour: new FormControl(null),
            month: new FormControl(null),
            year: new FormControl(null)
        });
        /** @internal */
        this.archiveTypes = ArchiveTypes;
        /** @internal */
        this.periods = [];
        /** @internal */
        this.hours = [];
        /** @internal */
        this.months = [];
        /** @internal */
        this.years = [];
        this.maxDate = moment().format(MomentDateFormat); // format(new Date(), DateFormat);
        this.ngUnsubscribe = new Subject();
    }
    SelectPeriodComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initYears();
        this.initMonths();
        this.initHours();
        this.form.get('type_id').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe(function (val) {
            _this.definePeriods();
            _this.chr.markForCheck();
        });
        this.form.get('period_id').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe(function (val) {
            if ([DataArchiveType.hour, DataArchiveType.hour_int].includes(_this.form.get('period_id').value) && _this.form.get('by_period').value) {
                _this.initHours();
                _this.chr.markForCheck();
            }
            if ([DataArchiveType.month, DataArchiveType.month_int].includes(_this.form.get('period_id').value) && _this.form.get('by_period').value) {
                _this.initMonths();
                _this.chr.markForCheck();
            }
            if ([DataArchiveType.day, DataArchiveType.day_int].includes(_this.form.get('period_id').value) && !_this.form.get('by_period').value) {
                _this.initMonths();
                _this.chr.markForCheck();
            }
        });
        this.form.get('date').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe(function (val) {
            if ([DataArchiveType.hour, DataArchiveType.hour_int].includes(_this.form.get('period_id').value) && _this.form.get('by_period').value) {
                _this.initHours();
                _this.chr.markForCheck();
            }
        });
        this.form.get('year').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe(function (val) {
            if ([DataArchiveType.month, DataArchiveType.month_int].includes(_this.form.get('period_id').value) && _this.form.get('by_period').value) {
                _this.initMonths();
                _this.chr.markForCheck();
            }
            if ([DataArchiveType.day, DataArchiveType.day_int].includes(_this.form.get('period_id').value) && !_this.form.get('by_period').value) {
                _this.initMonths();
                _this.chr.markForCheck();
            }
        });
        this.form.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), debounceTime(150), distinctUntilChanged())
            .subscribe(function (val) {
            if (_this.form.dirty) {
                if (WSLUtils.isDifferentObjects(_this.filter, val)) {
                    _this.periodChanged.emit(_this.form.value);
                }
            }
        });
        this.fillForm();
    };
    SelectPeriodComponent.prototype.ngOnChanges = function (changes) {
        if (changes.filter) {
            this.fillForm();
        }
        if (changes.availablePeriodTypes) {
            this.definePeriods();
        }
    };
    SelectPeriodComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(SelectPeriodComponent.prototype, "showPeriods", {
        get: function () {
            return this.periods.length > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectPeriodComponent.prototype, "showSelectYear", {
        get: function () {
            return this.form.get('by_period').value ? [
                DataArchiveType.month,
                DataArchiveType.month_int
            ].includes(this.form.get('period_id').value) : [
                DataArchiveType.day,
                DataArchiveType.day_int,
                DataArchiveType.month,
                DataArchiveType.month_int
            ].includes(this.form.get('period_id').value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectPeriodComponent.prototype, "showSelectMonth", {
        get: function () {
            return this.form.get('by_period').value ? [
                DataArchiveType.month,
                DataArchiveType.month_int
            ].includes(this.form.get('period_id').value) : [
                DataArchiveType.day,
                DataArchiveType.day_int,
            ].includes(this.form.get('period_id').value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectPeriodComponent.prototype, "showSelectDay", {
        get: function () {
            return this.form.get('by_period').value ? [
                DataArchiveType.day,
                DataArchiveType.day_int,
                DataArchiveType.hour,
                DataArchiveType.hour_int
            ].includes(this.form.get('period_id').value) : [
                DataArchiveType.hour,
                DataArchiveType.hour_int
            ].includes(this.form.get('period_id').value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectPeriodComponent.prototype, "showSelectHour", {
        get: function () {
            return this.form.get('by_period').value ? [
                DataArchiveType.hour,
                DataArchiveType.hour_int
            ].includes(this.form.get('period_id').value) : [].includes(this.form.get('period_id').value);
        },
        enumerable: true,
        configurable: true
    });
    SelectPeriodComponent.prototype.onSelectArchiveType = function (type_id) {
        if (this.form.get('type_id').value !== type_id) {
            this.form.get('type_id').setValue(type_id);
            this.form.get('type_id').markAsDirty();
        }
    };
    SelectPeriodComponent.prototype.onSelectPeriod = function (period_id) {
        if (this.form.get('period_id').value !== period_id) {
            this.form.get('period_id').setValue(period_id);
            this.form.get('period_id').markAsDirty();
        }
    };
    SelectPeriodComponent.prototype.trackById = function (index, obj) {
        return obj.id;
    };
    SelectPeriodComponent.prototype.fillForm = function () {
        if (this.form && this.filter && this.filter.by_period !== null && this.filter.period_id !== null) {
            WSLFormHelper.fillForm(this.form, this.filter);
            var dirty = false;
            if (this.form.pristine) {
                var today = moment(); // new Date();
                if ((!this.form.get('date').value || this.form.get('date').value === '') && (!this.filter.date || this.filter.date === '')) {
                    if (this.form.get('by_period').value) {
                        this.form.get('date').setValue(moment().subtract(1, 'day').format(MomentDateFormat), { emitEvent: false });
                    }
                    else {
                        this.form.get('date').setValue(today.format(MomentDateFormat), { emitEvent: false });
                    }
                    dirty = true;
                }
                if (this.form.get('year').value === null) {
                    if (this.form.get('by_period').value && today.month() === 0) {
                        this.form.get('year').setValue(moment().subtract(1, 'month').year(), { emitEvent: false });
                    }
                    else {
                        this.form.get('year').setValue(today.year(), { emitEvent: false });
                    }
                    dirty = true;
                }
                if (this.form.get('month').value === null) {
                    if (this.form.get('by_period').value /*&& today.date() < 2*/) {
                        this.form.get('month').setValue(moment().subtract(1, 'month').month());
                    }
                    else {
                        this.form.get('month').setValue(today.month());
                    }
                    dirty = true;
                }
                if (this.form.get('hour').value === null) {
                    if (this.form.get('by_period').value /* && today.hours() < 2*/) {
                        this.form.get('hour').setValue(moment().subtract(1, 'hour').hour());
                    }
                    else {
                        this.form.get('hour').setValue(today.hour());
                    }
                    dirty = true;
                }
            }
            this.definePeriods();
            if (!this.filter.period_id) {
                dirty = true;
            }
            this.form.markAsUntouched();
            this.form.markAsPristine();
            if (dirty) {
                this.periodChanged.emit(this.form.value);
            }
        }
    };
    SelectPeriodComponent.prototype.definePeriods = function () {
        var _this = this;
        switch (this.form.get('type_id').value) {
            case ArchiveType.interval:
                if (this.form.get('by_period').value) {
                    this.periods = [
                        { id: DataArchiveType.hour, name: 'за час' },
                        { id: DataArchiveType.day, name: 'за день' },
                        { id: DataArchiveType.month, name: 'за месяц' }
                    ];
                }
                else {
                    this.periods = [
                        { id: DataArchiveType.hour, name: 'по часам' },
                        { id: DataArchiveType.day, name: 'по дням' },
                        { id: DataArchiveType.month, name: 'по месяцам' }
                    ];
                }
                break;
            case ArchiveType.integral:
                if (this.form.get('by_period').value) {
                    this.periods = [
                        { id: DataArchiveType.hour_int, name: 'за час' },
                        { id: DataArchiveType.day_int, name: 'за день' },
                        { id: DataArchiveType.month_int, name: 'за месяц' }
                    ];
                }
                else {
                    this.periods = [
                        { id: DataArchiveType.hour_int, name: 'по часам' },
                        { id: DataArchiveType.day_int, name: 'по дням' },
                        { id: DataArchiveType.month_int, name: 'по месяцам' }
                    ];
                }
                break;
        }
        this.periods = this.periods.filter(function (p) { return _this.availablePeriodTypes.some(function (t) { return t === p.id; }); });
        this.periods = this.periods.slice(0);
        if (this.periods.length === 0) {
            this.form.get('period_id').setValue(null, { emitEvent: false });
            // this.form.get('type_id').setValue(null, {emitEvent: false});
        }
        this.archiveTypes = ArchiveTypes
            .filter(function (at) { return DataArchiveTypes
            .filter(function (dt) { return _this.availablePeriodTypes.includes(dt.id); })
            .some(function (dt) { return dt.type === at.id; }); });
        if (this.periods.length > 0 && !this.periods.map(function (p) { return p.id; }).includes(this.form.get('period_id').value)) {
            this.revertPeriod();
        }
    };
    SelectPeriodComponent.prototype.revertPeriod = function () {
        var period = null;
        switch (this.form.get('period_id').value) {
            case DataArchiveType.hour:
                period = DataArchiveType.hour_int;
                break;
            case DataArchiveType.hour_int:
                period = DataArchiveType.hour;
                break;
            case DataArchiveType.day:
                period = DataArchiveType.day_int;
                break;
            case DataArchiveType.day_int:
                period = DataArchiveType.day;
                break;
            case DataArchiveType.month:
                period = DataArchiveType.month_int;
                break;
            case DataArchiveType.month_int:
                period = DataArchiveType.month;
                break;
        }
        if (!this.periods.map(function (p) { return p.id; }).includes(period)) {
            period = this.periods[0].id;
        }
        this.form.get('period_id').setValue(period, { emitEvent: false });
    };
    SelectPeriodComponent.prototype.initHours = function () {
        var curDate = isSameDay(new Date(), new Date(this.form.get('date').value));
        // moment().isSame(moment(this.form.get('date').value, DateFormat), 'day');
        this.hours = [];
        var max = /*curDate ? moment().hour() :*/ 24;
        for (var i = 0; i < max; i++) {
            this.hours.push({ id: i, name: i + " - " + (i + 1) });
        }
        if (curDate && this.form.get('hour').value > this.hours[max - 1].id) {
            this.form.get('hour').setValue(this.hours[max - 1].id);
        }
    };
    SelectPeriodComponent.prototype.initMonths = function () {
        var curMonth = moment().month(); // new Date().getMonth();
        var curYear = moment().year() === this.form.get('year').value; // new Date().getFullYear() === this.form.get('year').value;
        var max = curYear ? curMonth + 1 : 12;
        this.months = [];
        for (var i = 0; i < max; i++) {
            var date = moment().month(i);
            // date.setMonth(i); // moment().month(i);
            this.months.push({
                id: date.month(),
                name: date.format('MMMM') // format(date, 'LLLL', {locale: ruLocale}) // date.format('MMMM')
            });
        }
        if (curYear && (this.form.get('month').value === null || this.form.get('month').value > curMonth)) { // moment().month()) {
            this.form.get('month').setValue(curMonth); // moment().month()); // this.months[max - 1].id);
        }
        else if (!this.months.map(function (m) { return m.id; }).includes(this.form.get('month').value)) {
            this.form.get('month').setValue(this.months[0].id);
        }
        this.form.get('month').markAsDirty();
    };
    SelectPeriodComponent.prototype.initYears = function () {
        this.years = [];
        var curYear = moment().year(); // new Date().getFullYear(); // moment().year();
        for (var i = curYear; i >= 2017; i--) {
            this.years.unshift({ id: i, name: i });
        }
        if (!this.form.get('year').value) {
            this.form.get('year').setValue(this.years[this.years.length - 1].id);
            this.form.get('year').markAsDirty();
        }
    };
    return SelectPeriodComponent;
}());
export { SelectPeriodComponent };

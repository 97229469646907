
    <ng-container *ngIf="showMessage && messages.length > 0">
      <ng-container *ngIf="messages.length == 1; else elseBlock">
        <span class="wsl-red" *ngFor="let msg of messages" [innerHtml]="msg"></span>
      </ng-container>
      <ng-template #elseBlock>
        <span class="wsl-red">есть ошибки!</span>
        <a class="wsl-hover" [attr.data-activates]="'dropdownErrors'+uniqID">смотреть</a>
        <!-- Dropdown Structure -->
        <ul [attr.id]="'dropdownErrors'+uniqID" class='dropdown-content wsl-p-a-10' style="min-width: 290px;">
          <li *ngFor="let msg of messages" [innerHtml]="msg"></li>
        </ul>
      </ng-template>
    </ng-container>
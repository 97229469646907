import { Injectable } from '@angular/core';
import { DBDateTimeFormat, IWSLHttpResponse, WSLLocalStorageService } from 'wsl-core';
import { IWSLCommunicator } from 'wsl-device';
import { ApiHttpClientWithPermissionsService, CommunicatorPermissions, UserProfileService, IWSLEntityPermissions } from 'wsl-ek-core';
import { IWSLCommunicatorFilter } from '../models/communicator-filter';
import { HttpClient } from '@angular/common/http';
import { ConnectionType, ConnectionTypes } from '../enums/connection-type.enum';
import { StorageKey } from 'wsl-ek-core';
import { format, isSameHour } from 'date-fns';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CommunicatorService extends ApiHttpClientWithPermissionsService<IWSLCommunicator, IWSLCommunicatorFilter> {

  constructor(public http: HttpClient, private localStorage: WSLLocalStorageService, public userProfileService: UserProfileService) {
    super(http, 'communicator', <IWSLEntityPermissions>CommunicatorPermissions, userProfileService);
  }

  public static getConnectionType(communicator: IWSLCommunicator) {
    switch (communicator.network.channel_id) {
      case ConnectionType.tcp:
      case ConnectionType.tcp_gprs:
        return ConnectionTypes.find(nt => nt.id === ConnectionType.tcp);
      case ConnectionType.gprs:
        return ConnectionTypes.find(nt => nt.id === ConnectionType.gprs);
      case ConnectionType.lora:
        return ConnectionTypes.find(nt => nt.id === ConnectionType.lora);
      default:
        return null;
    }
  }

  public static getConnectionParameters(communicator: IWSLCommunicator) {
    let network_parameters = '';
    switch (communicator.network.channel_id) {
      case ConnectionType.tcp:
        network_parameters = `tcp ip ${communicator.network.tcp.ip}:${communicator.network.tcp.port}`;
        break;
      case ConnectionType.tcp_gprs:
        network_parameters = `tcp ip ${communicator.network.tcp.ip}:${communicator.network.tcp.port}`;
        if (communicator.network.gprs.id1) {
          network_parameters += `, gprs ${communicator.network.gprs.id1}`;
        }
        break;
      case ConnectionType.gprs:
        network_parameters = `gprs id1 ${communicator.network.gprs.id1}`;
        if (communicator.network.gprs.id2) {
          network_parameters += `, id2 ${communicator.network.gprs.id2}`;
        }
        break;
      case ConnectionType.lora:
        network_parameters = `lora ${communicator.network.lora.devEUI}`;
        break;
      default:
        return null;
    }
    return network_parameters;
  }

  public getGPRSGate() {
    const dict = this.localStorage.get(StorageKey.gprs_gate);
    if (dict != null && isSameHour(new Date(), new Date(dict.time))) {
      return of(dict);
    }
    return this.http.get<IWSLHttpResponse<any>>('gprs_gate')
      .pipe(map(data => {
        this.localStorage.set(StorageKey.gprs_gate, {
          time: format(new Date(), DBDateTimeFormat),
          item: data.item
        });
        return data;
      }));
  }

}

<!--Left static column. Navigation.-->
<div class="wsl-hide-xs wsl-hide-sm wsl-navigation-buttons" [class.with-scrollTop]="!hideScrollTop">
    <div class="">
        <!--<a class="wsl-btn-round wsl-bg-gray_hover wsl-white z-depth-1 wsl-m-b-10"
           *ngIf="showBack"
           [routerLink]="[backURL]"
           matTooltip="Назад" matTooltipPosition="right">
            <span class="icon-chevron-left"></span>
        </a>-->

        <a *ngFor="let nav of allNavs; trackBy: trackByRoute" class="wsl-btn-round wsl-bg-gray_hover wsl-white z-depth-1 wsl-m-b-10"
           routerLinkActive="wsl-bg-orange_nohover" [routerLinkActiveOptions]="{exact:true}"
           #rla="routerLinkActive"
           routerLink="{{nav.route}}"
           [matTooltip]="nav.name" matTooltipPosition="right">
            <span [ngClass]="nav.icon"></span>
        </a>
    </div>

    <div></div>
</div>

<wsl-fab-button class="wsl-show-sm wsl-show-xs nav-fab-xs"  *ngIf="!!navs && navs.length > 1"
                [wrapperStyles]="{'bottom': '15px', 'left': '15px', 'right': 'auto'}"
                [ulStyles]="{'left': '0px', 'right': 'auto', 'bottom': '40px'}"
                [fabActionClasses]="'wsl-bg-gray_hover'"
                [icon]="'icon-menu'"
                [isNav]="true"
                [direction]="'top'"
                [buttons]="allNavs"></wsl-fab-button>


<div class="wsl-show-sm wsl-show-xs fixed-action-btn" style="bottom: 15px; left: 15px; right: auto;" *ngIf="!!navs && navs.length < 2">
    <a class="btn-floating wsl-bg-gray_hover"
       *ngIf="showBack"
       [routerLink]="[backURL]"
       matTooltip="Назад" matTooltipPosition="right"><span class="icon-chevron-left"></span>
    </a>
    <a class="btn-floating" *ngIf="!!navs && navs.length === 1"
       routerLinkActive
       [routerLinkActiveOptions]="{exact:true}"
       #rla="routerLinkActive"
       [ngClass]="{'wsl-bg-gray_hover':!rla.isActive,'wsl-bg-orange':rla.isActive}"
       routerLink="{{navs[0].route}}"
       [matTooltip]="navs[0].name" matTooltipPosition="right">
        <span [ngClass]="navs[0].icon"></span>
    </a>
</div>

<style>
    .nav-fab-xs .fixed-action-btn.active ul {
        position: fixed;
        left: 0px;
        right: auto;
        bottom: 0px;
        margin-right: 0px;
        margin-top: -20px;
        overflow-y: auto;
        height: calc(100% - 44px);
        width: 67px;
        background: #fff;
        border-bottom: 65px solid #eee;
    }
</style>

import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLDeviceSensor, IWSLDeviceSensorFilter } from 'wsl-device';

export enum DeviceSensorActionTypes {
  AddDeviceSensor = '[DeviceSensor] Add',
  AddDeviceSensorSuccess = '[DeviceSensor] Add Success',
  AddDeviceSensorFail = '[DeviceSensor] Add Fail',

  UpdateDeviceSensor = '[DeviceSensor] Update',
  UpdateDeviceSensorSuccess = '[DeviceSensor] Update Success',
  UpdateDeviceSensorFail = '[DeviceSensor] Update Fail',

  DeleteDeviceSensor = '[DeviceSensor] Delete',
  DeleteDeviceSensorSuccess = '[DeviceSensor] Delete Success',
  DeleteDeviceSensorFail = '[DeviceSensor] Delete Fail',

  DeleteDeviceSensors = '[DeviceSensor] Delete Many',
  DeleteDeviceSensorsSuccess = '[DeviceSensor] Delete Many Success',
  DeleteDeviceSensorsFail = '[DeviceSensor] Delete Many Fail',

  SelectDeviceSensor = '[DeviceSensor] Select',

  LoadDeviceSensor = '[DeviceSensor] Load',
  LoadDeviceSensorSuccess = '[DeviceSensor] Load Success',
  LoadDeviceSensorFail = '[DeviceSensor] Load Fail',

  LoadDeviceSensors = '[DeviceSensor] Load Many',
  LoadDeviceSensorsSuccess = '[DeviceSensor] Load Many Success',
  LoadDeviceSensorsFail = '[DeviceSensor] Load Many Fail',

  UpsertDeviceSensors = '[DeviceSensor] Upsert Many',
  UpsertDeviceSensorsSuccess = '[DeviceSensor] Upsert Many Success',
  UpsertDeviceSensorsFail = '[DeviceSensor] Upsert Many Fail',

  ClearDeviceSensors = '[DeviceSensor] Clear'
}

/** Add */
export class AddDeviceSensor implements Action {
  readonly type = DeviceSensorActionTypes.AddDeviceSensor;

  constructor(public payload: IWSLDeviceSensor) {
  }
}

export class AddDeviceSensorSuccess implements Action {
  readonly type = DeviceSensorActionTypes.AddDeviceSensorSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

export class AddDeviceSensorFail implements Action {
  readonly type = DeviceSensorActionTypes.AddDeviceSensorFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

/** Update */
export class UpdateDeviceSensor implements Action {
  readonly type = DeviceSensorActionTypes.UpdateDeviceSensor;

  constructor(public payload: IWSLDeviceSensor) {
  }
}

export class UpdateDeviceSensorSuccess implements Action {
  readonly type = DeviceSensorActionTypes.UpdateDeviceSensorSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

export class UpdateDeviceSensorFail implements Action {
  readonly type = DeviceSensorActionTypes.UpdateDeviceSensorFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

/** Delete */
export class DeleteDeviceSensor implements Action {
  readonly type = DeviceSensorActionTypes.DeleteDeviceSensor;

  constructor(public payload: number) {
  }
}

export class DeleteDeviceSensorSuccess implements Action {
  readonly type = DeviceSensorActionTypes.DeleteDeviceSensorSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

export class DeleteDeviceSensorFail implements Action {
  readonly type = DeviceSensorActionTypes.DeleteDeviceSensorFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

/** Delete Many */
export class DeleteDeviceSensors implements Action {
  readonly type = DeviceSensorActionTypes.DeleteDeviceSensors;

  constructor(public payload: number[]) {
  }
}

export class DeleteDeviceSensorsSuccess implements Action {
  readonly type = DeviceSensorActionTypes.DeleteDeviceSensorsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

export class DeleteDeviceSensorsFail implements Action {
  readonly type = DeviceSensorActionTypes.DeleteDeviceSensorsFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

/** Select */
export class SelectDeviceSensor implements Action {
  readonly type = DeviceSensorActionTypes.SelectDeviceSensor;

  constructor(public payload: number) {
  }
}

/** Load One */
export class LoadDeviceSensor implements Action {
  readonly type = DeviceSensorActionTypes.LoadDeviceSensor;

  constructor(public payload: number) {
  }
}

export class LoadDeviceSensorSuccess implements Action {
  readonly type = DeviceSensorActionTypes.LoadDeviceSensorSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

export class LoadDeviceSensorFail implements Action {
  readonly type = DeviceSensorActionTypes.LoadDeviceSensorFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

/** Load Many */
export class LoadDeviceSensors implements Action {
  readonly type = DeviceSensorActionTypes.LoadDeviceSensors;

  constructor(public payload: IWSLDeviceSensorFilter) {
  }
}

export class LoadDeviceSensorsSuccess implements Action {
  readonly type = DeviceSensorActionTypes.LoadDeviceSensorsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

export class LoadDeviceSensorsFail implements Action {
  readonly type = DeviceSensorActionTypes.LoadDeviceSensorsFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

/** Upsert Many */
export class UpsertDeviceSensors implements Action {
  readonly type = DeviceSensorActionTypes.UpsertDeviceSensors;

  constructor(public payload: IWSLDeviceSensor[]) {
  }
}

export class UpsertDeviceSensorsSuccess implements Action {
  readonly type = DeviceSensorActionTypes.UpsertDeviceSensorsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

export class UpsertDeviceSensorsFail implements Action {
  readonly type = DeviceSensorActionTypes.UpsertDeviceSensorsFail;

  constructor(public payload: IWSLHttpResponse<IWSLDeviceSensor>) {
  }
}

export class ClearDeviceSensors implements Action {
  readonly type = DeviceSensorActionTypes.ClearDeviceSensors;
}

export type DeviceSensorActions =
  AddDeviceSensor |
  AddDeviceSensorSuccess |
  AddDeviceSensorFail |

  UpdateDeviceSensor |
  UpdateDeviceSensorSuccess |
  UpdateDeviceSensorFail |

  DeleteDeviceSensor |
  DeleteDeviceSensorSuccess |
  DeleteDeviceSensorFail |

    DeleteDeviceSensors |
    DeleteDeviceSensorsSuccess |
    DeleteDeviceSensorsFail |

  SelectDeviceSensor |

  LoadDeviceSensor |
  LoadDeviceSensorSuccess |
  LoadDeviceSensorFail |

  LoadDeviceSensors |
  LoadDeviceSensorsSuccess |
  LoadDeviceSensorsFail |

  UpsertDeviceSensors |
  UpsertDeviceSensorsSuccess |
  UpsertDeviceSensorsFail |

  ClearDeviceSensors;


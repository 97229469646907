import * as tslib_1 from "tslib";
var _a;
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceConfActionTypes } from './device-conf.actions';
import { createEntityAdapter } from '@ngrx/entity';
export var adapter = createEntityAdapter({
    selectId: function (item) { return item.id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DeviceConfActionTypes.AddDeviceConf:
        case DeviceConfActionTypes.UpdateDeviceConf:
        case DeviceConfActionTypes.DeleteDeviceConf:
            return tslib_1.__assign({}, state, { pending: true, errors: null });
        case DeviceConfActionTypes.AddDeviceConfSuccess:
        case DeviceConfActionTypes.UpdateDeviceConfSuccess:
        case DeviceConfActionTypes.DeleteDeviceConfSuccess:
            return tslib_1.__assign({}, state, { pending: false });
        case DeviceConfActionTypes.AddDeviceConfFail:
        case DeviceConfActionTypes.UpdateDeviceConfFail:
        case DeviceConfActionTypes.DeleteDeviceConfFail:
            return tslib_1.__assign({}, state, { pending: false, errors: action.payload.asyncMessages });
        case DeviceConfActionTypes.LoadDeviceConf:
            return tslib_1.__assign({}, state, { 
                // selectedID: null,
                pending: true, loaded: false, errors: null });
        case DeviceConfActionTypes.LoadDeviceConfSuccess:
            return adapter.upsertOne(tslib_1.__assign({}, action.payload.item), tslib_1.__assign({}, state, { pending: false, loaded: true }));
        case DeviceConfActionTypes.LoadDeviceConfFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceConfActionTypes.SelectDeviceConf:
            return tslib_1.__assign({}, state, { selectedID: action.payload });
        case DeviceConfActionTypes.LoadDeviceConfs:
            if (!action.payload.offset) {
                return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: true, loaded: false, errors: null }));
            }
            return tslib_1.__assign({}, state, { pending: true, loaded: false, errors: null });
        case DeviceConfActionTypes.LoadDeviceConfsSuccess:
            return adapter.upsertMany(action.payload.items, tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true }));
        case DeviceConfActionTypes.LoadDeviceConfsFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceConfActionTypes.ClearDeviceConfs:
            return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: false, loaded: true }));
        default:
            return state;
    }
}
export var getSelectedID = function (state) { return state.selectedID; };
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
export var getErrors = function (state) { return state.errors; };
export var getDeviceConfState = createFeatureSelector('deviceConf');
export var selectDeviceConfSelectedID = createSelector(getDeviceConfState, getSelectedID);
export var selectDeviceConfTotalCount = createSelector(getDeviceConfState, getTotalCount);
export var selectDeviceConfLoaded = createSelector(getDeviceConfState, getLoaded);
export var selectDeviceConfPending = createSelector(getDeviceConfState, getPending);
export var selectDeviceConfErrors = createSelector(getDeviceConfState, getErrors);
export var selectDeviceConfEntities = (_a = adapter.getSelectors(getDeviceConfState), _a.selectEntities), selectDeviceConfAll = _a.selectAll;
export var selectDeviceConf = createSelector(selectDeviceConfEntities, selectDeviceConfSelectedID, function (entities, id) { return entities && entities[id] ? entities[id] : null; });

import { IWSLBCompanyAccount } from '@app/billing-company/models/b-company-account';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  BillingCompanyAccountActions,
  BillingCompanyAccountActionTypes
} from '@store/billing/billing-company-account.actions';
import { IWSLAsyncErrors } from 'wsl-core';

export interface State extends EntityState<IWSLBCompanyAccount> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
  clear: boolean;
}

export const adapter: EntityAdapter<IWSLBCompanyAccount> = createEntityAdapter<IWSLBCompanyAccount>({
  selectId: (item: IWSLBCompanyAccount) => item.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0,
  clear: true
});

export function reducer(state = initialState, action: BillingCompanyAccountActions): State {
  switch (action.type) {

    case BillingCompanyAccountActionTypes.AddBCompanyAccount:
    case BillingCompanyAccountActionTypes.UpdateBCompanyAccount:
    case BillingCompanyAccountActionTypes.DeleteBCompanyAccount:
      return {...state, pending: true, loaded: false};

    case BillingCompanyAccountActionTypes.AddBCompanyAccountSuccess:
    case BillingCompanyAccountActionTypes.AddBCompanyAccountFail:
    case BillingCompanyAccountActionTypes.UpdateBCompanyAccountSuccess:
    case BillingCompanyAccountActionTypes.UpdateBCompanyAccountFail:
    case BillingCompanyAccountActionTypes.DeleteBCompanyAccountSuccess:
    case BillingCompanyAccountActionTypes.DeleteBCompanyAccountFail:
      return {...state, pending: false, loaded: true};

    case BillingCompanyAccountActionTypes.LoadBCompanyAccounts:
      return {...state, clear: action.payload.offset === 0 || !action.payload.offset, pending: true, loaded: false};

    case BillingCompanyAccountActionTypes.LoadBCompanyAccountsSuccess:
      if (state.clear) {
        return adapter.addAll(action.payload.items,
          adapter.removeAll({
            ...state,
            totalCount: action.payload.count,
            pending: false,
            loaded: true
          }));
      }
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case BillingCompanyAccountActionTypes.LoadBCompanyAccountsFail:
      if (state.clear) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          errors: action.payload.asyncMessages,
          pending: false,
          loaded: true
        });
      }
      return {...state, errors: action.payload.asyncMessages, pending: false, loaded: true};


    default:
      return state;
  }
}

export const getTotalCount = (state: State) => state.totalCount;
export const getLoaded = (state: State) => state.loaded;
export const getPending = (state: State) => state.pending;

const {selectIds, selectEntities, selectAll, selectTotal} = adapter.getSelectors();

export const getBCompanyAccountEntities = selectEntities;
export const getBCompanyAccountAll = selectAll;

import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyRoleType, KV_ROLES } from 'wsl-ek-core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WSLFormHelper, WSLFormValidators, WSLUtils } from 'wsl-core';
import { EK_ROLES, OPERATOR_ROLES, TS_ROLES, UC_ROLES, HOME_ROLES, UserPermissions, UserProfileService, UserRoleType } from 'wsl-ek-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { CompanyProfileService } from 'wsl-ek-core';
import { environment } from '@env/environment';
var UserProfileSettingsComponent = /** @class */ (function () {
    function UserProfileSettingsComponent(userProfileService, companyService, chr) {
        this.userProfileService = userProfileService;
        this.companyService = companyService;
        this.chr = chr;
        this.save = new EventEmitter();
        this.load = new EventEmitter();
        /** @internal */
        this.form = new FormGroup({
            id: new FormControl(null),
            default_company_id: new FormControl(null),
            default_town_id: new FormControl(null),
            default_room_id: new FormControl(null),
            role_ids: new FormControl([]),
            role_controller: new FormControl(false),
            role_reader: new FormControl(false),
            role_demo: new FormControl(false),
            password: new FormControl(''),
            password_confirmation: new FormControl('')
        }, {
            validators: WSLFormValidators.matchingPasswords('password', 'password_confirmation')
        });
        this.showReader = false;
        this.showDemo = false;
        this.multipleRoles = false;
        this.roles = [];
        this.ngUnsubscribe = new Subject();
    }
    UserProfileSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var roles = [];
        switch (this.companyService.getRoleId()) {
            case CompanyRoleType.uc:
                roles = UC_ROLES.filter(function (role) { return ![UserRoleType.demo, UserRoleType.reader, UserRoleType.controller].includes(role.id); }).slice();
                this.multipleRoles = !this.userProfileService.isOwner() && !this.userProfileService.isRenter() && !this.userProfileService.isController();
                break;
            case CompanyRoleType.data_provider:
                roles = OPERATOR_ROLES.slice();
                this.multipleRoles = true;
                break;
            case CompanyRoleType.tech_support:
                roles = TS_ROLES.slice();
                this.multipleRoles = true;
                break;
            case CompanyRoleType.home_users:
                roles = HOME_ROLES.slice();
                this.multipleRoles = false;
                break;
            default:
                roles = [];
        }
        if (environment.kv) {
            roles = KV_ROLES.slice();
            this.multipleRoles = false;
        }
        this.roles = roles.map(function (role) { return (tslib_1.__assign({}, role)); });
        /*if (this.isMyProfile && this.userProfileService.hasRole(EK_ROLES.admin)) {
          const adminRole = this.roles.find(r => r.id === UserRoleType.admin);
          if (adminRole) {
            adminRole.disabled = true;
          }
          this.roles = this.roles.slice(0);
        }*/
        /*if (this.userProfileService.hasRole(EK_ROLES.admin)) {
          const adminRole = this.roles.find(r => r.id === UserRoleType.admin);
          if (adminRole) {
            adminRole.disabled = true;
          }
        }*/
        this.fillForm();
        this.form.get('default_company_id').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), debounceTime(350), 
        // delay(150),
        distinctUntilChanged()).subscribe(function (comp_id) {
            _this.form.get('default_town_id').setValue(_this.companyTowns.length > 0 ? _this.companyTowns[0].id : null);
            _this.chr.markForCheck();
        });
        this.form.get('role_reader').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (val) {
            if (_this.form.get('role_reader').dirty) {
                if (val) {
                    _this.form.get('role_demo').setValue(false, { emitEvent: false });
                    _this.form.get('role_demo').disable({ emitEvent: false });
                    _this.form.get('role_controller').setValue(false, { emitEvent: false });
                    _this.form.get('role_controller').disable({ emitEvent: false });
                    _this.form.get('role_ids').disable({ emitEvent: false });
                }
                else {
                    _this.form.get('role_demo').setValue(false, { emitEvent: false });
                    _this.form.get('role_demo').enable({ emitEvent: false });
                    _this.form.get('role_controller').setValue(false, { emitEvent: false });
                    _this.form.get('role_controller').enable({ emitEvent: false });
                    _this.form.get('role_ids').enable({ emitEvent: false });
                }
                _this.chr.markForCheck();
            }
        });
        this.form.get('role_demo').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (val) {
            if (_this.form.get('role_demo').dirty) {
                if (val) {
                    _this.form.get('role_reader').setValue(false, { emitEvent: false });
                    _this.form.get('role_reader').disable({ emitEvent: false });
                    _this.form.get('role_controller').setValue(false, { emitEvent: false });
                    _this.form.get('role_controller').disable({ emitEvent: false });
                    _this.form.get('role_ids').disable({ emitEvent: false });
                }
                else {
                    _this.form.get('role_reader').setValue(false, { emitEvent: false });
                    _this.form.get('role_reader').enable({ emitEvent: false });
                    _this.form.get('role_controller').setValue(false, { emitEvent: false });
                    _this.form.get('role_controller').enable({ emitEvent: false });
                    _this.form.get('role_ids').enable({ emitEvent: false });
                }
                _this.chr.markForCheck();
            }
        });
        this.form.get('role_controller').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (val) {
            if (_this.form.get('role_controller').dirty) {
                if (val) {
                    _this.form.get('role_reader').setValue(false, { emitEvent: false });
                    _this.form.get('role_reader').disable({ emitEvent: false });
                    _this.form.get('role_demo').setValue(false, { emitEvent: false });
                    _this.form.get('role_demo').disable({ emitEvent: false });
                    _this.form.get('role_ids').disable({ emitEvent: false });
                }
                else {
                    _this.form.get('role_reader').setValue(false, { emitEvent: false });
                    _this.form.get('role_reader').enable({ emitEvent: false });
                    _this.form.get('role_demo').setValue(false, { emitEvent: false });
                    _this.form.get('role_demo').enable({ emitEvent: false });
                    _this.form.get('role_ids').enable({ emitEvent: false });
                }
                _this.chr.markForCheck();
            }
        });
    };
    UserProfileSettingsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.user) {
            this.fillForm();
        }
    };
    UserProfileSettingsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(UserProfileSettingsComponent.prototype, "isMyProfile", {
        get: function () {
            return this.user && this.user.id === this.userProfileService.getUserID();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileSettingsComponent.prototype, "isAdmin", {
        get: function () {
            return this.userProfileService.isAdmin();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileSettingsComponent.prototype, "availableCompanies", {
        get: function () {
            return this.companies.map(function (c) { return ({ id: c.id, name: c.short_name }); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileSettingsComponent.prototype, "availableRooms", {
        get: function () {
            return this.rooms.map(function (r) { return (tslib_1.__assign({}, r, { name: r.street + " " + r.house_num + ", \u043A\u0432. " + r.name })); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileSettingsComponent.prototype, "companyTowns", {
        get: function () {
            var _this = this;
            var comp = this.companies.find(function (c) { return c.id === _this.form.get('default_company_id').value; });
            return comp ? comp.towns : [];
        },
        enumerable: true,
        configurable: true
    });
    UserProfileSettingsComponent.prototype.onSave = function () {
        if (this.form.dirty && this.form.valid) {
            if (this.userProfileService.isDemo()) {
                // @todo show demo user message
            }
            else {
                var data = this.form.getRawValue();
                if (data.role_demo) {
                    data.role_ids = [UserRoleType.demo];
                }
                if (data.role_reader) {
                    data.role_ids = [UserRoleType.reader];
                }
                if (data.role_controller) {
                    data.role_ids = [UserRoleType.controller];
                }
                delete data.role_demo;
                delete data.role_reader;
                delete data.role_controller;
                if (data.role_ids.length < 1) {
                    WSLMaterializeHelper.toast({ html: 'Укажите роль для пользователя' });
                }
                else {
                    this.save.emit({
                        user: tslib_1.__assign({}, this.user, data),
                        refresh: this.isMyProfile && /*((!!data.password && data.password !== '') ||*/ WSLUtils.isDifferentArrays(data.role_ids, this.user.role_ids)
                    });
                }
            }
        }
        else {
            WSLMaterializeHelper.toast({ html: 'Есть ошибки заполнения формы' });
        }
    };
    UserProfileSettingsComponent.prototype.onCancel = function () {
        if (this.form.dirty) {
            this.load.emit(this.user.id);
        }
    };
    UserProfileSettingsComponent.prototype.onSelectRoles = function (roles) {
        if (WSLUtils.isDifferentArrays(roles, this.form.get('role_ids').value)) {
            this.form.get('role_ids').setValue(roles);
            this.form.get('role_ids').markAsDirty();
        }
    };
    UserProfileSettingsComponent.prototype.onSelectRole = function (roles) {
        if (WSLUtils.isDifferentArrays([roles], this.form.get('role_ids').value)) {
            this.form.get('role_ids').setValue([roles]);
            this.form.get('role_ids').markAsDirty();
        }
    };
    UserProfileSettingsComponent.prototype.trackById = function (index, obj) {
        return obj.id;
    };
    UserProfileSettingsComponent.prototype.fillForm = function () {
        this.form.reset();
        this.showReader = this.appEnv.isUc; // && !(this.isMyProfile && this.userProfileService.hasPermission(UserPermissions.update));
        this.showDemo = false; // this.companyService.isUC();
        WSLFormHelper.fillForm(this.form, this.user);
        if ((this.userProfileService.hasPermission(UserPermissions.update) || this.isMyProfile) && !this.userProfileService.isReader() && !this.userProfileService.isDemo()) {
            if (this.appEnv.isUc) {
                this.form.enable();
                WSLFormHelper.enableAll(this.form);
                var adminRole = this.roles.find(function (r) { return r.id === UserRoleType.admin; });
                if (adminRole) {
                    adminRole.disabled = this.isMyProfile && this.userProfileService.hasRole(EK_ROLES.admin);
                }
                this.roles = this.roles.slice(0);
                if (!this.userProfileService.isAdmin()) {
                    this.form.get('role_reader').disable();
                    this.form.get('role_demo').disable();
                    this.form.get('role_ids').disable();
                }
                if (this.isMyProfile && this.userProfileService.hasPermission(UserPermissions.update)) {
                    this.form.get('role_reader').disable();
                }
            }
            else if (this.companyService.isHomeUsers()) {
                this.form.get('role_ids').disable();
            }
            else if (environment.kv) {
                if (this.userProfileService.isOwner() && !this.isMyProfile && this.user.role_ids.includes(UserRoleType.renter)) {
                    /** арендатора можем повысить до проживающего */
                    this.form.get('role_ids').enable();
                }
                else {
                    this.form.get('role_ids').disable();
                }
            }
            else if (this.companyService.isDP() || this.companyService.isTS()) {
                this.form.enable();
                WSLFormHelper.enableAll(this.form);
                var adminRole = this.roles.find(function (r) { return r.id === UserRoleType.admin; });
                if (adminRole) {
                    adminRole.disabled = this.isMyProfile && this.userProfileService.hasRole(EK_ROLES.admin);
                }
                this.roles = this.roles.slice(0);
                if (!this.userProfileService.isAdmin()) {
                    this.form.get('role_ids').disable();
                }
            }
        }
        else {
            this.form.disable();
            WSLFormHelper.disableAll(this.form);
        }
        if (this.user && this.user.role_ids.every(function (role_id) { return role_id === UserRoleType.reader || role_id === UserRoleType.demo; })) { // @todo merge demo with reader
            this.form.get('role_reader').setValue(true, { emitEvent: false });
            this.form.get('role_demo').setValue(false, { emitEvent: false });
            this.form.get('role_demo').disable({ emitEvent: false });
            this.form.get('role_ids').setValue([], { emitEvent: false });
            this.form.get('role_ids').disable({ emitEvent: false });
        }
        /*if (this.user && this.user.role_ids.every(role_id => role_id === UserRoleType.demo)) {
          this.form.get('role_demo').setValue(true, {emitEvent: false});
          this.form.get('role_reader').setValue(false, {emitEvent: false});
          this.form.get('role_reader').disable({emitEvent: false});
          this.form.get('role_ids').setValue([], {emitEvent: false});
          this.form.get('role_ids').disable({emitEvent: false});
        }*/
        this.form.markAsUntouched();
        this.form.markAsPristine();
    };
    return UserProfileSettingsComponent;
}());
export { UserProfileSettingsComponent };

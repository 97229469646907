import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WSLHttpHelper } from 'wsl-core';
import { ApiHttpClientWithPermissionsService, ObjectRoomPermissions, UserProfileService } from 'wsl-ek-core';
var RoomService = /** @class */ (function (_super) {
    tslib_1.__extends(RoomService, _super);
    function RoomService(http, userProfileService) {
        var _this = _super.call(this, http, 'room', ObjectRoomPermissions, userProfileService) || this;
        _this.http = http;
        _this.userProfileService = userProfileService;
        return _this;
    }
    RoomService.sortApartments = function (a, b) {
        if (a.name === b.name) {
            return 0;
        }
        if (Number.isNaN(+a.name) && Number.isNaN(+b.name)) {
            return a.name < b.name ? -1 : 1;
        }
        else if (Number.isNaN(+a.name)) {
            return 1;
        }
        else if (Number.isNaN(+b.name)) {
            return -1;
        }
        return +a.name < +b.name ? -1 : 1;
    };
    RoomService.prototype.getRoomID = function () {
        return this.userProfileService.getRoomID();
    };
    RoomService.prototype.addMany = function (rooms) {
        return this.http.post('rooms_add', { params: rooms });
    };
    RoomService.prototype.deleteMany = function (ids) {
        return this.http.post('rooms_delete', { ids: ids });
    };
    RoomService.prototype.generateActivationCode = function (room_id) {
        return this.http.get('generate_activation_code', { params: WSLHttpHelper.getParams({ room_id: room_id }) });
    };
    return RoomService;
}(ApiHttpClientWithPermissionsService));
export { RoomService };

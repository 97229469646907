import {NgModule} from '@angular/core';
import {SelectPeriodComponent} from '@common/components/select-period/select-period.component';
import * as moment from 'moment';
import {WslSharedModule} from 'wsl-shared';
import {AskModalComponent} from './components/ask-modal/ask-modal.component';
import {ResourceToPortComponent} from './components/resource-to-port/resource-to-port.component';
import {ScanBarcodeComponent} from './components/scan-barcode/scan-barcode.component';
import {AmChartsModule} from '@amcharts/amcharts3-angular';
import {PromotionSnackBarComponent} from '@common/components/promotion-snack-bar/promotion-snack-bar.component';
import {BackdropComponent} from '@common/components/backdrop/backdrop.component';
import {ResourceIconComponent} from '@common/components/resource-icon/resource-icon.component';

moment.locale('ru');

const COMPONENTS = [
  SelectPeriodComponent,
  AskModalComponent,
  ResourceToPortComponent,
  ScanBarcodeComponent,
  PromotionSnackBarComponent,
  BackdropComponent,
  ResourceIconComponent
];

@NgModule({
  imports: [
    WslSharedModule,
    AmChartsModule,
  ],
  entryComponents: [PromotionSnackBarComponent],
  declarations: COMPONENTS,
  exports: [
    AmChartsModule,
    ...COMPONENTS
  ]
})
export class AppCommonModule { }

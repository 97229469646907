/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../environment-setup/environment-setup.component.ngfactory";
import * as i2 from "../environment-setup/environment-setup.component";
import * as i3 from "wsl-ek-core";
import * as i4 from "../../services/town.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../dist/wsl-ek-core/wsl-ek-core.ngfactory";
import * as i7 from "@angular/material/core";
import * as i8 from "@angular/material-moment-adapter";
import * as i9 from "../user-profile-settings/user-profile-settings.component.ngfactory";
import * as i10 from "../user-profile-settings/user-profile-settings.component";
import * as i11 from "wsl-shared";
import * as i12 from "./profile-sidenav.component";
import * as i13 from "@ngrx/store";
import * as i14 from "@ngrx/effects";
var styles_ProfileSidenavComponent = [];
var RenderType_ProfileSidenavComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileSidenavComponent, data: {} });
export { RenderType_ProfileSidenavComponent as RenderType_ProfileSidenavComponent };
function View_ProfileSidenavComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "wsl-flex-column wsl-p-x-15 wsl-p-t-15"], ["style", "background: #fcfcfc;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "wsl-b2b-environment-setup", [], null, [[null, "edit"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("edit" === en)) {
        var pd_0 = (_co.onEditEnvironment($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closeSideNav() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_EnvironmentSetupComponent_0, i1.RenderType_EnvironmentSetupComponent)), i0.ɵdid(2, 245760, null, 0, i2.EnvironmentSetupComponent, [i3.UserProfileService, i3.CompanyProfileService, i4.TownService], { companies: [0, "companies"], rooms: [1, "rooms"] }, { edit: "edit", close: "close" }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.companies$)); var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform(_co.rooms$)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ProfileSidenavComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "div", [["class", "wsl-flex-column-spaceBetween-ng"], ["style", "flex-grow: 1;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 22, "ul", [["class", "profile_collapsible collapsible wsl-m-y-0 z-depth-0"], ["style", "background: #fcfcfc;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "collapsible-header wsl-bg-gray-lightest wsl-relative"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u041B\u0438\u0447\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435 "])), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "collapsible-body wsl-p-a-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "wsl-user-profile", [], null, [[null, "visibleCalendar"], [null, "save"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleCalendar" === en)) {
        var pd_0 = (_co.onToggleCalendar($event) !== false);
        ad = (pd_0 && ad);
    } if (("save" === en)) {
        var pd_1 = (_co.onUpdateUser($event) !== false);
        ad = (pd_1 && ad);
    } if (("load" === en)) {
        var pd_2 = (_co.onLoadUser($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_UserProfileComponent_0, i6.RenderType_UserProfileComponent)), i0.ɵprd(4608, null, i7.DateAdapter, i8.MomentDateAdapter, [i7.MAT_DATE_LOCALE]), i0.ɵdid(8, 770048, null, 0, i3.UserProfileComponent, [i3.UserProfileService, i0.ChangeDetectorRef], { user: [0, "user"], asyncErrors: [1, "asyncErrors"] }, { visibleCalendar: "visibleCalendar", save: "save", load: "load" }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵprd(256, null, i7.MAT_DATE_FORMATS, i3.MY_FORMATS, []), (_l()(), i0.ɵeld(12, 0, null, null, 11, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "div", [["class", "collapsible-header wsl-bg-gray-lightest wsl-relative"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438 "])), (_l()(), i0.ɵeld(15, 0, null, null, 8, "div", [["class", "collapsible-body wsl-p-a-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 7, "wsl-b2b-user-profile-settings", [], null, [[null, "save"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.onUpdateUser($event) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.onLoadUser($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_UserProfileSettingsComponent_0, i9.RenderType_UserProfileSettingsComponent)), i0.ɵdid(17, 770048, null, 0, i10.UserProfileSettingsComponent, [i3.UserProfileService, i3.CompanyProfileService, i0.ChangeDetectorRef], { appEnv: [0, "appEnv"], user: [1, "user"], company: [2, "company"], companies: [3, "companies"], rooms: [4, "rooms"], asyncErrors: [5, "asyncErrors"] }, { save: "save", load: "load" }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.user$)); var currVal_1 = i0.ɵunv(_v, 8, 1, i0.ɵnov(_v, 10).transform(_co.asyncErrors$)); _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform(_co.appEnv$)); var currVal_3 = i0.ɵunv(_v, 17, 1, i0.ɵnov(_v, 19).transform(_co.user$)); var currVal_4 = i0.ɵunv(_v, 17, 2, i0.ɵnov(_v, 20).transform(_co.company$)); var currVal_5 = i0.ɵunv(_v, 17, 3, i0.ɵnov(_v, 21).transform(_co.companies$)); var currVal_6 = i0.ɵunv(_v, 17, 4, i0.ɵnov(_v, 22).transform(_co.rooms$)); var currVal_7 = i0.ɵunv(_v, 17, 5, i0.ɵnov(_v, 23).transform(_co.asyncErrors$)); _ck(_v, 17, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ProfileSidenavComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "profile-sidenav wsl-side-nav"]], null, [[null, "wslClickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("wslClickOutside" === en)) {
        var pd_1 = (_co.closeSideNav() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 212992, null, 0, i11.WSLClickOutsideDirective, [i0.ElementRef], { wslClickOutsideListen: [0, "wslClickOutsideListen"] }, { wslClickOutside: "wslClickOutside" }), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [["class", "wsl-flex-column-ng"], ["style", "min-height: 100%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "wsl-p-y-5 wsl-p-x-15 wsl-bg-black wsl-white wsl-flex-spaceBetween-middle-ng"], ["style", "height: 44px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", " ", ""])), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [["class", "wsl-white wsl-st4 wsl-p-x-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeSideNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "icon-cross"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileSidenavComponent_1)), i0.ɵdid(11, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ProfileSidenavComponent_2)), i0.ɵdid(13, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opened; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.isMyProfile; _ck(_v, 11, 0, currVal_3); var tmp_4_0 = null; var currVal_4 = (((tmp_4_0 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform(_co.user$))) == null) ? null : tmp_4_0.id); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.user$))) == null) ? null : tmp_1_0.first_name); var tmp_2_0 = null; var currVal_2 = (((tmp_2_0 = i0.ɵunv(_v, 5, 1, i0.ɵnov(_v, 7).transform(_co.user$))) == null) ? null : tmp_2_0.last_name); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_ProfileSidenavComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wsl-profile-sidenav", [], null, null, null, View_ProfileSidenavComponent_0, RenderType_ProfileSidenavComponent)), i0.ɵdid(1, 245760, null, 0, i12.ProfileSidenavComponent, [i13.Store, i14.Actions, i0.ChangeDetectorRef, i3.UserProfileService, i3.CompanyProfileService, i4.TownService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileSidenavComponentNgFactory = i0.ɵccf("wsl-profile-sidenav", i12.ProfileSidenavComponent, View_ProfileSidenavComponent_Host_0, {}, {}, []);
export { ProfileSidenavComponentNgFactory as ProfileSidenavComponentNgFactory };

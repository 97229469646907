import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceConfExtActions, DeviceConfExtActionTypes } from './device-conf-ext.actions';
import * as fromRoot from '@store/reducers/index';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IWSLAsyncErrors } from 'wsl-core';
import { IWSLDataConf } from '@app/device-conf-ext/models/data-conf';

export interface DeviceConfExtState extends EntityState<IWSLDataConf> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLDataConf> = createEntityAdapter<IWSLDataConf>({
  selectId: (item: IWSLDataConf) => item.id,
  sortComparer: false
});

export const initialState: DeviceConfExtState = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(state = initialState, action: DeviceConfExtActions): DeviceConfExtState {
  switch (action.type) {

    case DeviceConfExtActionTypes.AddDeviceConfExt:
    case DeviceConfExtActionTypes.UpdateDeviceConfExt:
    case DeviceConfExtActionTypes.DeleteDeviceConfExt:
      return {...state, pending: true, errors: null};

    case DeviceConfExtActionTypes.AddDeviceConfExtSuccess:
    case DeviceConfExtActionTypes.UpdateDeviceConfExtSuccess:
    case DeviceConfExtActionTypes.DeleteDeviceConfExtSuccess:
      return {...state, pending: false};

    case DeviceConfExtActionTypes.AddDeviceConfExtFail:
    case DeviceConfExtActionTypes.UpdateDeviceConfExtFail:
    case DeviceConfExtActionTypes.DeleteDeviceConfExtFail:
      return {...state, pending: false, errors: action.payload.asyncMessages};

    case DeviceConfExtActionTypes.LoadDeviceConfExt:
      return {
        ...state,
        // selectedID: null,
        pending: true,
        loaded: false,
        errors: null
      };

    case DeviceConfExtActionTypes.LoadDeviceConfExtSuccess:
      return adapter.upsertOne({
        ...action.payload.item
      }, {
        ...state,
        pending: false,
        loaded: true
      });

    case DeviceConfExtActionTypes.LoadDeviceConfExtFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case DeviceConfExtActionTypes.SelectDeviceConfExt:
      return {...state, selectedID: action.payload};

    case DeviceConfExtActionTypes.LoadDeviceConfExts:
      if (!action.payload.offset) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          pending: true,
          loaded: false,
          errors: null
        });
      }
      return {...state, pending: true, loaded: false, errors: null};

    case DeviceConfExtActionTypes.LoadDeviceConfExtsSuccess:
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case DeviceConfExtActionTypes.LoadDeviceConfExtsFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case DeviceConfExtActionTypes.ClearDeviceConfExts:
      return adapter.removeAll({
        ...state,
        totalCount: 0,
        pending: false,
        loaded: true
      });

    default:
      return state;
  }
}

export const getSelectedID = (state: DeviceConfExtState) => state.selectedID;
export const getTotalCount = (state: DeviceConfExtState) => state.totalCount;
export const getLoaded = (state: DeviceConfExtState) => state.loaded;
export const getPending = (state: DeviceConfExtState) => state.pending;
export const getErrors = (state: DeviceConfExtState) => state.errors;

export interface State extends fromRoot.State {
  'deviceConfExt': DeviceConfExtState;
}

export const getDeviceConfExtState = createFeatureSelector<DeviceConfExtState>('deviceConfExt');

export const selectDeviceConfExtSelectedID = createSelector(getDeviceConfExtState, getSelectedID);
export const selectDeviceConfExtTotalCount = createSelector(getDeviceConfExtState, getTotalCount);
export const selectDeviceConfExtLoaded = createSelector(getDeviceConfExtState, getLoaded);
export const selectDeviceConfExtPending = createSelector(getDeviceConfExtState, getPending);
export const selectDeviceConfExtErrors = createSelector(getDeviceConfExtState, getErrors);

export const {
  selectEntities: selectDeviceConfExtEntities,
  selectAll: selectDeviceConfExtAll
} = adapter.getSelectors(getDeviceConfExtState);

export const selectDeviceConfExt = createSelector(
  selectDeviceConfExtEntities,
  selectDeviceConfExtSelectedID,
  (entities, id) => entities && entities[id] ? entities[id] : null
);

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WSLApiHttpClientService} from 'wsl-core';
import {IWSLDeviceConfGroupFileIndex, IWSLDeviceConfGroupFileIndexFilter} from '@app/device-conf-group/models/device-conf-group-file-index.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceConfGroupFileIndexService extends WSLApiHttpClientService<IWSLDeviceConfGroupFileIndex, IWSLDeviceConfGroupFileIndexFilter> {

  constructor(public http: HttpClient) {
    super(http, 'device_conf_group_file_index');
  }
}

import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { AuthService } from '@core/services/auth.service';
import { MenuHelperService } from '@core/services/menu-helper.service';
import { PushNotificationService } from '@core/services/push-notification.service';
import { TownService } from '@core/services/town.service';
import { environment } from '@env/environment';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ClearContractData, HideMenuTabBar, SetAppEnvironment, StartLoading, StopLoading } from '@store/actions/app.actions';
import { AuthActionTypes, LoginSuccess, Logout } from '@store/actions/auth.actions';
import * as fromRoot from '@store/reducers/index';
import { CrossStorageClient } from 'cross-storage';
import * as moment from 'moment';
import { interval, Subject, timer } from 'rxjs';
import { distinctUntilChanged, filter, finalize, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { MomentDBDateFormat, MomentDBDateTimeFormat, WSLLocalStorageService, WSLUtils, WSLWindowService } from 'wsl-core';
import { CompanyProfileService, EK_ROLES, UserProfileService } from 'wsl-ek-core';
import { SYSTEM_SUB_TITLE, SYSTEM_SUB_TITLE_XS, SYSTEM_TITLE, SYSTEM_TITLE_XS } from '../config/app.config';
import { WSLMaterializeHelper } from 'wsl-shared';
import { PromotionSnackBarComponent } from '@common/components/promotion-snack-bar/promotion-snack-bar.component';
var AppComponent = /** @class */ (function () {
    function AppComponent(store$, 
    // private billingStore: Store<fromBilling.BillingState>,
    router, route, updates$, chr, window, windowService, localStorage, menuService, userProfileService, companyProfileService, townService, swUpdate, swPush, pushService, snackBar) {
        var _this = this;
        this.store$ = store$;
        this.router = router;
        this.route = route;
        this.updates$ = updates$;
        this.chr = chr;
        this.window = window;
        this.windowService = windowService;
        this.localStorage = localStorage;
        this.menuService = menuService;
        this.userProfileService = userProfileService;
        this.companyProfileService = companyProfileService;
        this.townService = townService;
        this.swUpdate = swUpdate;
        this.swPush = swPush;
        this.pushService = pushService;
        this.snackBar = snackBar;
        /** @internal */
        this.systemTitle = SYSTEM_TITLE;
        /** @internal */
        this.systemSubTitle = SYSTEM_SUB_TITLE;
        /** @internal */
        this.systemTitleXS = SYSTEM_TITLE_XS;
        /** @internal */
        this.systemSubTitleXS = SYSTEM_SUB_TITLE_XS;
        /** @internal */
        this.menu = [];
        /** @internal */
        this.mainMenu = null;
        /** @internal */
        this.isScrolled = false;
        /** @internal */
        this.scrollTop = 0;
        this.contractData = null;
        this.showCompany = environment.dom;
        this.authorized = null;
        this.tokenRefreshed = false;
        this.aliveTimer = false;
        this.ngUnsubscribe = new Subject();
        this.VAPID_KEY = 'BO6OY05yLHpkVTQtsTaelaZQYWeaPBvXtBxg1XYordBViTtDnOsSc5ERm7hPLBA8jPvxEb03JLumnn3mPo6vLMA';
        /**
         * Selectors can be applied with the `select` operator which passes the state
         * tree to the provided selector
         */
        this.pending$ = store$.pipe(select(fromRoot.selectPending));
        // this.showSidenav$ = store.pipe(select(fromRoot.getShowSidenav));
        this.loggedIn$ = store$.pipe(select(fromRoot.selectLoggedIn));
        this.menu$ = store$.pipe(select(fromRoot.selectMenu));
        this.updates$
            .pipe(takeUntil(this.ngUnsubscribe), ofType(AuthActionTypes.RefreshTokenSuccess, AuthActionTypes.LoginSuccess), tap(function (action) {
            _this.tokenRefreshed = true;
            _this.chr.markForCheck();
        })).subscribe();
        /*this.user$ = */
        store$.pipe(select(fromRoot.selectUserProfile), filter(function (user) { return !!user && !!user.id; }), tap(function (user) {
            _this.user = user;
            _this.userProfileService.definePermissionsAndRoles();
            _this.store$.dispatch(new SetAppEnvironment({
                isUc: environment.dom && _this.companyProfileService.isUC(),
                isKv: environment.kv && !!_this.userProfileService.hasRole([EK_ROLES.owner, EK_ROLES.renter], false, false),
                isHome: environment.home && _this.companyProfileService.isHomeUsers()
            }));
            _this.mainMenu = [];
        }), switchMap(function (user) { return _this.menuService.defineMainMenu().pipe(take(1)); }), tap(function (menu) {
            AuthService.redirectURL = AuthService.redirectURL.split('#')[0].split('?')[0];
            var redirectUrl = AuthService.redirectURL.lastIndexOf('/') > 0 ? ('/' + AuthService.redirectURL.split('/')[1]) : AuthService.redirectURL;
            if (menu.length > 0) {
                _this.mainMenu = menu.slice(0);
            }
            var curUrl = _this.router.routerState.snapshot.url.split('#')[0].split('?')[0];
            var curBasePageUrl = curUrl.lastIndexOf('/') > 0 ? ('/' + curUrl.split('/')[1]) : curUrl;
            if (['/login', '/reset', '/forgot', '/confirm', '/registration'].some(function (s) { return AuthService.redirectURL.startsWith(s); })) {
                _this.router.navigate(['/'], { queryParams: { uid: WSLUtils.randomID() }, queryParamsHandling: 'merge' });
                return;
            }
            if (_this.tokenRefreshed) {
                _this.store$.dispatch(new HideMenuTabBar());
                _this.router.navigate([!_this.companyProfileService.isUC() || _this.mainMenu.length > 0 ? '/' : '/404'], { queryParams: { uid: WSLUtils.randomID() }, queryParamsHandling: 'merge' });
                _this.tokenRefreshed = false;
            }
            _this.chr.markForCheck();
        })).subscribe();
        this.snackbarMessage$ = store$.pipe(select(fromRoot.selectSnackBarMessage));
        this.company$ = store$.pipe(select(fromRoot.selectUserCompanyProfile), filter(function (company) { return !!company; }), take(1), tap(function (company) {
            _this.townName = _this.townService.getTownName(company);
            if (!!company.contract_date_end && moment(company.contract_date_end, MomentDBDateFormat, true).isValid() &&
                moment(company.contract_date_end, MomentDBDateFormat).diff(moment(), 'days') < 14) {
                var snack = _this.snackBar.open('У Вас заканчивается срок действия договора пользования ресурсом ЭнергоКабинет', 'Ок', {
                    duration: 10 * 60 * 1000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top'
                });
                snack.onAction().pipe(take(1)).subscribe(function (e) {
                    // snack.dismiss();
                });
            }
            _this.chr.markForCheck();
        }));
        this.store$
            .pipe(select(fromRoot.selectNoAccessData), tap(function (data) {
            if (!!data) {
                _this.contractData = tslib_1.__assign({}, data);
                _this.initModal();
                _this.MModal.open();
            }
            else {
                _this.contractData = null;
            }
        }))
            .subscribe();
        this.store$
            .pipe(select(fromRoot.selectShowPromotion), filter(function (show) { return !!show; }), take(1), tap(function (show) {
            var showPromo = _this.localStorage.get('show_promotion');
            if (!showPromo) {
                var snack = _this.snackBar.openFromComponent(PromotionSnackBarComponent, {
                    duration: 4 * 1000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top'
                });
                snack.afterDismissed().subscribe(function () {
                    _this.localStorage.set('show_promotion', true);
                });
            }
        }))
            .subscribe();
        if (swUpdate.isEnabled) {
            interval(10 * 60 * 1000).subscribe(function () { return swUpdate.checkForUpdate(); });
        }
        // this.pushService.requestPermission();
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscribeLoggedIn();
        this.subscribeRouteChange();
        if (this.window) {
            this.window.addEventListener('beforeunload', function (event) {
                // this.socketService.disconnect();
                _this.ngUnsubscribe.next(null);
                _this.ngUnsubscribe.complete();
            });
            // срабатывает при обновлениях, сделанных в том же хранилище из других документов
            window.onstorage = function (event) {
                if (event.key !== 'token') {
                    return;
                }
                window.location.reload();
            };
        }
        if (this.swUpdate.isEnabled) {
            this.subscribeNewAppVersion();
        }
        else {
        }
        /* @todo push not work for phone browsers
         if (this.swPush.isEnabled) {
           this.subscribeBackendNotifications();
         } else {
           console.log('swPush disabled');
         }*/
        this.menu$
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe(function (val) {
            _this.menu = val;
            _this.addBadgeToMenuTabBar();
            _this.chr.detectChanges();
        });
        if (environment.home && this.window.location.pathname !== '/test' && window.opener && window.opener !== window) {
            var storage_1 = new CrossStorageClient(environment.hubCrossStorageLink, {
                timeout: 10000,
                frameId: 'storageFrame'
            });
            storage_1.onConnect()
                .then(function () {
                return storage_1.get('home_token');
            })
                .then(function (res) {
                if (res) {
                    _this.store$.dispatch(new LoginSuccess({ token: res }));
                }
                return res;
            })
                .catch(function (err) {
                // Handle error
            });
        }
        // this.checkBrowserFeatures();
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(AppComponent.prototype, "withNavSecond", {
        get: function () {
            return this.menu && this.menu.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.getState = function (outlet) {
        return outlet.activatedRouteData.state;
    };
    AppComponent.prototype.onScroll = function (scrollTop) {
        this.scrollTop = scrollTop;
        this.isScrolled = scrollTop > 50;
        this.chr.markForCheck();
    };
    AppComponent.prototype.closeSidenav = function () {
        /**
         * All state updates are handled through dispatched actions in 'container'
         * components. This provides a clear, reproducible history of state
         * updates and user interaction through the life of our
         * application.
         */
        // this.store.dispatch(new app.CloseSidenav());
    };
    AppComponent.prototype.openSidenav = function () {
        // this.store.dispatch(new app.OpenSidenav());
    };
    AppComponent.prototype.onToggleProfile = function () {
        if (!this.userProfileService.isWildOwner()) {
            this.userProfileService.setUser(this.userProfileService.getUserID());
        }
    };
    AppComponent.prototype.onLogout = function () {
        this.closeSidenav();
        this.store$.dispatch(new Logout());
    };
    AppComponent.prototype.addBadgeToMenuTabBar = function () {
        this.menu = this.menu.map(function (item) {
            if (item.alias) {
                switch (item.alias) {
                    /*  case MenuItemAliasType.event:
                        return Object.assign({}, item, {badge: this.newestEventCount});*/
                    default:
                        return item;
                }
            }
            return item;
        });
    };
    AppComponent.prototype.subscribeRouteChange = function () {
        var _this = this;
        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe(function (routeEvent) {
            // console.log('route', routeEvent);
            // const routeEvent = await val;
            // if (this.authorized) {
            if (routeEvent instanceof NavigationStart) {
                // NavigationRecognized ??
                // this.store.dispatch(new RouterActions.NavigationStart());
                _this.store$.dispatch(new StartLoading('route'));
            }
            if (routeEvent instanceof NavigationEnd) {
                // this.store.dispatch(new RouterActions.NavigationEnd({current: val.urlAfterRedirects.split('#')[0], prev: this.prevRoute}));
                // this.prevRoute = val.urlAfterRedirects;
                AuthService.redirectURL = routeEvent.urlAfterRedirects.split('#')[0].split('?')[0];
                _this.windowService.scrollTop();
                /*const data = WSLRouterHelperService.collectRouteData(this.route);
                if (data.menu) {
                  this.store$.dispatch(new ShowMenuTabBar(data.menu));
                } else {
                  this.store$.dispatch(new HideMenuTabBar());
                }*/
                _this.hideSpinner();
            }
            if (routeEvent instanceof NavigationCancel) {
                _this.hideSpinner();
            }
            if (routeEvent instanceof NavigationError) {
                _this.hideSpinner();
            }
            // }
        });
    };
    AppComponent.prototype.subscribeLoggedIn = function () {
        var _this = this;
        this.loggedIn$
            .pipe(takeUntil(this.ngUnsubscribe), 
        //  delay(100),
        //   distinctUntilChanged(),
        tap(function (loginEvent) {
            if (loginEvent) {
                _this.defineLogoutTimer();
                var popupNoBlocked = _this.localStorage.get('check_popup');
                if (!popupNoBlocked) {
                    var popup = window.open('/test', '_blank');
                    _this.checkPopupBlocked(popup);
                }
            }
            else if (loginEvent === false) {
                if (_this.authorized === true) {
                    _this.router.navigateByUrl('/login');
                }
                _this.aliveTimer = false;
                _this.hideSpinner();
            }
            _this.authorized = loginEvent;
        }), finalize(function () {
            _this.chr.markForCheck();
        }))
            .subscribe();
    };
    AppComponent.prototype.defineLogoutTimer = function () {
        var _this = this;
        if (this.timer) {
            this.timer.unsubscribe();
        }
        this.aliveTimer = true;
        this.timer = timer(moment(this.userProfileService.getTokenExpiration(), MomentDBDateTimeFormat).toDate())
            // moment(this.userProfileService.getTokenExpiration(), MomentDBDateTimeFormat).diff(moment(), 'ms'))
            .pipe(takeWhile(function () { return _this.aliveTimer; }), tap(function () {
            _this.aliveTimer = false;
            _this.onLogout();
        }))
            .subscribe();
    };
    AppComponent.prototype.hideSpinner = function () {
        // this.loading = false;
        this.store$.dispatch(new StopLoading('route'));
        this.userProfileService.setUser(null);
    };
    AppComponent.prototype.checkPopupBlocked = function (popup_window) {
        var _this = this;
        if (popup_window) {
            /*this.window.on('message', function(event) {
              alert(event.originalEvent.data.loaded)
            });*/
            this.window.addEventListener('message', function (event) {
                if (event.data && event.data.loaded) {
                    _this.localStorage.set('check_popup', true);
                }
            }, false);
            if (/chrome/.test(navigator.userAgent.toLowerCase())) {
                setTimeout(function () {
                    _this.isPopupBlocked(popup_window);
                }, 200);
            }
            else {
                popup_window.onload = function () {
                    _this.isPopupBlocked(popup_window);
                };
            }
        }
        else {
            this.displayPopubBlockedError();
        }
    };
    AppComponent.prototype.isPopupBlocked = function (popup_window) {
        popup_window.opener.postMessage({ 'loaded': true }, '*');
        popup_window.close();
        /*if ((popup_window.innerHeight > 0)==false){
          this.displayPopubBlockedError();
        } else {
          popup_window.close();
        }*/
    };
    AppComponent.prototype.displayPopubBlockedError = function () {
        var snack = this.snackBar.open('У Вас включен блокировщик всплывающих окон. Добавьте сайт в исключения', 'Ок', {
            duration: 10 * 60 * 1000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
        });
        snack.onAction().pipe(take(1)).subscribe(function (e) {
            // snack.dismiss();
        });
    };
    AppComponent.prototype.subscribeBackendNotifications = function () {
        var _this = this;
        this.swPush.messages.subscribe(function (msg) {
        });
        this.swPush.requestSubscription({ serverPublicKey: this.VAPID_KEY })
            .then(function (pushSubscription) {
            _this.pushService.addPushSubscriber(pushSubscription).subscribe(function () {
                //     this.pushService.getNews().subscribe();
            }, function (err) { return console.log('Could not send subscription object to server, reason: ', err); });
        })
            .catch(function (err) { return console.error('Could not subscribe to notifications', err); });
    };
    AppComponent.prototype.subscribeNewAppVersion = function () {
        var _this = this;
        this.swUpdate.available.subscribe(function (event) {
            var snack = _this.snackBar.open('Доступна новая версия', 'Обновить', {
                duration: 10 * 60 * 1000,
                horizontalPosition: 'right',
                verticalPosition: 'bottom'
            });
            snack.onAction().pipe(take(1)).subscribe(function (e) {
                _this.window.location.reload();
            });
            // WSLMaterializeHelper.toast({html: 'Загружена новая версия'});
            /*if (this.pushService.isSupported()) {
              const notification = this.pushService.create('Новая версия', {body: 'Мы сделали много изменений и поравили ошибки'});
              notification.subscribe(e => {
                console.log('notif', e);
              });
            } else {
              if (confirm('Доступна новая версия. Загрузить?')) {
                this.window.location.reload();
              }
            }*/
        });
        this.swUpdate.activated.subscribe(function (event) {
            // this.swPush.
            console.log('old version was', event.previous);
            console.log('new version is', event.current);
            /* if (this.pushService.isSupported()) {
               this.pushService.create('Обновление', {body: 'Мы сделали много изменений и поравили ошибки'});
             }*/
        });
    };
    AppComponent.prototype.closeModal = function () {
        this.MModal.close();
        this.store$.dispatch(new ClearContractData());
    };
    AppComponent.prototype.initModal = function () {
        if (!this.MModal) {
            this.MModal = WSLMaterializeHelper.initModal('#no_access_modal', { dismissible: false });
        }
    };
    return AppComponent;
}());
export { AppComponent };

<wsl-guest-layout [title]="systemTitle"
                  [subTitle]="systemSubTitle"
                  pageTitle="Сброс пароля">

  <wsl-reset-password-form *ngIf="canReset"
                           [pending]="pending$ | async"
                           [asyncErrors]="errors$ | async"
                           (submitted)="onSubmit($event)"></wsl-reset-password-form>

  <div class="wsl-flex-spaceBetween_xs-Cols-ng wsl-m-t-10" *ngIf="canReset">
    <a class="" [routerLink]="['/login']">Авторизоваться</a>
  </div>

</wsl-guest-layout>

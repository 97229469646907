import { IWSLBCompanyAccount, IWSLBCompanyAccountFilter } from '@app/billing-company/models/b-company-account';
import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';

export enum BillingCompanyAccountActionTypes {
  LoadBCompanyAccounts = '[Billing] Load Billings Company Accounts',
  LoadBCompanyAccountsSuccess = '[Billing] Load Billings Company Accounts Success',
  LoadBCompanyAccountsFail = '[Billing] Load Billings Company Accounts Fail',

  AddBCompanyAccount = '[Billing] Add Billings Company Accounts',
  AddBCompanyAccountSuccess = '[Billing] Add Billings Company Accounts Success',
  AddBCompanyAccountFail = '[Billing] Add Billings Company Accounts Fail',

  UpdateBCompanyAccount = '[Billing] Update Billings Company Accounts',
  UpdateBCompanyAccountSuccess = '[Billing] Update Billings Company Accounts Success',
  UpdateBCompanyAccountFail = '[Billing] Update Billings Company Accounts Fail',

  DeleteBCompanyAccount = '[Billing] Delete Billings Company Accounts',
  DeleteBCompanyAccountSuccess = '[Billing] Delete Billings Company Accounts Success',
  DeleteBCompanyAccountFail = '[Billing] Delete Billings Company Accounts Fail'
}

// LOAD BCompany Accounts
export class LoadBCompanyAccounts implements Action {
  readonly type = BillingCompanyAccountActionTypes.LoadBCompanyAccounts;

  constructor(public payload: IWSLBCompanyAccountFilter) {
  }
}

export class LoadBCompanyAccountsSuccess implements Action {
  readonly type = BillingCompanyAccountActionTypes.LoadBCompanyAccountsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLBCompanyAccount>) {
  }
}

export class LoadBCompanyAccountsFail implements Action {
  readonly type = BillingCompanyAccountActionTypes.LoadBCompanyAccountsFail;

  constructor(public payload: IWSLHttpResponse<IWSLBCompanyAccount>) {
  }
}

// ADD BCompany Accounts
export class AddBCompanyAccount implements Action {
  readonly type = BillingCompanyAccountActionTypes.AddBCompanyAccount;

  constructor(public payload: IWSLBCompanyAccount) {
  }
}

export class AddBCompanyAccountSuccess implements Action {
  readonly type = BillingCompanyAccountActionTypes.AddBCompanyAccountSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLBCompanyAccount>) {
  }
}

export class AddBCompanyAccountFail implements Action {
  readonly type = BillingCompanyAccountActionTypes.AddBCompanyAccountFail;

  constructor(public payload: IWSLHttpResponse<IWSLBCompanyAccount>) {
  }
}

// UPDATE BCompany Accounts
export class UpdateBCompanyAccount implements Action {
  readonly type = BillingCompanyAccountActionTypes.UpdateBCompanyAccount;

  constructor(public payload: IWSLBCompanyAccount) {
  }
}

export class UpdateBCompanyAccountSuccess implements Action {
  readonly type = BillingCompanyAccountActionTypes.UpdateBCompanyAccountSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLBCompanyAccount>) {
  }
}

export class UpdateBCompanyAccountFail implements Action {
  readonly type = BillingCompanyAccountActionTypes.UpdateBCompanyAccountFail;

  constructor(public payload: IWSLHttpResponse<IWSLBCompanyAccount>) {
  }
}

// DELETE BCompany Accounts
export class DeleteBCompanyAccount implements Action {
  readonly type = BillingCompanyAccountActionTypes.DeleteBCompanyAccount;

  constructor(public payload: number) {
  }
}

export class DeleteBCompanyAccountSuccess implements Action {
  readonly type = BillingCompanyAccountActionTypes.DeleteBCompanyAccountSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLBCompanyAccount>) {
  }
}

export class DeleteBCompanyAccountFail implements Action {
  readonly type = BillingCompanyAccountActionTypes.DeleteBCompanyAccountFail;

  constructor(public payload: IWSLHttpResponse<IWSLBCompanyAccount>) {
  }
}

export type BillingCompanyAccountActions =
  LoadBCompanyAccounts |
  LoadBCompanyAccountsSuccess |
  LoadBCompanyAccountsFail |

  AddBCompanyAccount |
  AddBCompanyAccountSuccess |
  AddBCompanyAccountFail |

  UpdateBCompanyAccount |
  UpdateBCompanyAccountSuccess |
  UpdateBCompanyAccountFail |

  DeleteBCompanyAccount |
  DeleteBCompanyAccountSuccess |
  DeleteBCompanyAccountFail;

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Registration } from '@store/actions/auth.actions';
import * as fromRoot from '@store/reducers';
import { Observable } from 'rxjs';
import { IWSLRegistration } from 'wsl-auth';
import { IWSLAsyncErrors } from 'wsl-core';
import {environment} from '@env/environment';

@Component({
  selector: 'wsl-home-registration-page',
  templateUrl: './registration.page.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationPage implements OnInit {
  /** @internal */
  pending$: Observable<boolean>;
  /** @internal */
  errors$: Observable<IWSLAsyncErrors>;

  showCodeActivation = environment.kv;

  constructor(private store$: Store<fromRoot.State>) {
    this.pending$ = store$.pipe(select(fromRoot.selectPending));
    this.errors$ = store$.pipe(select(fromRoot.selectSnackBarMessage));
  }

  ngOnInit() {
  }

  onSubmit(data: IWSLRegistration) {
    this.store$.dispatch(new Registration(data));
  }

}

<form [formGroup]="form"
      novalidate>
    <div class="wsl-col" [class.wsl-hide]="availableRooms?.length < 2">
        <wsl-select label="" prefix="profile_room" [items]="availableRooms" formControlName="room_id"></wsl-select>
    </div>
    <div class="wsl-col" [class.wsl-hide]="availableCompanies?.length < 2">
        <wsl-select label="" prefix="profile_company" [items]="availableCompanies" formControlName="company_id"></wsl-select>
    </div>
    <div class="wsl-col" [class.wsl-hide]="companyTowns?.length < 2">
        <wsl-select label="" prefix="profile_town" [items]="companyTowns" formControlName="town_id"></wsl-select>
    </div>

    <div class="wsl-col" *ngIf="availableCompanies?.length > 1 || companyTowns?.length > 1 || availableRooms?.length > 1">
        <div class="wsl-flex-right-ng">
            <a class="wsl-flex-center-middle-ng wsl-btn-round wsl-bg-green_hover wsl-white wsl-m-x-5 z-depth-1"
               (click)="save()" matTooltip="Сохранить" matTooltipPosition="left">
                <span class="icon-check"></span>
            </a>
        </div>
    </div>
</form>

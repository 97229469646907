import { IWSLUser } from 'wsl-user';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IWSLAsyncErrors } from 'wsl-core';
import { UserActions, UserActionTypes } from './user.actions';
import * as fromRoot from '@store/reducers/index';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface UserState extends EntityState<IWSLUser> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLUser> = createEntityAdapter<IWSLUser>({
  selectId: (item: IWSLUser) => item.id,
  sortComparer: false
});

export const initialState: UserState = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(state = initialState, action: UserActions): UserState {
  switch (action.type) {

    case UserActionTypes.LoadUser:
      return {
        ...state,
        // selectedID: null,
        pending: true,
        loaded: false
      };

    case UserActionTypes.LoadUserSuccess:
      return adapter.upsertOne({
        ...action.payload.item
      }, {
        ...state,
        pending: false,
        loaded: true
      });

    case UserActionTypes.LoadUserFail:
      return {...state, pending: false, loaded: true};

    case UserActionTypes.SelectUser:
      return {...state, selectedID: action.payload};

    case UserActionTypes.LoadUsers:
      if (!action.payload.offset) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          pending: true,
          loaded: false
        });
      }
      return {...state, pending: true, loaded: false};

    case UserActionTypes.LoadUsersSuccess:
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case UserActionTypes.LoadUsersFail:
      return {...state, pending: false, loaded: true};

    default:
      return state;
  }
}

export const getSelectedID = (state: UserState) => state.selectedID;
export const getTotalCount = (state: UserState) => state.totalCount;
export const getLoaded = (state: UserState) => state.loaded;
export const getPending = (state: UserState) => state.pending;

export interface State extends fromRoot.State {
  'user': UserState;
}

export const getUserState = createFeatureSelector<UserState>('user');

export const selectUserSelectedID = createSelector(getUserState, getSelectedID);
export const selectUserTotalCount = createSelector(getUserState, getTotalCount);
export const selectUserLoaded = createSelector(getUserState, getLoaded);
export const selectUserPending = createSelector(getUserState, getPending);

export const {
  selectEntities: selectUserEntities,
  selectAll: selectUserAll
} = adapter.getSelectors(getUserState);

export const selectUserSelected = createSelector(
  selectUserSelectedID,
  selectUserEntities,
  (userID, entities) => entities[userID] ? entities[userID] : null
);

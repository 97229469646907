import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WSLApiHttpClientService } from 'wsl-core';
import { IWSLDataConf } from '../models/data-conf';
import { IWSLDataConfFilter } from '../models/data-conf-filter';

@Injectable()
export class DeviceConfExtService extends WSLApiHttpClientService<IWSLDataConf, IWSLDataConfFilter> {

  public static withImpulseSensors(confs: IWSLDataConf[]) {
    return confs ? confs.some(c => c.is_sensor) : false;
    // const dmodel = device ? dmodels.find(dm => dm.id === device.dmodel_id) : null;
    // return dmodel ? !dmodel.ext.calc : false;
  }

  public static maxImpulseSensors(confs: IWSLDataConf[]) {
    return confs ? confs.filter(c => c.is_sensor).length : 0;
  }


  constructor(public http: HttpClient) {
    super(http, 'device_conf_ext');
  }

  public deleteAll(id: number) {
    return this.http.delete(`${this.baseUrl}_all/${id}`);
  }
}

<wsl-guest-layout [title]="systemTitle"
                  [subTitle]="systemSubTitle"
                  pageTitle="Авторизация">

  <wsl-login-form [pending]="pending$ | async"
                  [asyncErrors]="errors$ | async"
                  (submitted)="onSubmit($event)"></wsl-login-form>
  <div class="wsl-flex-spaceBetween_xs-Cols-ng wsl-m-t-10">
    <a class="" [routerLink]="['/forgot']">Восстановить пароль</a>
    <ng-container *ngIf="showRegistration">
      <div class="wsl-p-a-10 wsl-show-xs"></div>
      <a class="" *ngIf="!isDom" [routerLink]="['/registration']" >Зарегистрироваться</a>
      <a class="" *ngIf="isDom" [attr.href]="'https://reg.energokabinet.ru'">Зарегистрироваться</a>
    </ng-container>
  </div>

</wsl-guest-layout>

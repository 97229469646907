<form class="wsl-flex-column-nrg wsl-m-t-20"
      [formGroup]="form"
      novalidate>
    <wsl-error-message [message]="asyncErrors?.get('common')"></wsl-error-message>

    <div class="wsl-col">
        <wsl-input-wrapper label="Фамилия" inputId="profile_last_name"
                           [readonly]="!isMyProfile"
                           [asyncError]="asyncErrors?.get('last_name')">
            <input type="text"
                   id="profile_last_name"
                   class="form-control"
                   [readonly]="!isMyProfile"
                   formControlName="last_name">
        </wsl-input-wrapper>
    </div>

    <div class="wsl-col">
        <wsl-input-wrapper label="Имя (Отчество)" inputId="profile_first_name"
                           [readonly]="!isMyProfile"
                           [asyncError]="asyncErrors?.get('first_name')">
            <input type="text"
                   id="profile_first_name"
                   class="form-control"
                   [readonly]="!isMyProfile"
                   formControlName="first_name">
        </wsl-input-wrapper>
    </div>

    <div class="wsl-col">
       <!-- <wsl-input-wrapper label="Дата рождения" inputId="profile_birthday"
                           [readonly]="!isMyProfile"
                           [asyncError]="asyncErrors?.get('birthday')">
            <input class="form-control" matInput
                   [matDatepicker]="picker"
                   [readonly]="!isMyProfile"
                   placeholder="Выберите дату"
                   formControlName="birthday">
            <div class="wsl-abs wsl-abs-right wsl-abs-bottom">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #picker (opened)="onOpenPicker($event)" (closed)="onClosePicker($event)"></mat-datepicker>

           &lt;!&ndash; <input type="text"
                   id="profile_birthday"
                   class="form-control"
                   [readonly]="!isMyProfile"
                   formControlName="birthday">&ndash;&gt;
        </wsl-input-wrapper>-->
        <wsl-select-date label="Дата рождения"
                         prefix="profile_birthday"
                         [asyncError]="asyncErrors?.get('birthday')"
                         [min]="'1900-01-01'"
                         (visibleCalendar)="visibleCalendar.emit($event)"
                         formControlName="birthday"
        ></wsl-select-date>
    </div>

    <div class="wsl-col">
        <wsl-input-wrapper label="Пол" class="wsl-select"
                           [readonly]="!isMyProfile">
            <wsl-select prefix="profile_sex_select" [items]="sexTypes" formControlName="sex"></wsl-select>
            <!--
            <select class="profile_sex_select" formControlName="sex">
                <option value="" disabled selected>Выберите пол</option>
                <option value="m">Мужской</option>
                <option value="f">Женский</option>
            </select>-->
        </wsl-input-wrapper>
    </div>

    <!--
      <div class="wsl-col">
        <wsl-input-wrapper label="Должность" inputId="profile_state"
                           [readonly]="!isMyProfile"
                           [asyncError]="asyncErrors?.position">
          <input type="text"
                 id="profile_state"
                 class="form-control"
                 [readonly]="!isMyProfile"
                 formControlName="position">
        </wsl-input-wrapper>
      </div>-->

    <div class="wsl-col">
        <wsl-input-wrapper label="Эл. Почта" inputId="profile_email"
                           [readonly]="true"
                           [asyncError]="asyncErrors?.get('email')">
            <input type="text"
                   id="profile_email"
                   class="form-control"
                   [readonly]="true"
                   placeholder="your@email.com"
                   formControlName="email">
        </wsl-input-wrapper>
    </div>

    <div class="wsl-col">
        <wsl-input-wrapper label="Телефон" inputId="profile_phone"
                           [readonly]="!isMyProfile"
                           [asyncError]="asyncErrors?.get('phone')">
            <input type="text"
                   id="profile_phone"
                   class="form-control"
                   [readonly]="!isMyProfile"
                   placeholder="+7 (___) ___-__-__"
                   [textMask]="{mask: phoneMask}"
                   formControlName="phone">
        </wsl-input-wrapper>
    </div>


    <div class="wsl-col" *ngIf="isMyProfile">
        <div class="wsl-flex-right-ng">
            <a class="wsl-flex-center-middle-ng wsl-btn-round wsl-white wsl-m-x-5 z-depth-1"
               [ngClass]="{'wsl-bg-green_hover': form.dirty, 'wsl-bg-gray_hover': !form.dirty}"
               (click)="onSave()" matTooltip="Сохранить" matTooltipPosition="left">
                <span class="icon-check"></span>
            </a>
            <a class="wsl-flex-center-middle-ng wsl-btn-round  wsl-white wsl-m-x-5 z-depth-1"
               [ngClass]="{'wsl-bg-cyan_hover': form.dirty, 'wsl-bg-gray_hover': !form.dirty}"
               (click)="onCancel()" matTooltip="Отменить" matTooltipPosition="left">
                <span class="icon-undo2"></span>
            </a>
        </div>
    </div>
</form>

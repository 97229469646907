import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { SetSnackBarMessage } from '@store/actions/app.actions';
import { LoadDevicesSuccess } from '@store/device/device.actions';
import * as fromDevice from '@store/device/device.reducer';
import * as fromRoot from '@store/reducers';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { IWSLHttpResponse, WSLHttpHelper, WSLRouterHelperService } from 'wsl-core';
import { DeviceService, IWSLDevice } from 'wsl-device';

export let WSL_HAS_DEVICES_GUARD_TIMEOUT = new InjectionToken<number>('WSL_HAS_DEVICES_GUARD_TIMEOUT');

@Injectable()
export class HasDevicesGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>,
              private deviceService: DeviceService,
              @Optional() @Inject(WSL_HAS_DEVICES_GUARD_TIMEOUT) private waitTimeout: number = 1000) {
  }

  /**
   * This method creates an observable that waits for the `loaded` property
   * of the collection state to turn `true`, emitting one time once loading
   * has finished.
   */
  waitForCollectionToLoad(): Observable<boolean> {
    return this.store$
      .pipe(
        select(fromDevice.selectDeviceLoaded),
        filter(loaded => loaded),
        take(1),
        timeout(this.waitTimeout),
        catchError(() => of(false))
      );
  }

  /**
   * This method checks if a device with the given ID is already registered
   * in the Store
   */
  hasInStore(params): Observable<boolean> {
    return this.store$
      .pipe(
        select(fromDevice.selectDeviceAll),
        map(entities => {
          return params && params.objectID ? entities.filter(e => e.object_id === (+params.objectID)).length > 0 : entities.length > 0;
        }),
        take(1)
      );
  }

  /**
   * This method loads a device with the given ID from the API and caches
   * it in the store, returning `true` or `false` if it was found.
   */
  hasInApi(params): Observable<boolean> {
    return this.deviceService
      .getMany({offset: 0, object_id: params && params.objectID ? params.objectID : null})
      .pipe(
        // map(deviceEntity => new LoadDeviceSuccess(deviceEntity)),
        //  tap((action: LoadDeviceSuccess) => this.store.dispatch(action)),
        tap(resp => this.store$.dispatch(new LoadDevicesSuccess(resp))),
        map((resp: IWSLHttpResponse<IWSLDevice>) => resp.items),
        map(entities => entities.length > 0),
        catchError((response) => {
          return of(false);
        })
      );
  }

  /**
   * `hasEntity` composes `hasInStore` and `hasInApi`. It first checks
   * if the device is in store, and if not it then checks if it is in the
   * API.
   */
  hasEntities(params, collectionLoaded: boolean): Observable<boolean> {
    if (collectionLoaded) {
      return this.hasInStore(params)
        .pipe(
          switchMap(inStore => {
            if (inStore) {
              return of(inStore);
            }

            return this.hasInApi(params);
          }));
    }
    return this.hasInApi(params);
  }

  check(params: any): Observable<boolean> {
    return this.waitForCollectionToLoad()
      .pipe(switchMap((loaded) => this.hasEntities(params, loaded)));
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const params = WSLRouterHelperService.collectRouteSnapshotParams(next);
    return this.check(params)
      .pipe(
        tap(res => {
          if (!res) {
            this.store$.dispatch(new SetSnackBarMessage(WSLHttpHelper.parseAsyncMessage('Нет приборов')));
            return of(false);
          }
        })
      );
  }
}

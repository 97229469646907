import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  AddDevice,
  AddDeviceFail,
  AddDeviceSuccess,
  DeleteDevice,
  DeleteDeviceFail,
  DeleteDeviceSuccess,
  DeviceActions,
  DeviceActionTypes,
  HideDevice,
  HideDeviceFail,
  HideDeviceSuccess,
  LoadDevice,
  LoadDeviceFail,
  LoadDevices,
  LoadDevicesFail,
  LoadDevicesSuccess,
  LoadDeviceSuccess,
  RenameDevice,
  RenameDeviceFail,
  RenameDeviceSuccess,
  UpdateDeviceCommunicator,
  UpdateDeviceCommunicatorFail,
  UpdateDeviceCommunicatorSuccess,
  UpdateDeviceGroupConf, UpdateDeviceGroupConfFail, UpdateDeviceGroupConfSuccess,
  UpdateTimezoneDevice,
  UpdateTimezoneDeviceFail,
  UpdateTimezoneDeviceSuccess
} from './device.actions';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceService} from 'wsl-device';
import {StartLoading, StopLoading} from '@store/actions/app.actions';
import {catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil} from 'rxjs/operators';
import {StoreHelperService} from '@core/services/store-helper.service';

@Injectable()
export class DeviceEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DeviceActionTypes.AddDevice),
    map((action: AddDevice) => action.payload),
    exhaustMap(payload => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceActionTypes.AddDevice), skip(1));

      return this.deviceService.add(payload)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddDeviceSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddDeviceFail))
        );
    })
  );

  /* @Effect({dispatch: false})
   addSuccess$ = this.actions$.pipe(
     ofType(DeviceActionTypes.AddDeviceSuccess),
     map((action: any) => action.payload),
     tap(payload => this.router.navigate(['settings', 'devices', payload]))
   );*/

  @Effect()
  updateGroupConf$ = this.actions$.pipe(
    ofType(DeviceActionTypes.UpdateDeviceGroupConf),
    map((action: UpdateDeviceGroupConf) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceActionTypes.UpdateDeviceGroupConf), skip(1));

      return this.deviceService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateDeviceGroupConfSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateDeviceGroupConfFail))
        );
    })
  );

  @Effect()
  updateCommunicator$ = this.actions$.pipe(
    ofType(DeviceActionTypes.UpdateDeviceCommunicator),
    map((action: UpdateDeviceCommunicator) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceActionTypes.UpdateDeviceCommunicator), skip(1));

      return this.deviceService.updateCommunicator(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateDeviceCommunicatorSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateDeviceCommunicatorFail))
        );
    })
  );

  @Effect()
  rename$ = this.actions$.pipe(
    ofType(DeviceActionTypes.RenameDevice),
    map((action: RenameDevice) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceActionTypes.RenameDevice), skip(1));

      return this.deviceService.updateExt(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, RenameDeviceSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, RenameDeviceFail))
        );
    })
  );

  @Effect()
  updateTimezone$ = this.actions$.pipe(
    ofType(DeviceActionTypes.UpdateTimezoneDevice),
    map((action: UpdateTimezoneDevice) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceActionTypes.UpdateTimezoneDevice), skip(1));

      return this.deviceService.updateExt(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateTimezoneDeviceSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateTimezoneDeviceFail))
        );
    })
  );

  @Effect()
  hide$ = this.actions$.pipe(
    ofType(DeviceActionTypes.HideDevice),
    map((action: HideDevice) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceActionTypes.HideDevice), skip(1));

      return this.deviceService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, HideDeviceSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, HideDeviceFail))
        );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(DeviceActionTypes.LoadDevice),
    map((action: LoadDevice) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceActionTypes.LoadDevice), skip(1));

      return this.deviceService.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceFail))
        );
    })
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(DeviceActionTypes.LoadDevices),
    map((action: LoadDevices) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceActionTypes.LoadDevices), skip(1));

      return this.deviceService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDevicesSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDevicesFail))
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeviceActionTypes.DeleteDevice),
    map((action: DeleteDevice) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceActionTypes.DeleteDevice), skip(1));

      return this.deviceService.delete(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteDeviceSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteDeviceFail))
        );
    })
  );

  @Effect()
  startLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceActionTypes.AddDevice,
        DeviceActionTypes.LoadDevice,
        DeviceActionTypes.UpdateDeviceGroupConf,
        DeviceActionTypes.UpdateDeviceCommunicator,
        DeviceActionTypes.DeleteDevice
      ),
      map(() => new StartLoading('device'))
    );

  @Effect()
  startLoadMany$ = this.actions$
    .pipe(
      ofType(DeviceActionTypes.LoadDevices),
      map((action: LoadDevices) => action.payload),
      filter((payload) => payload.offset === 0),
      map(() => new StartLoading('device'))
    );


  @Effect()
  stopLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceActionTypes.AddDeviceSuccess, DeviceActionTypes.AddDeviceFail,
        DeviceActionTypes.LoadDeviceSuccess, DeviceActionTypes.LoadDeviceFail,
        DeviceActionTypes.UpdateDeviceGroupConfSuccess, DeviceActionTypes.UpdateDeviceGroupConfFail,
        DeviceActionTypes.UpdateDeviceCommunicatorSuccess, DeviceActionTypes.UpdateDeviceCommunicatorFail,
        DeviceActionTypes.DeleteDeviceSuccess, DeviceActionTypes.DeleteDeviceFail,
        DeviceActionTypes.LoadDevicesSuccess, DeviceActionTypes.LoadDevicesFail
      ),
      map(() => new StopLoading('device'))
    );

  constructor(private actions$: Actions<DeviceActions>,
              private router: Router,
              private route: ActivatedRoute,
              private storeHelper: StoreHelperService,
              private deviceService: DeviceService) {
  }
}

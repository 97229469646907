import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AddDeviceConfExtFail, AddDeviceConfExtSuccess, DeleteDeviceConfExtFail, DeleteDeviceConfExtSuccess, DeviceConfExtActionTypes, LoadDeviceConfExtFail, LoadDeviceConfExtsFail, LoadDeviceConfExtsSuccess, LoadDeviceConfExtSuccess, UpdateDeviceConfExtFail, UpdateDeviceConfExtSuccess } from './device-conf-ext.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { DeviceConfExtService } from '@app/device-conf-ext/services/device-conf-ext.service';
var DeviceConfExtEffects = /** @class */ (function () {
    function DeviceConfExtEffects(actions$, router, route, storeHelper, communicatorService) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.route = route;
        this.storeHelper = storeHelper;
        this.communicatorService = communicatorService;
        this.add$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.AddDeviceConfExt), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfExtActionTypes.AddDeviceConfExt), skip(1));
            return _this.communicatorService.add(payload)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddDeviceConfExtSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddDeviceConfExtFail); }));
        }));
        this.update$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.UpdateDeviceConfExt), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfExtActionTypes.UpdateDeviceConfExt), skip(1));
            return _this.communicatorService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateDeviceConfExtSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateDeviceConfExtFail); }));
        }));
        this.load$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.LoadDeviceConfExt), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfExtActionTypes.LoadDeviceConfExt), skip(1));
            return _this.communicatorService.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceConfExtSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceConfExtFail); }));
        }));
        this.loadMany$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.LoadDeviceConfExts), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfExtActionTypes.LoadDeviceConfExts), skip(1));
            return _this.communicatorService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceConfExtsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceConfExtsFail); }));
        }));
        this.delete$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.DeleteDeviceConfExt), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfExtActionTypes.DeleteDeviceConfExt), skip(1));
            return _this.communicatorService.delete(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteDeviceConfExtSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteDeviceConfExtFail); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(DeviceConfExtActionTypes.AddDeviceConfExt, DeviceConfExtActionTypes.LoadDeviceConfExt, DeviceConfExtActionTypes.UpdateDeviceConfExt, DeviceConfExtActionTypes.DeleteDeviceConfExt), map(function () { return new StartLoading('device_conf_ext'); }));
        this.startLoadMany$ = this.actions$
            .pipe(ofType(DeviceConfExtActionTypes.LoadDeviceConfExts), map(function (action) { return action.payload; }), filter(function (payload) { return payload.offset === 0; }), map(function () { return new StartLoading('device_conf_ext'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(DeviceConfExtActionTypes.AddDeviceConfExtSuccess, DeviceConfExtActionTypes.AddDeviceConfExtFail, DeviceConfExtActionTypes.LoadDeviceConfExtSuccess, DeviceConfExtActionTypes.LoadDeviceConfExtFail, DeviceConfExtActionTypes.UpdateDeviceConfExtSuccess, DeviceConfExtActionTypes.UpdateDeviceConfExtFail, DeviceConfExtActionTypes.DeleteDeviceConfExtSuccess, DeviceConfExtActionTypes.DeleteDeviceConfExtFail, DeviceConfExtActionTypes.LoadDeviceConfExtsSuccess, DeviceConfExtActionTypes.LoadDeviceConfExtsFail), map(function () { return new StopLoading('device_conf_ext'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfExtEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfExtEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfExtEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfExtEffects.prototype, "loadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfExtEffects.prototype, "delete$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfExtEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfExtEffects.prototype, "startLoadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfExtEffects.prototype, "stopLoad$", void 0);
    return DeviceConfExtEffects;
}());
export { DeviceConfExtEffects };

import { Injectable } from '@angular/core';
import { IWSLHttpResponse, WSLHttpHelper, WSLLocalStorageService } from 'wsl-core';
import { Action } from '@ngrx/store';
import {SetSnackBarMessage, ShowNoAccessMessage} from '@store/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Logout } from '@store/actions/auth.actions';
import { StorageKey } from 'wsl-ek-core';

@Injectable()
export class StoreHelperService {

  constructor(private localStorage: WSLLocalStorageService) { }

  public static generateActionTypes(name) {
    return {
      [`Add${name}`]: `[${name}] Add`,
      [`Add${name}Success`]: `[${name}] Add Success`,
      [`Add${name}Fail`]: `[${name}] Add Fail`,

      [`Load${name}`]: `[${name}] Load`,
      [`Load${name}Success`]: `[${name}] Load Success`,
      [`Load${name}Fail`]: `[${name}] Load Fail`,

      [`Update${name}`]: `[${name}] Update`,
      [`Update${name}Success`]: `[${name}] Update Success`,
      [`Update${name}Fail`]: `[${name}] Update Fail`,

      [`Delete${name}`]: `[${name}] Delete`,
      [`Delete${name}Success`]: `[${name}] Delete Success`,
      [`Delete${name}Fail`]: `[${name}] Delete Fail`,

      [`Load${name}s`]: `[${name}] Load Many`,
      [`Load${name}sSuccess`]: `[${name}] Load Many Success`,
      [`Load${name}sFail`]: `[${name}] Load Many Fail`,

      [`Select${name}`]: `[${name}] Select`
    };
  }


  successHandler(response: IWSLHttpResponse<any>, action, extra = null) {
    if (response) {
      const message = response.view ? WSLHttpHelper.parseAsyncMessage(response.message) : null; // HttpHelper.parseAsyncMessage('Успешно выполнено');
      const actions: Array<Action> = [new action({...response, asyncMessages: message, ...extra})];
      if (message) {
        actions.push(new SetSnackBarMessage(message));
      }
      return actions;
    }
    return [new action()/*, new SetSnackBarMessage(HttpHelper.parseAsyncMessage('Успешно выполнено'))*/];
  }

  errorHandler(response: HttpErrorResponse, action = null) {
    const error = WSLHttpHelper.parseHTTPErrorResponse(response);
    const message = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
    const actions: Array<Action> = [];
    if (action) {
      if (message) {
        actions.push(new action({asyncMessages: message}));
      } else {
        actions.push(new action(error));
      }
    }
    if (this.localStorage.get(StorageKey.token) /*isAuthenticated()*/ && error.logout && !error.contract_num) {
      actions.push(new Logout());
    }
    if (error.contract_num) {
      actions.push(new ShowNoAccessMessage(error));
    }

    if (message) {
      actions.push(new SetSnackBarMessage(message));
    }
    return actions;
  }

}

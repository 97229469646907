<label>{{label}}</label>
<div [attr.id]="prefix+'select_date_dropdown'">
    <a class="wsl-block wsl-border-b-dot"
       *ngIf="!disabled; else elseReadonly"
       (click)="openCalendar()">
        {{value ? value : 'укажите дату'}}
    </a>
    <input type="text" class="form-control  {{prefix}}_datepicker wsl-hide" [(ngModel)]="value">

    <ng-template #elseReadonly><span>{{value ? value : 'укажите дату'}}</span>
    </ng-template>
</div>
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IDeviceDocument} from '@app/device/models/device-document.model';
import {IDeviceDocumentFilter} from '@app/device/models/device-document-filter.model';
import {WSLApiHttpClientService} from 'wsl-core';

@Injectable()
export class DeviceDocumentService extends WSLApiHttpClientService<IDeviceDocument, IDeviceDocumentFilter>{

  constructor(public http: HttpClient) {
    super(http, 'files');
  }

}

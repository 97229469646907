import * as tslib_1 from "tslib";
import { OnChanges, OnInit } from '@angular/core';
import { DeviceService } from 'wsl-device';
import { ActivatedRoute } from '@angular/router';
import { MomentDateTimeFormat, MomentDBDateTimeFormat, WSLRouterHelperService } from 'wsl-core';
import { AllResources, DataArchiveType, DataArchiveTypes, ResourceType } from 'wsl-ek-core';
import { CommunicatorService } from '@app/communicator/services/communicator.service';
import * as moment from 'moment';
var DeviceCardProComponent = /** @class */ (function () {
    function DeviceCardProComponent(route) {
        this.route = route;
        this.links = [];
        this.showExtra = true;
        this.extra = [];
        this.withDateNext = false;
        this.withImpulseSensors = false;
        this.isInWork = false;
        this.isOutWork = false;
        this.devEUI = '';
        this.showRename = false;
        this.showRenameInput = false;
        this.alias = '';
        this.image = null;
        this.internalResources = [];
        this.sensors = [];
        this.connections = [];
        this.deviceArchives = '';
        this.activeServiceProcedure = null;
        this.activeServiceProcedureTooltip = '';
        this.deviceLink = null;
        this.dmodels = [];
        this.sp = [];
        this.so = [];
    }
    Object.defineProperty(DeviceCardProComponent.prototype, "serviceProcedures", {
        set: function (serviceProcedures) {
            var _this = this;
            if (serviceProcedures) {
                this.deviceServiceProcedures = serviceProcedures;
                this.activeServiceProcedure = serviceProcedures.find(function (s) { return s.device_id === _this.device.id && !s.cancel && !s.close; });
                this.defineActiveServiceProcedureTooltip();
            }
        },
        enumerable: true,
        configurable: true
    });
    DeviceCardProComponent.prototype.ngOnInit = function () {
        var data = WSLRouterHelperService.collectRouteData(this.route);
        this.dmodels = data.dmodels;
        this.sp = data.sp;
        this.so = data.so;
        this.init();
    };
    DeviceCardProComponent.prototype.ngOnChanges = function (changes) {
        if (changes.device) {
            this.init();
        }
    };
    DeviceCardProComponent.prototype.clickRename = function () {
        this.showRenameInput = true;
    };
    DeviceCardProComponent.prototype.save = function () {
        // this.renameDevice.emit({id: this.device.id, alias: this.alias});
    };
    DeviceCardProComponent.prototype.cancel = function () {
        this.alias = this.device.alias;
        this.showRenameInput = false;
    };
    DeviceCardProComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    DeviceCardProComponent.prototype.init = function () {
        var _this = this;
        if (this.device) {
            this.alias = this.device.alias;
            var dmodel = this.dmodels ? this.dmodels.find(function (dm) { return dm.id === _this.device.dmodel_id; }) : null;
            this.image = dmodel && dmodel.ext && dmodel.ext.image ? dmodel.ext.image : null;
            this.withDateNext = DeviceService.withDateNext(this.dmodels, this.device);
            // this.withImpulseSensors = DeviceService.withImpulseSensors(this.dmodels, this.device);
            if (this.showRenameInput) {
                this.showRenameInput = false;
            }
            this.showRename = !this.device.individual;
            this.isInWork = !!this.device.date_input;
            this.isOutWork = !!this.device.date_output;
            this.devEUI = null;
            if (!!this.device.communicators && this.device.communicators.length > 0 && this.device.communicators[0].network.lora) {
                this.devEUI = this.device.communicators[0].network.lora.devEUI;
            }
            this.internalResources = [];
            if (!!this.device.sensors) {
                this.internalResources = AllResources
                    .filter(function (ar) { return !!_this.device.resources && _this.device.resources.includes(ar.id) &&
                    !_this.device.sensors.map(function (s) { return s.resource_id; }).includes(ar.id); });
            }
            else {
                this.internalResources = AllResources.filter(function (ar) { return !!_this.device.resources &&
                    _this.device.resources.includes(ar.id); });
            }
            this.sensors = [];
            if (!!this.device && !!this.device.sensors) {
                this.sensors = this.device.sensors.map(function (sensor) {
                    var res = AllResources.find(function (r) { return r.id === sensor.resource_id; });
                    return {
                        serial_num: sensor.serial_num,
                        date_next: sensor.date_next,
                        resource_id: res.id,
                        resource_icon: res.icon,
                        entry: sensor.entry
                    };
                });
            }
            this.connections = [];
            if (this.device && this.device.communicators) {
                this.device.communicators.forEach(function (com) {
                    if (com) {
                        var nt = CommunicatorService.getConnectionType(com);
                        if (!nt) {
                            console.error(com);
                        }
                        else {
                            _this.connections.push({
                                id: com.id,
                                on_board: com.network.on_board,
                                icon: nt.icon,
                                name: nt.name,
                                describe: CommunicatorService.getConnectionParameters(com)
                            });
                        }
                    }
                });
            }
            this.deviceArchives = '';
            if (this.device.archives) {
                this.deviceArchives = this.device.archives ? DataArchiveTypes
                    .filter(function (a) { return _this.device.archives.includes(a.id); })
                    .map(function (a) {
                    if (_this.device.resources && _this.device.resources.includes(ResourceType.elec) && a.id === DataArchiveType.hour) {
                        return tslib_1.__assign({}, a, { name: 'профиль мощности' });
                    }
                    return a;
                })
                    .reduce(function (prev, cur) { return prev + ' ' + cur.name; }, '') : '';
                this.defineActiveServiceProcedureTooltip();
            }
            this.deviceLink = this.links ? this.links.find(function (l) { return l.device_id === _this.device.id; }) : null;
        }
    };
    DeviceCardProComponent.prototype.defineActiveServiceProcedureTooltip = function () {
        var _this = this;
        this.activeServiceProcedureTooltip = '';
        if (this.activeServiceProcedure) {
            if (this.sp) {
                var sp = this.sp.find(function (p) { return p.id === _this.activeServiceProcedure.sprocedure_id; });
                this.activeServiceProcedureTooltip = sp ? sp.name : '';
            }
            if (this.so) {
                var o = this.so.find(function (so) { return so.id === _this.activeServiceProcedure.so_data_soperation_id; });
                this.activeServiceProcedureTooltip += o ? (': ' + o.name) : '';
            }
            this.activeServiceProcedureTooltip += ' от ' +
                moment(this.activeServiceProcedure.time_beg, MomentDBDateTimeFormat).format(MomentDateTimeFormat);
        }
    };
    return DeviceCardProComponent;
}());
export { DeviceCardProComponent };

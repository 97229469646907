import { Router } from '@angular/router';
import { MainMenuType } from '@core/enums/main-menu-type.enum';
import { MenuHelperService } from '@core/services/menu-helper.service';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataPermissions, UserProfileService } from 'wsl-ek-core';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "wsl-ek-core";
import * as i3 from "../services/menu-helper.service";
import * as i4 from "@angular/router";
var AvailableDataPageGuard = /** @class */ (function () {
    function AvailableDataPageGuard(store$, userProfileService, menuService, router) {
        this.store$ = store$;
        this.userProfileService = userProfileService;
        this.menuService = menuService;
        this.router = router;
    }
    AvailableDataPageGuard.prototype.check = function () {
        var _this = this;
        return of(!!this.menuService.mainMenu.find(function (menu) { return menu.id === MainMenuType.data; }) ||
            this.userProfileService.hasPermission([DataPermissions.showObjectData, DataPermissions.showObjectRoomsData, DataPermissions.showDeviceData]))
            .pipe(tap(function (canLoad) {
            if (!canLoad) {
                if (_this.menuService.mainMenu && _this.menuService.mainMenu.length > 0) {
                    _this.router.navigate([_this.menuService.mainMenu[0].route], { queryParamsHandling: 'merge' });
                }
                else {
                    _this.router.navigateByUrl('/404');
                }
            }
        }));
    };
    AvailableDataPageGuard.prototype.canLoad = function (route) {
        return this.check();
    };
    AvailableDataPageGuard.prototype.canActivate = function (next, state) {
        return this.check();
    };
    AvailableDataPageGuard.ngInjectableDef = i0.defineInjectable({ factory: function AvailableDataPageGuard_Factory() { return new AvailableDataPageGuard(i0.inject(i1.Store), i0.inject(i2.UserProfileService), i0.inject(i3.MenuHelperService), i0.inject(i4.Router)); }, token: AvailableDataPageGuard, providedIn: "root" });
    return AvailableDataPageGuard;
}());
export { AvailableDataPageGuard };

<main>
  <div class="wsl-m-t-20">
    <div class="wsl-flex-center">
      <div class="wsl-col-4_md-5_sm-6_xs-12 wsl-text-center">
        <div class="wsl-flex-center-middle_xs-Cols-ng wsl-m-b-20">
          <a href="#" class="wsl-flex-middle-ng wsl-st4">
            <span>Энерго</span>
            <span class="wsl-thin">Кабинет</span>
          </a>
          <span class="wsl-gray wsl-m-x-10 wsl-st4 wsl-thin wsl-hide-xs">|</span>
          <span class="wsl-gray wsl-t4 wsl-thin">Подтверждение регистрации</span>
        </div>
        <wsl-error-message [message]="(errors$|async)?.get('common')"></wsl-error-message>

        <div *ngIf="(!pending$|async) && confirmed" class="wsl-green">
          Ваша регистрация подтверждена
        </div>
        <div *ngIf="(!pending$|async) && !confirmed" class="wsl-red">
          Ваша регистрация не подтверждена
        </div>
      </div>
    </div>
  </div>
</main>

<div class="wsl-flex-nogrow">
    <div class="wsl-col" *ngFor="let btn of buttons; trackBy: trackById">
        <a  class="waves-effect waves-light btn"
            wslDebounceClick (debounceClick)="btnClick.emit($event)" [debounceTime]="btn.debounceTime || debounceTime" [debounceId]="btn.id"
            [ngClass]="btn.classes"
            [matTooltip]="btn.label"
            [@actionButtonsState]="btn.visible.toString()">
            <span [ngClass]="btn.icon"></span>
        </a>
    </div>
    <ng-container *ngIf="!buttons || buttons.length === 0">
        <div class="wsl-col" *ngIf="showBack">
            <a class="waves-effect waves-light btn wsl-bg-gray-dark_hover"
               [matTooltip]="backLabel"
               (click)="back.emit()"><span class="icon-arrow-left"></span></a>
        </div>
        <div class="wsl-col" [class.wsl-hide]="!showNext">
            <a class="waves-effect waves-light btn" [class.wsl-bg-gray-dark]="disableNext"
               [matTooltip]="nextLabel"
               wslDebounceClick (debounceClick)="next.emit()" [debounceTime]="350"><span class="icon-arrow-right"></span></a>
        </div>
    </ng-container>
</div>

import * as tslib_1 from "tslib";
var _a;
import { createEntityAdapter } from '@ngrx/entity';
import { UserActionTypes } from './user.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export var adapter = createEntityAdapter({
    selectId: function (item) { return item.id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case UserActionTypes.LoadUser:
            return tslib_1.__assign({}, state, { 
                // selectedID: null,
                pending: true, loaded: false });
        case UserActionTypes.LoadUserSuccess:
            return adapter.upsertOne(tslib_1.__assign({}, action.payload.item), tslib_1.__assign({}, state, { pending: false, loaded: true }));
        case UserActionTypes.LoadUserFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true });
        case UserActionTypes.SelectUser:
            return tslib_1.__assign({}, state, { selectedID: action.payload });
        case UserActionTypes.LoadUsers:
            if (!action.payload.offset) {
                return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: true, loaded: false }));
            }
            return tslib_1.__assign({}, state, { pending: true, loaded: false });
        case UserActionTypes.LoadUsersSuccess:
            return adapter.upsertMany(action.payload.items, tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true }));
        case UserActionTypes.LoadUsersFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true });
        default:
            return state;
    }
}
export var getSelectedID = function (state) { return state.selectedID; };
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
export var getUserState = createFeatureSelector('user');
export var selectUserSelectedID = createSelector(getUserState, getSelectedID);
export var selectUserTotalCount = createSelector(getUserState, getTotalCount);
export var selectUserLoaded = createSelector(getUserState, getLoaded);
export var selectUserPending = createSelector(getUserState, getPending);
export var selectUserEntities = (_a = adapter.getSelectors(getUserState), _a.selectEntities), selectUserAll = _a.selectAll;
export var selectUserSelected = createSelector(selectUserSelectedID, selectUserEntities, function (userID, entities) { return entities[userID] ? entities[userID] : null; });

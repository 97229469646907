import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthActionTypes, ForgotPassword } from '@store/actions/auth.actions';
import * as fromRoot from '@store/reducers/index';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IWSLForgotPassword } from 'wsl-auth';
import { IWSLAsyncErrors } from 'wsl-core';
import { SYSTEM_SUB_TITLE, SYSTEM_TITLE } from '../../../../config/app.config';

@Component({
  selector: 'wsl-forgot-password',
  templateUrl: './forgot-password.page.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordPage implements OnInit, OnDestroy {
  /** @internal */
  readonly systemTitle = SYSTEM_TITLE;
  /** @internal */
  readonly systemSubTitle = SYSTEM_SUB_TITLE;
  /** @internal */
  pending$: Observable<boolean>;
  /** @internal */
  errors$: Observable<IWSLAsyncErrors>;
  /** @internal */
  submitted = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private store$: Store<fromRoot.State>,
              private updates$: Actions,
              private chr: ChangeDetectorRef) {
    this.pending$ = store$.pipe(select(fromRoot.selectPending));
    this.errors$ = store$.pipe(select(fromRoot.selectSnackBarMessage));

    this.updates$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        ofType(AuthActionTypes.ForgotPasswordSuccess),
        tap(() => {
          this.submitted = true;
          this.chr.markForCheck();
        })
      ).subscribe();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onSubmit(data: IWSLForgotPassword) {
    this.store$.dispatch(new ForgotPassword(data));
  }

}

import { Injectable } from '@angular/core';
import { BCompanyAccountService } from '@app/billing-company/services/b-company-account.service';
import { StoreHelperService } from '@core/services/store-helper.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import {
  AddBCompanyAccount, AddBCompanyAccountFail, AddBCompanyAccountSuccess,
  BillingCompanyAccountActionTypes, DeleteBCompanyAccount, DeleteBCompanyAccountFail, DeleteBCompanyAccountSuccess,
  LoadBCompanyAccounts,
  LoadBCompanyAccountsFail,
  LoadBCompanyAccountsSuccess, UpdateBCompanyAccount, UpdateBCompanyAccountFail, UpdateBCompanyAccountSuccess
} from './billing-company-account.actions';

@Injectable()
export class BillingCompanyAccountEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(BillingCompanyAccountActionTypes.AddBCompanyAccount),
    map((action: AddBCompanyAccount) => action.payload),
    exhaustMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.AddBCompanyAccount), skip(1));

      return this.bCompanyAccountService.add(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddBCompanyAccountSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddBCompanyAccountFail))
        );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(BillingCompanyAccountActionTypes.UpdateBCompanyAccount),
    map((action: UpdateBCompanyAccount) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.UpdateBCompanyAccount), skip(1));

      return this.bCompanyAccountService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateBCompanyAccountSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateBCompanyAccountFail))
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(BillingCompanyAccountActionTypes.DeleteBCompanyAccount),
    map((action: DeleteBCompanyAccount) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.DeleteBCompanyAccount), skip(1));

      return this.bCompanyAccountService.delete(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteBCompanyAccountSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteBCompanyAccountFail))
        );
    })
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(BillingCompanyAccountActionTypes.LoadBCompanyAccounts),
    map((action: LoadBCompanyAccounts) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(BillingCompanyAccountActionTypes.LoadBCompanyAccounts), skip(1));

      return this.bCompanyAccountService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadBCompanyAccountsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadBCompanyAccountsFail))
        );
    })
  );

  constructor(private actions$: Actions,
              private bCompanyAccountService: BCompanyAccountService,
              private storeHelper: StoreHelperService) {
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceConfActions, DeviceConfActionTypes } from './device-conf.actions';
import * as fromRoot from '@store/reducers/index';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IWSLAsyncErrors } from 'wsl-core';
import { IWSLDeviceConf } from '@app/device-conf/models/device-conf';

export interface DeviceConfState extends EntityState<IWSLDeviceConf> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLDeviceConf> = createEntityAdapter<IWSLDeviceConf>({
  selectId: (item: IWSLDeviceConf) => item.id,
  sortComparer: false
});

export const initialState: DeviceConfState = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(state = initialState, action: DeviceConfActions): DeviceConfState {
  switch (action.type) {

    case DeviceConfActionTypes.AddDeviceConf:
    case DeviceConfActionTypes.UpdateDeviceConf:
    case DeviceConfActionTypes.DeleteDeviceConf:
      return {...state, pending: true, errors: null};

    case DeviceConfActionTypes.AddDeviceConfSuccess:
    case DeviceConfActionTypes.UpdateDeviceConfSuccess:
    case DeviceConfActionTypes.DeleteDeviceConfSuccess:
      return {...state, pending: false};


    case DeviceConfActionTypes.AddDeviceConfFail:
    case DeviceConfActionTypes.UpdateDeviceConfFail:
    case DeviceConfActionTypes.DeleteDeviceConfFail:
      return {...state, pending: false, errors: action.payload.asyncMessages};

    case DeviceConfActionTypes.LoadDeviceConf:
      return {
        ...state,
        // selectedID: null,
        pending: true,
        loaded: false,
        errors: null
      };

    case DeviceConfActionTypes.LoadDeviceConfSuccess:
      return adapter.upsertOne({
        ...action.payload.item
      }, {
        ...state,
        pending: false,
        loaded: true
      });

    case DeviceConfActionTypes.LoadDeviceConfFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case DeviceConfActionTypes.SelectDeviceConf:
      return {...state, selectedID: action.payload};

    case DeviceConfActionTypes.LoadDeviceConfs:
      if (!action.payload.offset) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          pending: true,
          loaded: false,
          errors: null
        });
      }
      return {...state, pending: true, loaded: false, errors: null};

    case DeviceConfActionTypes.LoadDeviceConfsSuccess:
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case DeviceConfActionTypes.LoadDeviceConfsFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case DeviceConfActionTypes.ClearDeviceConfs:
      return adapter.removeAll({
        ...state,
        totalCount: 0,
        pending: false,
        loaded: true
      });

    default:
      return state;
  }
}

export const getSelectedID = (state: DeviceConfState) => state.selectedID;
export const getTotalCount = (state: DeviceConfState) => state.totalCount;
export const getLoaded = (state: DeviceConfState) => state.loaded;
export const getPending = (state: DeviceConfState) => state.pending;
export const getErrors = (state: DeviceConfState) => state.errors;

export interface State extends fromRoot.State {
  'deviceConf': DeviceConfState;
}

export const getDeviceConfState = createFeatureSelector<DeviceConfState>('deviceConf');

export const selectDeviceConfSelectedID = createSelector(getDeviceConfState, getSelectedID);
export const selectDeviceConfTotalCount = createSelector(getDeviceConfState, getTotalCount);
export const selectDeviceConfLoaded = createSelector(getDeviceConfState, getLoaded);
export const selectDeviceConfPending = createSelector(getDeviceConfState, getPending);
export const selectDeviceConfErrors = createSelector(getDeviceConfState, getErrors);

export const {
  selectEntities: selectDeviceConfEntities,
  selectAll: selectDeviceConfAll
} = adapter.getSelectors(getDeviceConfState);

export const selectDeviceConf = createSelector(
  selectDeviceConfEntities,
  selectDeviceConfSelectedID,
  (entities, id) => entities && entities[id] ? entities[id] : null
);

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AddDeviceConf,
  AddDeviceConfFail,
  AddDeviceConfSuccess,
  DeleteDeviceConf,
  DeleteDeviceConfFail,
  DeleteDeviceConfSuccess,
  DeviceConfActionTypes,
  LoadDeviceConf,
  LoadDeviceConfFail,
  LoadDeviceConfs,
  LoadDeviceConfsFail,
  LoadDeviceConfsSuccess,
  LoadDeviceConfSuccess,
  UpdateDeviceConf,
  UpdateDeviceConfFail,
  UpdateDeviceConfSuccess
} from './device-conf.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { DeviceConfService } from '@app/device-conf/services/device-conf.service';

@Injectable()
export class DeviceConfEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DeviceConfActionTypes.AddDeviceConf),
    map((action: AddDeviceConf) => action.payload),
    exhaustMap(payload => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfActionTypes.AddDeviceConf), skip(1));

      return this.deviceConfService.add(payload)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddDeviceConfSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddDeviceConfFail))
        );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(DeviceConfActionTypes.UpdateDeviceConf),
    map((action: UpdateDeviceConf) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfActionTypes.UpdateDeviceConf), skip(1));

      return this.deviceConfService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateDeviceConfSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateDeviceConfFail))
        );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(DeviceConfActionTypes.LoadDeviceConf),
    map((action: LoadDeviceConf) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfActionTypes.LoadDeviceConf), skip(1));

      return this.deviceConfService.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceConfSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceConfFail))
        );
    })
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(DeviceConfActionTypes.LoadDeviceConfs),
    map((action: LoadDeviceConfs) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfActionTypes.LoadDeviceConfs), skip(1));

      return this.deviceConfService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceConfsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceConfsFail))
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeviceConfActionTypes.DeleteDeviceConf),
    map((action: DeleteDeviceConf) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfActionTypes.DeleteDeviceConf), skip(1));

      return this.deviceConfService.delete(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteDeviceConfSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteDeviceConfFail))
        );
    })
  );

  @Effect()
  startLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceConfActionTypes.AddDeviceConf,
        DeviceConfActionTypes.LoadDeviceConf,
        DeviceConfActionTypes.UpdateDeviceConf,
        DeviceConfActionTypes.DeleteDeviceConf
      ),
      map(() => new StartLoading('device_conf'))
    );

  @Effect()
  startLoadMany$ = this.actions$
    .pipe(
      ofType(DeviceConfActionTypes.LoadDeviceConfs),
      map((action: LoadDeviceConfs) => action.payload),
      filter((payload) => payload.offset === 0),
      map(() => new StartLoading('device_conf'))
    );


  @Effect()
  stopLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceConfActionTypes.AddDeviceConfSuccess, DeviceConfActionTypes.AddDeviceConfFail,
        DeviceConfActionTypes.LoadDeviceConfSuccess, DeviceConfActionTypes.LoadDeviceConfFail,
        DeviceConfActionTypes.UpdateDeviceConfSuccess, DeviceConfActionTypes.UpdateDeviceConfFail,
        DeviceConfActionTypes.DeleteDeviceConfSuccess, DeviceConfActionTypes.DeleteDeviceConfFail,
        DeviceConfActionTypes.LoadDeviceConfsSuccess, DeviceConfActionTypes.LoadDeviceConfsFail
      ),
      map(() => new StopLoading('device_conf'))
    );

  constructor(private actions$: Actions,
              private router: Router,
              private route: ActivatedRoute,
              private storeHelper: StoreHelperService,
              private deviceConfService: DeviceConfService) {
  }
}

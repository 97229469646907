import { Action } from '@ngrx/store';
import { IWSLEntityFilter, IWSLHttpResponse } from 'wsl-core';
import { IWSLUser } from 'wsl-user';

export enum UserActionTypes {
  AddUsers = '[User] Add User',
  AddUsersSuccess = '[User] Add User Success',
  AddUsersFail = '[User] Add User Fail',

  UpdateUser = '[User] Update User',
  UpdateUserSuccess = '[User] Update User Success',
  UpdateUserFail = '[User] Update User Fail',

  DeleteUser = '[User] Delete User',
  DeleteUserSuccess = '[User] Delete User Success',
  DeleteUserFail = '[User] Delete User Fail',

  LoadUser = '[User] Load User',
  LoadUserSuccess = '[User] Load User Success',
  LoadUserFail = '[User] Load User Fail',

  SelectUser = '[User] Select',

  LoadUsers = '[User] Load Users',
  LoadUsersSuccess = '[User] Load Users Success',
  LoadUsersFail = '[User] Load Users Fail'
}

export class AddUsers implements Action {
  readonly type = UserActionTypes.AddUsers;

  constructor(public  payload: any) {
  }
}

export class AddUsersSuccess implements Action {
  readonly type = UserActionTypes.AddUsersSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLUser>) {
  }
}

export class AddUsersFail implements Action {
  readonly type = UserActionTypes.AddUsersFail;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;

  constructor(public  payload: IWSLUser) {
  }
}

export class UpdateUserSuccess implements Action {
  readonly type = UserActionTypes.UpdateUserSuccess;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export class UpdateUserFail implements Action {
  readonly type = UserActionTypes.UpdateUserFail;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export class DeleteUser implements Action {
  readonly type = UserActionTypes.DeleteUser;

  constructor(public  payload: number) {
  }
}

export class DeleteUserSuccess implements Action {
  readonly type = UserActionTypes.DeleteUserSuccess;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export class DeleteUserFail implements Action {
  readonly type = UserActionTypes.DeleteUserFail;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export class LoadUser implements Action {
  readonly type = UserActionTypes.LoadUser;

  constructor(public  payload: number) {
  }
}

export class LoadUserSuccess implements Action {
  readonly type = UserActionTypes.LoadUserSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLUser>) {
  }
}

export class LoadUserFail implements Action {
  readonly type = UserActionTypes.LoadUserFail;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export class SelectUser implements Action {
  readonly type = UserActionTypes.SelectUser;

  constructor(public payload: number) {
  }
}


export class LoadUsers implements Action {
  readonly type = UserActionTypes.LoadUsers;

  constructor(public  payload: IWSLEntityFilter) {
  }
}

export class LoadUsersSuccess implements Action {
  readonly type = UserActionTypes.LoadUsersSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLUser>) {
  }
}

export class LoadUsersFail implements Action {
  readonly type = UserActionTypes.LoadUsersFail;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export type UserActions =
  AddUsers |
  AddUsersSuccess |
  AddUsersFail |

  UpdateUser |
  UpdateUserSuccess |
  UpdateUserFail |

  DeleteUser |
  DeleteUserSuccess |
  DeleteUserFail |

  LoadUser |
  LoadUserSuccess |
  LoadUserFail |

  SelectUser |

  LoadUsers |
  LoadUsersSuccess |
  LoadUsersFail;

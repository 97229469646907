import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WSLApiHttpClientService } from 'wsl-core';
var BCompanyAccountService = /** @class */ (function (_super) {
    tslib_1.__extends(BCompanyAccountService, _super);
    function BCompanyAccountService(http) {
        var _this = _super.call(this, http, 'bcompany_account') || this;
        _this.http = http;
        return _this;
    }
    return BCompanyAccountService;
}(WSLApiHttpClientService));
export { BCompanyAccountService };

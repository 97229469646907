import { catchError, filter, map, take } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceValueService } from 'wsl-ek-core';
import { EMPTY } from 'rxjs';

@Injectable()
export class ResourceValueResolver implements Resolve<any> {
  constructor(private router: Router, private resourceValueService: ResourceValueService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return this.resourceValueService.get()
      .pipe(
        filter(res => res.items.length > 0),
        take(1),
        map(res => res.items),
        catchError(() => EMPTY)
      );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { IWSLDeviceConf } from '@app/device-conf/models/device-conf';
import { IWSLAsyncErrors } from 'wsl-core';
import { IWSLActionButton, IWSLFabActionButton, WSLActionButtonHelper, WSLActionButtonType } from 'wsl-shared';
import { IWSLDataConf } from '../../models/data-conf';
import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'wsl-device-conf-ext-list',
  templateUrl: './device-conf-ext-list.component.html',
  styles: [],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [

        query(':enter', style({opacity: 0}), {optional: true}),
        query(':enter', stagger('10ms', [
          animate('1s ease-in', keyframes([
            style({opacity: 0, transform: 'rotateX(90deg)', offset: 0}),
            style({opacity: 1, transform: 'rotateX(0deg)', offset: 0.3}),
          ]))]), {optional: true}),

        query(':leave', stagger('10ms', [
          animate('1s ease-in', keyframes([
            style({opacity: 1, transform: 'rotateX(0deg)', offset: 0}),
            style({opacity: 0, transform: 'rotateX(90deg)', offset: 0.3}),
          ]))]), {optional: true})
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfExtListComponent implements OnInit, OnChanges {
  @Input() conf: IWSLDataConf[];
  @Input() deviceConf: IWSLDeviceConf;
  @Input() selectedID: number;
  @Input() readonly = true;
  @Input() asyncErrors: IWSLAsyncErrors;
  @Output() edit = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();
  @Output() update = new EventEmitter<IWSLDataConf>();
  @Output() cancel = new EventEmitter<null>();
  /** @internal */
  actionButtons: Array<IWSLActionButton | IWSLFabActionButton> = [
    //  {...AddButtonType}
  ];

  constructor(private chr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes.conf) {
      this.validateConfiguration();
    }
  }

  get confs() {
    return this.conf ? this.conf.filter(c => c.dconf_id === this.deviceConf.id) : [];
  }

  onBtnClick(ev) {
    switch (ev) {
      case WSLActionButtonType.add:
        if (this.selectedID === null) {
          this.conf.push({
            id: this.conf.length > 0 ? (-this.conf.length - 1) : -1,
            index: this.conf.length > 0 ? (this.conf[this.conf.length - 1].index + this.conf[this.conf.length - 1].len) : 1,
            type_data: null, // DataConfType.other,
            len: 0,
            little_endian: true
          });
          this.conf = this.conf.slice(0);
          this.onEdit(this.conf[this.conf.length - 1].id);
        }
        break;
    }
  }

  onEdit(id: number) {
    this.actionButtons = WSLActionButtonHelper.dirtyAddActionButton(<any>{dirty: true}, this.actionButtons);
    // this.actionButtons = this.actionButtons.slice(0);
    this.edit.emit(id);
  }

  /*onUpdate(conf: IWSLDataConf) {
    this.update.emit(conf);
  }*/

  onCancel() {
    this.cancel.emit();

  }

  trackById(index: number, item: IWSLDataConf) {
    return item.id;
  }

  private validateConfiguration() {
    if (this.conf && !this.readonly) {
      this.conf = this.conf.map(p => {
        if (this.conf.some(c => c.id !== p.id && p.index >= c.index && p.index < c.index + c.len)) {
          return {
            ...p,
            meta: {
              wrongIndex: true
            }
          };
        }
        return {
          ...p,
          meta: {
            wrongIndex: false
          }
        };
      });
    }
    this.actionButtons = WSLActionButtonHelper.dirtyAddActionButton(<any>{dirty: false}, this.actionButtons);
    this.chr.markForCheck();
  }

}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
    LoadAvailableCompaniesFail,
    LoadAvailableCompaniesSuccess, LoadAvailableRoomsFail, LoadAvailableRoomsSuccess,
    LoadUserProfileFail,
    LoadUserProfileSuccess,
    UserProfileActionTypes
} from '../actions/user-profile.actions';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '../actions/app.actions';
import { StoreHelperService } from '@core/services/store-helper.service';
import { CompanyProfileService } from 'wsl-ek-core';
import { TownService } from '@core/services/town.service';
import {
  CompanyProfileActionTypes,
  LoadCompanyProfileFail,
  LoadCompanyProfileSuccess, StartPromotion, StartPromotionFailure, StartPromotionSuccess,
  UpdateCompanyProfile,
  UpdateCompanyProfileFail,
  UpdateCompanyProfileSuccess
} from '../actions/company-profile.actions';
import { TownActionTypes } from '../actions/town.actions';
import { UserProfileService } from 'wsl-ek-core';
import {environment} from "@env/environment";

@Injectable()
export class UserProfileEffects {

  @Effect()
  loadUserProfile$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserProfileActionTypes.LoadUserProfile),
      exhaustMap(() =>
        this.userProfileService
          .get()
          .pipe(
            map(response => new LoadUserProfileSuccess(response)),
            catchError(response => this.storeHelper.errorHandler(response, LoadUserProfileFail))
          )
      )
    );

  @Effect()
  loadCompanyProfile$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserProfileActionTypes.LoadUserProfileSuccess,
        CompanyProfileActionTypes.LoadCompanyProfile,
        CompanyProfileActionTypes.UpdateCompanyProfileSuccess,
        CompanyProfileActionTypes.StartPromotionSuccess),
      filter(action => this.companyProfileService.isUC() || this.companyProfileService.isDP() || this.companyProfileService.isTS() || (this.companyProfileService.isHomeUsers() && this.userProfileService.isAdmin())),
      exhaustMap(() =>
        this.companyProfileService
          .get()
          .pipe(
            map(response => new LoadCompanyProfileSuccess(response)),
            catchError(response => this.storeHelper.errorHandler(response, LoadCompanyProfileFail))
          )
      )
    );

  @Effect()
  updateCompanyProfile$: Observable<Action> = this.actions$
    .pipe(
      ofType(CompanyProfileActionTypes.UpdateCompanyProfile),
      map((action: UpdateCompanyProfile) => action.payload),
      switchMap((data) => {
          const nextLoad$ = this.actions$.pipe(ofType(CompanyProfileActionTypes.UpdateCompanyProfile), skip(1));

          return this.companyProfileService
            .update(data)
            .pipe(
              takeUntil(nextLoad$),
              map(response => new UpdateCompanyProfileSuccess(response)),
              catchError(response => this.storeHelper.errorHandler(response, UpdateCompanyProfileFail))
            );
        }
      )
    );

  @Effect()
  loadAvailableCompanies$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserProfileActionTypes.LoadUserProfileSuccess, UserProfileActionTypes.LoadAvailableCompanies),
      filter(action => !!environment.dom &&
        this.companyProfileService.isUC() ||
        this.companyProfileService.isDP() ||
        this.companyProfileService.isTS() ||
        (this.companyProfileService.isHomeUsers() && this.userProfileService.isAdmin())
      ),
      exhaustMap(() =>
        this.userProfileService
          .getAvailableCompanies()
          .pipe(
            map(response => new LoadAvailableCompaniesSuccess(response)),
            catchError(response => this.storeHelper.errorHandler(response, LoadAvailableCompaniesFail))
          )
      )
    );

    @Effect()
    loadAvailableRooms$: Observable<Action> = this.actions$
        .pipe(
            ofType(UserProfileActionTypes.LoadUserProfileSuccess, UserProfileActionTypes.LoadAvailableRooms),
            filter(action => !!environment.kv),
            exhaustMap(() =>
                this.userProfileService
                    .getAvailableRooms()
                    .pipe(
                        map(response => new LoadAvailableRoomsSuccess(response)),
                        catchError(response => this.storeHelper.errorHandler(response, LoadAvailableRoomsFail))
                    )
            )
        );

  /*  @Effect()
    loadUserTown$: Observable<Action> = this.actions$
      .pipe(
        ofType(UserProfileActionTypes.LoadUserProfileSuccess, TownActionTypes.LoadTownProfile),
        exhaustMap(() =>
          this.townService
            .get()
            .pipe(
              map(response => new LoadTownProfileSuccess(response)),
              catchError(response => this.storeHelper.errorHandler(response, LoadTownProfileFail))
            )
        )
      );*/

  @Effect()
  startPromotion$: Observable<Action> = this.actions$
    .pipe(
      ofType(CompanyProfileActionTypes.StartPromotion),
      map((action: StartPromotion) => action.payload),
      exhaustMap((data) =>
        this.companyProfileService
          .startPromotion(data)
          .pipe(
            map(response => new StartPromotionSuccess(response)),
            catchError(response => this.storeHelper.errorHandler(response, StartPromotionFailure))
          )
      )
    );

  @Effect()
  startLoad$: Observable<Action> = this.actions$
    .pipe(
      ofType(
        UserProfileActionTypes.LoadUserProfile,
        CompanyProfileActionTypes.LoadCompanyProfile,
        CompanyProfileActionTypes.UpdateCompanyProfile,
        CompanyProfileActionTypes.StartPromotion,
        UserProfileActionTypes.LoadAvailableCompanies,
        TownActionTypes.LoadTownProfile
      ),
      map(() => new StartLoading('profile'))
    );

  @Effect()
  stopLoad$: Observable<Action> = this.actions$
    .pipe(
      ofType(
        UserProfileActionTypes.LoadUserProfileSuccess, UserProfileActionTypes.LoadUserProfileFail,
        CompanyProfileActionTypes.LoadCompanyProfileSuccess, CompanyProfileActionTypes.LoadCompanyProfileFail,
        CompanyProfileActionTypes.UpdateCompanyProfileSuccess, CompanyProfileActionTypes.UpdateCompanyProfileFail,
        CompanyProfileActionTypes.StartPromotionSuccess, CompanyProfileActionTypes.StartPromotionFailure,
        UserProfileActionTypes.LoadAvailableCompaniesSuccess, UserProfileActionTypes.LoadAvailableCompaniesFail,
        TownActionTypes.LoadTownProfileSuccess, TownActionTypes.LoadTownProfileFail
      ),
      map(() => new StopLoading('profile'))
    );

  constructor(private actions$: Actions,
              private storeHelper: StoreHelperService,
              private userProfileService: UserProfileService,
              private companyProfileService: CompanyProfileService,
              private townService: TownService) {
  }
}

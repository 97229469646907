import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AppActionTypes, SetSnackBarMessage } from '../actions/app.actions';
var AppEffects = /** @class */ (function () {
    function AppEffects(actions$) {
        this.actions$ = actions$;
        this.clearAsyncMessages$ = this.actions$
            .pipe(ofType(AppActionTypes.SetSnackBarMessage), 
        // delay(5000),
        map(function (action) { return action.payload; }), filter(function (message) { return !!message; }), debounceTime(5000), distinctUntilChanged(), map(function () { return new SetSnackBarMessage(null); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppEffects.prototype, "clearAsyncMessages$", void 0);
    return AppEffects;
}());
export { AppEffects };

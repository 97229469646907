import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WSLApiHttpClientService } from 'wsl-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UnbalanceSettingsService = /** @class */ (function (_super) {
    tslib_1.__extends(UnbalanceSettingsService, _super);
    function UnbalanceSettingsService(http) {
        var _this = _super.call(this, http, 'unbalance_settings') || this;
        _this.http = http;
        return _this;
    }
    UnbalanceSettingsService.ngInjectableDef = i0.defineInjectable({ factory: function UnbalanceSettingsService_Factory() { return new UnbalanceSettingsService(i0.inject(i1.HttpClient)); }, token: UnbalanceSettingsService, providedIn: "root" });
    return UnbalanceSettingsService;
}(WSLApiHttpClientService));
export { UnbalanceSettingsService };

import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
var DeviceConfGroupFileIndexesComponent = /** @class */ (function () {
    function DeviceConfGroupFileIndexesComponent() {
        this.checklist = [];
        this.checkable = false;
        this.check = new EventEmitter();
        this.addConf = new EventEmitter();
        this.updateConf = new EventEmitter();
        this.confs = [];
        this.editingId = null;
        this.onlyCustom = false;
    }
    DeviceConfGroupFileIndexesComponent.prototype.ngOnInit = function () {
    };
    DeviceConfGroupFileIndexesComponent.prototype.ngOnChanges = function (changes) {
        if (changes.groupConfs || changes.companies) {
            this.defineConfs();
        }
    };
    DeviceConfGroupFileIndexesComponent.prototype.trackById = function (index, obj) {
        return obj.id;
    };
    DeviceConfGroupFileIndexesComponent.prototype.defineConfs = function () {
        var _this = this;
        this.confs = [];
        this.onlyCustom = false;
        if (this.groupConfs && this.groupConfs.length > 0) {
            this.confs = this.groupConfs.map(function (c) { return (tslib_1.__assign({}, c, { company_name: c.company_id && _this.companies && _this.companies.length > 0 ?
                    _this.companies.find(function (comp) { return comp.id === c.company_id; }).short_name : '' })); });
            this.onlyCustom = !!this.confs.find(function (c) { return c.company_id === null; });
        }
        if (!!this.editingId) {
            this.editingId = null;
        }
    };
    DeviceConfGroupFileIndexesComponent.prototype.onEditConf = function (id) {
        this.editingId = id;
    };
    DeviceConfGroupFileIndexesComponent.prototype.onUpdateConf = function (conf) {
        if (conf.id > 0) {
            this.updateConf.emit(conf);
        }
        else {
            delete conf.id;
            this.addConf.emit(conf);
        }
    };
    DeviceConfGroupFileIndexesComponent.prototype.onCancelEditConf = function () {
        if (this.editingId < 0) {
            this.confs = this.confs.slice(1);
        }
        this.editingId = null;
    };
    DeviceConfGroupFileIndexesComponent.prototype.onAddConf = function () {
        if (!!this.editingId) {
            return;
        }
        this.confs.unshift({
            id: -1,
            name: '',
            group_id: this.group.id,
            company_id: null,
            company_name: '',
            config: ''
        });
        this.editingId = -1;
    };
    return DeviceConfGroupFileIndexesComponent;
}());
export { DeviceConfGroupFileIndexesComponent };

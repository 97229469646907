import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { format, isSameHour } from 'date-fns';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DBDateTimeFormat, WSLHttpHelper, WSLLocalStorageService } from 'wsl-core';
import { ApiHttpClientWithPermissionsService, ObjectPermissions, StorageKey, UserProfileService } from 'wsl-ek-core';
var ObjectService = /** @class */ (function (_super) {
    tslib_1.__extends(ObjectService, _super);
    function ObjectService(http, localStorage, userProfileService) {
        var _this = _super.call(this, http, 'object', ObjectPermissions, userProfileService) || this;
        _this.http = http;
        _this.localStorage = localStorage;
        _this.userProfileService = userProfileService;
        return _this;
    }
    ObjectService.sort = function (a, b) {
        if (a.name === b.name) {
            return 0;
        }
        if (Number.isNaN(+a.name) && Number.isNaN(+b.name)) {
            return a.name < b.name ? -1 : 1;
        }
        else if (Number.isNaN(+a.name)) {
            return 1;
        }
        else if (Number.isNaN(+b.name)) {
            return -1;
        }
        return +a.name < +b.name ? -1 : 1;
    };
    ObjectService.prototype.generateActivationCode = function (object_id) {
        return this.http.get('generate_activation_code', { params: WSLHttpHelper.getParams({ object_id: object_id }) });
    };
    ObjectService.prototype.getObjectTypes = function () {
        var _this = this;
        var dict = this.localStorage.get(StorageKey.object_types);
        if (dict != null && isSameHour(new Date(), new Date(dict.time))) {
            return of(dict);
        }
        return this.http.get('getObjectType')
            .pipe(map(function (data) {
            _this.localStorage.set(StorageKey.object_types, {
                time: format(new Date(), DBDateTimeFormat),
                items: data.items
            });
            return data;
        }));
    };
    ObjectService.prototype.update = function (obj) {
        return this.http.put("object_living_info/" + obj.id, obj);
    };
    ObjectService.prototype.getMeteoStationSuggestionByCoordinate = function (lat, lon) {
        return this.http.get('meteoStationSuggestion', {
            params: WSLHttpHelper.getParams({
                lat: lat,
                lon: lon
            })
        });
    };
    ObjectService.prototype.getMeteoStationSuggestionByObject = function (object_id) {
        return this.http.get('meteoStationSuggestion', { params: WSLHttpHelper.getParams({ object_id: object_id }) });
    };
    return ObjectService;
}(ApiHttpClientWithPermissionsService));
export { ObjectService };

import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WSLApiHttpClientService } from 'wsl-core';
var DeviceConfExtService = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceConfExtService, _super);
    function DeviceConfExtService(http) {
        var _this = _super.call(this, http, 'device_conf_ext') || this;
        _this.http = http;
        return _this;
    }
    DeviceConfExtService.withImpulseSensors = function (confs) {
        return confs ? confs.some(function (c) { return c.is_sensor; }) : false;
        // const dmodel = device ? dmodels.find(dm => dm.id === device.dmodel_id) : null;
        // return dmodel ? !dmodel.ext.calc : false;
    };
    DeviceConfExtService.maxImpulseSensors = function (confs) {
        return confs ? confs.filter(function (c) { return c.is_sensor; }).length : 0;
    };
    DeviceConfExtService.prototype.deleteAll = function (id) {
        return this.http.delete(this.baseUrl + "_all/" + id);
    };
    return DeviceConfExtService;
}(WSLApiHttpClientService));
export { DeviceConfExtService };

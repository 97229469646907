import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { IWSLDataConfType } from '@app/device/models/data-conf-type';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IWSLDeviceExecution, IWSLDeviceModel } from 'wsl-device';
import { IWSLAsyncErrors, WSLFormHelper, WSLFormValidators, WSLWindowService } from 'wsl-core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AllSystems, IWSLResourceUnit, IWSLResourceValue, IWSLResourceVariable, ResourceType } from 'wsl-ek-core';
import { IWSLDataConf } from '../../models/data-conf';
import { IWSLDeviceConf } from '@app/device-conf/models/device-conf';
import { WSLMaterializeHelper } from 'wsl-shared';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[wsl-device-conf-ext-edit-row]',
  templateUrl: './device-conf-ext-edit-row.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfExtEditRowComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy {
  @Input() conf: IWSLDataConf[];
  @Input() deviceConf: IWSLDeviceConf;
  @Input() asyncErrors: IWSLAsyncErrors;
  @Output() save = new EventEmitter<IWSLDeviceConf>();
  @Output() cancel = new EventEmitter<any>();

  /** @internal */
  form = new FormGroup({
    dconf_id: new FormControl(null, [Validators.required]),
    id: new FormControl(null, []),
    //  name: new FormControl('', [Validators.required]),
    type_data: new FormControl(null, [Validators.required]),
    index: new FormControl(null, [Validators.required, WSLFormValidators.positiveNumberValidator]),
    len: new FormControl(null, [Validators.required, WSLFormValidators.positiveNumberValidator]),
    little_endian: new FormControl(true),
    resource_id: new FormControl(null),
    rvariable_id: new FormControl(null, [Validators.required]),
    rvalue_id: new FormControl(null),
    resource_inx: new FormControl(null),
    is_sensor: new FormControl(false),
    unit_id: new FormControl(null)
  });
  /** @internal */
  resources = [];
  /** @internal */
  dataTypes: IWSLDataConfType[] = [];

  private MSelectResource;
   private ngUnsubscribe: Subject<void> = new Subject<void>();
  private rvariables: IWSLResourceVariable[] = [];
  private rvalues: IWSLResourceValue[] = [];
  private units: IWSLResourceUnit[] = [];
  private dmodels: IWSLDeviceModel[] = [];
  private dexecutions: IWSLDeviceExecution[] = [];
  private maxResCount = 0;

  constructor(private route: ActivatedRoute,
              private chr: ChangeDetectorRef,
              private elem: ElementRef,
              private windowService: WSLWindowService) {
  }

  ngOnInit() {
    this.rvariables = this.route.snapshot.data.resourceVariables;
    this.rvalues = this.route.snapshot.data.resourceValues;
    this.units = this.route.snapshot.data.resourceUnits;
    this.dmodels = this.route.snapshot.data.dmodels;
    this.dexecutions = this.route.snapshot.data.dexecutions;
    this.dataTypes = this.route.snapshot.data.dataTypes;

    this.form.get('type_data').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe(val => {
        /* if (val === DataConfType.other) {
           //    this.form.get('len').setValue(null);
           this.form.get('len').setValidators([Validators.required, FormValidators.positiveNumberValidator]);
           this.form.get('len').enable();
         } else {*/
        const dataType = this.dataTypes.find(t => t.id === val);
        if (dataType) {
          this.form.get('len').setValue(dataType.len);
        }
        this.form.get('len').clearValidators();
        this.form.get('len').disable();
        // }
        this.form.get('len').updateValueAndValidity();
        this.chr.markForCheck();
      });
    this.form.get('resource_id').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe(val => {
        if (!this.availableRvariables.find(ar => ar.id === this.form.get('rvariable_id').value)) {
          this.form.get('rvariable_id').setValue(null);
          this.form.get('rvalue_id').setValue(null);
          this.form.get('resource_inx').setValue(null);
          this.form.get('unit_id').setValue(null);
        }

        if (val === ResourceType.system) {
          this.form.get('rvalue_id').clearValidators();
          this.form.get('rvalue_id').setValue(null);
          this.form.get('rvalue_id').disable();
          this.form.get('resource_inx').clearValidators();
          this.form.get('resource_inx').setValue(null);
          this.form.get('resource_inx').disable();
          this.form.get('unit_id').setValue(null);
          this.form.get('unit_id').clearValidators();
          this.form.get('unit_id').disable();
          this.form.get('is_sensor').setValue(false);
          this.form.get('is_sensor').disable();
        } else {
          this.form.get('rvalue_id').enable();
          this.form.get('resource_inx').enable();
          this.form.get('unit_id').enable();
          this.form.get('is_sensor').enable();

          this.defineValidators();

          this.form.get('unit_id').setValidators([Validators.required]);
        }
        this.form.updateValueAndValidity();
        this.chr.markForCheck();
      });
    this.form.get('rvariable_id').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe(val => {
        if (!this.availableRvalues.find(ar => ar.id === this.form.get('rvalue_id').value)) {
          this.form.get('rvalue_id').setValue(null);
          this.form.get('resource_inx').setValue(null);
          this.form.get('unit_id').setValue(null);
        }
        this.chr.markForCheck();
      });

    this.fillForm();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  ngOnChanges(changes) {
    if (changes.conf || changes.deviceConf) {
      this.fillForm();
    }
  }

  ngOnDestroy() {
    if (this.MSelectResource) {
      this.MSelectResource.destroy();
    }
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  get availableRvariables() {
    return this.form.get('resource_id').value ?
      this.rvariables.filter(rv => rv.resource_ids.includes(this.form.get('resource_id').value))
        .map(rv => ({...rv, name: rv.alias + ' - ' + rv.name})) :
      this.rvariables.map(rv => ({...rv, name: rv.alias + ' - ' + rv.name}));
  }

  get availableRvalues() {
    /* if (this.form.get('resource_id').value === ResourceType.system) {
       const rvar = this.rvariables.find(rv => rv.id === this.form.get('rvariable_id').value);
       if (!rvar) {
         return [];
       }
       if (rvar.alias === 'TimeArch') {
         return [
           {id: 1, name: 'YYYY-MM-DD'},
           {id: 2, name: 'YYYY-MM-DD HH:mm'},
           {id: 3, name: 'DD.MM.YYYY'}
         ];
       } else if (rvar.alias === 'Code') {
         return [];
       } else if (rvar.alias === 'Type') {
         return [];
       }
     }*/
    return this.rvalues.filter(rv => rv.resource_id === this.form.get('resource_id').value &&
      rv.rvariable_id === this.form.get('rvariable_id').value).map(rv => ({...rv, name: rv.name + ' - ' + rv.note}));
  }

  get availableUnits() {
    return this.units.filter(u => u.rvariable_id === this.form.get('rvariable_id').value);
  }

  get availableResourceNum() {
    return this.deviceConf && this.deviceConf.template ? Array.from(new Array(this.maxResCount), (val, index) => ({
        id: index + 1,
        name: `№ ${index + 1}`
      })) : [];
  }

  onSave() {
    this.form.updateValueAndValidity();
    WSLFormHelper.markAllDirty(this.form);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      const data = this.form.getRawValue();
      if (data.id < 0) {
        delete data.id;
      }
      this.save.emit(data);
    } else {
      WSLMaterializeHelper.toast({html: 'Форма заполнена не правильно', displayLength: 2000});
    }
    this.chr.markForCheck();
  }

  onCancel() {
    this.fillForm();
    this.cancel.emit();
  }

  trackById(index: number, obj: any): any {
    return obj.id;
  }

  private fillForm() {
    this.defineValidators();
    if (this.deviceConf && this.conf) {
      WSLFormHelper.fillForm(this.form, this.conf);
      this.form.get('dconf_id').setValue(this.deviceConf.id);
      this.form.updateValueAndValidity();
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.resources = AllSystems.map(ar => {
        return {
          ...ar,
          disabled: ar.id !== ResourceType.system && (!this.deviceConf || /*!this.deviceConf.dmodel_ids ||*/
            (this.deviceConf.template && this.dexecutions
                .filter(de =>
                  this.dmodels
                //    .filter(dm => this.deviceConf.dmodel_ids.includes(dm.id))
                    .map(dm => dm.id)
                    .includes(de.dmodel_id) && de.resources.includes(ar.id)
                ).length === 0
            ) ||
            (!this.deviceConf.template &&
              this.deviceConf.ext// &&
           //   this.deviceConf.ext.resources &&
           //   !this.deviceConf.ext.resources.find(r => r.resource_id === ar.id)
            ))
        };
      });
      this.maxResCount = 0;
     /* if (this.deviceConf.dmodel_ids) {
        this.dexecutions
          .filter(de =>
            this.dmodels
              .filter(dm => this.deviceConf.dmodel_ids.includes(dm.id))
              .map(dm => dm.id)
              .includes(de.dmodel_id)
          )
          .forEach(de => {
            const dmodel = this.dmodels.find(dm => dm.id === de.dmodel_id);
            if (de.v + dmodel.count_in > this.maxResCount) {
              this.maxResCount = de.v + dmodel.count_in;
            }
          });
      }*/
      setTimeout(() => {
        this.MSelectResource = WSLMaterializeHelper.initSelect('.resource_select');
      }, 350);
    }
  }

  private defineValidators() {
    if (this.form.get('resource_id').value !== ResourceType.system) {
      if (!this.deviceConf.template) {
        this.form.get('resource_id').setValidators([Validators.required]);
        this.form.get('rvalue_id').setValidators([Validators.required]);
        this.form.get('resource_inx').setValidators([Validators.required]);
      } else {
        this.form.get('resource_id').clearValidators();
        this.form.get('rvalue_id').clearValidators();
        this.form.get('resource_inx').clearValidators();
      }
    }
    this.form.updateValueAndValidity();
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      try {
        const p = this.windowService.getScrollingElement(this.elem.nativeElement);
        this.windowService.scrollToInContainer(this.elem.nativeElement.offsetTop, 150, p);
      } catch (err) {
      }
    }, 350);
  }

}

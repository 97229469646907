<div class="wsl-flex-ng form-control wsl-abs wsl-abs-bottom wsl-abs-left"
     style="overflow: hidden; white-space: nowrap; line-height: inherit;"
     [class.wsl-hide]="!inFocus">
  <span style="color: transparent;" [innerHTML]="hintText"></span>
  <span style="color: #aaa;" [innerHTML]="hintValue"></span>
</div>

<input #input
       type="{{type}}"
       placeholder="{{pH}}"
       class="form-control"
       [class.input-w-meta]="showMeta"
       [class.input-w-icon]="!showMeta && hasValue"
       autocomplete="off"
       autocorrect="off"
       [readonly]="disabled"
       [disabled]="disabled"
       [attr.id]="inputID"
       [value]="value"
       [wslSetFocus]="focus"
       (focus)="onFocus()"
       (blur)="onBlur($event)"
       (keyup)="keyup.next($event)">


<ul class="wsl-bg-white wsl-m-t-5 wsl-abs z-depth-1 wsl-w-100"
    style="z-index: 999; overflow-y: auto;"
    [style.max-height.px]="maxHeight"
    *ngIf="!disabled"
    (mousedown)="onMousedownList($event)"
    [wslClickOutsideListen]="inFocus"
    (wslClickOutside)="onBlurList($event)"
    [class.wsl-hide]="!inFocus">
  <ng-content></ng-content>
</ul>

import * as tslib_1 from "tslib";
var _a;
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunicatorActionTypes } from './communicator.actions';
import { createEntityAdapter } from '@ngrx/entity';
export var adapter = createEntityAdapter({
    selectId: function (item) { return item.id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case CommunicatorActionTypes.AddCommunicator:
        case CommunicatorActionTypes.UpdateCommunicator:
        case CommunicatorActionTypes.DeleteCommunicator:
            return tslib_1.__assign({}, state, { pending: true, errors: null });
        case CommunicatorActionTypes.AddCommunicatorSuccess:
        case CommunicatorActionTypes.UpdateCommunicatorSuccess:
        case CommunicatorActionTypes.DeleteCommunicatorSuccess:
            return tslib_1.__assign({}, state, { pending: false });
        case CommunicatorActionTypes.AddCommunicatorFail:
        case CommunicatorActionTypes.UpdateCommunicatorFail:
        case CommunicatorActionTypes.DeleteCommunicatorFail:
            return tslib_1.__assign({}, state, { pending: false, errors: action.payload.asyncMessages });
        case CommunicatorActionTypes.LoadCommunicator:
            return tslib_1.__assign({}, state, { 
                // selectedID: null,
                pending: true, loaded: false, errors: null });
        case CommunicatorActionTypes.LoadCommunicatorSuccess:
            return adapter.upsertOne(tslib_1.__assign({}, action.payload.item), tslib_1.__assign({}, state, { pending: false, loaded: true }));
        case CommunicatorActionTypes.LoadCommunicatorFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case CommunicatorActionTypes.SelectCommunicator:
            return tslib_1.__assign({}, state, { selectedID: action.payload });
        case CommunicatorActionTypes.LoadCommunicators:
            if (!action.payload.offset) {
                return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: true, loaded: false, errors: null }));
            }
            return tslib_1.__assign({}, state, { pending: true, loaded: false, errors: null });
        case CommunicatorActionTypes.LoadCommunicatorsSuccess:
            return adapter.upsertMany(action.payload.items, tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true }));
        case CommunicatorActionTypes.LoadCommunicatorsFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case CommunicatorActionTypes.ClearCommunicators:
            return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: false, loaded: true }));
        default:
            return state;
    }
}
export var getSelectedID = function (state) { return state.selectedID; };
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
export var getErrors = function (state) { return state.errors; };
export var getCommunicatorState = createFeatureSelector('communicator');
export var selectCommunicatorSelectedID = createSelector(getCommunicatorState, getSelectedID);
export var selectCommunicatorTotalCount = createSelector(getCommunicatorState, getTotalCount);
export var selectCommunicatorLoaded = createSelector(getCommunicatorState, getLoaded);
export var selectCommunicatorPending = createSelector(getCommunicatorState, getPending);
export var selectCommunicatorErrors = createSelector(getCommunicatorState, getErrors);
export var selectCommunicatorEntities = (_a = adapter.getSelectors(getCommunicatorState), _a.selectEntities), selectCommunicatorAll = _a.selectAll;
export var selectCommunicator = createSelector(selectCommunicatorEntities, selectCommunicatorSelectedID, function (entities, id) { return entities && entities[id] ? entities[id] : null; });

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {DeviceConfGroupFileIndexService} from '@app/device-conf-group/services/device-conf-group-file-index.service';
import {catchError, map, mergeMap, skip, switchMap, takeUntil} from 'rxjs/operators';
import {
  DeviceConfGroupFileIndexActionTypes, LoadDeviceConfGroupFileIndex,
  LoadDeviceConfGroupFileIndexes,
  LoadDeviceConfGroupFileIndexesFail,
  LoadDeviceConfGroupFileIndexesSuccess, LoadDeviceConfGroupFileIndexFail, LoadDeviceConfGroupFileIndexSuccess
} from '@store/device-conf-group-file-index/actions/device-conf-group-file-index.actions';
import {StoreHelperService} from '@core/services/store-helper.service';


@Injectable()
export class DeviceConfGroupFileIndexEffects {

  @Effect()
  load$ = this.actions$.pipe(
    ofType(DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndex),
    map((action: LoadDeviceConfGroupFileIndex) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndex), skip(1));

      return this.service.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceConfGroupFileIndexSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceConfGroupFileIndexFail))
        );
    })
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexes),
    map((action: LoadDeviceConfGroupFileIndexes) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfGroupFileIndexActionTypes.LoadDeviceConfGroupFileIndexes), skip(1));

      return this.service.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceConfGroupFileIndexesSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceConfGroupFileIndexesFail))
        );
    })
  );

  constructor(private actions$: Actions,
              private service: DeviceConfGroupFileIndexService,
              private storeHelper: StoreHelperService) {}

}

// import { WorkerAppModule } from '@angular/platform-webworker';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { ApplicationRef } from '@angular/core';
import { getRuPaginatorIntl } from '@app/ru-paginator-intl';
import { getLocalStorage, getWindow } from 'wsl-core';
import { StorageKey } from 'wsl-ek-core';
import { createInputTransfer, createNewHosts, removeNgStyles } from '@angularclass/hmr';
registerLocaleData(localeRu);
export function getToken() {
    if (typeof window !== 'undefined' && window.localStorage) {
        /** Is platform browser */
        var token = window.localStorage.getItem(StorageKey.token);
        if (token) {
            return token;
        }
        return null;
    }
    return null;
    // return LocalStorageService.get(StorageKey.token);
}
var ɵ0 = getLocalStorage, ɵ1 = getWindow, ɵ2 = getRuPaginatorIntl();
var AppModule = /** @class */ (function () {
    function AppModule(appRef) {
        this.appRef = appRef;
    }
    AppModule.prototype.hmrOnInit = function (store) {
        if (!store || !store.state) {
            return;
        }
        if ('restoreInputValues' in store) {
            store.restoreInputValues();
        }
        // change detection
        this.appRef.tick();
        delete store.state;
        delete store.restoreInputValues;
    };
    AppModule.prototype.hmrOnDestroy = function (store) {
        var cmpLocation = this.appRef.components.map(function (cmp) { return cmp.location.nativeElement; });
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // inject your AppStore and grab state then set it on store
        // var appState = this.AppStore.get()
        store.state = { data: 'yolo' };
        // store.state = Object.assign({}, appState)
        // save input values
        store.restoreInputValues = createInputTransfer();
        // remove styles
        removeNgStyles();
    };
    AppModule.prototype.hmrAfterDestroy = function (store) {
        // display new elements
        store.disposeOldHosts();
        delete store.disposeOldHosts;
        // anything you need done the component is removed
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };

import { NgModule } from '@angular/core';
import { HomeUsersFilterComponent } from '@app/user/components/home-users-filter/home-users-filter.component';
import { HomeUsersListComponent } from '@app/user/components/home-users-list/home-users-list.component';
import { HomeUsersRowComponent } from '@app/user/components/home-users-row/home-users-row.component';
import { WslSharedModule } from 'wsl-shared';
import { AppCommonModule } from '@common/app-common.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromUser from '@store/user/user.reducer';
import { UserEffects } from '@store/user/user.effects';
import { WslUserModule } from 'wsl-user';

@NgModule({
  imports: [
    WslSharedModule,
    WslUserModule,
    AppCommonModule,
    StoreModule.forFeature('user', fromUser.reducer),
    EffectsModule.forFeature([UserEffects])
  ],
  declarations: [
    HomeUsersListComponent,
    HomeUsersFilterComponent,
    HomeUsersRowComponent
  ],
  exports: [
    WslUserModule,
    HomeUsersListComponent,
    HomeUsersFilterComponent
  ]
})
export class UserModule { }

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AddDeviceSensor,
  AddDeviceSensorFail,
  AddDeviceSensorSuccess,
  DeleteDeviceSensor,
  DeleteDeviceSensorFail, DeleteDeviceSensors, DeleteDeviceSensorsFail, DeleteDeviceSensorsSuccess,
  DeleteDeviceSensorSuccess,
  DeviceSensorActionTypes,
  LoadDeviceSensor,
  LoadDeviceSensorFail,
  LoadDeviceSensors,
  LoadDeviceSensorsFail,
  LoadDeviceSensorsSuccess,
  LoadDeviceSensorSuccess,
  UpdateDeviceSensor,
  UpdateDeviceSensorFail,
  UpdateDeviceSensorSuccess,
  UpsertDeviceSensors,
  UpsertDeviceSensorsFail,
  UpsertDeviceSensorsSuccess
} from './device-sensor.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { DeviceSensorService } from '@app/device-sensor/services/device-sensor.service';
import {forkJoin, Observable, of} from "rxjs";

@Injectable()
export class DeviceSensorEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DeviceSensorActionTypes.AddDeviceSensor),
    map((action: AddDeviceSensor) => action.payload),
    exhaustMap(payload => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.AddDeviceSensor), skip(1));

      return this.deviceConfService.add(payload)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddDeviceSensorSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddDeviceSensorFail))
        );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(DeviceSensorActionTypes.UpdateDeviceSensor),
    map((action: UpdateDeviceSensor) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.UpdateDeviceSensor), skip(1));

      return this.deviceConfService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateDeviceSensorSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateDeviceSensorFail))
        );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(DeviceSensorActionTypes.LoadDeviceSensor),
    map((action: LoadDeviceSensor) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.LoadDeviceSensor), skip(1));

      return this.deviceConfService.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceSensorSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceSensorFail))
        );
    })
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(DeviceSensorActionTypes.LoadDeviceSensors),
    map((action: LoadDeviceSensors) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.LoadDeviceSensors), skip(1));

      return this.deviceConfService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceSensorsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceSensorsFail))
        );
    })
  );

  @Effect()
  upsertMany$ = this.actions$.pipe(
    ofType(DeviceSensorActionTypes.UpsertDeviceSensors),
    map((action: UpsertDeviceSensors) => action.payload),
    exhaustMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.UpsertDeviceSensors), skip(1));

      return this.deviceConfService.upsertMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpsertDeviceSensorsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpsertDeviceSensorsFail))
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeviceSensorActionTypes.DeleteDeviceSensor),
    map((action: DeleteDeviceSensor) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.DeleteDeviceSensor), skip(1));

      return this.deviceConfService.delete(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteDeviceSensorSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteDeviceSensorFail))
        );
    })
  );

  @Effect()
  deleteMany$ = this.actions$.pipe(
      ofType(DeviceSensorActionTypes.DeleteDeviceSensors),
      map((action: DeleteDeviceSensors) => action.payload),
      switchMap((data: number[]) => {
        const nextLoad$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.DeleteDeviceSensors), skip(1));

        return of(data)
            .pipe(
                takeUntil(nextLoad$),
                mergeMap(ids => forkJoin(...ids.map(id => this.deviceConfService.delete(id)))),
                mergeMap(resp => this.storeHelper.successHandler(resp, DeleteDeviceSensorsSuccess)),
                catchError(resp => this.storeHelper.errorHandler(resp, DeleteDeviceSensorsFail))
            );

      })
  );

  @Effect()
  startLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceSensorActionTypes.AddDeviceSensor,
        DeviceSensorActionTypes.LoadDeviceSensor,
        DeviceSensorActionTypes.UpdateDeviceSensor,
        DeviceSensorActionTypes.DeleteDeviceSensor,
        DeviceSensorActionTypes.UpsertDeviceSensors,
      ),
      map(() => new StartLoading('device_sensor'))
    );

  @Effect()
  startLoadMany$ = this.actions$
    .pipe(
      ofType(DeviceSensorActionTypes.LoadDeviceSensors),
      map((action: LoadDeviceSensors) => action.payload),
      filter((payload) => payload.offset === 0),
      map(() => new StartLoading('device_sensor'))
    );


  @Effect()
  stopLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceSensorActionTypes.AddDeviceSensorSuccess, DeviceSensorActionTypes.AddDeviceSensorFail,
        DeviceSensorActionTypes.LoadDeviceSensorSuccess, DeviceSensorActionTypes.LoadDeviceSensorFail,
        DeviceSensorActionTypes.UpdateDeviceSensorSuccess, DeviceSensorActionTypes.UpdateDeviceSensorFail,
        DeviceSensorActionTypes.DeleteDeviceSensorSuccess, DeviceSensorActionTypes.DeleteDeviceSensorFail,
        DeviceSensorActionTypes.LoadDeviceSensorsSuccess, DeviceSensorActionTypes.LoadDeviceSensorsFail,
        DeviceSensorActionTypes.UpsertDeviceSensorsSuccess, DeviceSensorActionTypes.UpsertDeviceSensorsFail
      ),
      map(() => new StopLoading('device_sensor'))
    );

  constructor(private actions$: Actions,
              private router: Router,
              private route: ActivatedRoute,
              private storeHelper: StoreHelperService,
              private deviceConfService: DeviceSensorService) {
  }
}

import {Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output} from '@angular/core';
import {IDeviceDocument} from '@app/device/models/device-document.model';
import {IWSLUser} from 'wsl-user';
import {UserProfileService} from 'wsl-ek-core';
import {MomentDBDateTimeFormat} from 'wsl-core';
import * as moment from 'moment';
import {DeviceDocumentTypes} from '@app/device/enums/device-document-type.enum';

@Component({
  selector: 'wsl-device-file',
  templateUrl: './device-file.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceFileComponent implements OnInit {
  @Input() file: IDeviceDocument;
  @Input() users: IWSLUser[];
  @Output() downloadFile = new EventEmitter<number>();
  @Output() deleteFile = new EventEmitter<number>();

  type = null;
  cuser: IWSLUser;
  canDelete = false;

  constructor(private userProfileService: UserProfileService) { }

  ngOnInit() {
    this.type = DeviceDocumentTypes.find(ddt => ddt.id === this.file.type);
    this.cuser = this.users ? this.users.find(u => u.id === this.file.cuser) : null;
    this.canDelete = this.cuser && this.cuser.id === this.userProfileService.getUserID() &&
      moment().isSame(moment(this.file.ctime, MomentDBDateTimeFormat), 'day');
  }

}

import { Injectable } from '@angular/core';
import { StorageKey } from 'wsl-ek-core';
import { HttpClient } from '@angular/common/http';
import { DBDateTimeFormat, IWSLHttpResponse, WSLHttpHelper, WSLLocalStorageService, WSLUtils } from 'wsl-core';
import { format, isSameHour } from 'date-fns';
import { IWSLDeviceSchemaFilter } from '../models/device-schema-filter';
import { IWSLDeviceSchema } from '../models/device-schema';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class DeviceSchemaService {
  constructor(private http: HttpClient, private localStorage: WSLLocalStorageService) {
  }

  public get(filter: IWSLDeviceSchemaFilter) {
    if (WSLUtils.isEmptyObject(filter)) {
      const dict = this.localStorage.get(StorageKey.device_schemas);
      if (dict != null && isSameHour(new Date(), new Date(dict.time))) {
        return of(dict);
      }
    }
    return this.http.get<IWSLHttpResponse<IWSLDeviceSchema>>('device_schema', {
      params: WSLHttpHelper.getParams(filter)
    })
      .pipe(map(data => {
        if (WSLUtils.isEmptyObject(filter)) {
          this.localStorage.set(StorageKey.device_schemas, {
            time: format(new Date(), DBDateTimeFormat),
            items: data.items
          });
        }
        return data;
      }));
  }

}

import { NgModule } from '@angular/core';
import { DeviceChipComponent } from '@app/device/components/device-chip/device-chip.component';
import { DevicesPerRoomsListComponent } from '@app/device/components/devices-per-rooms-list/devices-per-rooms-list.component';
import { DevicesPerRoomsRowComponent } from '@app/device/components/devices-per-rooms-row/devices-per-rooms-row.component';
import { SelectDeviceComponent } from '@app/device/components/select-device/select-device.component';
import { HasDevicesGuard } from '@app/device/guards/has-devices.guard';
import { StoreModule } from '@ngrx/store';
import * as fromDevice from '@store/device/device.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DeviceEffects } from '@store/device/device.effects';
import { DeviceModelService } from './services/device-model.service';
import { DeviceExecutionService } from './services/device-execution.service';
import { DeviceSchemaService } from './services/device-schema.service';
import { DeviceExistsGuard } from './guards/device-exists.guard';
import { DeviceModelsResolver } from './resolvers/device-models.resolver';
import { DeviceExecutionsResolver } from './resolvers/device-executions.resolver';
import { DeviceSchemasResolver } from './resolvers/device-schemas.resolver';
import { WslSharedModule } from 'wsl-shared';
import { AppCommonModule } from '@common/app-common.module';
import { DevicesTableComponent } from './components/devices-table/devices-table.component';
import { DeviceRowComponent } from './components/device-row/device-row.component';
import { CommunicatorModule } from '../communicator/communicator.module';
import { ObjectModule } from '../object/object.module';
import { RoomModule } from '../room/room.module';
import { DataConfTypeService } from './services/data-conf-type.service';
import { DataConfTypesResolver } from './resolvers/data-conf-types.resolver';
import { DeviceConfModule } from '../device-conf/device-conf.module';
import { DeviceConfExtModule } from '../device-conf-ext/device-conf-ext.module';
import { DeviceSensorModule } from '../device-sensor/device-sensor.module';
import { DeviceConfGroupModule } from '../device-conf-group/device-conf-group.module';
import { DeviceBindService } from './services/device-bind.service';
import { DeviceBriefInfoComponent } from './components/device-brief-info/device-brief-info.component';
import { DeviceVerificationTableComponent } from '@app/device/components/device-verification-table/device-verification-table.component';
import { DeviceVerificationRowComponent } from '@app/device/components/device-verification-row/device-verification-row.component';
import { WslDeviceModule, } from 'wsl-device';
import {ElectDeviceTariffsDataChartComponent} from '@app/device/components/elect-device-tariffs-data-chart/elect-device-tariffs-data-chart.component';
import {DeviceCardProComponent} from '@app/device/components/device-card-pro/device-card-pro.component';
import {ElectPowerProfilePerDeviceComponent} from '@app/device/components/elect-power-profile-per-device/elect-power-profile-per-device.component';
import {AddDeviceGuideComponent} from '@app/device/components/add-device-guide/add-device-guide.component';
import {AddDeviceSelectPlacementTypeComponent} from '@app/device/components/add-device-select-placement-type/add-device-select-placement-type.component';
import {AddDeviceSelectLocationComponent} from '@app/device/components/add-device-select-location/add-device-select-location.component';
import {AddDeviceBarcodeInputComponent} from '@app/device/components/add-device-barcode-input/add-device-barcode-input.component';
import {AddDeviceSetupMeteringNodeComponent} from '@app/device/components/add-device-setup-metering-node/add-device-setup-metering-node.component';
import {AddDeviceSelectConfigurationComponent} from '@app/device/components/add-device-select-configuration/add-device-select-configuration.component';
import {DeviceInfoComponent} from '@app/device/components/device-info/device-info.component';
import {DeviceInputSetupFormComponent} from '@app/device/components/device-input-setup-form/device-input-setup-form.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AddDeviceSelectCommunicatorComponent} from '@app/device/components/add-device-select-communicator/add-device-select-communicator.component';
import { DeviceFilesComponent } from './components/device-files/device-files.component';
import {DeviceDocumentService} from '@app/device/services/device-document.service';
import { AddDeviceFileComponent } from './components/add-device-file/add-device-file.component';
import {DeviceFileComponent} from '@app/device/components/device-file/device-file.component';

const COMPONENTS = [
  DevicesTableComponent,
  DeviceRowComponent,
  DeviceBriefInfoComponent,
  DeviceVerificationTableComponent,
  DeviceVerificationRowComponent,
  DevicesPerRoomsListComponent,
  DevicesPerRoomsRowComponent,
  DeviceChipComponent,
  SelectDeviceComponent,
  ElectDeviceTariffsDataChartComponent,
  DeviceCardProComponent,
  ElectPowerProfilePerDeviceComponent,
  AddDeviceGuideComponent,
  AddDeviceSelectPlacementTypeComponent,
  AddDeviceSelectLocationComponent,
  AddDeviceBarcodeInputComponent,
  AddDeviceSetupMeteringNodeComponent,
  AddDeviceSelectConfigurationComponent,
  DeviceInfoComponent,
  DeviceInputSetupFormComponent,
  AddDeviceSelectCommunicatorComponent,
  DeviceFilesComponent,
  DeviceFileComponent
];

@NgModule({
  imports: [
    WslSharedModule,
    AppCommonModule,
    DragDropModule,
    WslDeviceModule,
    ObjectModule,
    RoomModule,
    CommunicatorModule,
    DeviceConfGroupModule,
    DeviceConfModule,
    DeviceConfExtModule,
    DeviceSensorModule,
    StoreModule.forFeature('device', fromDevice.reducer),
    EffectsModule.forFeature([DeviceEffects])
  ],
  declarations: [
    COMPONENTS,
    AddDeviceFileComponent,
  ],
  providers: [
    DeviceModelService,
    DeviceExecutionService,
    DeviceSchemaService,
    DataConfTypeService,
    DeviceBindService,
    DeviceDocumentService,

    DeviceExistsGuard,
    HasDevicesGuard,

    DeviceModelsResolver,
    DeviceExecutionsResolver,
    DeviceSchemasResolver,
    DataConfTypesResolver
  ],
  exports: [WslDeviceModule, ...COMPONENTS]
})
export class DeviceModule {
}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { CompanyRoleType } from 'wsl-ek-core';
import { CompanyProfileService } from 'wsl-ek-core';

@Injectable()
export class TechSupportGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private companyService: CompanyProfileService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  private check() {
    switch (this.companyService.getRoleId()) {
      case CompanyRoleType.uc:
        this.router.navigateByUrl('/data');
        return false;
      case CompanyRoleType.data_provider:
        this.router.navigateByUrl('/operator');
        return false;
      case CompanyRoleType.tech_support:
        return true;
      default:
        this.router.navigateByUrl('/login');
        return false;
    }
  }
}

export const environment = {
  production: false,
  hmr: false,
  dom: false,
  kv: true,
  home: false,
  link: '/',
  absoluteLink: 'https://karat-b2c.ws-lab.ru',
  homeLink: 'https://karat-wp.ws-lab.ru',
  hubCrossStorageLink: 'https://karat-b2b.ws-lab.ru'
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWSLBillingCompany, IWSLBillingCompanyFilter } from '@app/billing-company/models/billing-company';
import {
  ApiHttpClientWithPermissionsService,
  BillingCompanyPermissions,
  IWSLEntityPermissions,
  UserProfileService
} from 'wsl-ek-core';

@Injectable({
  providedIn: 'root'
})
export class BillingCompanyService extends ApiHttpClientWithPermissionsService<IWSLBillingCompany, IWSLBillingCompanyFilter> {

  constructor(public http: HttpClient, public userProfileService: UserProfileService) {
    super(http, 'bcompany', <IWSLEntityPermissions>BillingCompanyPermissions, userProfileService);
  }

  public getServices(id: number) {
    return this.http.get('bcompany_link');
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IWSLDataConf} from '@app/device-conf-ext/models/data-conf';
import {IWSLAsyncErrors, WSLFormHelper, WSLUtils} from 'wsl-core';
import {DataArchiveTypes} from 'wsl-ek-core';
import {Subject} from 'rxjs';
import {IWSLDeviceConf} from '../../models/device-conf';
import {ActivatedRoute, Router} from '@angular/router';
import {KaratLoraProtocolService} from '@core/services/karat-lora-protocol.service';

@Component({
  selector: 'wsl-device-conf-form',
  templateUrl: './device-conf-form.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() deviceConf: IWSLDeviceConf;
  @Input() baseDeviceConf: IWSLDeviceConf;
  @Input() asyncErrors: IWSLAsyncErrors;
  @Input() prefix = WSLUtils.randomID();

  /** @internal */
  form = new FormGroup({
    id: new FormControl(null),
    template: new FormControl(false), // глобальная либа или компании @todo add external param
    kit_id: new FormControl(null), // комплект пакетов лора
    code: new FormControl(null), // код лора
    base: new FormControl({value: true, disable: true}), // основной пакет
    name: new FormControl('', [Validators.required]),
    archive_types: new FormControl([]),
  //  protocols: new FormControl([]),
    magnet_control: new FormControl(false),
    //  resource_count: new FormControl(0),
    //  resources: new FormArray([])
  });
  /** @internal */
  multiArchiveTypes = [...DataArchiveTypes];
  confs: IWSLDataConf[] = [];

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  karatLoraProtocols = KaratLoraProtocolService.get();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private chr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.fillForm();
  }

  ngOnChanges(changes) {
    if (changes.deviceConf || changes.baseDeviceConf) {
      this.fillForm();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onSelectConnection(connect_id) {
    if (this.form.get('type').value !== connect_id) {
      this.form.get('type').setValue(connect_id);
      this.form.markAsDirty();
    }
  }

  private fillForm() {
    if (!this.form) {
      return;
    }
    if (this.deviceConf) {
      WSLFormHelper.fillForm(this.form, this.deviceConf);
      if (this.deviceConf.ext) {
        this.form.get('magnet_control').setValue(this.deviceConf.ext.magnet_control);
        if (this.deviceConf.ext.archive_types === null) {
          this.form.get('archive_types').setValue(this.multiArchiveTypes.map(a => a.id));
        } else if (!!this.deviceConf.ext.archive_types) {
          this.form.get('archive_types').setValue(this.deviceConf.ext.archive_types);
        }
      }
      this.form.markAsPristine();
      this.form.markAsUntouched();
    }
    this.form.disable();
  }

}

import {Injectable} from '@angular/core';
import {ObjectService} from '@app/object/services/object.service';
import {MainMenuType} from '@core/enums/main-menu-type.enum';
import {environment} from '@env/environment';
import {Observable, of, timer} from 'rxjs';
import {catchError, filter, map, switchMap, take, tap, timeout} from 'rxjs/operators';
import {IWSLMenu, IWSLNavMenu, IWSLTabMenu} from 'wsl-core';
import {
  AppealPermissions,
  CommunicatorPermissions,
  CompanyProfileService,
  DataPermissions,
  DevicePermissions,
  DeviceServiceProcedurePermissions,
  EK_ROLES,
  EventSubscribePermissions,
  EventTypePermissions,
  MonitoringPermissions,
  NoticePermissions,
  ObjectPermissions,
  ObjectRoomOwnerPermissions,
  ObjectRoomPermissions,
  ReportPermissions,
  ResourceType,
  UserProfileService
} from 'wsl-ek-core';
import {IWSLDevice} from 'wsl-device';
import {DefineDataFilterService} from '@core/services/define-data-filter.service';

@Injectable({
  providedIn: 'root'
})
export class MenuHelperService {
  public mainMenu: IWSLMenu[] = [];
  public settingMenu: IWSLTabMenu[] = [];
  public serviceMenu: IWSLTabMenu[] = [];
  // public reportMenu: IWSLTabMenu[] = [];

  private hasObjects = false;
  private mainMenuDefined = false;

  private availableArchives = [];
  private availableResources = [];

  constructor(private userProfileService: UserProfileService,
              private companyProfileService: CompanyProfileService,
              private objectService: ObjectService,
              private defineFilterService: DefineDataFilterService) {
  }

  public defineMainMenu(): Observable<IWSLMenu[]> {
    const mainMenu: IWSLMenu[] = [];
    this.reset();

    if (environment.dom && this.companyProfileService.isUC()) {
      if (this.userProfileService.isController()) {
        mainMenu.push({
          route: '/control',
          name: 'Поверка',
          icon: 'icon-calendar-31',
          id: null
        });
      } else {
        const hasObjPerm = this.userProfileService.hasPermission(ObjectPermissions.get);
        const objectsGuard: Observable<IWSLMenu[]> = hasObjPerm ?
          this.objectService
            .getMany({offset: 0})
            .pipe(
              // take(1),
              map(res => res.items && res.items.length > 0),
              map(hasObjects => {
                this.hasObjects = hasObjects;
                if (hasObjects) {
                  if (this.userProfileService.hasPermission([DataPermissions.showObjectData])) {
                    mainMenu.push({route: '/data', name: 'Учет', icon: 'icon-graph', id: MainMenuType.data});
                  }

                  // has either roles
                  if (this.userProfileService.hasPermission([
                    MonitoringPermissions.showDeviceData,
                    MonitoringPermissions.showLoraFrames,
                    MonitoringPermissions.showTempGraphic,
                    MonitoringPermissions.showDeviations,
                    MonitoringPermissions.showEvents
                  ])) {
                    mainMenu.push({route: '/monitoring', name: 'Мониторинг', icon: 'icon-pulse', id: MainMenuType.monitoring});
                  }

                  // has either role
                  if (this.userProfileService.hasPermission([ReportPermissions.showData, ReportPermissions.showBilling])) {
                    mainMenu.push({route: '/reports', name: 'Отчеты', icon: 'icon-file-stats', id: MainMenuType.reports});
                  }

                  // has either role
                  if (this.userProfileService.hasPermission([DevicePermissions.get, AppealPermissions.get, NoticePermissions.get])) {
                    mainMenu.push({route: '/service', name: 'Сервис', icon: 'icon-speed-slow', id: MainMenuType.service});
                  }
                }
                if (this.userProfileService.hasRole([EK_ROLES.admin, EK_ROLES.dispatcher, EK_ROLES.reader])) {
                  mainMenu.push({route: '/settings', name: 'Настройки', icon: 'icon-cog', id: MainMenuType.settings});
                }
                if (!environment.production) {
                  mainMenu.push({route: '/dev', name: 'DEV', icon: 'icon-laptop-phone'});
                }
                this.mainMenu = mainMenu.slice(0);
                return mainMenu;
              }),
              catchError(e => {
                if (this.userProfileService.hasRole([EK_ROLES.admin, EK_ROLES.dispatcher, EK_ROLES.reader])) {
                  mainMenu.push({route: '/settings', name: 'Настройки', icon: 'icon-cog', id: MainMenuType.settings});
                }
                this.mainMenu = mainMenu.slice(0);
                return of(mainMenu);
              })
            ) :
          of([])
            .pipe(
              map(m => {
                if (this.userProfileService.hasRole([EK_ROLES.admin, EK_ROLES.dispatcher, EK_ROLES.reader])) {
                  mainMenu.push({route: '/settings', name: 'Настройки', icon: 'icon-cog', id: MainMenuType.settings});
                }
                this.mainMenu = mainMenu.slice(0);
                return mainMenu;
              })
            );

        return objectsGuard
          .pipe(
            switchMap(menu => {
              return this.defineFilterService
                .get({})
                .pipe(
                  take(1),
                  map(res => res.item),
                  map(available => {
                    this.availableArchives = available.resources ? available.resources.filter(r => !!r) : [];
                    this.availableResources = available.archives ? available.archives.filter(a => !!a) : [];
                    return menu;
                  }),
                  catchError(err => {
                    return of(menu);
                  })
                );
            }),
            tap(() => {
              this.mainMenuDefined = true;
            })
          );
      }
    } else if (this.companyProfileService.isDP()) {
      mainMenu.push({route: '/operator', id: MainMenuType.operator});
    } else if (this.companyProfileService.isTS()) {
      mainMenu.push({route: '/support', id: MainMenuType.support});
    } else if (this.companyProfileService.isHomeUsers()) {
      if (this.userProfileService.hasRole([EK_ROLES.wildOwner])) {
        mainMenu.push({route: '/data', name: 'Показания', icon: 'icon-graph', id: MainMenuType.data});
        mainMenu.push({route: '/reports', name: 'Отчеты', icon: 'icon-file-stats', id: MainMenuType.reports});
        mainMenu.push({route: '/service', name: 'Сервис', icon: 'icon-speed-slow', id: MainMenuType.service});
      } else if (this.userProfileService.hasRole([EK_ROLES.admin])) {
        mainMenu.push({route: '/settings', name: 'Настройки', icon: 'icon-cog', id: MainMenuType.settings});
      } else if (this.userProfileService.hasRole([EK_ROLES.serviceEngineer])) {
        mainMenu.push({route: '/service', name: 'Сервис', icon: 'icon-speed-slow', id: MainMenuType.service});
      }
    } else if (environment.kv) {
      if (this.userProfileService.hasPermission(DataPermissions.showDeviceData)) {
        mainMenu.push({route: '/data', name: 'Учет', icon: 'icon-graph', id: MainMenuType.data});
      }
      if (this.userProfileService.hasPermission([DevicePermissions.get, AppealPermissions.get, NoticePermissions.get], false, false)) {
        mainMenu.push({route: '/service', name: 'Сервис', icon: 'icon-speed-slow', id: MainMenuType.service});
      }
      if (this.userProfileService.hasRole([EK_ROLES.owner])) {
        mainMenu.push({route: '/settings', name: 'Настройки', icon: 'icon-cog', id: MainMenuType.settings});
      }
    }
    this.mainMenu = mainMenu.slice(0);
    this.mainMenuDefined = true;
    return of(mainMenu);
  }

  public getDataObjectNavs() {
    if (this.companyProfileService.isUC()) {
      return [
        {name: 'Объект', icon: 'icon-home5', route: '../'},
        {name: 'Балансовые группы', icon: 'icon-balance', route: '../balance'},
        {name: 'Помещения', icon: 'icon-bathtub', route: '../rooms'},
        {name: 'Реестр потребления', icon: 'icon-register', route: '../register'}
      ];
    }
    return [];
  }

  public getMonitoringMenu(): Observable<IWSLNavMenu[]> {
    if (!this.mainMenuDefined) {
      return timer(500, 500)
        .pipe(
          filter(() => !!this.mainMenuDefined),
          timeout(10000),
          map(() => this.defineMonitoringMenu()),
          catchError(() => of([]))
        );
    }
    return of(this.defineMonitoringMenu());
  }

  private defineMonitoringMenu(): IWSLNavMenu[] {
    return this.getMonitoringNav();
  }

  public getMonitoringNav(): IWSLNavMenu[] {
    const menu: IWSLNavMenu[] = [];
    if (!this.mainMenu || !this.mainMenu.find(item => item.id === MainMenuType.monitoring)) {
      return menu;
    }
    if (this.companyProfileService.isUC()) {
      if (this.userProfileService.hasPermission(MonitoringPermissions.showDeviations)) {
        // if (this.userProfileService.hasRole([EK_ROLES.dispatcher, EK_ROLES.reader])) {
        menu.push({name: 'Отклонения', icon: 'icon-chart-growth', route: '/monitoring/deviation'});
      }
      if (this.userProfileService.hasPermission(MonitoringPermissions.showDeviceData)) {
        menu.push({name: 'Данные', icon: 'icon-database', route: '/monitoring/data'});
      }
      if (this.userProfileService.hasPermission(MonitoringPermissions.showLoraFrames)) {
        menu.push({name: 'Нода', icon: 'icon-antenna', route: '/monitoring/lora'});
      }
      if (this.userProfileService.hasPermission(MonitoringPermissions.showEvents)) {
        // if (this.userProfileService.hasRole([EK_ROLES.dispatcher, EK_ROLES.reader])) {
        menu.push({name: 'Журнал событий', icon: 'icon-heart-pulse', route: '/monitoring/events'});
      }
      if (this.userProfileService.hasPermission(MonitoringPermissions.showTempGraphic)) {
        // if (this.userProfileService.hasRole([EK_ROLES.dispatcher, EK_ROLES.serviceEngineer, EK_ROLES.reader])) {
        menu.push({name: 'Температурный график', icon: 'icon-graph', route: '/monitoring/temp_graphic'});
      }
    }
    return menu;
  }

  public getSettingsMenu(): Observable<IWSLTabMenu[]> {
    if (!this.mainMenuDefined) {
      return timer(500, 500)
        .pipe(
          filter(() => !!this.mainMenuDefined),
          timeout(10000),
          map(() => this.defineSettingsMenu()),
          catchError(() => of([]))
        );
    }
    return of(this.defineSettingsMenu());
  }

  private defineSettingsMenu(): IWSLTabMenu[] {
    const menu: IWSLTabMenu[] = [];
    if (!this.mainMenu || !this.mainMenu.find(item => item.id === MainMenuType.settings)) {
      return menu;
    }
    if (environment.dom && this.companyProfileService.isUC()) {
      if (this.hasObjects) {
        if (this.userProfileService.hasPermission(ObjectPermissions.show)) {
          const objectsMenu: IWSLTabMenu = {
            route: '/settings/objects',
            name: 'Объекты',
            tab_id: 'settings_objects',
            navMenu: this.getObjectNavs()
          };
          menu.push(objectsMenu);
        }
        if (this.userProfileService.hasPermission(ObjectPermissions.updateUnbalance)) {
          menu.push({route: '/settings/unbalance', name: 'Небаланс', tab_id: 'settings_unbalance'});
        }
        if (this.userProfileService.hasPermission(ObjectPermissions.updateUnbalance)) {
          menu.push({route: '/settings/power', name: 'Ограничение профиля мощности', tab_id: 'settings_power'});
        }
      }
      if (this.userProfileService.hasPermission(DeviceServiceProcedurePermissions.showDeadlines)) {
        menu.push({route: '/settings/so_deadlines', name: 'Сроки выполнения СО', tab_id: 'settings_so_deadlines'});
      }
      if (this.userProfileService.hasPermission([EventTypePermissions.show, EventSubscribePermissions.show])) {
        menu.push({route: '/settings/events', name: 'Подписка на события', tab_id: 'settings_events'});
      }

      menu.push(
        {route: '/settings/users', name: 'Пользователи', tab_id: 'settings_users'},
        {route: '/settings/company', name: 'Компания', tab_id: 'settings_company'});
    }
    if (environment.kv && this.companyProfileService.isHomeUsers()) {
      if (this.userProfileService.hasRole([EK_ROLES.admin])) {
        menu.push({route: '/settings/owners', name: 'Пользователи', tab_id: 'settings_owners'});
      }
    }
    if (environment.kv) {
      menu.push({route: '/settings/users', name: 'Пользователи', tab_id: 'settings_users'});
    }
    this.settingMenu = menu.slice(0);
    return menu;
  }

  public getObjectNavs() {
    const navMenu: IWSLNavMenu[] = [{name: 'Объект', icon: 'icon-home5', route: '../'}];
    if (this.userProfileService.hasPermission(ObjectRoomPermissions.show)) {
      navMenu.push({name: 'Помещения', icon: 'icon-bathtub', route: '../rooms'});
    }
    if (this.userProfileService.hasPermission(ObjectRoomOwnerPermissions.show)) {
      navMenu.push({name: 'Собственники', icon: 'icon-users2', route: '../owners'});
    }
    if (this.userProfileService.hasPermission(DevicePermissions.showLinkIpu)) {
      navMenu.push({name: 'Привязка ИПУ', icon: 'icon-link', route: '../link'});
      navMenu.push({name: 'Балансовые группы', icon: 'icon-balance', route: '../balance'});
    }
    if (this.userProfileService.hasPermission(ObjectRoomPermissions.showBillingAccount)) {
      navMenu.push({name: 'Биллинг', icon: 'icon-bag-dollar', route: '../bill_account'});
    }
    if (this.userProfileService.hasRole([EK_ROLES.admin, EK_ROLES.dispatcher, EK_ROLES.reader])) {
      navMenu.push({name: 'Отклонения', icon: 'icon-equalizer', route: '../deviations'});
    }
    if (this.userProfileService.hasRole([EK_ROLES.dispatcher, EK_ROLES.reader])) {
      navMenu.push({name: 'Температурный график', icon: 'icon-graph', route: '../temp_graphic'});
    }
    return navMenu;
  }

  public getServiceMenu(): Observable<IWSLTabMenu[]> {
    if (!this.mainMenuDefined) {
      return timer(500, 500)
        .pipe(
          filter(() => !!this.mainMenuDefined),
          timeout(10000),
          map(() => this.defineServiceMenu()),
          catchError(() => of([]))
        );
    }
    return of(this.defineServiceMenu());
  }

  private defineServiceMenu(): IWSLTabMenu[] {
    const menu: IWSLTabMenu[] = [];
    if (!this.mainMenu || !this.mainMenu.find(item => item.id === MainMenuType.service)) {
      return menu;
    }
    if (this.userProfileService.hasPermission(DevicePermissions.get)) {
      menu.push({
        route: '/service/devices',
        name: 'Приборы',
        tab_id: 'service_devices',
        navMenu: [], // this.getDeviceNav()
      });
      if ((environment.dom || environment.home ) && (this.companyProfileService.isUC() || this.companyProfileService.isHomeUsers())) {
        menu.push({route: '/service/verification', name: 'Поверка', tab_id: 'service_verification'});
      }
    }
    if (environment.dom) {
      if (this.companyProfileService.isUC() && this.userProfileService.hasPermission(DeviceServiceProcedurePermissions.get)) {
        menu.push({route: '/service/procedures', name: 'Операции', tab_id: 'service_procedures'});
      }
      /** @todo
       if (this.userProfileService.hasPermission(DevicePermissions.showJournal)) {
      menu.push({route: '/service/journal', name: 'Журнал', tab_id: 'service_journal'});
    }
       */
      if (this.companyProfileService.isUC() && this.userProfileService.hasPermission(CommunicatorPermissions.get)) {
        menu.push({route: '/service/communicators', name: 'Связь', tab_id: 'service_communicators'});
      }
      if (this.companyProfileService.isUC() && this.userProfileService.hasPermission(AppealPermissions.get)) {
        menu.push({route: '/service/appeals', name: 'Диспетчерская', tab_id: 'service_appeals'});
      }
      if (this.companyProfileService.isUC() && this.userProfileService.hasPermission(NoticePermissions.get)) {
        menu.push({route: '/service/notices', name: 'Уведомления', tab_id: 'service_notices'});
      }
      if (this.companyProfileService.isUC() && this.userProfileService.hasPermission(ReportPermissions.showData)) {
        menu.push({route: '/service/report', name: 'Протокол выгрузки', tab_id: 'service_report'});
      }
      if (this.companyProfileService.isUC() && this.userProfileService.hasPermission(ObjectPermissions.get)) {
        menu.push({route: '/service/season', name: 'Сезон', tab_id: 'service_season'});
      }
      if (this.companyProfileService.isUC() && this.availableResources.includes(ResourceType.elec)) {
        menu.push({route: '/service/power', name: 'Ограничение потребления', tab_id: 'service_power'});
      }
    }
    if (this.companyProfileService.isHomeUsers() && this.userProfileService.hasRole([EK_ROLES.wildOwner])) {
      menu.push({route: '/service/reports', name: 'Извещения', tab_id: 'service_reports'});
    }
    if (environment.kv) {
      if (this.userProfileService.hasPermission(AppealPermissions.get)) {
        menu.push({route: '/service/appeals', name: 'Обращения', tab_id: 'service_appeals'});
      }
      if (this.userProfileService.hasPermission(NoticePermissions.get)) {
        menu.push({route: '/service/notices', name: 'Уведомления', tab_id: 'service_notices'});
      }
    }
    this.serviceMenu = menu.slice(0);
    return menu;
  }

  public getDeviceNav(device: IWSLDevice) {
    if (!!device && !!device.id && (this.companyProfileService.isUC() || this.companyProfileService.isHomeUsers())) {
      const navMenu: IWSLNavMenu[] = [{
        name: 'Прибор учета',
        icon: 'icon-speed-slow',
        route: `/service/devices/${device.id}`
      }]; // /service/devices/device_id/
      if (this.userProfileService.hasPermission(DeviceServiceProcedurePermissions.get)) {
        navMenu.push({
          name: 'Сервисные операции',
          icon: 'icon-hammer-wrench',
          route: `/service/devices/${device.id}/procedures`
        }); // /service/devices/device_id/procedures
      }
      if (!!device.date_input && this.userProfileService.hasPermission(DataPermissions.showDeviceData)) {
        navMenu.push({
          name: 'Данные',
          icon: 'icon-graph',
          route: '/data/device/' + device.id ,
          ext: {add_id: true}
        });
      }
      return navMenu;
    }
    return [];
  }

  /*  public getReportMenu(): Observable<IWSLTabMenu[]> {
      if (!this.mainMenuDefined) {
        return timer(100, 100)
          .pipe(
            //  takeWhile(() => timerAlive),
            filter(() => !!this.mainMenuDefined),
            timeout(1000),
            map(() => this.defineReportMenu()),
            catchError(() => of([]))
          );
      }
      return of(this.defineReportMenu());
    }

    private defineReportMenu(): IWSLTabMenu[] {
      const menu: IWSLTabMenu[] = [];
      if (!this.mainMenu || !this.mainMenu.find(item => item.name === 'Отчеты')) {
        return menu;
      }
      if (this.userProfileService.hasPermission(ReportPermissions.showData)) {
        menu.push({route: '/reports/odpu-data-cards', name: 'Карточки ОДПУ', tab_id: 'odpu-data-cards'});
      }
      if (this.userProfileService.hasPermission(ReportPermissions.showBilling)) {
        const billingMenu: IWSLTabMenu = {
          route: '/reports/billing',
          name: 'Биллинг',
          tab_id: 'billing',
          navMenu: this.getBillingReportNav()
        };
        menu.push(billingMenu);
      }

      this.reportMenu = menu.slice(0);
      return menu;
    }

    public getBillingReportNav() {
      const navMenu: IWSLNavMenu[] = [{name: 'Данные', icon: 'icon-bag-dollar', route: '../../billing'}];
      if (this.userProfileService.hasPermission(ObjectRoomPermissions.showBillingAccount)) {
        navMenu.push({name: 'Настройка аккаунтов', icon: 'icon-cog2', route: '../../billing/accounts'});
      }
      return navMenu;
    }*/

  private reset() {
    this.mainMenu = [];
    this.settingMenu = [];
    this.serviceMenu = [];
    this.availableArchives = [];
    this.availableResources = [];
    //  this.reportMenu = [];
    this.hasObjects = false;
    this.mainMenuDefined = false;
  }
}


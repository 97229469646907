export enum DeviceFabricType {
  karat = 1,
  pzip = 2,
  vega = 3
}

export const DeviceFabrics = [
  {id: DeviceFabricType.karat, name: 'НПО "Карат"'},
  {id: DeviceFabricType.pzip, name: 'ПЗИП'},
  {id: DeviceFabricType.vega, name: 'Vega'}
];

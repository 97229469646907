import {Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges, SimpleChanges} from '@angular/core';
import {IWSLDeviceConfGroupFileIndex, IWSLDeviceConfGroupFileIndexView} from '@app/device-conf-group/models/device-conf-group-file-index.model';
import {IWSLAsyncErrors} from 'wsl-core';
import {IWSLCompany} from 'wsl-ek-core';
import {IWSLDeviceConfGroup} from '@app/device-conf-group/models/device-conf-group';

@Component({
  selector: 'wsl-device-conf-group-file-indexes',
  templateUrl: './device-conf-group-file-indexes.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfGroupFileIndexesComponent implements OnInit, OnChanges {
  @Input() group: IWSLDeviceConfGroup;
  @Input() groupConfs: IWSLDeviceConfGroupFileIndex[];
  @Input() pending: boolean;
  @Input() asyncErrors: IWSLAsyncErrors;
  @Input() readonly: boolean;
  @Input() checklist: number[] = [];
  @Input() checkable = false;
  @Input() companies: IWSLCompany[];
  @Output() check = new EventEmitter<{id: number, checked: boolean}>();
  @Output() addConf = new EventEmitter<IWSLDeviceConfGroupFileIndex>();
  @Output() updateConf = new EventEmitter<IWSLDeviceConfGroupFileIndex>();

  confs: IWSLDeviceConfGroupFileIndexView[] = [];
  editingId: number = null;
  onlyCustom = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groupConfs || changes.companies) {
      this.defineConfs();
    }
  }

  trackById(index: number, obj: IWSLDeviceConfGroupFileIndexView) {
    return obj.id;
  }

  private defineConfs() {
    this.confs = [];
    this.onlyCustom = false;
    if (this.groupConfs && this.groupConfs.length > 0) {
      this.confs = this.groupConfs.map(c => ({
        ...c,
        company_name: c.company_id && this.companies && this.companies.length > 0 ?
          this.companies.find(comp => comp.id === c.company_id).short_name : ''
      }));
      this.onlyCustom = !!this.confs.find(c => c.company_id === null);
    }
    if (!!this.editingId) {
      this.editingId = null;
    }
  }

  onEditConf(id: number) {
    this.editingId = id;
  }

  onUpdateConf(conf: IWSLDeviceConfGroupFileIndex) {
    if (conf.id > 0) {
      this.updateConf.emit(conf);
    } else {
      delete conf.id;
      this.addConf.emit(conf);
    }
  }

  onCancelEditConf() {
    if (this.editingId < 0) {
      this.confs = this.confs.slice(1);
    }
    this.editingId = null;
  }

  onAddConf() {
    if (!!this.editingId) {
      return;
    }
    this.confs.unshift({
      id: -1,
      name: '',
      group_id: this.group.id,
      company_id: null,
      company_name: '',
      config: ''
    });
    this.editingId = -1;
  }
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AppActionTypes, SetSnackBarMessage } from '../actions/app.actions';

@Injectable()
export class AppEffects {

  @Effect()
  clearAsyncMessages$ = this.actions$
    .pipe(
      ofType(AppActionTypes.SetSnackBarMessage),
      // delay(5000),
      map((action: SetSnackBarMessage) => action.payload),
      filter(message => !!message),
      debounceTime(5000),
      distinctUntilChanged(),
      map(() => new SetSnackBarMessage(null))
    );

  constructor(private actions$: Actions) {
  }
}

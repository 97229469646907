import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AddDeviceConfGroup,
  AddDeviceConfGroupFail,
  AddDeviceConfGroupSuccess,
  DeleteDeviceConfGroup,
  DeleteDeviceConfGroupFail,
  DeleteDeviceConfGroupSuccess,
  DeviceConfGroupActionTypes,
  LoadDeviceConfGroup,
  LoadDeviceConfGroupFail,
  LoadDeviceConfGroups,
  LoadDeviceConfGroupsFail,
  LoadDeviceConfGroupsSuccess,
  LoadDeviceConfGroupSuccess,
  UpdateDeviceConfGroup,
  UpdateDeviceConfGroupFail,
  UpdateDeviceConfGroupSuccess
} from './device-conf-group.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { DeviceConfGroupService } from '@app/device-conf-group/services/device-conf-group.service';

@Injectable()
export class DeviceConfGroupEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DeviceConfGroupActionTypes.AddDeviceConfGroup),
    map((action: AddDeviceConfGroup) => action.payload),
    exhaustMap(payload => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.AddDeviceConfGroup), skip(1));

      return this.deviceConfGroupService.add(payload)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddDeviceConfGroupSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddDeviceConfGroupFail))
        );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(DeviceConfGroupActionTypes.UpdateDeviceConfGroup),
    map((action: UpdateDeviceConfGroup) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.UpdateDeviceConfGroup), skip(1));

      return this.deviceConfGroupService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateDeviceConfGroupSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateDeviceConfGroupFail))
        );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroup),
    map((action: LoadDeviceConfGroup) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroup), skip(1));

      return this.deviceConfGroupService.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceConfGroupSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceConfGroupFail))
        );
    })
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroups),
    map((action: LoadDeviceConfGroups) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroups), skip(1));

      return this.deviceConfGroupService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceConfGroupsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceConfGroupsFail))
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeviceConfGroupActionTypes.DeleteDeviceConfGroup),
    map((action: DeleteDeviceConfGroup) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.DeleteDeviceConfGroup), skip(1));

      return this.deviceConfGroupService.delete(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteDeviceConfGroupSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteDeviceConfGroupFail))
        );
    })
  );

  @Effect()
  startLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceConfGroupActionTypes.AddDeviceConfGroup,
        DeviceConfGroupActionTypes.LoadDeviceConfGroup,
        DeviceConfGroupActionTypes.UpdateDeviceConfGroup,
        DeviceConfGroupActionTypes.DeleteDeviceConfGroup
      ),
      map(() => new StartLoading('device_conf_group'))
    );

  @Effect()
  startLoadMany$ = this.actions$
    .pipe(
      ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroups),
      map((action: LoadDeviceConfGroups) => action.payload),
      filter((payload) => payload.offset === 0),
      map(() => new StartLoading('device_conf_group'))
    );


  @Effect()
  stopLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceConfGroupActionTypes.AddDeviceConfGroupSuccess, DeviceConfGroupActionTypes.AddDeviceConfGroupFail,
        DeviceConfGroupActionTypes.LoadDeviceConfGroupSuccess, DeviceConfGroupActionTypes.LoadDeviceConfGroupFail,
        DeviceConfGroupActionTypes.UpdateDeviceConfGroupSuccess, DeviceConfGroupActionTypes.UpdateDeviceConfGroupFail,
        DeviceConfGroupActionTypes.DeleteDeviceConfGroupSuccess, DeviceConfGroupActionTypes.DeleteDeviceConfGroupFail,
        DeviceConfGroupActionTypes.LoadDeviceConfGroupsSuccess, DeviceConfGroupActionTypes.LoadDeviceConfGroupsFail
      ),
      map(() => new StopLoading('device_conf_group'))
    );

  constructor(private actions$: Actions,
              private router: Router,
              private route: ActivatedRoute,
              private storeHelper: StoreHelperService,
              private deviceConfGroupService: DeviceConfGroupService) {
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { LoadCommunicators } from '@store/communicator/communicator.actions';
import { LoadDeviceConfGroup, SelectDeviceConfGroup } from '@store/device-conf-group/device-conf-group.actions';
import { LoadDeviceSensors } from '@store/device-sensor/device-sensor.actions';
import { LoadDevice, LoadDeviceSuccess, SelectDevice } from '@store/device/device.actions';
import * as fromDevice from '@store/device/device.reducer';
import { LoadObject, SelectObject } from '@store/object/object.actions';
import { LoadRoom, SelectRoom } from '@store/room/room.actions';
import { LoadServiceProcedures } from '@store/service-procedure/service-procedure.actions';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { IWSLHttpResponse } from 'wsl-core';
import { DeviceService, IWSLDevice } from 'wsl-device';
import {
  CommunicatorPermissions, DeviceConfPermissions,
  DevicePermissions,
  DeviceSensorPermissions, DeviceServiceProcedurePermissions, ObjectPermissions,
  ObjectRoomPermissions,
  UserProfileService
} from 'wsl-ek-core';
import {environment} from "@env/environment";

@Injectable()
export class DeviceExistsGuard implements CanActivate, CanActivateChild {
  constructor(private store$: Store<fromDevice.State>,
              private deviceService: DeviceService,
              private userProfileService: UserProfileService,
              private router: Router) {
  }

  /**
   * This method creates an observable that waits for the `loaded` property
   * of the collection state to turn `true`, emitting one time once loading
   * has finished.
   */
  waitForCollectionToLoad(): Observable<boolean> {
    return this.store$
      .pipe(
        select(fromDevice.selectDeviceLoaded),
        filter(loaded => loaded),
        take(1),
        timeout(1000),
        catchError(() => of(false))
      );
  }

  /**
   * This method checks if a device with the given ID is already registered
   * in the Store
   */
  hasInStore(id: number): Observable<boolean> {
    return this.store$
      .select(fromDevice.selectDeviceEntities)
      .pipe(
        tap(entities => {
          if (entities[id]) {
            this.dispatchMeta(entities[id]);
            this.store$.dispatch(new LoadDevice(entities[id].id));
          }
        }),
        map(entities => !!entities[id]),
        take(1)
      );
  }

  /**
   * This method loads a device with the given ID from the API and caches
   * it in the store, returning `true` or `false` if it was found.
   */
  hasInApi(id: number): Observable<boolean> {
    return this.deviceService
      .get(id)
      .pipe(
        // map(deviceEntity => new LoadDeviceSuccess(deviceEntity)),
        //  tap((action: LoadDeviceSuccess) => this.store.dispatch(action)),
        tap(resp => this.store$.dispatch(new LoadDeviceSuccess(resp))),
        map((resp: IWSLHttpResponse<IWSLDevice>) => resp.item),
        tap(entity => {
          if (entity) {
            this.dispatchMeta(entity);
          }
        }),
        map(entity => !!entity),
        catchError((response) => {
          this.router.navigate(['/404']);
          return of(false);
        })
      );
  }

  /**
   * `hasEntity` composes `hasInStore` and `hasInApi`. It first checks
   * if the device is in store, and if not it then checks if it is in the
   * API.
   */
  hasEntity(id: number, collectionLoaded: boolean): Observable<boolean> {
    if (Number.isNaN(id)) {
      this.router.navigate(['/404']);
      return of(false);
    }
    if (collectionLoaded) {
      return this.hasInStore(id)
        .pipe(
          switchMap(inStore => {
            if (inStore) {
              return of(inStore);
            }

            return this.hasInApi(id);
          }));
    }
    return this.hasInApi(id);
  }


  private dispatchMeta(device: IWSLDevice) {
    this.store$.dispatch(new SelectObject(null));
    if (!environment.kv && device.object_id && this.userProfileService.hasPermission(ObjectPermissions.get)) {
      this.store$.dispatch(new LoadObject(device.object_id));
      this.store$.dispatch(new SelectObject(device.object_id));
    }

    this.store$.dispatch(new SelectRoom(null));
    if (!environment.kv && device.room_id && this.userProfileService.hasPermission(ObjectRoomPermissions.get)) {
      this.store$.dispatch(new LoadRoom(device.room_id));
      this.store$.dispatch(new SelectRoom(device.room_id));
    }

    if (!environment.kv && device.communicator_ids && device.communicator_ids.length > 0 && this.userProfileService.hasPermission(CommunicatorPermissions.get)) {
      this.store$.dispatch(new LoadCommunicators({device_id: device.id, offset: 0}));
    }

    this.store$.dispatch(new SelectDeviceConfGroup(null));
    if (!environment.kv && device.group_id && this.userProfileService.hasPermission(DeviceConfPermissions.get)) {
      this.store$.dispatch(new LoadDeviceConfGroup(device.group_id));
      this.store$.dispatch(new SelectDeviceConfGroup(device.group_id));
    }
    /*if (device.dconf_ids) {
      this.store$.dispatch(new LoadDeviceConfs({dconf_ids: device.dconf_ids, offset: 0}));
      this.store$.dispatch(new LoadDeviceConfExts({dconf_ids: device.dconf_ids, offset: 0}));
    }*/
    this.store$.dispatch(new SelectDevice(null));
    if (device.id) {
      this.store$.dispatch(new SelectDevice(device.id)); // @todo test
      if (!environment.kv && this.userProfileService.hasPermission(DeviceSensorPermissions.get)) {
        this.store$.dispatch(new LoadDeviceSensors({device_id: device.id, offset: 0}));
      }
      if (!environment.kv && this.userProfileService.hasPermission(DeviceServiceProcedurePermissions.get)) {
        this.store$.dispatch(new LoadServiceProcedures({device_id: device.id, offset: 0}));
      }
    }
  }

  check(deviceID) {
    if (!this.userProfileService.hasPermission(DevicePermissions.get)) {
      return of(false);
    }
    return this.waitForCollectionToLoad()
      .pipe(switchMap((loaded) => this.hasEntity(deviceID, loaded)));
  }

  /**
   * This is the actual method the router will call when our guard is run.
   *
   * Our guard waits for the collection to load, then it checks if we need
   * to request a book from the API or if we already have it in our cache.
   * If it finds it in the cache or in the API, it returns an Observable
   * of `true` and the route is rendered successfully.
   *
   * If it was unable to find it in our cache or in the API, this guard
   * will return an Observable of `false`, causing the router to move
   * on to the next candidate route. In this case, it will move on
   * to the 404 page.
   */
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(+next.params['deviceID'])
      .pipe(tap(test => {
        if (!test) {
          this.router.navigateByUrl('/404');
        }
      }));
  }

  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(+next.params['deviceID'])
      .pipe(tap(test => {
        if (!test) {
          this.router.navigateByUrl('/404');
        }
      }));
  }
}

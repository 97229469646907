import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IWSLDataConfType } from '@app/device/models/data-conf-type';
import { AllSystems, IWSLResourceUnit, IWSLResourceValue, IWSLResourceVariable } from 'wsl-ek-core';
import { ActivatedRoute } from '@angular/router';
import { IWSLDataConf } from '../../models/data-conf';
import { WSLRouterHelperService } from 'wsl-core';

@Component({
  selector: '[wsl-device-conf-ext-row]',
  templateUrl: './device-conf-ext-row.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfExtRowComponent implements OnInit {
  @Input() conf: IWSLDataConf;
  @Input() showButtons = true;
  @Output() edit = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();

  private rvariables: IWSLResourceVariable[] = [];
  private rvalues: IWSLResourceValue[] = [];
  private units: IWSLResourceUnit[] = [];
  private dataTypes: IWSLDataConfType[] = [];

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.rvariables = data.resourceVariables;
    this.rvalues = data.resourceValues;
    this.units = data.resourceUnits;
    this.dataTypes = data.dataTypes;
  }

  get typeName() {
    return this.conf.type_data ? this.dataTypes.find(t => t.id === this.conf.type_data).name : 'не задано';
  }

  get resource() {
    return AllSystems.find(ar => this.conf.resource_id === ar.id);
  }

  get rvariable() {
    return this.rvariables.find(rv => rv.id === this.conf.rvariable_id);
  }

  get rvalue() {
    return this.rvalues.find(rv => rv.id === this.conf.rvalue_id);
  }

  get unit() {
    return this.units.find(u => u.id === this.conf.unit_id);
  }

}

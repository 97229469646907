import { Injectable } from '@angular/core';
import { IWSLEntityFilter, WSLApiHttpClientService } from 'wsl-core';
import { IWSLTimeZone } from '@data-provider/models/time-zone';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TimeZoneService extends WSLApiHttpClientService<IWSLTimeZone, IWSLEntityFilter> {

  constructor(public http: HttpClient) {
    super(http, 'time_zone');
  }

  public formatUTCOffset(tz: IWSLTimeZone[]) {
    tz.forEach(item => {
      item.utc_offset = item.utc_offset.split(':')[0];
      item.utc_offset = parseInt(item.utc_offset, 10) > 0 ? ('+' + item.utc_offset ) : item.utc_offset;
    });
  }
}

import { Injectable } from '@angular/core';
import { WSLApiHttpClientService } from 'wsl-core';
import { IWSLDeviceConf } from '../models/device-conf';
import { IWSLDeviceConfFilter } from '../models/device-conf-filter';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeviceConfService extends WSLApiHttpClientService<IWSLDeviceConf, IWSLDeviceConfFilter> {

  constructor(public http: HttpClient) {
    super(http, 'device_conf');
  }

}

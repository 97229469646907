<wsl-guest-layout [title]="systemTitle"
                  [subTitle]="systemSubTitle"
                  pageTitle="Восстановить пароль">

  <wsl-forgot-password-form *ngIf="!submitted"
                            [pending]="pending$ | async"
                            [asyncErrors]="errors$ | async"
                            (submitted)="onSubmit($event)"></wsl-forgot-password-form>

  <div class="wsl-bg-white wsl-p-x-20 wsl-p-y-25 z-depth-1 wsl-hide-bg-xs" *ngIf="submitted">
    <p class="wsl-green wsl-st6 wsl-text-left">
      Мы отправили Вам письмо на указанный email с дальнейшими инструкциями
    </p>
  </div>

  <div class="wsl-flex-spaceBetween_xs-Cols-ng wsl-m-t-10">
    <a class="" [routerLink]="['/login']">Авторизоваться</a>
  </div>

</wsl-guest-layout>

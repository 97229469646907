import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthActionTypes, ResetPassword } from '@store/actions/auth.actions';
import * as fromRoot from '@store/reducers/index';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { IWSLResetPassword } from 'wsl-auth';
import { IWSLAsyncErrors, WSLHttpHelper } from 'wsl-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { SYSTEM_SUB_TITLE, SYSTEM_TITLE } from '../../../../config/app.config';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'wsl-reset-password',
  templateUrl: './reset-password.page.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordPage implements OnInit, OnDestroy {
  /** @internal */
  readonly systemTitle = SYSTEM_TITLE;
  /** @internal */
  readonly systemSubTitle = SYSTEM_SUB_TITLE;
  /** @internal */
  pending$: Observable<boolean>;
  /** @internal */
  errors$: Observable<IWSLAsyncErrors>;
  /** @internal */
  canReset = false;
  /** @internal */
  submitted = false;


  private queryParams = {};
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private store$: Store<fromRoot.State>,
              private updates$: Actions,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private chr: ChangeDetectorRef) {
    this.pending$ = store$.pipe(select(fromRoot.selectPending));
    this.errors$ = store$.pipe(select(fromRoot.selectSnackBarMessage));
    this.updates$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        ofType(AuthActionTypes.ResetPasswordSuccess),
        tap(() => {
          this.submitted = true;
          this.chr.markForCheck();
        })
      ).subscribe();
  }

  ngOnInit() {
    const token = this.route.snapshot.queryParams['token'];
    const uid = this.route.snapshot.queryParams['uid'];
    if (!token || !uid) {
      WSLMaterializeHelper.toast({html: 'Ссылка не правильная', displayLength: 2000});
      setTimeout(() => this.router.navigateByUrl('login'), 3000);
    } else {
      Object.assign(this.queryParams, {
        token,
        uid
      });
      this.authService.checkReset(this.queryParams)
        .pipe(
          take(1)
        )
        .subscribe(
          res => {
            this.canReset = true;
            this.chr.markForCheck();
          },
          err => {
            const error = WSLHttpHelper.parseHTTPErrorResponse(err);
            const message = error.view ? error.message : 'Ссылка не правильная';
            WSLMaterializeHelper.toast({html: message, displayLength: 2000});
            setTimeout(() => this.router.navigateByUrl('login'), 500);
          }
        );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onSubmit(data: IWSLResetPassword) {
    this.store$.dispatch(new ResetPassword(Object.assign({}, data, this.queryParams)));
  }

}

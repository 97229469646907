import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ObjectActions, ObjectActionTypes} from './object.actions';
import {IWSLAsyncErrors} from 'wsl-core';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IWSLObject} from '@app/object/models/object';
import * as fromRoot from '@store/reducers/index';

export interface ObjectState extends EntityState<IWSLObject> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLObject> = createEntityAdapter<IWSLObject>({
  selectId: (item: IWSLObject) => item.id,
  sortComparer: false
});

export const initialState: ObjectState = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(state = initialState, action: ObjectActions): ObjectState {
  switch (action.type) {

    case ObjectActionTypes.LoadObject:
      return {
        ...state,
        // selectedID: null,
        pending: true,
        loaded: false,
        errors: null
      };

    case ObjectActionTypes.LoadObjectSuccess:
      return adapter.upsertOne({
        ...action.payload.item
      }, {
        ...state,
        pending: false,
        loaded: true
      });

    case ObjectActionTypes.LoadObjectFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case ObjectActionTypes.SelectObject:
      return {...state, selectedID: action.payload};

    case ObjectActionTypes.LoadObjects:
      if (!action.payload.offset) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          pending: true,
          loaded: false,
          errors: null
        });
      }
      return {...state, pending: true, loaded: false, errors: null};

    case ObjectActionTypes.LoadObjectsSuccess:
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case ObjectActionTypes.LoadObjectsFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case ObjectActionTypes.ClearObjects:
      return adapter.removeAll(state);

    default:
      return state;
  }
}

export const getSelectedID = (state: ObjectState) => state.selectedID;
export const getTotalCount = (state: ObjectState) => state.totalCount;
export const getLoaded = (state: ObjectState) => state.loaded;
export const getPending = (state: ObjectState) => state.pending;
export const getErrors = (state: ObjectState) => state.errors;

export interface State extends fromRoot.State {
  'object': ObjectState;
}

export const getObjectState = createFeatureSelector<ObjectState>('object');

export const selectObjectSelectedID = createSelector(getObjectState, getSelectedID);
export const selectObjectTotalCount = createSelector(getObjectState, getTotalCount);
export const selectObjectLoaded = createSelector(getObjectState, getLoaded);
export const selectObjectPending = createSelector(getObjectState, getPending);
export const selectObjectErrors = createSelector(getObjectState, getErrors);

export const {
  selectEntities: selectObjectEntities,
  selectAll: selectObjectAll
} = adapter.getSelectors(getObjectState);

export const selectObject = createSelector(
  selectObjectEntities,
  selectObjectSelectedID,
  (entities, id) => entities && entities[id] ? entities[id] : null
);


<main>
  <div class="wsl-m-t-20">
    <div class="wsl-flex-center">
      <div class="wsl-col-4_md-5_sm-6_xs-12 wsl-text-center">
        <div class="wsl-flex-center-middle_xs-Cols-ng wsl-m-b-20">
          <a href="/" class="wsl-flex-middle-ng wsl-st4">
            <span>{{title}}</span>
            <span class="wsl-thin">{{subTitle}}</span>
          </a>
          <span class="wsl-gray wsl-m-x-10 wsl-st4 wsl-thin wsl-hide-xs">|</span>
          <span class="wsl-gray wsl-t4 wsl-thin">{{pageTitle}}</span>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</main>

import * as i0 from "@angular/core";
var KaratLoraProtocolService = /** @class */ (function () {
    function KaratLoraProtocolService() {
    }
    KaratLoraProtocolService.get = function () {
        return [
            { id: '1.7', name: '1.7' },
            { id: '1.8', name: '1.8' },
            { id: '1.9', name: '1.9' },
            { id: '2.0', name: '2.0' }
        ];
    };
    KaratLoraProtocolService.ngInjectableDef = i0.defineInjectable({ factory: function KaratLoraProtocolService_Factory() { return new KaratLoraProtocolService(); }, token: KaratLoraProtocolService, providedIn: "root" });
    return KaratLoraProtocolService;
}());
export { KaratLoraProtocolService };

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPage } from '@app/main.page';
import { AuthorizedGuard } from '@core/guards/authorized.guard';
import { IsDomGuard } from '@core/guards/is-dom.guard';
import { IsHomeGuard } from '@core/guards/is-home.guard';
import { MainPageGuard } from '@core/guards/main-page.guard';
import { UnauthorizedGuard } from '@core/guards/unauthorized.guard';
import { ConfirmRegistrationPage } from '@core/pages/confirm-registration/confirm-registration.page';
import { ForgotPasswordPage } from '@core/pages/forgot-password/forgot-password.page';
import { LoginPage } from '@core/pages/login/login.page';
import { NotFoundPage } from '@core/pages/not-found/not-found.page';
import { RegistrationPage } from '@core/pages/registration/registration.page';
import { ResetPasswordPage } from '@core/pages/reset-password/reset-password.page';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPage,
    canActivate: [UnauthorizedGuard]
  },
  {
    path: 'forgot',
    component: ForgotPasswordPage,
    canActivate: [UnauthorizedGuard]
  },
  {
    path: 'reset',
    component: ResetPasswordPage,
    canActivate: [UnauthorizedGuard]
  },
  {
    path: 'registration',
    component: RegistrationPage,
    canActivate: [UnauthorizedGuard]
  },
  {
    path: 'check_email',
    component: ConfirmRegistrationPage,
    canActivate: [UnauthorizedGuard, IsHomeGuard]
  },
  {
    path: '',
    //   redirectTo: 'data',
    //  pathMatch: 'full',
    component: MainPage,
    canActivate: [AuthorizedGuard, MainPageGuard]
  },
  /*{
    path: 'data',
    loadChildren: 'app/+data/data.module#DataModule',
    canActivate: [AuthorizedGuard],
    component:
    data: { preload: true }
  },*/
  {
    path: 'control',
    loadChildren: 'src/app/+control/control.module#ControlModule',
    canLoad: [AuthorizedGuard]
  },
  {
    path: 'data',
    loadChildren: 'src/app/+data/data.module#DataModule',
    canLoad: [AuthorizedGuard],
  },
  {
    path: 'monitoring',
    loadChildren: 'src/app/+monitoring/monitoring.module#DataMonitoringModule',
    canLoad: [AuthorizedGuard, IsDomGuard],
  },
  {
    path: 'service',
    loadChildren: 'src/app/+service/service.module#ServiceModule',
    canLoad: [AuthorizedGuard]
  },
  {
    path: 'settings',
    loadChildren: 'src/app/+settings/settings.module#SettingsModule',
    canLoad: [AuthorizedGuard]
  },
  /*{
    path: 'billing',
    loadChildren: 'src/app/+billing/billing.module#BillingModule',
    canLoad: [AuthorizedGuard]
  },*/

  {
    path: 'reports',
    loadChildren: 'src/app/+reports/reports.module#ReportsModule',
    canLoad: [AuthorizedGuard],
  },

  {
    path: 'support',
    loadChildren: 'src/app/++tech-support/tech-support.module#TechSupportModule',
    canLoad: [AuthorizedGuard, IsDomGuard]
  },

  {
    path: 'operator',
    loadChildren: 'src/app/++data-provider/data-provider.module#DataProviderModule',
    canLoad: [AuthorizedGuard, IsDomGuard]
  },

  {
    path: 'dev',
    loadChildren: 'src/app/+dev/dev.module#DevModule',
    canLoad: [AuthorizedGuard]
  },

  {
    path: '**',
    component: NotFoundPage
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

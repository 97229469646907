import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromDeviceConfGroup from '@store/device-conf-group/device-conf-group.reducer';
import * as fromDeviceConfGroupFileIndex from '@store/device-conf-group-file-index/reducers/device-conf-group-file-index.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DeviceConfGroupEffects } from '@store/device-conf-group/device-conf-group.effects';
import { DeviceConfGroupService } from './services/device-conf-group.service';
import { WslSharedModule } from 'wsl-shared';
import { AppCommonModule } from '@common/app-common.module';
import { DeviceConfGroupListComponent } from './components/device-conf-group-list/device-conf-group-list.component';
import { DeviceConfGroupRowComponent } from './components/device-conf-group-row/device-conf-group-row.component';
import { DeviceConfGroupSelectByMetaComponent } from './components/device-conf-group-select-by-meta/device-conf-group-select-by-meta.component';
import {DeviceConfGroupFileIndexesComponent} from '@app/device-conf-group/components/device-conf-group-file-indexes/device-conf-group-file-indexes.component';
import {DeviceConfGroupFileIndexComponent} from '@app/device-conf-group/components/device-conf-group-file-index/device-conf-group-file-index.component';
import {DeviceConfGroupFileIndexEffects} from '@store/device-conf-group-file-index/effects/device-conf-group-file-index.effects';
import {DeviceConfGroupFileIndexService} from '@app/device-conf-group/services/device-conf-group-file-index.service';

const COMPONENTS = [
  DeviceConfGroupListComponent,
  DeviceConfGroupRowComponent,
  DeviceConfGroupSelectByMetaComponent,
  DeviceConfGroupFileIndexesComponent,
  DeviceConfGroupFileIndexComponent
];

@NgModule({
  imports: [
    WslSharedModule,
    AppCommonModule,
    StoreModule.forFeature('deviceConfGroup', fromDeviceConfGroup.reducer),
    StoreModule.forFeature('deviceConfGroupFileIndex', fromDeviceConfGroupFileIndex.reducer),
    EffectsModule.forFeature([DeviceConfGroupEffects, DeviceConfGroupFileIndexEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    DeviceConfGroupService,
    DeviceConfGroupFileIndexService
  ]
})
export class DeviceConfGroupModule {
}

export enum DeviceDocumentType {
  passportDevice = 1,
  actInput = 2,
  verificationCertificate = 3,
  passportSensor = 4,
  verificationCertificateSensor = 5,
}

export enum DocumentObjectType {
  device = 1,
  sensor = 2
}

export const DeviceDocumentTypes = [
  {id: DeviceDocumentType.passportDevice, name: 'Паспорт прибора', objectType: DocumentObjectType.device},
  {id: DeviceDocumentType.actInput, name: 'Акт ввода в эксплуатацию узла учета', objectType: DocumentObjectType.device},
  {id: DeviceDocumentType.verificationCertificate, name: 'Свидетельство о поверке прибора', objectType: DocumentObjectType.device},
  {id: DeviceDocumentType.passportSensor, name: 'Паспорт датчика', objectType: DocumentObjectType.sensor},
  {id: DeviceDocumentType.verificationCertificateSensor, name: 'Свидетельство о поверке датчика', objectType: DocumentObjectType.sensor},
];

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { IWSLEntity } from 'wsl-core';
import { ObjectService } from '../services/object.service';

@Injectable()
export class ObjectTypeResolver implements Resolve<IWSLEntity[]> {
  constructor(private router: Router, private objectService: ObjectService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return this.objectService.getObjectTypes()
      .pipe(
        filter(res => (<any>res).items.length > 0),
        take(1),
        map(res => (<any>res).items)
      );
  }
}

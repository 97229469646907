import { NgModule } from '@angular/core';
import { LinkDevicesToBcompanyDevicesByAccountComponent } from '@app/billing-company/components/link-devices-to-bcompany-devices-by-account/link-devices-to-bcompany-devices-by-account.component';
import { ObjectRoomsAccountsRowComponent } from '@app/billing-company/components/object-rooms-accounts-row/object-rooms-accounts-row.component';
import { ObjectRoomsAccountsTableComponent } from '@app/billing-company/components/object-rooms-accounts-table/object-rooms-accounts-table.component';
import { HasBCompanyLinkGuard } from '@app/billing-company/guards/has-b-company-link.guard';
import { BillingCompaniesResolver } from '@app/billing-company/resolvers/billing-companies.resolver';
import { BillingCompanyLinksResolver } from '@app/billing-company/resolvers/billing-company-links.resolver';
import { BCompanyAccountLogService } from '@app/billing-company/services/b-company-account-log.service';
import { BCompanyAccountService } from '@app/billing-company/services/b-company-account.service';
import { BCompanyDeviceService } from '@app/billing-company/services/b-company-device.service';
import { BillingCompanyLinkService } from '@app/billing-company/services/billing-company-link.service';
import { BillingCompanyService } from '@app/billing-company/services/billing-company.service';
import { BillingDataService } from '@app/billing-company/services/billing-data.service';
import { DeviceModule } from '@app/device/device.module';
import { ObjectModule } from '@app/object/object.module';
import { RoomModule } from '@app/room/room.module';
import { AppCommonModule } from '@common/app-common.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BillingCompanyAccountEffects } from '@store/billing/billing-company-account.effects';
import { BillingCompanyLinkEffects } from '@store/billing/billing-company-link.effects';
import { BillingCompanyEffects } from '@store/billing/billing-company.effects';
import * as fromBilling from '@store/billing/billing.reducer';
import { WslSharedModule } from 'wsl-shared';
import { TricSendDataComponent } from './components/tric-send-data/tric-send-data.component';
import { TricSendPacketComponent } from './components/tric-send-packet/tric-send-packet.component';
import { TricReceivePacketComponent } from './components/tric-receive-packet/tric-receive-packet.component';
import { TricMatchDevicesComponent } from './components/tric-match-devices/tric-match-devices.component';
import { TricMatchAccountsComponent } from './components/tric-match-accounts/tric-match-accounts.component';
import { TricReportComponent } from './components/tric-report/tric-report.component';
import { EnergoSystemReportComponent } from './components/energo-system-report/energo-system-report.component';
import { KazahReportComponent } from './components/kazah-report/kazah-report.component';
import { KazahReportFilterComponent } from './components/kazah-report-filter/kazah-report-filter.component';
import { EnergoSystemReportFilterComponent } from './components/energo-system-report-filter/energo-system-report-filter.component';
import { TricReportFilterComponent } from './components/tric-report-filter/tric-report-filter.component';
import {TricReportProtocolComponent} from './components/tric-report-protocol/tric-report-protocol.component';
import {TricReportProtocolRowInfoComponent} from './components/tric-report-protocol-row-info/tric-report-protocol-row-info.component';
import {TricReportProtocolExtComponent} from './components/tric-report-protocol-ext/tric-report-protocol-ext.component';

@NgModule({
  imports: [
    WslSharedModule,
    AppCommonModule,
    ObjectModule,
    RoomModule,
    DeviceModule,
    StoreModule.forFeature('billing', fromBilling.reducers),
    EffectsModule.forFeature([BillingCompanyEffects, BillingCompanyLinkEffects, BillingCompanyAccountEffects])
  ],
  declarations: [
    ObjectRoomsAccountsTableComponent,
    ObjectRoomsAccountsRowComponent,
    LinkDevicesToBcompanyDevicesByAccountComponent,
    TricSendDataComponent,
    TricSendPacketComponent,
    TricReceivePacketComponent,
    TricMatchDevicesComponent,
    TricMatchAccountsComponent,
    TricReportComponent,
    EnergoSystemReportComponent,
    KazahReportComponent,
    KazahReportFilterComponent,
    EnergoSystemReportFilterComponent,
    TricReportFilterComponent,
    TricReportProtocolComponent,
    TricReportProtocolRowInfoComponent,
    TricReportProtocolExtComponent,
  ],
  exports: [
    ObjectRoomsAccountsTableComponent,
    ObjectRoomsAccountsRowComponent,
    LinkDevicesToBcompanyDevicesByAccountComponent,
    TricSendDataComponent,
    TricSendPacketComponent,
    TricReceivePacketComponent,
    TricMatchDevicesComponent,
    TricMatchAccountsComponent,
    TricReportComponent,
    EnergoSystemReportComponent,
    KazahReportComponent,
    TricReportProtocolComponent,
  ],
  providers: [
    BillingCompanyService,
    BillingCompanyLinkService,
    BCompanyAccountService,
    BillingDataService,
    BCompanyDeviceService,
    BCompanyAccountLogService,

    HasBCompanyLinkGuard,

    BillingCompaniesResolver,
    BillingCompanyLinksResolver
  ]
})
export class BillingCompanyModule {
}

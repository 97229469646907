import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WSLApiHttpClientService, WSLHttpHelper } from 'wsl-core';
var BCompanyDeviceService = /** @class */ (function (_super) {
    tslib_1.__extends(BCompanyDeviceService, _super);
    function BCompanyDeviceService(http) {
        var _this = _super.call(this, http, 'bcompany_device') || this;
        _this.http = http;
        return _this;
    }
    BCompanyDeviceService.prototype.accountInfo = function (bcompany_account_id) {
        return this.http.get('get_account_tric', { params: WSLHttpHelper.getParams({ bcompany_account_id: bcompany_account_id }) });
    };
    return BCompanyDeviceService;
}(WSLApiHttpClientService));
export { BCompanyDeviceService };

import { HttpClient } from '@angular/common/http';
import { WSLHttpHelper } from 'wsl-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DefineDataFilterService = /** @class */ (function () {
    function DefineDataFilterService(http) {
        this.http = http;
    }
    DefineDataFilterService.prototype.get = function (filter) {
        return this.http.get('get_filter', { params: WSLHttpHelper.getParams(filter) });
    };
    DefineDataFilterService.ngInjectableDef = i0.defineInjectable({ factory: function DefineDataFilterService_Factory() { return new DefineDataFilterService(i0.inject(i1.HttpClient)); }, token: DefineDataFilterService, providedIn: "root" });
    return DefineDataFilterService;
}());
export { DefineDataFilterService };

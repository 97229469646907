import { Injectable } from '@angular/core';
import { StorageKey } from 'wsl-ek-core';
import { HttpClient } from '@angular/common/http';
import { DBDateTimeFormat, IWSLHttpResponse, WSLHttpHelper, WSLLocalStorageService, WSLUtils } from 'wsl-core';
import { format, isSameHour } from 'date-fns';
import { Observable } from 'rxjs';
import {IWSLDevice, IWSLDeviceExecution, IWSLDeviceExecutionFilter} from 'wsl-device';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class DeviceExecutionService {
  constructor(private http: HttpClient, private localStorage: WSLLocalStorageService) {
  }

  static getExecution(device: IWSLDevice, dexecutions: IWSLDeviceExecution[]) {
    return dexecutions && device ? dexecutions.find(de => de.id === device.dexecution_id) : null;
  }

  public get(filter: IWSLDeviceExecutionFilter): Observable<IWSLHttpResponse<IWSLDeviceExecution>> {
    if (WSLUtils.isEmptyObject(filter)) {
      const dict = this.localStorage.get(StorageKey.device_executions);
      if (dict != null && isSameHour(new Date(), new Date(dict.time))) {
        return of(dict);
      }
    }
    return this.http.get<IWSLHttpResponse<IWSLDeviceExecution>>('device_execution', {
      params: WSLHttpHelper.getParams(filter)
    })
      .pipe(map(data => {
        if (WSLUtils.isEmptyObject(filter)) {
          this.localStorage.set(StorageKey.device_executions, {
            time: format(new Date(), DBDateTimeFormat),
            items: data.items
          });
        }
        return data;
      }));
  }
}

import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AddDeviceConfGroupFail, AddDeviceConfGroupSuccess, DeleteDeviceConfGroupFail, DeleteDeviceConfGroupSuccess, DeviceConfGroupActionTypes, LoadDeviceConfGroupFail, LoadDeviceConfGroupsFail, LoadDeviceConfGroupsSuccess, LoadDeviceConfGroupSuccess, UpdateDeviceConfGroupFail, UpdateDeviceConfGroupSuccess } from './device-conf-group.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { DeviceConfGroupService } from '@app/device-conf-group/services/device-conf-group.service';
var DeviceConfGroupEffects = /** @class */ (function () {
    function DeviceConfGroupEffects(actions$, router, route, storeHelper, deviceConfGroupService) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.route = route;
        this.storeHelper = storeHelper;
        this.deviceConfGroupService = deviceConfGroupService;
        this.add$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.AddDeviceConfGroup), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfGroupActionTypes.AddDeviceConfGroup), skip(1));
            return _this.deviceConfGroupService.add(payload)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddDeviceConfGroupSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddDeviceConfGroupFail); }));
        }));
        this.update$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.UpdateDeviceConfGroup), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfGroupActionTypes.UpdateDeviceConfGroup), skip(1));
            return _this.deviceConfGroupService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateDeviceConfGroupSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateDeviceConfGroupFail); }));
        }));
        this.load$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroup), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroup), skip(1));
            return _this.deviceConfGroupService.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceConfGroupSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceConfGroupFail); }));
        }));
        this.loadMany$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroups), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroups), skip(1));
            return _this.deviceConfGroupService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceConfGroupsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceConfGroupsFail); }));
        }));
        this.delete$ = this.actions$.pipe(ofType(DeviceConfGroupActionTypes.DeleteDeviceConfGroup), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceConfGroupActionTypes.DeleteDeviceConfGroup), skip(1));
            return _this.deviceConfGroupService.delete(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteDeviceConfGroupSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteDeviceConfGroupFail); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(DeviceConfGroupActionTypes.AddDeviceConfGroup, DeviceConfGroupActionTypes.LoadDeviceConfGroup, DeviceConfGroupActionTypes.UpdateDeviceConfGroup, DeviceConfGroupActionTypes.DeleteDeviceConfGroup), map(function () { return new StartLoading('device_conf_group'); }));
        this.startLoadMany$ = this.actions$
            .pipe(ofType(DeviceConfGroupActionTypes.LoadDeviceConfGroups), map(function (action) { return action.payload; }), filter(function (payload) { return payload.offset === 0; }), map(function () { return new StartLoading('device_conf_group'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(DeviceConfGroupActionTypes.AddDeviceConfGroupSuccess, DeviceConfGroupActionTypes.AddDeviceConfGroupFail, DeviceConfGroupActionTypes.LoadDeviceConfGroupSuccess, DeviceConfGroupActionTypes.LoadDeviceConfGroupFail, DeviceConfGroupActionTypes.UpdateDeviceConfGroupSuccess, DeviceConfGroupActionTypes.UpdateDeviceConfGroupFail, DeviceConfGroupActionTypes.DeleteDeviceConfGroupSuccess, DeviceConfGroupActionTypes.DeleteDeviceConfGroupFail, DeviceConfGroupActionTypes.LoadDeviceConfGroupsSuccess, DeviceConfGroupActionTypes.LoadDeviceConfGroupsFail), map(function () { return new StopLoading('device_conf_group'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupEffects.prototype, "loadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupEffects.prototype, "delete$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupEffects.prototype, "startLoadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceConfGroupEffects.prototype, "stopLoad$", void 0);
    return DeviceConfGroupEffects;
}());
export { DeviceConfGroupEffects };

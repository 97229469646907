<wsl-input-wrapper [label]="label" class="wsl-block wsl-relative" [required]="required" [asyncError]="asyncError">
  <input class="form-control"
         matInput
         placeholder="Выберите месяц"
         readonly
         (click)="_openDatepickerOnClick()"
         (keydown.arrowdown)="_openDatepickerOnClick()"
         (keydown.enter)="_openDatepickerOnClick()"
         [matDatepicker]="picker"
         [matDatepickerFilter]="_customFilter"
         [formControl]="_inputCtrl"
         [max]="_max"
         [min]="_min">
  <div class="wsl-abs wsl-abs-right wsl-abs-bottom">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  </div>
  <mat-datepicker #picker
                  [disabled]="disabled"
                  (opened)="onOpenPicker($event)"
                  (closed)="onClosePicker($event)"
                  (monthSelected)="_monthSelectedHandler($event)"
                  startView="year"></mat-datepicker>
</wsl-input-wrapper>

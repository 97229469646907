/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./main.page";
var styles_MainPage = [];
var RenderType_MainPage = i0.ɵcrt({ encapsulation: 2, styles: styles_MainPage, data: {} });
export { RenderType_MainPage as RenderType_MainPage };
export function View_MainPage_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_MainPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wsl-b2b-main-page", [], null, null, null, View_MainPage_0, RenderType_MainPage)), i0.ɵdid(1, 114688, null, 0, i1.MainPage, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainPageNgFactory = i0.ɵccf("wsl-b2b-main-page", i1.MainPage, View_MainPage_Host_0, {}, {}, []);
export { MainPageNgFactory as MainPageNgFactory };

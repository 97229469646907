// import { WorkerAppModule } from '@angular/platform-webworker';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {ApplicationRef, LOCALE_ID, NgModule} from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BillingCompanyModule } from '@app/billing-company/billing-company.module';
import { DeviceModule } from '@app/device/device.module';
import { ObjectModule } from '@app/object/object.module';
import { getRuPaginatorIntl } from '@app/ru-paginator-intl';
import { JwtModule } from '@auth0/angular-jwt';
import { AppCommonModule } from '@common/app-common.module';
import { CoreModule } from '@core/core.module';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppEffects } from '@store/effects/app.effects';
import { AuthEffects } from '@store/effects/auth.effects';
import { UserProfileEffects } from '@store/effects/user-profile.effects';
import { metaReducers, reducers } from '@store/reducers/index';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxPermissionsModule } from 'ngx-permissions';
import { WslAuthModule } from 'wsl-auth';
import { getLocalStorage, getWindow, WslCoreModule } from 'wsl-core';
import { StorageKey, WslEkCoreModule } from 'wsl-ek-core';
import { WslSharedModule } from 'wsl-shared';
import {API_BASE_PATH, API_POSTFIX, EXCLUDE_POSTFIX_API, EXCLUDE_PREFIX_API} from '../config/api.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPage } from './main.page';
import {createInputTransfer, createNewHosts, removeNgStyles} from '@angularclass/hmr';

registerLocaleData(localeRu);

export function getToken() {
  if (typeof window !== 'undefined' && window.localStorage) {
    /** Is platform browser */
    const token = window.localStorage.getItem(StorageKey.token);
    if (token) {
      return token;
    }
    return null;
  }
  return null;
  // return LocalStorageService.get(StorageKey.token);
}

@NgModule({
  declarations: [
    AppComponent,
    MainPage,
  ],
  imports: [
    // WorkerAppModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    WslCoreModule.forRoot({
      smooth: true,
      apiPrefix: API_BASE_PATH,
      apiPostfix: API_POSTFIX,
      excludePostfix: EXCLUDE_POSTFIX_API,
      excludePrefix: EXCLUDE_PREFIX_API
    }),
    WslSharedModule,
    WslAuthModule,
    WslEkCoreModule.forRoot(),

    AppCommonModule,
    CoreModule.forRoot(),

    NgxPermissionsModule.forRoot(),

    DeviceDetectorModule.forRoot(),

    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        whitelistedDomains: environment.production ? ['dom.energokabinet.ru'] : environment.hmr ?
          ['dom.energokabinet.ru:4202', 'karat-b2b.ws-lab.ru:4202', 'localhost:4202'] : ['karat-b2b.ws-lab.ru']
      }
    }),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([
      AppEffects,
      AuthEffects,
      UserProfileEffects
    ]),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      // name: 'WP NgRx Store DevTools',
      maxAge: 50,
      logOnly: environment.production
    }),

    //  DataModule,
    //  ServiceModule,
    ObjectModule,
    DeviceModule,
    BillingCompanyModule,
    AppRoutingModule,

    ServiceWorkerModule.register('./ngsw-worker.js', {enabled: !environment.hmr})
  ],
  providers: [
    {provide: 'LOCALSTORAGE', useFactory: getLocalStorage},
    {provide: 'WINDOW', useFactory: getWindow},
    {provide: LOCALE_ID, useValue: 'ru-RU'},
    {provide: MatPaginatorIntl, useValue: getRuPaginatorIntl()},
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef) {}
  hmrOnInit(store) {
    if (!store || !store.state) { return; }
    if ('restoreInputValues' in store) {
      store.restoreInputValues();
    }
    // change detection
    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }
  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // inject your AppStore and grab state then set it on store
    // var appState = this.AppStore.get()
    store.state = {data: 'yolo'};
    // store.state = Object.assign({}, appState)
    // save input values
    store.restoreInputValues  = createInputTransfer();
    // remove styles
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
    // anything you need done the component is removed
  }
}

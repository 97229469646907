<main>
  <div class="wsl-m-t-20">
    <div class="wsl-flex-center">
      <div class="wsl-col-4_md-5_sm-6_xs-12 wsl-text-center">
        <div class="wsl-flex-center-middle_xs-Cols-ng wsl-m-b-20">
          <a href="#" class="wsl-flex-middle-ng wsl-st4">
            <span>Энерго</span>
            <span class="wsl-thin">Кабинет</span>
          </a>
          <span class="wsl-gray wsl-m-x-10 wsl-st4 wsl-thin wsl-hide-xs">|</span>
          <span class="wsl-gray wsl-t4 wsl-thin">Регистрация</span>
        </div>

        <wsl-registration-form [pending]="pending$|async"
                               [asyncErrors]="errors$|async"
                               [showCodeActivation]="showCodeActivation"
                               (submitted)="onSubmit($event)"></wsl-registration-form>
        <div class="wsl-flex-spaceBetween_xs-Cols-ng wsl-m-t-10">
          <a class="" [routerLink]="['/login']">Авторизоваться</a>
        </div>

      </div>
    </div>
  </div>
</main>

import { EventEmitter, OnInit } from '@angular/core';
import { AllSystems } from 'wsl-ek-core';
import { ActivatedRoute } from '@angular/router';
import { WSLRouterHelperService } from 'wsl-core';
var DeviceConfExtRowComponent = /** @class */ (function () {
    function DeviceConfExtRowComponent(route) {
        this.route = route;
        this.showButtons = true;
        this.edit = new EventEmitter();
        this.delete = new EventEmitter();
        this.rvariables = [];
        this.rvalues = [];
        this.units = [];
        this.dataTypes = [];
    }
    DeviceConfExtRowComponent.prototype.ngOnInit = function () {
        var data = WSLRouterHelperService.collectRouteData(this.route);
        this.rvariables = data.resourceVariables;
        this.rvalues = data.resourceValues;
        this.units = data.resourceUnits;
        this.dataTypes = data.dataTypes;
    };
    Object.defineProperty(DeviceConfExtRowComponent.prototype, "typeName", {
        get: function () {
            var _this = this;
            return this.conf.type_data ? this.dataTypes.find(function (t) { return t.id === _this.conf.type_data; }).name : 'не задано';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceConfExtRowComponent.prototype, "resource", {
        get: function () {
            var _this = this;
            return AllSystems.find(function (ar) { return _this.conf.resource_id === ar.id; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceConfExtRowComponent.prototype, "rvariable", {
        get: function () {
            var _this = this;
            return this.rvariables.find(function (rv) { return rv.id === _this.conf.rvariable_id; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceConfExtRowComponent.prototype, "rvalue", {
        get: function () {
            var _this = this;
            return this.rvalues.find(function (rv) { return rv.id === _this.conf.rvalue_id; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceConfExtRowComponent.prototype, "unit", {
        get: function () {
            var _this = this;
            return this.units.find(function (u) { return u.id === _this.conf.unit_id; });
        },
        enumerable: true,
        configurable: true
    });
    return DeviceConfExtRowComponent;
}());
export { DeviceConfExtRowComponent };

<ng-container [formGroup]="form">
    <label *ngIf="label !== ''">{{label}}</label>
    <ng-container *ngIf="isRadio">
        <div class="wsl-overflow-hidden">
            <div class="wsl-auto-grid">
                <div class="wsl-grid-item"
                     *ngFor="let item of items; trackBy: trackById"
                     [matTooltip]="item.name" [matTooltipDisabled]="!isIcons">
                    <ng-container *ngIf="isIcons; else isTextRadio">
                        <input class="icons"
                               formControlName="id"
                               type="radio"
                               *ngIf="!item.disabled; else isDisabledIcon"
                               [attr.id]="prefix+'_select_radio_item_'+item.id"
                               [value]="item.id">
                        <ng-template #isDisabledIcon>
                            <input class="icons"
                                   type="radio"
                                   disabled
                                   [attr.id]="prefix+'_select_radio_item_'+item.id"
                                   [value]="item.id">
                        </ng-template>
                        <label [ngClass]="{'several-radio': !!item.icons}" [attr.for]="prefix+'_select_radio_item_'+item.id">
                            <ng-container *ngIf="!!item.icons; else oneRadioIcon">
                                <ng-container *ngFor="let icon of item.icons; trackBy: trackByIcon">
                                    <span  class="{{icon}} {{item?.color}} wsl-hover"></span>
                                </ng-container>
                            </ng-container>
                            <ng-template #oneRadioIcon>
                                <span class="{{item.icon}} {{item?.color}} wsl-hover"></span>
                            </ng-template>
                        </label>
                    </ng-container>
                    <ng-template #isTextRadio>
                        <input class="stringbox"
                               [class.high]="!isLow"
                               formControlName="id"
                               type="radio"
                               [attr.id]="prefix+'_select_radio_item_'+item.id"
                               [value]="item.id"
                               *ngIf="!item.disabled; else isDisabledText">
                        <ng-template #isDisabledText>
                            <input class="stringbox"
                                   [class.high]="!isLow"
                                   type="radio"
                                   disabled
                                   [attr.id]="prefix+'_select_radio_item_'+item.id"
                                   [value]="item.id">
                        </ng-template>
                        <label [attr.for]="prefix+'_select_radio_item_'+item.id" class="wsl-text-nowrap">
                            <span class="wsl-hover" [innerHTML]="item.name"></span>
                        </label>
                    </ng-template>

                </div>
                <!--<div class="wsl-grid-item" *ngIf="hasHelp">
                    <wsl-shared-popover [html]="help?.html"></wsl-shared-popover>
                </div>-->
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isRadio" [formArrayName]="'ids'">
        <div class="wsl-overflow-hidden">
            <div class="wsl-auto-grid">
                <div class="wsl-grid-item"
                     *ngFor="let ctrl of idsControls; let id = index" [formGroupName]="id"
                     [matTooltip]="ctrl.get('name').value" [matTooltipDisabled]="!isIcons">
                    <ng-container *ngIf="isIcons; else isTextCheckbox">
                        <input class="icons" formControlName="enable" type="checkbox" [attr.id]="prefix+'_select_checkbox_item_'+ctrl.get('id').value">
                        <label [ngClass]="{'several-check': !!ctrl.get('icons')}" [attr.for]="prefix+'_select_checkbox_item_'+ctrl.get('id').value">
                            <ng-container *ngIf="!!ctrl.get('icons'); else oneCheckIcon">
                                <ng-container *ngFor="let icon of ctrl.get('icons').value; trackBy: trackByIcon">
                                    <span class="{{icon}} {{ctrl.get('color').value}} wsl-hover"></span>
                                </ng-container>
                            </ng-container>
                            <ng-template #oneCheckIcon>
                                <span class="{{ctrl.get('icon').value}} {{ctrl.get('color').value}} wsl-hover"></span>
                            </ng-template>
                        </label>
                    </ng-container>
                    <ng-template #isTextCheckbox>
                        <input class="stringbox" [class.high]="!isLow" formControlName="enable" type="checkbox" [attr.id]="prefix+'_select_checkbox_item_'+ctrl.get('id').value">
                        <label [attr.for]="prefix+'_select_checkbox_item_'+ctrl.get('id').value"><span class="wsl-hover" [innerHTML]="ctrl.get('name').value"></span></label>
                    </ng-template>
                </div>
                <!--<div class="wsl-grid-item" *ngIf="hasHelp">
                    <wsl-shared-popover [html]="help?.html"></wsl-shared-popover>
                </div>-->
            </div>
        </div>
    </ng-container>
    <wsl-shared-popover *ngIf="hasHelp" [html]="help"></wsl-shared-popover>
</ng-container>

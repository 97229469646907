import * as tslib_1 from "tslib";
import { AppActionTypes } from '../actions/app.actions';
export var initialState = {
    loading: new Map(),
    menu: [],
    asyncMessages: null,
    hasBilling: false,
    isUc: false,
    isKv: false,
    isHome: false,
    noAccessData: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AppActionTypes.StartLoading: {
            var loading = state.loading;
            if (action.payload === 'route') {
                loading.clear();
            }
            loading.set(action.payload, true);
            return tslib_1.__assign({}, state, { loading: loading, asyncMessages: null });
        }
        case AppActionTypes.StopLoading: {
            var loading = state.loading;
            loading.set(action.payload, false);
            return tslib_1.__assign({}, state, { loading: loading });
        }
        case AppActionTypes.SetAppEnvironment: {
            return tslib_1.__assign({}, state, action.payload);
        }
        case AppActionTypes.ShowMenuTabBar:
            return tslib_1.__assign({}, state, { menu: action.payload });
        case AppActionTypes.HideMenuTabBar:
            return tslib_1.__assign({}, state, { menu: [] });
        case AppActionTypes.SetSnackBarMessage:
            return tslib_1.__assign({}, state, { asyncMessages: action.payload });
        case AppActionTypes.ShowNoAccessMessage:
            return tslib_1.__assign({}, state, { noAccessData: tslib_1.__assign({}, action.payload) });
        case AppActionTypes.ClearContractData:
            return tslib_1.__assign({}, state, { noAccessData: null });
        case AppActionTypes.HasBilling:
            return tslib_1.__assign({}, state, { hasBilling: true });
        default:
            return state;
    }
}
export var getPending = function (state) { return state.loading ? Array.from(state.loading.values()).some(function (l) { return l; }) : false; };
export var getMenu = function (state) { return state.menu; };
export var getMessage = function (state) { return state.asyncMessages; };
export var getNoAccessData = function (state) { return state.noAccessData; };
export var getHasBilling = function (state) { return state.hasBilling; };
export var getIsUc = function (state) { return state.isUc; };
export var getIsKv = function (state) { return state.isKv; };
export var getIsHome = function (state) { return state.isHome; };

<style>
    .btn-floating span {
        font-size: 1rem;
    }
</style>

<div class="fixed-action-btn fab_activator_{{prefix}} wsl-flex-ng"
     [class.horizontal]="isHorizontal"
     [class.click-to-toggle]="isClickToggle"
     [class.toolbar]="toToolbar"
     [ngStyle]="wrapperStyles">
    <a class="btn-floating" [ngClass]="fabActionClasses" (click)="onClickFab()">
        <span [ngClass]="icon"></span>
    </a>
    <ul [ngStyle]="ulStyles" [style.margin-right]="isHorizontal ? '-25px' : '0'" [style.margin-top]="isHorizontal ? '0' : '-20px'">
        <ng-container *ngFor="let actionBtn of sortButtons; trackBy: trackByButton">
            <li *ngIf="!actionBtn.buttons; else isFabBtn" [style.margin-top]="isHorizontal ? '0' : '10px'">
                <a *ngIf="!isNav; else routesActiveBtn"
                   class="btn-floating wsl-bg-gray_hover"
                   wslDebounceClick (debounceClick)="btnClick.emit($event)" [debounceTime]="350"
                   [debounceId]="actionBtn.id"
                   [ngClass]="actionBtn.classes"
                   [matTooltip]="actionBtn.label" [matTooltipPosition]="!isHorizontal ? 'left' : 'below'">
                    <span [ngClass]="actionBtn.icon"></span>
                </a>
                <ng-template #routesActiveBtn>
                    <a class="btn-floating" routerLinkActive
                       [routerLinkActiveOptions]="{exact:true}"
                       #rla="routerLinkActive"
                       [ngClass]="{'wsl-bg-gray_hover':!rla.isActive,'wsl-bg-orange':rla.isActive}"
                       routerLink="{{actionBtn.route}}"
                       [matTooltip]="actionBtn.name" matTooltipPosition="right">
                        <span [ngClass]="actionBtn.icon"></span>
                    </a>
                </ng-template>
            </li>
            <ng-template #isFabBtn>
                <li *ngFor="let ab of actionBtn.buttons; trackBy: trackByButton">
                    <a class="btn-floating wsl-bg-gray_hover"
                       wslDebounceClick (debounceClick)="btnClick.emit($event)" [debounceTime]="350"
                       [debounceId]="actionBtn.id"
                       [ngClass]="ab.classes"
                       [matTooltip]="ab.label">
                        <span [ngClass]="ab.icon"></span>
                    </a>
                </li>
            </ng-template>
        </ng-container>
    </ul>
</div>

<!--<div class="wsl-show-xs fixed-action-btn__" style="bottom: 15px; left: 15px; right: auto;">
<a class="btn-floating wsl-bg-gray_hover">
    <span class="icon-menu"></span>
</a>
<ul style="left: 0px; right: auto; bottom: 40px;">
    <li *ngIf="showBack">
        <a class="btn-floating wsl-bg-gray_hover"
           [routerLink]="[backURL]"
           matTooltip="Назад" matTooltipPosition="right">
            <span class="icon-chevron-left"></span>
        </a>
    </li>
    <li *ngFor="let nav of navs">
        <a class="btn-floating" routerLinkActive
           [routerLinkActiveOptions]="{exact:true}"
           #rla="routerLinkActive"
           [ngClass]="{'wsl-bg-gray_hover':!rla.isActive,'wsl-bg-orange':rla.isActive}"
           routerLink="{{nav.route}}"
           [matTooltip]="nav.name" matTooltipPosition="right">
            <span [ngClass]="nav.icon"></span>
        </a>
    </li>
</ul>
</div>-->

<!--<a class="wsl-btn-round wsl-m-y-5 z-depth-1">
  <span [ngClass]="icon"></span>
</a>
<ul style="left: auto; right: 0px; bottom: 40px;">
  <li *ngFor="let actionBtn of buttons">
    <a class="btn-floating"
       wslDebounceClick (debounceClick)="btnClick.emit($event)" [debounceTime]="350" [debounceId]="actionBtn.id"
       [ngClass]="actionBtn.classes"
       [@actionButtonsState]="actionBtn.visible.toString()"
       [attr.data-wenk]="actionBtn.label" data-wenk-pos="left">
      <span [ngClass]="actionBtn.icon"></span>
    </a>
    &lt;!&ndash;<wsl-action-button [id]="btn.id"
                       [label]="btn.label"
                       [visible]="btn.visible"
                       [classes]="btn.classes"
                       [icon]="btn.icon"
                       (click)="click.emit($event)"></wsl-action-button>&ndash;&gt;
  </li>
</ul>-->

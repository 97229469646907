<nav id="menu" class="wsl-bg-primary" *ngIf="!sidenav" #navEl>
    <div class="wsl-flex-middle-nowrap-ng">

        <div class="wsl-col-nogrow">
            <a [routerLink]="['/']" class="wsl-flex-middle-nowrap-ng wsl-p-x-10 wsl-st4 wsl-white wsl-hover wsl-hide-xs"
               #titleEl>
                <span>{{title}}</span>
                <span class="wsl-thin">{{subTitle}}</span>
            </a>
            <a [routerLink]="['/']" class="wsl-flex-middle-nowrap-ng wsl-p-x-10 wsl-st4 wsl-white wsl-hover wsl-show-xs"
               #titleXSEl>
                <span>{{titleXS}}</span>
                <span class="wsl-thin">{{subTitleXS}}</span>
            </a>
        </div>

        <div class="wsl-col wsl-text-right">
            <ul id="nav" class="wsl-m-l-auto wsl-text-nowrap" style="height: 44px;">
                <li routerLinkActive="active" *ngFor="let item of topMenu; trackBy: trackById" #menuItemsEl>
                    <a class="wsl-flex-middle-nowrap-ng wsl-white wsl-hover wsl-relative" [routerLink]="[item.route]"
                       style="display: flex;">
                        <span class="wsl-st4 {{item.icon}}" [matTooltip]="item.name">
                            <span *ngIf="item.badge"
                                  class="wsl-badge wsl-small wsl-bg-white wsl-red wsl-abs wsl-abs-top wsl-abs-right wsl-m-t-5"
                                  style="z-index: 5; margin-right: 5px;">{{item.badge}}</span>
                        </span>
                        <span class="wsl-thin wsl-m-l-10 wsl-hide-xs">{{item.name}}</span>
                    </a>
                </li>
                <li #profileEl>
                    <a class="wsl-flex-middle-ng wsl-white wsl-hover"
                       (click)="toggleProfile.emit()">
                        <span class="icon-user wsl-st4"
                              [matTooltip]="userTooltip"></span>
                        <span class="wsl-thin wsl-hide-sm-down wsl-m-l-10">
                            {{user?.first_name}} {{user?.last_name}}
    
                            <span class="wsl-hide-md-down">
                                <ng-container *ngIf="showCompany">
                                    <span class="wsl-small wsl-p-x-5 wsl-white-dark">|</span>
                                    <span class="wsl-small">{{company?.short_name}}</span>
                                    <ng-container *ngIf="showCompanyTown">
                                        <span class="wsl-small wsl-p-x-5 wsl-white-dark">|</span>
                                        <span class="wsl-small">{{town}}</span>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="showRoom">
                                    <span class="wsl-small wsl-p-x-5 wsl-white-dark">|</span>
                                    <span class="wsl-small">{{room?.street}} {{room?.house_num}}, кв {{room?.name}}</span>
                                </ng-container>
                            </span>
                        </span>
                    </a>
                </li>
                <!--<li>
                    {{eventCount}}
                </li>-->
                <li #exitEl>
                    <a class="wsl-st4 wsl-white wsl-hover" (click)="logout.emit()" matTooltip="Выход">
                        <span class="icon-exit"></span>
                    </a>
                </li>
    
                <li id="drop" class="drop" #dropEl>
                    <!--<a class="dropdown-trigger" href="#!" data-target="dropdown1">Dropdown<i class="material-icons right">arrow_drop_down</i></a>-->
                    <a class="icon-menu wsl-st4 wsl-white wsl-hover menu_dropdown-trigger" data-target="dropdown_menu"></a>
                    <!--<ul></ul>-->
                </li>
            </ul>
        </div>

<!--        <ul>

        </ul>-->
    </div>

</nav>

<ul id="dropdown_menu" class="dropdown-content dropdown-menu" #dropMenuEl>
    <li routerLinkActive="active" *ngFor="let item of dropMenu; trackBy: trackById">
        <a class="wsl-flex-middle-nowrap-ng wsl-white wsl-hover wsl-relative" [routerLink]="[item.route]"
           style="display: flex;">
                        <span class="wsl-st4 {{item.icon}}" [matTooltip]="item.name">
                            <span *ngIf="item.badge"
                                  class="wsl-badge wsl-small wsl-bg-white wsl-red wsl-abs wsl-abs-top wsl-abs-right wsl-m-t-5"
                                  style="z-index: 5; margin-right: 5px;">{{item.badge}}</span>
                        </span>
            <span class="wsl-thin wsl-m-l-10 wsl-hide-xs">{{item.name}}</span>
        </a>
    </li>
</ul>

<ul *ngIf="sidenav">
    <li routerLinkActive="active" *ngFor="let item of menu; trackBy: trackById">
        <a class="wsl-white wsl-hover" [routerLink]="[item.route]">{{item.name}}</a>
    </li>
</ul>

<style>
    nav ul > li {
        float: none;
        display: inline-block;
    }

    nav ul > li > ul > li {
        display: block;
    }

    .dropdown-menu > li {
        display: block;
    }
    .dropdown-menu > li:hover {
        background-color: #555;
    }

    li.drop {
        display: none;
        position: relative;
    }

    .drop ul {
        display: none;
        position: absolute;
        top: 38px;
        left: 0;
    }

    .drop:hover ul {
        display: inline-block;
    }
</style>

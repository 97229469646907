import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLTown } from 'wsl-ek-core';

export enum TownActionTypes {
  LoadTownProfile = '[TownProfile] Load Town Profile',
  LoadTownProfileSuccess = '[TownProfile] Load Town Profile Success',
  LoadTownProfileFail = '[TownProfile] Load Town Profile Fail'
}

export class LoadTownProfile implements Action {
  readonly type = TownActionTypes.LoadTownProfile;
}

export class LoadTownProfileSuccess implements Action {
  readonly type = TownActionTypes.LoadTownProfileSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLTown>) {
  }
}

export class LoadTownProfileFail implements Action {
  readonly type = TownActionTypes.LoadTownProfileFail;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export type TownActions =
  LoadTownProfile |
  LoadTownProfileSuccess |
  LoadTownProfileFail;

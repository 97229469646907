import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {DeviceTypes, IWSLDevice, IWSLDeviceModel, IWSLDeviceModelFilter, DeviceType} from 'wsl-device';
import {IWSLCompany, StorageKey} from 'wsl-ek-core';
import { format, isSameHour } from 'date-fns';
import {DBDateTimeFormat, IWSLHttpResponse, MomentDBDateFormat, WSLHttpHelper, WSLLocalStorageService, WSLUtils} from 'wsl-core';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import {DeviceFabrics, DeviceFabricType} from '@app/device/enums/device-fabric-type.enum';
import * as moment from 'moment';

@Injectable()
export class DeviceModelService {

  constructor(private http: HttpClient, private localStorage: WSLLocalStorageService) {
  }

  static isCalc(dmodel: IWSLDeviceModel) {
    return dmodel && dmodel.ext ? !!dmodel.ext.calc_new : false;
  }

  static getFabric(dmodel: IWSLDeviceModel) {
    return dmodel && dmodel.ext ? DeviceFabrics.find(fab => fab.id === dmodel.fabric_id) : null;
  }

  static getDeviceType(dmodel: IWSLDeviceModel) {
    return dmodel && dmodel.ext && dmodel.ext.dev_type ? DeviceTypes.find(dt => dt.id === dmodel.ext.dev_type) : null;
  }

  static hasDateNext(dmodel: IWSLDeviceModel) {
    return dmodel && dmodel.ext ? [DeviceType.node_in_device, DeviceType.node_with_device].includes(dmodel.ext.dev_type) : false;
  }

  static mustSetupSensor(dmodel: IWSLDeviceModel) {
    return dmodel && dmodel.ext ? [DeviceType.node_with_sensor].includes(dmodel.ext.dev_type) : false;
  }
  static getModel(device: IWSLDevice, dmodels: IWSLDeviceModel[]) {
    return dmodels && device ? dmodels.find(dm => dm.id === device.dmodel_id) : null;
  }

  static isKarat926(dmodel: IWSLDeviceModel) {
    return dmodel && dmodel.driver_alias === '926' && dmodel.fabric_id === DeviceFabricType.karat;
  }

  static isKarat526(dmodel: IWSLDeviceModel) {
    return dmodel && dmodel.driver_alias === '526' && dmodel.fabric_id === DeviceFabricType.karat;
  }

  static isKarat300(dmodel: IWSLDeviceModel) {
    return dmodel && ['306', '307', '308'].includes(dmodel.driver_alias)  && dmodel.fabric_id === DeviceFabricType.karat;
  }

  static getAvailableArchives(dmodel: IWSLDeviceModel, company: IWSLCompany) {
    // is paid access
    return dmodel && dmodel.ext && company ? (
      company.contract_type && !!company.contract_date_beg && !!company.contract_date_end &&
      moment().isSameOrAfter(moment(company.contract_date_beg, MomentDBDateFormat), 'day') &&
      moment().isSameOrBefore(moment(company.contract_date_end, MomentDBDateFormat), 'day') ?
        dmodel.ext.archives_contract : dmodel.ext.archives_not_contract
    ) : [];
  }

  public get(filter: IWSLDeviceModelFilter) {
    if (WSLUtils.isEmptyObject(filter)) {
      const dict = this.localStorage.get(StorageKey.device_models);
      if (dict != null && isSameHour(new Date(), new Date(dict.time))) {
        return of(dict);
      }
    }
    return this.http.get<IWSLHttpResponse<IWSLDeviceModel>>('device_model', {
      params: WSLHttpHelper.getParams(filter)
    })
      .pipe(map(data => {
        if (WSLUtils.isEmptyObject(filter)) {
          this.localStorage.set(StorageKey.device_models, {
            time: format(new Date(), DBDateTimeFormat),
            items: data.items
          });
        }
        return data;
      }));
  }
}

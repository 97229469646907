<div class="wsl-flex-middle-nowrap-ng">
    <div *ngIf="collapsed">
        <div class="wsl-btn-round wsl-white wsl-bg-gray-light">{{num}}</div>
    </div>
    <div *ngIf="active">
        <div class="wsl-btn-round wsl-white wsl-bg-primary" *ngIf="!cancel">{{num}}</div>
        <div class="wsl-btn-round wsl-white wsl-bg-primary-light" *ngIf="cancel">
            <span class="icon-cross"></span>
        </div>
    </div>
    <div *ngIf="done">
        <div class="wsl-btn-round wsl-white wsl-bg-primary-light">
            <span class="icon-check"></span>
        </div>
    </div>
    <div *ngIf="collapsed; else visible" class="wsl-p-l-15">
        <div class="wsl-gray wsl-st5">{{header}}</div>
    </div>
    <ng-template #visible>
        <div class="wsl-p-l-15">
            <div class="wsl-st5">{{header}}</div>
            <div class="wsl-gray" *ngIf="active">{{describe}}</div>
        </div>
    </ng-template>
</div>

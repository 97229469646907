import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IWSLDeviceConfGroupFileIndex, IWSLDeviceConfGroupFileIndexView} from '@app/device-conf-group/models/device-conf-group-file-index.model';
import {IWSLAsyncErrors, WSLFormHelper} from 'wsl-core';
import {IWSLCompany} from 'wsl-ek-core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WSLMaterializeHelper} from 'wsl-shared';

@Component({
  selector: '[wsl-device-conf-group-file-index]',
  templateUrl: './device-conf-group-file-index.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfGroupFileIndexComponent implements OnInit, OnChanges {
  @Input() conf: IWSLDeviceConfGroupFileIndexView;
  @Input() pending: boolean;
  @Input() asyncErrors: IWSLAsyncErrors;
  @Input() readonly: boolean;
  @Input() editing: boolean;
  @Input() companies: IWSLCompany[];
  @Input() onlyCustom: boolean;
  @Input() checked = false;
  @Input() checkable = false;
  @Output() check = new EventEmitter<{ id: number, checked: boolean }>();
  @Output() editConf = new EventEmitter<number>();
  @Output() updateConf = new EventEmitter<IWSLDeviceConfGroupFileIndex>();
  @Output() cancelEditConf = new EventEmitter();

  form = new FormGroup({
    id: new FormControl(null),
    group_id: new FormControl(null, [Validators.required]),
    company_id: new FormControl(null),
    name: new FormControl('', [Validators.required]),
    config: new FormControl('', [Validators.required])
  });

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.conf || changes.onltCustom) {
      this.fillForm();
    }
  }

  onEdit() {
    if (this.readonly || this.conf.fixed) {
      return;
    }
    this.editConf.emit(this.conf.id);
  }

  onSave() {
    if (this.readonly || this.conf.fixed) {
      return;
    }
    if (this.form.dirty) {
      if (this.form.valid) {
        this.updateConf.emit(this.form.getRawValue());
      } else {
        if (this.form.get('company_id').invalid) {
          WSLMaterializeHelper.toast({html: 'Укажите компанию'});
        }
        if (this.form.get('group_id').invalid) {
          WSLMaterializeHelper.toast({html: 'Не задана группа конфигурации'});
        }
        if (this.form.get('name').invalid) {
          WSLMaterializeHelper.toast({html: 'Укажите название'});
        }
        if (this.form.get('config').invalid) {
          WSLMaterializeHelper.toast({html: 'Укажите конфиг'});
        }
      }
    } else {
      this.cancelEditConf.emit();
    }
  }

  onCancel() {
    this.cancelEditConf.emit();
  }

  onCheck($event) {
    this.check.emit({id: this.conf.id, checked: $event});
  }

  private fillForm() {
    this.form.reset();
    WSLFormHelper.fillForm(this.form, this.conf);
    if (this.onlyCustom) {
      this.form.get('company_id').setValidators([Validators.required]);
    } else {
      this.form.get('company_id').clearValidators();
    }
  }
}

import { Injectable } from '@angular/core';
import { DBDateTimeFormat, IWSLHttpResponse, WSLHttpHelper, WSLLocalStorageService } from 'wsl-core';
import { format, isSameHour } from 'date-fns';
import { StorageKey } from 'wsl-ek-core';
import { map } from 'rxjs/operators';
import { IWSLAlert } from '../models/alert';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable()
export class AlertService {

  constructor(private http: HttpClient, private localStorage: WSLLocalStorageService) {
  }

  get() {
    const dict = this.localStorage.get(StorageKey.alert);
    if (dict != null && isSameHour(new Date(), new Date(dict.time))) {
      // moment().isSame(moment(dict.time, DBDateTimeFormat), this.localStorage.cachePeriod())) {
      return of(dict);
    }
    return this.http.get<IWSLHttpResponse<IWSLAlert>>('alert', {
      params: WSLHttpHelper.getParams({})
    })
      .pipe(map(data => {
        this.localStorage.set(StorageKey.alert, {
          time: format(new Date(), DBDateTimeFormat),
          items: data.items
        });
        return data;
      }));
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { LoadCommunicator, LoadCommunicatorSuccess } from '@store/communicator/communicator.actions';
import * as fromCommunicator from '@store/communicator/communicator.reducer';
import { LoadDevice, SelectDevice } from '@store/device/device.actions';
import { LoadObject, SelectObject } from '@store/object/object.actions';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLCommunicator } from 'wsl-device';
import { CommunicatorPermissions, UserProfileService } from 'wsl-ek-core';
import { CommunicatorService } from '../services/communicator.service';

@Injectable()
export class CommunicatorExistsGuard implements CanActivate, CanActivateChild {
  constructor(private store$: Store<fromCommunicator.State>,
              private communicatorService: CommunicatorService,
              private userProfileService: UserProfileService,
              private router: Router) {
  }

  /**
   * This method creates an observable that waits for the `loaded` property
   * of the collection state to turn `true`, emitting one time once loading
   * has finished.
   */
  waitForCollectionToLoad(): Observable<boolean> {
    return this.store$
      .pipe(
        select(fromCommunicator.selectCommunicatorLoaded),
        filter(loaded => loaded),
        take(1),
        timeout(1000),
        catchError(() => of(false))
      );
  }

  /**
   * This method checks if a device with the given ID is already registered
   * in the Store
   */
  hasInStore(id: number): Observable<boolean> {
    return this.store$
      .select(fromCommunicator.selectCommunicatorEntities)
      .pipe(
        tap(entities => {
          if (entities[id]) {
            this.dispatchMeta(entities[id]);
            this.store$.dispatch(new LoadCommunicator(entities[id].id));
          }
        }),
        map(entities => !!entities[id]),
        take(1)
      );
  }

  /**
   * This method loads a device with the given ID from the API and caches
   * it in the store, returning `true` or `false` if it was found.
   */
  hasInApi(id: number): Observable<boolean> {
    return this.communicatorService
      .get(id)
      .pipe(
        tap(resp => this.store$.dispatch(new LoadCommunicatorSuccess(resp))),
        map((resp: IWSLHttpResponse<IWSLCommunicator>) => resp.item),
        tap(entity => {
          if (entity) {
            //  this.dispatchMeta(entity);
          }
        }),
        map(entity => !!entity),
        catchError((response) => {
          this.router.navigate(['/404']);
          return of(false);
        })
      );
  }

  /**
   * `hasEntity` composes `hasInStore` and `hasInApi`. It first checks
   * if the device is in store, and if not it then checks if it is in the
   * API.
   */
  hasEntity(id: number, collectionLoaded: boolean): Observable<boolean> {
    if (collectionLoaded) {
      return this.hasInStore(id)
        .pipe(
          switchMap(inStore => {
            if (inStore) {
              return of(inStore);
            }

            return this.hasInApi(id);
          }));
    }
    return this.hasInApi(id);
  }

  private dispatchMeta(communicator: IWSLCommunicator) {
    if (communicator.object_id) {
      this.store$.dispatch(new LoadObject(communicator.object_id));
      this.store$.dispatch(new SelectObject(communicator.object_id));
    }
    if (communicator.device_id) {
      this.store$.dispatch(new LoadDevice(communicator.device_id));
      this.store$.dispatch(new SelectDevice(communicator.device_id));
    }
  }

  check(communicatorID) {
    if (!this.userProfileService.hasPermission(CommunicatorPermissions.get)) {
      return of(false);
    }
    return this.waitForCollectionToLoad()
      .pipe(switchMap((loaded) => this.hasEntity(communicatorID, loaded)));
  }

  /**
   * This is the actual method the router will call when our guard is run.
   *
   * Our guard waits for the collection to load, then it checks if we need
   * to request a book from the API or if we already have it in our cache.
   * If it finds it in the cache or in the API, it returns an Observable
   * of `true` and the route is rendered successfully.
   *
   * If it was unable to find it in our cache or in the API, this guard
   * will return an Observable of `false`, causing the router to move
   * on to the next candidate route. In this case, it will move on
   * to the 404 page.
   */
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(+next.params['communicatorID'])
      .pipe(tap(test => {
        if (!test) {
          this.router.navigateByUrl('/404');
        }
      }));
  }

  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(+next.params['communicatorID'])
      .pipe(tap(test => {
        if (!test) {
          this.router.navigateByUrl('/404');
        }
      }));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWSLUnbalanceSetting } from '@core/models/unbalance-setting';
import { IWSLEntityFilter, WSLApiHttpClientService } from 'wsl-core';

@Injectable({
  providedIn: 'root'
})
export class UnbalanceSettingsService extends WSLApiHttpClientService<IWSLUnbalanceSetting, IWSLEntityFilter> {

  constructor(public http: HttpClient) {
    super(http, 'unbalance_settings');
  }
}

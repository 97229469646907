import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import * as fromObject from '@store/object/object.reducer';
import { catchError, filter, map, mergeMap, skip, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { StoreHelperService } from '@core/services/store-helper.service';
import { ObjectService } from '@app/object/services/object.service';
import { GenerateActivationCodeForObjectFail, GenerateActivationCodeForObjectSuccess, LoadObject, LoadObjectFail, LoadObjectsFail, LoadObjectsSuccess, LoadObjectSuccess, ObjectActionTypes, UpdateObjectFail, UpdateObjectSuccess } from './object.actions';
var ObjectEffects = /** @class */ (function () {
    function ObjectEffects(actions$, storeHelper, store$, objectService) {
        var _this = this;
        this.actions$ = actions$;
        this.storeHelper = storeHelper;
        this.store$ = store$;
        this.objectService = objectService;
        this.load$ = this.actions$.pipe(ofType(ObjectActionTypes.LoadObject), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(ObjectActionTypes.LoadObject), skip(1));
            return _this.objectService.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadObjectSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadObjectFail); }));
        }));
        this.update$ = this.actions$.pipe(ofType(ObjectActionTypes.UpdateObject), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(ObjectActionTypes.UpdateObject), skip(1));
            return _this.objectService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateObjectSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateObjectFail); }));
        }));
        this.updateSuccess$ = this.actions$.pipe(ofType(ObjectActionTypes.UpdateObjectSuccess), withLatestFrom(this.store$.pipe(select(fromObject.selectObjectSelectedID))), map(function (_a) {
            var action = _a[0], id = _a[1];
            return id;
        }), map(function (id) { return new LoadObject(id); }));
        this.loadMany$ = this.actions$.pipe(ofType(ObjectActionTypes.LoadObjects), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(ObjectActionTypes.LoadObjects), skip(1));
            return _this.objectService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadObjectsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadObjectsFail); }));
        }));
        this.generateActivationCode$ = this.actions$.pipe(ofType(ObjectActionTypes.GenerateActivationCodeForObject), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(ObjectActionTypes.GenerateActivationCodeForObject), skip(1));
            return _this.objectService.generateActivationCode(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, GenerateActivationCodeForObjectSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, GenerateActivationCodeForObjectFail); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(ObjectActionTypes.LoadObject, ObjectActionTypes.UpdateObject, ObjectActionTypes.GenerateActivationCodeForObject), map(function () { return new StartLoading('object'); }));
        this.startLoadMany$ = this.actions$
            .pipe(ofType(ObjectActionTypes.LoadObjects), map(function (action) { return action.payload; }), filter(function (payload) { return payload.offset === 0; }), map(function () { return new StartLoading('object'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(ObjectActionTypes.LoadObjectSuccess, ObjectActionTypes.LoadObjectFail, ObjectActionTypes.LoadObjectsSuccess, ObjectActionTypes.LoadObjectsFail, ObjectActionTypes.UpdateObjectSuccess, ObjectActionTypes.UpdateObjectFail, ObjectActionTypes.GenerateActivationCodeForObjectSuccess, ObjectActionTypes.GenerateActivationCodeForObjectFail), map(function () { return new StopLoading('object'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ObjectEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ObjectEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ObjectEffects.prototype, "updateSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ObjectEffects.prototype, "loadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ObjectEffects.prototype, "generateActivationCode$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ObjectEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ObjectEffects.prototype, "startLoadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ObjectEffects.prototype, "stopLoad$", void 0);
    return ObjectEffects;
}());
export { ObjectEffects };

<wsl-input-wrapper [label]="label" class="wsl-block wsl-relative" [required]="required" [asyncError]="asyncError">
<!--    <input type="text" class="form-control input-w-icon {{prefix}}_datepicker" [(ngModel)]="value" [disabled]="disabled">
    <div class="input-icon no-event" [class.wsl-hide]="hasValue"><a class="icon-calendar-full wsl-st5"></a></div>
    <div class="input-icon" [class.wsl-hide]="!hasValue || disabled"><a class="icon-cross wsl-st5 wsl-red wsl-hover" (click)="clear()"></a></div>-->
  <input class="form-control input-w-icon"
         matInput
         [matDatepicker]="picker"
         [disabled]="disabled"
         [max]="maxDate"
         [min]="minDate"
         placeholder="Выберите дату"
         [value]="date"
         (dateInput)="onDateInput($event)">
  <div class="wsl-abs wsl-abs-right wsl-abs-bottom">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  </div>
  <mat-datepicker #picker
                  [disabled]="disabled"
                  [startAt]="date"
                  (opened)="onOpenPicker($event)"
                  (closed)="onClosePicker($event)"></mat-datepicker>
  <!--<div class="input-icon" [class.wsl-hide]="!hasValue || disabled"><a class="icon-cross wsl-st5 wsl-red wsl-hover" (click)="clear()"></a></div>-->
</wsl-input-wrapper>

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wsl-b2b-main-page',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

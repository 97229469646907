import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { UserProfileActions, UserProfileActionTypes } from '../actions/user-profile.actions';

export interface State {
  loggedIn: boolean;
  token: string | null;
}

export const initialState: State = {
  loggedIn: false,
  token: null,
};

export function reducer(state = initialState, action: AuthActions | UserProfileActions): State {
  switch (action.type) {

    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        token: action.payload.token,
      };
    }

    case UserProfileActionTypes.LoadUserProfileSuccess: {
      return {...state, loggedIn: true};
    }

    case AuthActionTypes.LoginFail: {
      return initialState;
    }

    case AuthActionTypes.Logout: {
      return {...state, loggedIn: false};
    }


    default:
      return state;
  }
}

export const getLoggedId = (state: State) => state.loggedIn;
export const getToken = (state: State) => state.token;

<ng-container [formGroup]="form">
<wsl-input-wrapper class="wsl-block wsl-relative wsl-w-100"
                   [label]="label"
                   [required]="required"
                   [asyncError]="asyncError"
                   [readonly]="readonly"
                   [showClear]="showClear"
                   [value]="form.get('id').value"
                   (clear)="onClear()">

    <wsl-autocomplete type="text"
                      [focus]="inputFocus"
                      [showMeta]="showMeta && !!form.get('id').value"
                      formControlName="name"
                      [pH]="pH"
                      [required]="required"
                      (search)="onSearch($event)"
                      (enterKey)="onEnterKey($event)"
                      (blured)="onBlur()">
        <ng-container *ngIf="showSelect">
            <ng-container *ngIf="items && items.length > 0; else empty"  #container wslSpyScroll (scrolled)="onScroll($event)">
                <li *ngFor="let item of items; let id = index"
                    #row
                    (click)="onSelect(item)"
                    class="wsl-p-y-10 wsl-p-x-15 "
                    [ngClass]="{
                'wsl-bg-gray wsl-white': item.id === form.get('id').value,
                'wsl-bg-gray-lightest': id === selectedIndex && item.id !== form.get('id').value,
                'wsl-bg-white_hover wsl-hover': item.id !== form.get('id').value && id !== selectedIndex}">
                    <span *ngIf="!!item.label; else showName"  [innerHTML]="item.label"></span>
                    <ng-template #showName><span [innerHTML]="item.name"></span></ng-template>
                </li>
            </ng-container>
            <ng-template #empty>
                <li class="wsl-p-y-10 wsl-p-x-15">
                    <mat-progress-bar mode="buffer" *ngIf="pending"></mat-progress-bar>
                    <span [class.wsl-hide]="pending">Ничего не нашли по Вашему запросу</span>
                </li>
            </ng-template>
        </ng-container>
    </wsl-autocomplete>
</wsl-input-wrapper>
</ng-container>

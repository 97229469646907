import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { IWSLBillingCompanyLink } from '@app/billing-company/models/billing-company-link';
import { BillingCompanyLinkService } from '@app/billing-company/services/billing-company-link.service';
import { select, Store } from '@ngrx/store';
import { SetSnackBarMessage } from '@store/actions/app.actions';
import { LoadBCompanyLinks, LoadBCompanyLinksSuccess } from '@store/billing/billing-company-link.actions';
import * as fromBilling from '@store/billing/billing.reducer';
import * as fromRoot from '@store/reducers';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { IWSLHttpResponse, WSLHttpHelper } from 'wsl-core';

@Injectable({
  providedIn: 'root'
})
export class HasBCompanyLinkGuard implements CanActivate, CanActivateChild {
  constructor(private store$: Store<fromRoot.State>,
              private billingCompanyLinkService: BillingCompanyLinkService,
              @Optional() @Inject('waitTimeout') private waitTimeout: number = 1000) {
  }

  /**
   * This method creates an observable that waits for the `loaded` property
   * of the collection state to turn `true`, emitting one time once loading
   * has finished.
   */
  waitForCollectionToLoad(): Observable<boolean> {
    return this.store$
      .pipe(
        select(fromBilling.selectBillingCompanyLinksLoaded),
        filter(loaded => !!loaded),
        take(1),
        timeout(500),
        catchError(() => of(false))
      );
  }

  /**
   * This method checks if a links already
   * in the Store
   */
  hasInStore(): Observable<boolean> {
    return this.store$
      .select(fromBilling.selectBillingCompanyLinks)
      .pipe(
        tap(items => {
          if (items.length === 0) {
            this.store$.dispatch(new LoadBCompanyLinks({}));
          }
        }),
        map(items => items.length > 0),
        take(1)
      );
  }

  /**
   * This method loads a links from the API and caches
   * it in the store, returning `true` or `false` if it was found.
   */
  hasInApi(): Observable<boolean> {
    return this.billingCompanyLinkService
      .getMany({})
      .pipe(
        // map(deviceEntity => new LoadDeviceSuccess(deviceEntity)),
        //  tap((action: LoadDeviceSuccess) => this.store.dispatch(action)),
        tap(resp => this.store$.dispatch(new LoadBCompanyLinksSuccess(resp))),
        map((resp: IWSLHttpResponse<IWSLBillingCompanyLink>) => resp.items),
        map(items => items.length > 0),
        catchError((response) => {
          // this.router.navigate(['/404']);
          return of(false);
        })
      );
  }

  /**
   * `hasEntities` composes `hasInStore` and `hasInApi`. It first checks
   * if the links is in store, and if not it then checks if it is in the
   * API.
   */
  hasEntities(collectionLoaded: boolean): Observable<boolean> {
    if (collectionLoaded) {
      return this.hasInStore()
        .pipe(
          switchMap(inStore => {
            if (inStore) {
              return of(inStore);
            }
            return this.hasInApi();
          }));
    }
    return this.hasInApi();
  }

  public check() {
    return this.waitForCollectionToLoad()
      .pipe(switchMap((loaded) => this.hasEntities(loaded)));
  }

  /**
   * This is the actual method the router will call when our guard is run.
   *
   * Our guard waits for the collection to load, then it checks if we need
   * to request a book from the API or if we already have it in our cache.
   * If it finds it in the cache or in the API, it returns an Observable
   * of `true` and the route is rendered successfully.
   *
   * If it was unable to find it in our cache or in the API, this guard
   * will return an Observable of `false`, causing the router to move
   * on to the next candidate route. In this case, it will move on
   * to the 404 page.
   */
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check()
      .pipe(
        tap(res => {
          if (!res) {
            this.store$.dispatch(new SetSnackBarMessage(WSLHttpHelper.parseAsyncMessage('Нет договоров с биллинговыми компаниями')));
         //   this.router.navigate([next.data.redirectUrl ? next.data.redirectUrl : '/']);
            return of(false);
          }
        })
      );
  }

  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check()
      .pipe(
        tap(res => {
          if (!res) {
            this.store$.dispatch(new SetSnackBarMessage(WSLHttpHelper.parseAsyncMessage('Нет договоров с биллинговыми компаниями')));
           // this.router.navigate([next.data.redirectUrl ? next.data.redirectUrl : '/']);
            return of(false);
          }
        })
      );
  }
}

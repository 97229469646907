<wsl-input-wrapper [label]="label" class="wsl-select wsl-relative" [class.wsl-select-low]="low" [inputId]="inputID" [required]="required" [asyncError]="asyncError">
    <select *ngIf="!multiple" class="{{prefix}}_select"
            #input
            [(ngModel)]="value"
            [required]="required"
            [disabled]="disabled"
            [attr.id]="inputID">
        <option value="null" disabled selected>{{text}}</option>
        <option *ngFor="let option of items; trackBy: trackById"
                [ngValue]="option.id"
                [disabled]="option.disabled === true || option.enable === false"
                [innerHTML]="option.name"></option>
    </select>
    <select *ngIf="multiple" class="{{prefix}}_multiple_select" multiple
            #input_m
            [(ngModel)]="value"
            [required]="required"
            [attr.id]="inputID">
        <option value="null" disabled selected>{{text}}</option>
        <option *ngFor="let option of items; trackBy: trackById"
                [ngValue]="option.id"
                [disabled]="disabled || option.disabled === true || option.enable === false"
                [innerHTML]="option.name"></option>
    </select>
    <!--<div class="wsl-flex-center-middle-ng wsl-p-x-10 wsl-bg-red_hover" (click)="onClear()" [class.wsl-hide]="disabled || !value || !showClear">
        <span class="icon-cross wsl-white wsl-st5"></span>
    </div>-->

    <div class="input-icon wsl-m-r-15" (click)="onClear()" [class.wsl-hide]="disabled || (multiple ? (!value || (!!value && value.length === 0)) : !value) || !showClear" style="z-index: 1;">
        <a class="icon-cross wsl-st5 wsl-red wsl-hover"></a>
    </div>
</wsl-input-wrapper>

import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AddDeviceSensorFail, AddDeviceSensorSuccess, DeleteDeviceSensorFail, DeleteDeviceSensorsFail, DeleteDeviceSensorsSuccess, DeleteDeviceSensorSuccess, DeviceSensorActionTypes, LoadDeviceSensorFail, LoadDeviceSensorsFail, LoadDeviceSensorsSuccess, LoadDeviceSensorSuccess, UpdateDeviceSensorFail, UpdateDeviceSensorSuccess, UpsertDeviceSensorsFail, UpsertDeviceSensorsSuccess } from './device-sensor.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { DeviceSensorService } from '@app/device-sensor/services/device-sensor.service';
import { forkJoin, of } from "rxjs";
var DeviceSensorEffects = /** @class */ (function () {
    function DeviceSensorEffects(actions$, router, route, storeHelper, deviceConfService) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.route = route;
        this.storeHelper = storeHelper;
        this.deviceConfService = deviceConfService;
        this.add$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.AddDeviceSensor), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceSensorActionTypes.AddDeviceSensor), skip(1));
            return _this.deviceConfService.add(payload)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddDeviceSensorSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddDeviceSensorFail); }));
        }));
        this.update$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.UpdateDeviceSensor), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceSensorActionTypes.UpdateDeviceSensor), skip(1));
            return _this.deviceConfService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateDeviceSensorSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateDeviceSensorFail); }));
        }));
        this.load$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.LoadDeviceSensor), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceSensorActionTypes.LoadDeviceSensor), skip(1));
            return _this.deviceConfService.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceSensorSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceSensorFail); }));
        }));
        this.loadMany$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.LoadDeviceSensors), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceSensorActionTypes.LoadDeviceSensors), skip(1));
            return _this.deviceConfService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadDeviceSensorsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadDeviceSensorsFail); }));
        }));
        this.upsertMany$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.UpsertDeviceSensors), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceSensorActionTypes.UpsertDeviceSensors), skip(1));
            return _this.deviceConfService.upsertMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpsertDeviceSensorsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpsertDeviceSensorsFail); }));
        }));
        this.delete$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.DeleteDeviceSensor), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceSensorActionTypes.DeleteDeviceSensor), skip(1));
            return _this.deviceConfService.delete(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteDeviceSensorSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteDeviceSensorFail); }));
        }));
        this.deleteMany$ = this.actions$.pipe(ofType(DeviceSensorActionTypes.DeleteDeviceSensors), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(DeviceSensorActionTypes.DeleteDeviceSensors), skip(1));
            return of(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (ids) { return forkJoin.apply(void 0, ids.map(function (id) { return _this.deviceConfService.delete(id); })); }), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteDeviceSensorsSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteDeviceSensorsFail); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(DeviceSensorActionTypes.AddDeviceSensor, DeviceSensorActionTypes.LoadDeviceSensor, DeviceSensorActionTypes.UpdateDeviceSensor, DeviceSensorActionTypes.DeleteDeviceSensor, DeviceSensorActionTypes.UpsertDeviceSensors), map(function () { return new StartLoading('device_sensor'); }));
        this.startLoadMany$ = this.actions$
            .pipe(ofType(DeviceSensorActionTypes.LoadDeviceSensors), map(function (action) { return action.payload; }), filter(function (payload) { return payload.offset === 0; }), map(function () { return new StartLoading('device_sensor'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(DeviceSensorActionTypes.AddDeviceSensorSuccess, DeviceSensorActionTypes.AddDeviceSensorFail, DeviceSensorActionTypes.LoadDeviceSensorSuccess, DeviceSensorActionTypes.LoadDeviceSensorFail, DeviceSensorActionTypes.UpdateDeviceSensorSuccess, DeviceSensorActionTypes.UpdateDeviceSensorFail, DeviceSensorActionTypes.DeleteDeviceSensorSuccess, DeviceSensorActionTypes.DeleteDeviceSensorFail, DeviceSensorActionTypes.LoadDeviceSensorsSuccess, DeviceSensorActionTypes.LoadDeviceSensorsFail, DeviceSensorActionTypes.UpsertDeviceSensorsSuccess, DeviceSensorActionTypes.UpsertDeviceSensorsFail), map(function () { return new StopLoading('device_sensor'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "loadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "upsertMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "delete$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "deleteMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "startLoadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DeviceSensorEffects.prototype, "stopLoad$", void 0);
    return DeviceSensorEffects;
}());
export { DeviceSensorEffects };

import { Action } from '@ngrx/store';
import { IWSLHttpResponse } from 'wsl-core';
import { IWSLRoom } from '@app/room/models/room';
import { IWSLRoomFilter } from '@app/room/models/room-filter';

export enum RoomActionTypes {
  SelectRoom = '[Room] Select',

  AddRoom = '[Room] Add',
  AddRoomSuccess = '[Room] Add Success',
  AddRoomFail = '[Room] Add Fail',

  AddRooms = '[Room] Add Many',
  AddRoomsSuccess = '[Room] Add Many Success',
  AddRoomsFail = '[Room] Add Many Fail',

  DeleteRoom = '[Room] Delete',
  DeleteRoomSuccess = '[Room] Delete Success',
  DeleteRoomFail = '[Room] Delete Fail',

  DeleteRooms = '[Room] Delete Many',
  DeleteRoomsSuccess = '[Room] Delete Many Success',
  DeleteRoomsFail = '[Room] Delete Many Fail',

  LoadRoom = '[Room] Load',
  LoadRoomSuccess = '[Room] Load Success',
  LoadRoomFail = '[Room] Load Fail',

  UpdateRoom = '[Room] Update',
  UpdateRoomSuccess = '[Room] Update Success',
  UpdateRoomFail = '[Room] Update Fail',

  LoadRooms = '[Room] Load Many',
  LoadRoomsSuccess = '[Room] Load Many Success',
  LoadRoomsFail = '[Room] Load Many Fail',

  ClearRooms = '[Room] Clear',

  GenerateActivationCodeForRoom = '[Room] Generate Activation Code',
  GenerateActivationCodeForRoomSuccess = '[Object] Generate Activation Code Success',
  GenerateActivationCodeForRoomFail = '[Object] Generate Activation Code Fail'
}

/** Select */
export class SelectRoom implements Action {
  readonly type = RoomActionTypes.SelectRoom;

  constructor(public payload: number) {
  }
}

/** Add One */
export class AddRoom implements Action {
  readonly type = RoomActionTypes.AddRoom;

  constructor(public payload: IWSLRoom) {
  }
}

export class AddRoomSuccess implements Action {
  readonly type = RoomActionTypes.AddRoomSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

export class AddRoomFail implements Action {
  readonly type = RoomActionTypes.AddRoomFail;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}


/** Add Many */
export class AddRooms implements Action {
  readonly type = RoomActionTypes.AddRooms;

  constructor(public payload: IWSLRoom[]) {
  }
}

export class AddRoomsSuccess implements Action {
  readonly type = RoomActionTypes.AddRoomsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

export class AddRoomsFail implements Action {
  readonly type = RoomActionTypes.AddRoomsFail;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}


/** Delete One */
export class DeleteRoom implements Action {
  readonly type = RoomActionTypes.DeleteRoom;

  constructor(public payload: number) {
  }
}

export class DeleteRoomSuccess implements Action {
  readonly type = RoomActionTypes.DeleteRoomSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

export class DeleteRoomFail implements Action {
  readonly type = RoomActionTypes.DeleteRoomFail;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}


/** Delete Many */
export class DeleteRooms implements Action {
  readonly type = RoomActionTypes.DeleteRooms;

  constructor(public payload: number[]) {
  }
}

export class DeleteRoomsSuccess implements Action {
  readonly type = RoomActionTypes.DeleteRoomsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

export class DeleteRoomsFail implements Action {
  readonly type = RoomActionTypes.DeleteRoomsFail;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

/** Load One */
export class LoadRoom implements Action {
  readonly type = RoomActionTypes.LoadRoom;

  constructor(public payload: number) {
  }
}

export class LoadRoomSuccess implements Action {
  readonly type = RoomActionTypes.LoadRoomSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

export class LoadRoomFail implements Action {
  readonly type = RoomActionTypes.LoadRoomFail;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

/** Load Many */
export class LoadRooms implements Action {
  readonly type = RoomActionTypes.LoadRooms;

  constructor(public payload: IWSLRoomFilter) {
  }
}

export class LoadRoomsSuccess implements Action {
  readonly type = RoomActionTypes.LoadRoomsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

export class LoadRoomsFail implements Action {
  readonly type = RoomActionTypes.LoadRoomsFail;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

/** Update One */
export class UpdateRoom implements Action {
  readonly type = RoomActionTypes.UpdateRoom;

  constructor(public payload: IWSLRoom) {
  }
}

export class UpdateRoomSuccess implements Action {
  readonly type = RoomActionTypes.UpdateRoomSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

export class UpdateRoomFail implements Action {
  readonly type = RoomActionTypes.UpdateRoomFail;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

export class ClearRooms implements Action {
  readonly type = RoomActionTypes.ClearRooms;
}

/** Generate Activation Code */
export class GenerateActivationCodeForRoom implements Action {
  readonly type = RoomActionTypes.GenerateActivationCodeForRoom;

  constructor(public payload: number) {
  }
}

export class GenerateActivationCodeForRoomSuccess implements Action {
  readonly type = RoomActionTypes.GenerateActivationCodeForRoomSuccess;
}

export class GenerateActivationCodeForRoomFail implements Action {
  readonly type = RoomActionTypes.GenerateActivationCodeForRoomFail;
}


export type RoomActions =
  SelectRoom |

  AddRoom |
  AddRoomSuccess |
  AddRoomFail |

  AddRooms |
  AddRoomsSuccess |
  AddRoomsFail |

  DeleteRoom |
  DeleteRoomSuccess |
  DeleteRoomFail |

  DeleteRooms |
  DeleteRoomsSuccess |
  DeleteRoomsFail |

  LoadRoom |
  LoadRoomSuccess |
  LoadRoomFail |

  LoadRooms |
  LoadRoomsSuccess |
  LoadRoomsFail |

  UpdateRoom |
  UpdateRoomSuccess |
  UpdateRoomFail |

  ClearRooms |

  GenerateActivationCodeForRoom |
  GenerateActivationCodeForRoomSuccess |
  GenerateActivationCodeForRoomFail;

import { DeviceActions, DeviceActionTypes } from './device.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IWSLDevice } from 'wsl-device';
import { IWSLAsyncErrors } from 'wsl-core';
import * as fromRoot from '@store/reducers/index';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface DeviceState extends EntityState<IWSLDevice> {
  selectedID: number;
  errors: IWSLAsyncErrors;
  loaded: boolean;
  pending: boolean;
  totalCount: number;
}

export const adapter: EntityAdapter<IWSLDevice> = createEntityAdapter<IWSLDevice>({
  selectId: (item: IWSLDevice) => item.id,
  sortComparer: false
});

export const initialState: DeviceState = adapter.getInitialState({
  selectedID: null,
  errors: null,
  loaded: false,
  pending: false,
  totalCount: 0
});

export function reducer(state = initialState, action: DeviceActions): DeviceState {
  switch (action.type) {

    case DeviceActionTypes.AddDevice:
    case DeviceActionTypes.UpdateDeviceGroupConf:
    case DeviceActionTypes.UpdateDeviceCommunicator:
    case DeviceActionTypes.RenameDevice:
    case DeviceActionTypes.UpdateTimezoneDevice:
    case DeviceActionTypes.HideDevice:
    case DeviceActionTypes.DeleteDevice:
      return {...state, pending: true, errors: null};

    case DeviceActionTypes.AddDeviceFail:
    case DeviceActionTypes.UpdateDeviceGroupConfFail:
    case DeviceActionTypes.UpdateDeviceCommunicatorFail:
    case DeviceActionTypes.RenameDeviceFail:
    case DeviceActionTypes.UpdateTimezoneDeviceFail:
    case DeviceActionTypes.HideDeviceFail:
    case DeviceActionTypes.DeleteDeviceFail:
      return {...state, pending: false, errors: action.payload.asyncMessages};

    case DeviceActionTypes.AddDeviceSuccess:
    case DeviceActionTypes.UpdateDeviceGroupConfSuccess:
    case DeviceActionTypes.UpdateDeviceCommunicatorSuccess:
    case DeviceActionTypes.RenameDeviceSuccess:
    case DeviceActionTypes.UpdateTimezoneDeviceSuccess:
    case DeviceActionTypes.HideDeviceSuccess:
    case DeviceActionTypes.DeleteDeviceSuccess:
      return {...state, pending: false};

    case DeviceActionTypes.LoadDevice:
      return {
        ...state,
       // selectedID: null,
        pending: true,
        loaded: false,
        errors: null
      };

    case DeviceActionTypes.LoadDeviceSuccess:
      return adapter.upsertOne({
        ...action.payload.item
      }, {
        ...state,
        pending: false,
        loaded: true
      });

    case DeviceActionTypes.LoadDeviceFail:
      return {...state, pending: false, loaded: true, errors: action.payload.asyncMessages};

    case DeviceActionTypes.SelectDevice:
      return {...state, selectedID: action.payload};

    case DeviceActionTypes.LoadDevices:
      if (!action.payload.offset) {
        return adapter.removeAll({
          ...state,
          totalCount: 0,
          pending: true,
          loaded: false,
          errors: null
        });
      }
      return {...state, pending: true, loaded: false,  errors: null};

    case DeviceActionTypes.LoadDevicesSuccess:
      return adapter.upsertMany(action.payload.items,
        {
          ...state,
          totalCount: action.payload.count,
          pending: false,
          loaded: true
        }
      );

    case DeviceActionTypes.LoadDevicesFail:
      return {...state, pending: false, loaded: true,  errors: action.payload.asyncMessages};

    case DeviceActionTypes.ClearDevices:
      return adapter.removeAll({
        ...state,
        totalCount: 0,
        pending: false,
        loaded: true
      });


    default:
      return state;
  }
}

export const getSelectedID = (state: DeviceState) => state.selectedID;
export const getTotalCount = (state: DeviceState) => state.totalCount;
export const getLoaded = (state: DeviceState) => state.loaded;
export const getPending = (state: DeviceState) => state.pending;
export const getErrors = (state: DeviceState) => state.errors;

export interface State extends fromRoot.State {
  'device': DeviceState;
}

export const getDeviceState = createFeatureSelector<DeviceState>('device');

export const selectDeviceSelectedID = createSelector(getDeviceState, getSelectedID);
export const selectDeviceTotalCount = createSelector(getDeviceState, getTotalCount);
export const selectDeviceLoaded = createSelector(getDeviceState, getLoaded);
export const selectDevicePending = createSelector(getDeviceState, getPending);
export const selectDeviceErrors = createSelector(getDeviceState, getErrors);

export const {
  selectEntities: selectDeviceEntities,
  selectAll: selectDeviceAll
} = adapter.getSelectors(getDeviceState);

export const selectUnhiddenDevices = createSelector(
  selectDeviceAll,
  (devices) => devices.filter(d => !d.is_hidden)
);

export const selectDevice = createSelector(
  selectDeviceEntities,
  selectDeviceSelectedID,
  (entities, id) => entities && entities[id] ? entities[id] : null
);

import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WSLApiHttpClientService } from 'wsl-core';
var DeviceDocumentService = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceDocumentService, _super);
    function DeviceDocumentService(http) {
        var _this = _super.call(this, http, 'files') || this;
        _this.http = http;
        return _this;
    }
    return DeviceDocumentService;
}(WSLApiHttpClientService));
export { DeviceDocumentService };

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { SYSTEM_SUB_TITLE, SYSTEM_TITLE } from '../../../../config/app.config';
import { Observable } from 'rxjs';
import { IWSLAsyncErrors } from 'wsl-core';
import { IWSLAuthenticate } from 'wsl-auth';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@store/reducers/index';
import { Login } from '@store/actions/auth.actions';

@Component({
  selector: 'wsl-login',
  templateUrl: './login.page.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPage implements OnInit, OnDestroy {
  /** @internal */
  readonly systemTitle = SYSTEM_TITLE;
  /** @internal */
  readonly systemSubTitle = SYSTEM_SUB_TITLE;
  /** @internal */
  pending$: Observable<boolean>;
  /** @internal */
  errors$: Observable<IWSLAsyncErrors>;

  isDom = environment.dom;
  showRegistration = true; // environment.home || environment.kv;

  constructor(private store$: Store<fromRoot.State>) {
    this.pending$ = store$.pipe(select(fromRoot.selectPending));
    this.errors$ = store$.pipe(select(fromRoot.selectSnackBarMessage));

    // store.dispatch(new page.Listen());
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // this.store.dispatch(new page.UnListen());
  }

  onSubmit(data: IWSLAuthenticate) {
    this.store$.dispatch(new Login({email: data.login, password: data.password}));
  }

}

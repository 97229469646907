import * as tslib_1 from "tslib";
var _a;
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceConfGroupActionTypes } from './device-conf-group.actions';
import { createEntityAdapter } from '@ngrx/entity';
export var adapter = createEntityAdapter({
    selectId: function (item) { return item.id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    errors: null,
    loaded: false,
    pending: false,
    totalCount: 0
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DeviceConfGroupActionTypes.AddDeviceConfGroup:
        case DeviceConfGroupActionTypes.UpdateDeviceConfGroup:
        case DeviceConfGroupActionTypes.DeleteDeviceConfGroup:
            return tslib_1.__assign({}, state, { pending: true, errors: null });
        case DeviceConfGroupActionTypes.AddDeviceConfGroupSuccess:
        case DeviceConfGroupActionTypes.UpdateDeviceConfGroupSuccess:
        case DeviceConfGroupActionTypes.DeleteDeviceConfGroupSuccess:
            return tslib_1.__assign({}, state, { pending: false });
        case DeviceConfGroupActionTypes.DeleteDeviceConfGroupFail:
        case DeviceConfGroupActionTypes.UpdateDeviceConfGroupFail:
        case DeviceConfGroupActionTypes.AddDeviceConfGroupFail:
            return tslib_1.__assign({}, state, { pending: false, errors: action.payload.asyncMessages });
        case DeviceConfGroupActionTypes.LoadDeviceConfGroup:
            return tslib_1.__assign({}, state, { 
                // selectedID: null,
                pending: true, loaded: false, errors: null });
        case DeviceConfGroupActionTypes.LoadDeviceConfGroupSuccess:
            return adapter.upsertOne(tslib_1.__assign({}, action.payload.item), tslib_1.__assign({}, state, { pending: false, loaded: true }));
        case DeviceConfGroupActionTypes.LoadDeviceConfGroupFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceConfGroupActionTypes.SelectDeviceConfGroup:
            return tslib_1.__assign({}, state, { selectedID: action.payload });
        case DeviceConfGroupActionTypes.LoadDeviceConfGroups:
            if (!action.payload.offset) {
                return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: true, loaded: false, errors: null }));
            }
            return tslib_1.__assign({}, state, { pending: true, loaded: false, errors: null });
        case DeviceConfGroupActionTypes.LoadDeviceConfGroupsSuccess:
            return adapter.upsertMany(action.payload.items, tslib_1.__assign({}, state, { totalCount: action.payload.count, pending: false, loaded: true }));
        case DeviceConfGroupActionTypes.LoadDeviceConfGroupsFail:
            return tslib_1.__assign({}, state, { pending: false, loaded: true, errors: action.payload.asyncMessages });
        case DeviceConfGroupActionTypes.ClearDeviceConfGroups:
            return adapter.removeAll(tslib_1.__assign({}, state, { totalCount: 0, pending: false, loaded: true }));
        default:
            return state;
    }
}
export var getSelectedID = function (state) { return state.selectedID; };
export var getTotalCount = function (state) { return state.totalCount; };
export var getLoaded = function (state) { return state.loaded; };
export var getPending = function (state) { return state.pending; };
export var getErrors = function (state) { return state.errors; };
export var getDeviceConfGroupState = createFeatureSelector('deviceConfGroup');
export var selectDeviceConfGroupSelectedID = createSelector(getDeviceConfGroupState, getSelectedID);
export var selectDeviceConfGroupTotalCount = createSelector(getDeviceConfGroupState, getTotalCount);
export var selectDeviceConfGroupLoaded = createSelector(getDeviceConfGroupState, getLoaded);
export var selectDeviceConfGroupPending = createSelector(getDeviceConfGroupState, getPending);
export var selectDeviceConfGroupErrors = createSelector(getDeviceConfGroupState, getErrors);
export var selectDeviceConfGroupEntities = (_a = adapter.getSelectors(getDeviceConfGroupState), _a.selectEntities), selectDeviceConfGroupAll = _a.selectAll;
export var selectDeviceConfGroup = createSelector(selectDeviceConfGroupEntities, selectDeviceConfGroupSelectedID, function (entities, id) { return entities && entities[id] ? entities[id] : null; });

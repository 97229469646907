import {Action} from '@ngrx/store';
import {IWSLHttpResponse} from 'wsl-core';
import {IWSLUser} from 'wsl-user';
import {IWSLCompany} from 'wsl-ek-core';
import {IWSLRoom} from "@app/room/models/room";

export enum UserProfileActionTypes {
  LoadUserProfile = '[UserProfile] Load User Profile',
  LoadUserProfileSuccess = '[UserProfile] Load User Profile Success',
  LoadUserProfileFail = '[UserProfile] Load User Profile Fail',
  LoadAvailableCompanies = '[UserProfile] Load Available Companies',
  LoadAvailableCompaniesSuccess = '[UserProfile] Load Available Companies Success',
  LoadAvailableCompaniesFail = '[UserProfile] Load Available Companies Fail',
  LoadAvailableRooms = '[UserProfile] Load Available Rooms',
  LoadAvailableRoomsSuccess = '[UserProfile] Load Available Rooms Success',
  LoadAvailableRoomsFail = '[UserProfile] Load Available Rooms Fail',
  SetSelectedRoomId = '[UserProfile] Set Selected Room Id'
}

export class LoadUserProfile implements Action {
  readonly type = UserProfileActionTypes.LoadUserProfile;
}

export class LoadUserProfileSuccess implements Action {
  readonly type = UserProfileActionTypes.LoadUserProfileSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLUser>) {}
}

export class LoadUserProfileFail implements Action {
  readonly type = UserProfileActionTypes.LoadUserProfileFail;

  constructor(public payload: IWSLHttpResponse<any>) {}
}

export class LoadAvailableCompanies implements Action {
  readonly type = UserProfileActionTypes.LoadAvailableCompanies;
}

export class LoadAvailableCompaniesSuccess implements Action {
  readonly type = UserProfileActionTypes.LoadAvailableCompaniesSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLCompany>) {}
}

export class LoadAvailableCompaniesFail implements Action {
  readonly type = UserProfileActionTypes.LoadAvailableCompaniesFail;

  constructor(public payload: IWSLHttpResponse<any>) {}
}

export class LoadAvailableRooms implements Action {
  readonly type = UserProfileActionTypes.LoadAvailableRooms;
}

export class LoadAvailableRoomsSuccess implements Action {
  readonly type = UserProfileActionTypes.LoadAvailableRoomsSuccess;

  constructor(public payload: IWSLHttpResponse<IWSLRoom>) {
  }
}

export class LoadAvailableRoomsFail implements Action {
  readonly type = UserProfileActionTypes.LoadAvailableRoomsFail;

  constructor(public payload: IWSLHttpResponse<any>) {
  }
}

export class SetSelectedRoomId implements Action {
  readonly type = UserProfileActionTypes.SetSelectedRoomId;

  constructor(public payload: number) {
  }
}

export type UserProfileActions =
    LoadUserProfile |
    LoadUserProfileSuccess |
    LoadUserProfileFail |

    LoadAvailableCompanies |
    LoadAvailableCompaniesSuccess |
    LoadAvailableCompaniesFail |

    LoadAvailableRooms |
    LoadAvailableRoomsSuccess |
    LoadAvailableRoomsFail |

    SetSelectedRoomId;

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { WSLMaterializeHelper } from 'wsl-shared';

@Component({
  selector: 'wsl-ask-modal',
  templateUrl: './ask-modal.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AskModalComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() id = 'ask_modal';
  @Input() title = 'Вы уверены?';
  @Input() description = '';
  @Input() show = false;
  @Input() noBtn = 'Нет';
  @Input() yesBtn = 'Да';
  @Output() yes = new EventEmitter();
  @Output() no = new EventEmitter();

  private MModal;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.MModal = WSLMaterializeHelper.initModal('#' + this.id, {dismissible: true});
  }

  ngOnChanges(changes) {
    if (this.MModal && changes.show) {
      if (this.show) {
        this.MModal.open();
      } else {
        this.MModal.close();
      }
    }
  }

  ngOnDestroy() {
    if (this.MModal) {
      this.MModal.close();
      this.MModal.destroy();
    }
  }

}

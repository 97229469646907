<!--Right static column. Actions.-->
<div class="wsl-flex-column-middle-spaceBetween wsl-hide-xs wsl-hide-sm wsl-action-buttons">
    <div class="wsl-flex-column">
        <ng-container *ngFor="let actionBtn of visibleButtons; trackBy: trackById">
            <a *ngIf="!actionBtn.buttons; else fabButton" class="wsl-btn-round waves-light wsl-m-b-10 z-depth-1"
               wslDebounceClick (debounceClick)="btnClick.emit($event)"
               [debounceTime]="actionBtn.debounceTime || debounceTime" [debounceId]="actionBtn.id"
               [ngClass]="actionBtn.classes"
               [@actionButtonsState]="actionBtn.visible.toString()"
               [matTooltip]="actionBtn.label" matTooltipPosition="left">
                <span [ngClass]="actionBtn.icon"></span>
            </a>
            <ng-template #fabButton>
                <wsl-fab-button [icon]="actionBtn.icon"
                                [fabActionClasses]="actionBtn.classes"
                                [direction]="actionBtn?.direction || 'left'"
                                [isClickToggle]="actionBtn?.isClickToggle"
                                [toToolbar]="actionBtn?.toToolbar"
                                [buttons]="actionBtn.buttons"
                                (btnClick)="btnClick.emit($event)"></wsl-fab-button>
            </ng-template>
        </ng-container>
    </div>

    <div></div>
</div>

<!--<div class="wsl-show-xs fixed-action-btn" style="bottom: 15px; right: 15px;">
    <div [class.wsl-hide]="!oneButton">
        <ng-template ngFor [ngForOf]="buttons" let-actionBtn [ngForTrackBy]="trackById">
            <a *ngIf="!actionBtn.buttons; else fabButton" class="btn-floating"
               wslDebounceClick (debounceClick)="btnClick.emit($event)" [debounceTime]="actionBtn.debounceTime || debounceTime" [debounceId]="actionBtn.id"
               [ngClass]="actionBtn.classes"
               [@actionButtonsState]="actionBtn.visible.toString()"
               [matTooltip]="actionBtn.label">
                <span [ngClass]="actionBtn.icon"></span>
            </a>
            <ng-template #fabButton>
                <wsl-fab-button [icon]="actionBtn.icon"
                                [fabActionClasses]="actionBtn.classes"
                                [buttons]="actionBtn.buttons"
                                (btnClick)="btnClick.emit($event)"></wsl-fab-button>
            </ng-template>
        </ng-template>
    </div>
</div>-->


<wsl-fab-button class="wsl-show-sm wsl-show-xs" *ngIf="visibleButtons.length > 1"
                [wrapperStyles]="{'bottom': '15px', 'right': '15px'}"
                [ulStyles]="{'right': '0px', 'left': 'auto', 'bottom': '40px'}"
                [icon]="'icon-pencil'"
                [direction]="'top'"
                [isClickToggle]="true"
                [toToolbar]="false"
                [buttons]="visibleButtons"
                (btnClick)="btnClick.emit($event)"></wsl-fab-button>

<wsl-fab-button class="wsl-show-sm wsl-show-xs" *ngIf="!!fabButton"
                [wrapperStyles]="{'bottom': '15px', 'right': '15px'}"
                [ulStyles]="{'right': '0px', 'left': 'auto', 'bottom': '40px'}"
                [icon]="fabButton?.icon"
                [fabActionClasses]="fabButton?.classes"
                [direction]="'top'"
                [isClickToggle]="fabButton?.isClickToggle"
                [toToolbar]="fabButton?.toToolbar"
                [buttons]="fabButton?.buttons"
                (btnClick)="btnClick.emit($event)"></wsl-fab-button>


<div class="wsl-show-sm wsl-show-xs fixed-action-btn" style="bottom: 15px; right: 15px;" *ngIf="visibleButtons.length === 1 && !fabButton">
    <a class="btn-floating"
       wslDebounceClick (debounceClick)="btnClick.emit($event)" [debounceTime]="visibleButtons[0].debounceTime || debounceTime"
       [debounceId]="visibleButtons[0].id"
       [@actionButtonsState]="visibleButtons[0].visible.toString()"
       [matTooltip]="visibleButtons[0].label">
        <span [ngClass]="visibleButtons[0].icon"></span>
    </a>
    <!-- <ng-template #showFabButton>
         <wsl-fab-button [icon]="fabButton?.icon"
                         [fabActionClasses]="fabButton?.classes"
                         [direction]="fabButton?.direction || 'bottom'"
                         [buttons]="fabButton?.buttons"
                         (btnClick)="btnClick.emit($event)"></wsl-fab-button>
     </ng-template>-->
</div>


<!--<div class="wsl-show-xs" [class.wsl-hide]="oneButton">

</div>-->

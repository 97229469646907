import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { StoreHelperService } from '@core/services/store-helper.service';
import { AddUsersFail, AddUsersSuccess, DeleteUserFail, DeleteUserSuccess, LoadUserFail, LoadUsersFail, LoadUsersSuccess, LoadUserSuccess, UpdateUserFail, UpdateUserSuccess, UserActionTypes } from './user.actions';
import { UserProfileActionTypes } from '@store/actions/user-profile.actions';
import { WSLUserService } from 'wsl-user';
var UserEffects = /** @class */ (function () {
    function UserEffects(actions$, storeHelper, userService) {
        var _this = this;
        this.actions$ = actions$;
        this.storeHelper = storeHelper;
        this.userService = userService;
        this.add$ = this.actions$.pipe(ofType(UserActionTypes.AddUsers), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            var nextLoad$ = _this.actions$.pipe(ofType(UserActionTypes.AddUsers), skip(1));
            return _this.userService.add(payload)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, AddUsersSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, AddUsersFail); }));
        }));
        this.update$ = this.actions$.pipe(ofType(UserActionTypes.UpdateUser), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(UserActionTypes.UpdateUser), skip(1));
            return _this.userService.update(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, UpdateUserSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, UpdateUserFail); }));
        }));
        this.load$ = this.actions$.pipe(ofType(UserActionTypes.LoadUser), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(UserActionTypes.LoadUser), skip(1));
            return _this.userService.get(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadUserSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadUserFail); }));
        }));
        this.loadUserProfileSuccess$ = this.actions$.pipe(ofType(UserProfileActionTypes.LoadUserProfileSuccess), map(function (action) { return action.payload; }), map(function (payload) { return new LoadUserSuccess(payload); }));
        this.loadMany$ = this.actions$.pipe(ofType(UserActionTypes.LoadUsers), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(UserActionTypes.LoadUsers), skip(1));
            return _this.userService.getMany(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, LoadUsersSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, LoadUsersFail); }));
        }));
        this.delete$ = this.actions$.pipe(ofType(UserActionTypes.DeleteUser), map(function (action) { return action.payload; }), switchMap(function (data) {
            var nextLoad$ = _this.actions$.pipe(ofType(UserActionTypes.DeleteUser), skip(1));
            return _this.userService.delete(data)
                .pipe(takeUntil(nextLoad$), mergeMap(function (resp) { return _this.storeHelper.successHandler(resp, DeleteUserSuccess); }), catchError(function (resp) { return _this.storeHelper.errorHandler(resp, DeleteUserFail); }));
        }));
        this.startLoad$ = this.actions$
            .pipe(ofType(UserActionTypes.AddUsers, UserActionTypes.LoadUser, UserActionTypes.UpdateUser, UserActionTypes.DeleteUser), map(function () { return new StartLoading('user'); }));
        this.startLoadMany$ = this.actions$
            .pipe(ofType(UserActionTypes.LoadUsers), map(function (action) { return action.payload; }), filter(function (payload) { return payload.offset === 0; }), map(function () { return new StartLoading('user'); }));
        this.stopLoad$ = this.actions$
            .pipe(ofType(UserActionTypes.AddUsersSuccess, UserActionTypes.AddUsersFail, UserActionTypes.LoadUserSuccess, UserActionTypes.LoadUserFail, UserActionTypes.UpdateUserSuccess, UserActionTypes.UpdateUserFail, UserActionTypes.DeleteUserSuccess, UserActionTypes.DeleteUserFail, UserActionTypes.LoadUsersSuccess, UserActionTypes.LoadUsersFail), map(function () { return new StopLoading('user'); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "add$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "loadUserProfileSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "loadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "delete$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "startLoad$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "startLoadMany$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "stopLoad$", void 0);
    return UserEffects;
}());
export { UserEffects };

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@store/reducers/index';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IWSLAsyncErrors, WSLHttpHelper } from 'wsl-core';
import { WSLMaterializeHelper } from 'wsl-shared';

@Component({
  selector: 'wsl-confirm-registration-page',
  templateUrl: './confirm-registration.page.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmRegistrationPage implements OnInit {
  /** @internal */
  pending$: Observable<boolean>;
  /** @internal */
  errors$: Observable<IWSLAsyncErrors>;
  /** @internal */
  confirmed = false;
  private queryParams = {};
  constructor(private store$: Store<fromRoot.State>,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private chr: ChangeDetectorRef) {
    this.pending$ = store$.pipe(select(fromRoot.selectPending));
    this.errors$ = store$.pipe(select(fromRoot.selectSnackBarMessage));
  }

  ngOnInit() {
    const token = this.route.snapshot.queryParams['token'];
    const uid = this.route.snapshot.queryParams['uid'];
    if (!token || !uid) {
      WSLMaterializeHelper.toast({html: 'Ссылка не правильная', displayLength: 2000});
      setTimeout(() => this.router.navigateByUrl('login'), 3000);
    } else {
      Object.assign(this.queryParams, {
        token,
        uid
      });
      this.authService.confirmRegistration(this.queryParams)
        .pipe(
          take(1)
        )
        .subscribe(
          res => {
            this.confirmed = true;
            this.chr.markForCheck();
          },
          err => {
            const error = WSLHttpHelper.parseHTTPErrorResponse(err);
            const message = error.view ? error.message : 'Ссылка не правильная';
            WSLMaterializeHelper.toast({html: message, displayLength: 2000});
            setTimeout(() => this.router.navigateByUrl('login'), 500);
          }
        );
    }
  }

}

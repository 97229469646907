<div class="wsl-text-left wsl-relative">
    <label class="wsl-text-nowrap" *ngIf="label" [attr.for]="inputId" [class.active]="value">{{label}}<span
        class="wsl-red" *ngIf="required" matTooltip="поле обязательное">*</span> </label>
    <ng-content></ng-content>

    <ng-container *ngIf="showClear && !readonly">
        <!--<div [class.wsl-hide]="isEmpty" (click)="clear.emit()"
             class="icon-cross wsl-red wsl-hover wsl-st4 wsl-abs wsl-abs-right wsl-abs-bottom wsl-m-b-2 wsl-m-r-5"></div>-->
        <div class="input-icon" [class.wsl-hide]="isEmpty || readonly" (click)="onClear()"><a class="icon-cross wsl-st5 wsl-red wsl-hover"></a></div>
    </ng-container>

    <!--<span *ngIf="!readonly" class="helper-text" [@state]="!!message" [attr.data-error]="message">Helper text</span>
    <span *ngIf="!readonly" class="helper-text" [@state]="!!asyncError" [attr.data-error]="asyncError">Helper text</span>-->
    <ng-container [ngSwitch]="position">
        <ng-container *ngSwitchCase="'pop-up'">
            <div class="wsl-abs wsl-bg-red wsl-p-a-5 wsl-m-t-2" style="z-index: 1;" *ngIf="!readonly && ((isTouched && message) || asyncError)">
                <div class="wsl-small wsl-white" [@state]="isTouched">{{message}}</div>
                <div class="wsl-small wsl-white" [@state]="!!asyncError">{{asyncError}}</div>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="wsl-abs" *ngIf="!readonly">
                <div class="wsl-small wsl-red wsl-text-right" [@state]="isTouched">{{message}}</div>
                <div class="wsl-small wsl-red wsl-text-right" [@state]="!!asyncError">{{asyncError}}</div>
            </div>
        </ng-container>
    </ng-container>
</div>

import { EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
var ResourceToPortComponent = /** @class */ (function () {
    function ResourceToPortComponent() {
        this.prefix = '';
        this.showNone = false;
        this.selectItem = new EventEmitter();
        /** @internal */
        this.form = new FormGroup({
            resource_id: new FormControl(null)
        });
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(ResourceToPortComponent.prototype, "disabled", {
        set: function (isDisable) {
            if (isDisable) {
                this.form.disable();
            }
            else {
                this.form.enable();
            }
        },
        enumerable: true,
        configurable: true
    });
    ResourceToPortComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.get('resource_id').setValue(this.selectedResource);
        this.form.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), debounceTime(250), distinctUntilChanged())
            .subscribe(function (val) {
            _this.selectItem.emit(val.resource_id);
        });
    };
    ResourceToPortComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectedResource && this.form.get('resource_id').value !== this.selectedResource) {
            this.form.get('resource_id').setValue(this.selectedResource);
        }
    };
    ResourceToPortComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    return ResourceToPortComponent;
}());
export { ResourceToPortComponent };

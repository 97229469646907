import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromDeviceConfExt from '@store/device-conf-ext/device-conf-ext.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DeviceConfExtEffects } from '@store/device-conf-ext/device-conf-ext.effects';
import { DeviceConfExtService } from './services/device-conf-ext.service';
import { WslSharedModule } from 'wsl-shared';
import { AppCommonModule } from '@common/app-common.module';
import { DeviceConfExtRowComponent } from './components/device-conf-ext-row/device-conf-ext-row.component';
import { DeviceConfExtListComponent } from './components/device-conf-ext-list/device-conf-ext-list.component';
import { DeviceConfExtEditRowComponent } from './components/device-conf-ext-edit-row/device-conf-ext-edit-row.component';
import {DeviceConfExtSensorInfoComponent} from "@app/device-conf-ext/components/device-conf-ext-sensor-info/device-conf-ext-sensor-info.component";

const COMPONENTS = [
  DeviceConfExtRowComponent,
  DeviceConfExtListComponent,
  DeviceConfExtEditRowComponent,
  DeviceConfExtSensorInfoComponent
];

@NgModule({
  imports: [
    WslSharedModule,
    AppCommonModule,
    StoreModule.forFeature('deviceConfExt', fromDeviceConfExt.reducer),
    EffectsModule.forFeature([DeviceConfExtEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    DeviceConfExtService
  ]
})
export class DeviceConfExtModule {
}

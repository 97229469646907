import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { BillingCompanyLinkService } from '@app/billing-company/services/billing-company-link.service';
import { select, Store } from '@ngrx/store';
import { SetSnackBarMessage } from '@store/actions/app.actions';
import { LoadBCompanyLinks, LoadBCompanyLinksSuccess } from '@store/billing/billing-company-link.actions';
import * as fromBilling from '@store/billing/billing.reducer';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { WSLHttpHelper } from 'wsl-core';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../services/billing-company-link.service";
var HasBCompanyLinkGuard = /** @class */ (function () {
    function HasBCompanyLinkGuard(store$, billingCompanyLinkService, waitTimeout) {
        if (waitTimeout === void 0) { waitTimeout = 1000; }
        this.store$ = store$;
        this.billingCompanyLinkService = billingCompanyLinkService;
        this.waitTimeout = waitTimeout;
    }
    /**
     * This method creates an observable that waits for the `loaded` property
     * of the collection state to turn `true`, emitting one time once loading
     * has finished.
     */
    HasBCompanyLinkGuard.prototype.waitForCollectionToLoad = function () {
        return this.store$
            .pipe(select(fromBilling.selectBillingCompanyLinksLoaded), filter(function (loaded) { return !!loaded; }), take(1), timeout(500), catchError(function () { return of(false); }));
    };
    /**
     * This method checks if a links already
     * in the Store
     */
    HasBCompanyLinkGuard.prototype.hasInStore = function () {
        var _this = this;
        return this.store$
            .select(fromBilling.selectBillingCompanyLinks)
            .pipe(tap(function (items) {
            if (items.length === 0) {
                _this.store$.dispatch(new LoadBCompanyLinks({}));
            }
        }), map(function (items) { return items.length > 0; }), take(1));
    };
    /**
     * This method loads a links from the API and caches
     * it in the store, returning `true` or `false` if it was found.
     */
    HasBCompanyLinkGuard.prototype.hasInApi = function () {
        var _this = this;
        return this.billingCompanyLinkService
            .getMany({})
            .pipe(
        // map(deviceEntity => new LoadDeviceSuccess(deviceEntity)),
        //  tap((action: LoadDeviceSuccess) => this.store.dispatch(action)),
        tap(function (resp) { return _this.store$.dispatch(new LoadBCompanyLinksSuccess(resp)); }), map(function (resp) { return resp.items; }), map(function (items) { return items.length > 0; }), catchError(function (response) {
            // this.router.navigate(['/404']);
            return of(false);
        }));
    };
    /**
     * `hasEntities` composes `hasInStore` and `hasInApi`. It first checks
     * if the links is in store, and if not it then checks if it is in the
     * API.
     */
    HasBCompanyLinkGuard.prototype.hasEntities = function (collectionLoaded) {
        var _this = this;
        if (collectionLoaded) {
            return this.hasInStore()
                .pipe(switchMap(function (inStore) {
                if (inStore) {
                    return of(inStore);
                }
                return _this.hasInApi();
            }));
        }
        return this.hasInApi();
    };
    HasBCompanyLinkGuard.prototype.check = function () {
        var _this = this;
        return this.waitForCollectionToLoad()
            .pipe(switchMap(function (loaded) { return _this.hasEntities(loaded); }));
    };
    /**
     * This is the actual method the router will call when our guard is run.
     *
     * Our guard waits for the collection to load, then it checks if we need
     * to request a book from the API or if we already have it in our cache.
     * If it finds it in the cache or in the API, it returns an Observable
     * of `true` and the route is rendered successfully.
     *
     * If it was unable to find it in our cache or in the API, this guard
     * will return an Observable of `false`, causing the router to move
     * on to the next candidate route. In this case, it will move on
     * to the 404 page.
     */
    HasBCompanyLinkGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.check()
            .pipe(tap(function (res) {
            if (!res) {
                _this.store$.dispatch(new SetSnackBarMessage(WSLHttpHelper.parseAsyncMessage('Нет договоров с биллинговыми компаниями')));
                //   this.router.navigate([next.data.redirectUrl ? next.data.redirectUrl : '/']);
                return of(false);
            }
        }));
    };
    HasBCompanyLinkGuard.prototype.canActivateChild = function (next, state) {
        var _this = this;
        return this.check()
            .pipe(tap(function (res) {
            if (!res) {
                _this.store$.dispatch(new SetSnackBarMessage(WSLHttpHelper.parseAsyncMessage('Нет договоров с биллинговыми компаниями')));
                // this.router.navigate([next.data.redirectUrl ? next.data.redirectUrl : '/']);
                return of(false);
            }
        }));
    };
    HasBCompanyLinkGuard.ngInjectableDef = i0.defineInjectable({ factory: function HasBCompanyLinkGuard_Factory() { return new HasBCompanyLinkGuard(i0.inject(i1.Store), i0.inject(i2.BillingCompanyLinkService), i0.inject("waitTimeout", 8)); }, token: HasBCompanyLinkGuard, providedIn: "root" });
    return HasBCompanyLinkGuard;
}());
export { HasBCompanyLinkGuard };

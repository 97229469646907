import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AddDeviceConfExt,
  AddDeviceConfExtFail,
  AddDeviceConfExtSuccess,
  DeleteDeviceConfExt,
  DeleteDeviceConfExtFail,
  DeleteDeviceConfExtSuccess,
  DeviceConfExtActionTypes,
  LoadDeviceConfExt,
  LoadDeviceConfExtFail,
  LoadDeviceConfExts,
  LoadDeviceConfExtsFail,
  LoadDeviceConfExtsSuccess,
  LoadDeviceConfExtSuccess,
  UpdateDeviceConfExt,
  UpdateDeviceConfExtFail,
  UpdateDeviceConfExtSuccess
} from './device-conf-ext.actions';
import { catchError, exhaustMap, filter, map, mergeMap, skip, switchMap, takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '@store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreHelperService } from '@core/services/store-helper.service';
import { DeviceConfExtService } from '@app/device-conf-ext/services/device-conf-ext.service';

@Injectable()
export class DeviceConfExtEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DeviceConfExtActionTypes.AddDeviceConfExt),
    map((action: AddDeviceConfExt) => action.payload),
    exhaustMap(payload => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.AddDeviceConfExt), skip(1));

      return this.communicatorService.add(payload)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, AddDeviceConfExtSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, AddDeviceConfExtFail))
        );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(DeviceConfExtActionTypes.UpdateDeviceConfExt),
    map((action: UpdateDeviceConfExt) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.UpdateDeviceConfExt), skip(1));

      return this.communicatorService.update(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, UpdateDeviceConfExtSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, UpdateDeviceConfExtFail))
        );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(DeviceConfExtActionTypes.LoadDeviceConfExt),
    map((action: LoadDeviceConfExt) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.LoadDeviceConfExt), skip(1));

      return this.communicatorService.get(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceConfExtSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceConfExtFail))
        );
    })
  );

  @Effect()
  loadMany$ = this.actions$.pipe(
    ofType(DeviceConfExtActionTypes.LoadDeviceConfExts),
    map((action: LoadDeviceConfExts) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.LoadDeviceConfExts), skip(1));

      return this.communicatorService.getMany(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, LoadDeviceConfExtsSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, LoadDeviceConfExtsFail))
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeviceConfExtActionTypes.DeleteDeviceConfExt),
    map((action: DeleteDeviceConfExt) => action.payload),
    switchMap(data => {
      const nextLoad$ = this.actions$.pipe(ofType(DeviceConfExtActionTypes.DeleteDeviceConfExt), skip(1));

      return this.communicatorService.delete(data)
        .pipe(
          takeUntil(nextLoad$),
          mergeMap(resp => this.storeHelper.successHandler(resp, DeleteDeviceConfExtSuccess)),
          catchError(resp => this.storeHelper.errorHandler(resp, DeleteDeviceConfExtFail))
        );
    })
  );

  @Effect()
  startLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceConfExtActionTypes.AddDeviceConfExt,
        DeviceConfExtActionTypes.LoadDeviceConfExt,
        DeviceConfExtActionTypes.UpdateDeviceConfExt,
        DeviceConfExtActionTypes.DeleteDeviceConfExt
      ),
      map(() => new StartLoading('device_conf_ext'))
    );

  @Effect()
  startLoadMany$ = this.actions$
    .pipe(
      ofType(DeviceConfExtActionTypes.LoadDeviceConfExts),
      map((action: LoadDeviceConfExts) => action.payload),
      filter((payload) => payload.offset === 0),
      map(() => new StartLoading('device_conf_ext'))
    );


  @Effect()
  stopLoad$ = this.actions$
    .pipe(
      ofType(
        DeviceConfExtActionTypes.AddDeviceConfExtSuccess, DeviceConfExtActionTypes.AddDeviceConfExtFail,
        DeviceConfExtActionTypes.LoadDeviceConfExtSuccess, DeviceConfExtActionTypes.LoadDeviceConfExtFail,
        DeviceConfExtActionTypes.UpdateDeviceConfExtSuccess, DeviceConfExtActionTypes.UpdateDeviceConfExtFail,
        DeviceConfExtActionTypes.DeleteDeviceConfExtSuccess, DeviceConfExtActionTypes.DeleteDeviceConfExtFail,
        DeviceConfExtActionTypes.LoadDeviceConfExtsSuccess, DeviceConfExtActionTypes.LoadDeviceConfExtsFail
      ),
      map(() => new StopLoading('device_conf_ext'))
    );

  constructor(private actions$: Actions,
              private router: Router,
              private route: ActivatedRoute,
              private storeHelper: StoreHelperService,
              private communicatorService: DeviceConfExtService) {
  }
}

<label class="wsl-m-r-5" *ngIf="showLabel">{{label}}<span class="wsl-red" *ngIf="required"
                                                          matTooltip="поле обязательное">*</span></label>
<div class="wsl-select-dropdown">
    <div class="wsl-flex-nowrap-ng" *ngIf="isBlock; else isLine">
        <div class="wsl-flex-center-middle-ng wsl-p-x-10 wsl-bg-red_hover" (click)="onClear()" [class.wsl-hide]="disabled || isEmpty || !showClear">
            <span class="icon-cross wsl-white wsl-st5"></span>
        </div>
        <div class="wsl-p-x-15 wsl-p-y-8 {{prefix}}_dropdown_trigger"
             [ngClass]="{'wsl-bg-gray-lightest_hover' : !showVariants && selectedValue.length < 1, 'wsl-bg-gray_hover wsl-white' : showVariants || selectedValue.length > 0}"
             [attr.data-target]="'dropdown_'+prefix"
             (click)="onShowVariants()">
            <div class="wsl-overflow-hidden">
                <div class="wsl-auto-grid">
                    <div class="wsl-grid-item wsl-text-overflow wsl-relative wsl-p-r-20">
                        <!--<a class="icon-cross wsl-m-r-10 wsl-white wsl-hover" [class.wsl-hide]="isRadio && disabled"></a>-->
                        <span class="wsl-m-r-5">{{selectedValue.length > 0 ? selectedValue : (showLabel ? 'выберите' : label)}}</span>
                        <span class="icon-chevron-down wsl-abs wsl-abs-right wsl-m-r-0" [class.wsl-hide]="isRadio && disabled"
                              [ngClass]="{'wsl-btn-dropdown': !showVariants, 'wsl-btn-dropdown-open': showVariants}"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #isLine>
        <div class="wsl-flex-middle-nowrap-ng">
            <a class="icon-cross wsl-m-r-10 wsl-red wsl-hover" [class.wsl-hide]="disabled || isEmpty  || !showClear" (click)="onClear()"></a>
            <div (click)="onShowVariants()" class="wsl-overflow-hidden {{prefix}}_dropdown_trigger"
                 [attr.data-target]="'dropdown_'+prefix">
                <div class="wsl-auto-grid">
                    <div class="wsl-grid-item wsl-text-overflow wsl-relative wsl-p-r-20">
                        <ng-container *ngIf="disabled && isRadio; else activeDropAction">
                            <span>{{!!selectedValue ? selectedValue : (showLabel ? ' не выбрано' : label)}}</span>
                        </ng-container>
                        <ng-template #activeDropAction>
                            <a [class.wsl-gray]="disabled">{{selectedValue.length > 0 ? selectedValue : (showLabel ? 'выберите' : label)}}</a>
                            <a class="icon-chevron-down wsl-abs wsl-abs-right wsl-m-r-0"
                               [class.wsl-hide]="isRadio && disabled"
                               [class.wsl-gray]="disabled"
                               [ngClass]="{'wsl-btn-dropdown': !showVariants, 'wsl-btn-dropdown-open': showVariants}"></a>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div [attr.id]="'dropdown_'+prefix" class="dropdown-content" style="overflow-y: hidden;">

        <div class="wsl-flex-column-ng wsl-h-100">
            <div class="wsl-p-x-15 wsl-p-y-10 wsl-border-b wsl-bg-gray-lightest" *ngIf="showSearch">
                <div class="wsl-relative">
                    <input class="form-control"
                           type="text"
                           [disabled]="disabled"
                           [(ngModel)]="search"
                           (ngModelChange)="onWriteSearch($event)">
                    <div class="input-icon" [class.wsl-hide]="search==='' || disabled" (click)="onClearSearch()"><a class="icon-cross wsl-st5 wsl-red wsl-hover"></a></div>
                </div>
            </div>

            <div class="wsl-col" style="max-height: 400px; flex-grow: 10; overflow-y: auto;"  >
                <div class="wsl-text-nowrap" style="outline: none;" #container wslSpyScroll (scrolled)="onScroll($event)"
                     *ngFor="let option of viewItems; trackBy: trackById" #row>
                    <ng-container *ngIf="isRadio; else isCheckbox">
                        <div class="wsl-flex-middle-nowrap-ng">
                            <div class="wsl-col">
                                <label [attr.for]="prefix+'_select_radio_item_'+option.id"
                                       class="wsl-p-x-10 wsl-p-y-5 wsl-w-100 wsl-hover wsl-relative"
                                       [ngClass]="{'wsl-bg-blue wsl-white': option.id === value, 'wsl-bg-white_hover': option.id !== value}">
                                    <input class="" type="radio" [(ngModel)]="value" [value]="option.id"
                                           [disabled]="disabled"
                                           [attr.id]="prefix+'_select_radio_item_'+option.id">
                                    <span [innerHTML]="option.name"></span>
                                    <style>
                                        .wsl-select-dropdown [type="radio"]:not(:checked)+span, [type="radio"]:checked+span {
                                            padding-left: 0px;
                                        }
                                        .wsl-select-dropdown [type="radio"]+span:before, [type="radio"]+span:after {
                                            display: none;
                                        }
                                    </style>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #isCheckbox>
                        <div class="wsl-flex-middle-nowrap-ng">
                            <div class="wsl-col">
                                <label [attr.for]="prefix+'_select_checkbox_item_'+option.id"
                                       class="wsl-p-x-10 wsl-p-y-5 wsl-w-100 wsl-hover wsl-relative"
                                       [ngClass]="{'wsl-bg-white_hover': option.id !== value}">
                                    <input type="checkbox" class="filled-in"
                                           [attr.id]="prefix+'_select_checkbox_item_'+option.id"
                                           [disabled]="disabled"
                                           [ngModel]="value.indexOf(option.id) >= 0"
                                           (ngModelChange)="onToggle($event, option.id)"/>
                                    <span [innerHTML]="option.name"></span>
                                </label>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div class="wsl-text-nowrap" style="outline: none;" *ngIf="pending">
                    <mat-progress-bar mode="buffer"></mat-progress-bar>
                </div>
            </div>

            <div class="wsl-flex-spaceBetween-nowrap-ng wsl-p-x-15 wsl-p-y-10 wsl-border-t" *ngIf="showButtons">
                <div class="btn btn-small wsl-m-r-15" (click)="onAccept()">Применить</div>
                <div class="btn btn-small wsl-bg-red_hover" (click)="onClear()">Сбросить</div>
            </div>
        </div>

    </div>
</div>

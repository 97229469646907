import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { catchError, filter, map, take } from 'rxjs/operators';
import { CompanyProfileService } from 'wsl-ek-core';
import { DataConfTypeService } from '../services/data-conf-type.service';
import {environment} from "@env/environment";

@Injectable()
export class DataConfTypesResolver implements Resolve<any> {
  constructor(private router: Router,
              private companyProfileService: CompanyProfileService,
              private dataConfTypeService: DataConfTypeService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if (environment.kv) {
      return [];
    }
    return this.dataConfTypeService.get()
      .pipe(
        filter(res => res.items.length > 0),
        take(1),
        map(res => res.items),
        catchError(() => EMPTY)
      );
  }
}

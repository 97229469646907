<form class="wsl-flex-column-nrg wsl-m-t-20"
      [formGroup]="form"
      novalidate>

  <wsl-error-message [message]="asyncErrors?.get('common')"></wsl-error-message>

  <ng-container *ngIf="isMyProfile && appEnv.isUc">
    <div class="wsl-col">
      <wsl-select label="Компания по умолчанию"
                  prefix="profile_default_company"
                  [items]="availableCompanies"
                  formControlName="default_company_id"></wsl-select>
    </div>

    <div class="wsl-col">
      <wsl-select label="Город по умолчанию"
                  prefix="profile_default_town"
                  [items]="companyTowns"
                  formControlName="default_town_id"
      ></wsl-select>
    </div>
  </ng-container>
  <ng-container *ngIf="isMyProfile && appEnv.isKv">
    <div class="wsl-col">
      <wsl-select label="Помещение по умолчанию"
                  prefix="profile_default_room_select"
                  [items]="availableRooms"
                  formControlName="default_room_id"></wsl-select>

    </div>
  </ng-container>

  <wsl-select-radio-checkbox *ngIf="multipleRoles; else singleRole"
                             class="wsl-col-nogrow wsl-flex-ng"
                             prefix="profile_roles"
                             [isIcons]="false"
                             [isRadio]="false"
                             [readonly]="form.get('role_ids').disabled"
                             [items]="roles"
                             [selected]="form.get('role_ids').value"
                             (selectItem)="onSelectRoles($event)"></wsl-select-radio-checkbox>

  <ng-template #singleRole>
    <wsl-select-radio-checkbox class="wsl-col-nogrow wsl-flex-ng"
                               prefix="profile_roles"
                               [isIcons]="false"
                               [isRadio]="true"
                               [readonly]="form.get('role_ids').disabled"
                               [items]="roles"
                               [selected]="form.get('role_ids').value[0]"
                               (selectItem)="onSelectRole($event)"></wsl-select-radio-checkbox>
  </ng-template>


  <div class="wsl-col">

    <label for="role_reader" *ngIf="showReader">
      <input type="checkbox" class="filled-in" id="role_reader" formControlName="role_reader">
      <span>Только просмотр</span>
    </label>
  </div>

  <div class="wsl-col">
    <label for="role_demo" *ngIf="showDemo">
      <input type="checkbox" class="filled-in" id="role_demo" formControlName="role_demo">
      <span>Демо доступ</span>
    </label>
  </div>

  <div class="wsl-col" *ngIf="isMyProfile">
    <wsl-input-wrapper label="Новый пароль" inputId="profile_password"
                       [asyncError]="asyncErrors?.get('password')">
      <input id="profile_password"
             type="password"
             class="form-control"
             formControlName="password">
    </wsl-input-wrapper>
  </div>

  <div class="wsl-col" *ngIf="isMyProfile">
    <wsl-input-wrapper label="Пароль еще раз" inputId="profile_confirm_password"
                       [asyncError]="asyncErrors?.get('password_confirmation')">
      <input id="profile_confirm_password"
             type="password"
             class="form-control"
             formControlName="password_confirmation">
      <div class="wsl-abs wsl-w-100" *ngIf="form.get('password_confirmation').touched">
        <div class="wsl-small wsl-red wsl-text-right" *ngIf="form.hasError('mismatchedPasswords')">
          Пароли не совпадают
        </div>
      </div>
    </wsl-input-wrapper>
  </div>

  <div class="wsl-col" *ngIf="isAdmin || isMyProfile">
    <div class="wsl-flex-right-ng">
      <a class="wsl-block wsl-btn-round wsl-white wsl-m-x-5 z-depth-1"
         [ngClass]="{'wsl-bg-green_hover': form.dirty, 'wsl-bg-gray_hover': !form.dirty}"
         (click)="onSave()" matTooltip="Сохранить" matTooltipPosition="left">
        <span class="icon-check"></span>
      </a>
      <a class="wsl-block wsl-btn-round wsl-white wsl-m-x-5 z-depth-1"
         [ngClass]="{'wsl-bg-cyan_hover': form.dirty, 'wsl-bg-gray_hover': !form.dirty}"
         (click)="onCancel()" matTooltip="Отменить" matTooltipPosition="left">
        <span class="icon-undo2"></span>
      </a>
    </div>
  </div>
</form>
